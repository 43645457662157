import React, { useState } from 'react';
import clsx from 'clsx';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Container,
  Typography,
  makeStyles,
  Grid,
  Button
} from '@material-ui/core';

import { ROUTES, ROUTES_AUTHEN } from 'src/constants';
import LoginForm from './LoginForm';
import { useSelector } from 'react-redux';
import { settingSelector } from 'src/slices/settings';

// eslint-disable-next-line arrow-parens
const useStyles = makeStyles((theme) => ({
  root: {
    justifyContent: 'center',
    display: 'flex',
    flexDirection: 'column',
    paddingTop: 100,
    [theme.breakpoints.down(700)]: {
      padding: '80px 5px 0'
    }
  },
  loginBorderBottom: {
    borderBottom: '1px solid #919090'
  },
  loginTitleText: {
    fontWeight: 'bold',
    marginBottom: 6,
    fontSize: 25,
    [theme.breakpoints.down(700)]: {
      fontSize: 20
    }
  },
  loginSubTitleText: {
    [theme.breakpoints.down(700)]: {
      marginBottom: 20
    }
  },
  loginDescription: {
    padding: '5px 0'
  },
  font18: {
    fontSize: 18
  },
  loginTitle: {
    borderBottom: '1px solid #707070',
    width: 'fit-content',
    marginBottom: 10
  },
  loginButtonForgot: {
    backgroundColor: '#006EB1',
    color: '#FFFFFF',
    padding: '9px 46px',
    borderRadius: 0,
    [theme.breakpoints.down(700)]: {
      padding: '4px 20px',
      fontSize: 16
    }
  },
  loginButtonMember: {
    backgroundColor: '#006EB1',
    color: '#FFFFFF',
    width: '100%',
    padding: '10px 0',
    borderRadius: 0,
    [theme.breakpoints.down(700)]: {
      padding: '5px 0',
      fontSize: 16
    }
  },
  buttonHover: {
    color: '#fff !important',
    '&:hover': {
      backgroundColor: 'rgb(34 152 221);'
    }
  }
}));

function LoginView() {
  const classes = useStyles();
  const navigate = useNavigate();

  const settings = useSelector(settingSelector);

  const [hoverButton1, setHoverButton1] = useState(false);
  const [hoverButton2, setHoverButton2] = useState(false);

  const handleSubmitSuccess = () => {
    navigate(ROUTES.HOMEVIEW);
  };

  return (
    <div className={classes.root}>
      <Container maxWidth="md">
        <Box
          sx={{ justifyContent: 'center' }}
          mb={5}
          display="flex"
          alignItems="center"
          className={clsx(classes.loginBorderBottom)}
        >
          <Typography className={clsx(classes.loginTitleText)}>
            ログイン
          </Typography>
        </Box>

        <Box display="flex" sx={{ justifyContent: 'center' }}>
          <Grid item xs={12} sm={7}>
            <LoginForm onSubmitSuccess={handleSubmitSuccess} />
          </Grid>
        </Box>

        <Box
          mt={window.innerWidth <= 700 ? 10 : 15}
          className={clsx(classes.loginBorderBottom)}
        >
          <Typography
            className={clsx(
              classes.loginDescription,
              classes.loginTitle,
              classes.font18
            )}
          >
            ID ご登録メールアドレスが不明な方
          </Typography>
          <Typography
            className={clsx(classes.loginDescription, classes.font18)}
          >
            IDが不明な場合はお問い合わせ専用メールフォームより、お問い合わせ項目の「その他」を選択し、本文に、「会員番号照会」、
            氏名、住所、電話番号、生年月日を明記のうえ、ファンクラブまでご連絡ください。
          </Typography>

          <Box mt={2}>
            <Typography
              className={clsx(classes.loginDescription, classes.loginTitle)}
            >
              パスワードが不明な方
            </Typography>
            <Typography className={classes.loginDescription}>
              下記リンクから必要事項を入力のうえ、再設定を行ってください。
            </Typography>
          </Box>

          <Box mt={2} mb={5}>
            <Button
              onClick={() => navigate(ROUTES_AUTHEN.FORGOT_PASSWORD)}
              onMouseOver={() => setHoverButton1(true)}
              onMouseOut={() => setHoverButton1(false)}
              style={{
                backgroundColor: hoverButton1
                  ? settings?.settings?.theme_color1 + 'cc'
                  : settings?.settings?.theme_color1
              }}
              className={clsx(
                classes.loginButtonForgot,
                classes.buttonHover,
                classes.font18
              )}
            >
              パスワード忘れた方はこちら
            </Button>
          </Box>
        </Box>

        <Box
          sx={{ justifyContent: 'center' }}
          mt={10}
          display="flex"
          alignItems="center"
          className={clsx(classes.loginBorderBottom)}
        >
          <Typography
            className={clsx(classes.loginTitleText, classes.loginSubTitleText)}
          >
            まだ会員登録をされていない方
          </Typography>
        </Box>

        <Box mb={20} className={clsx(classes.loginBorderBottom)}>
          <Box
            sx={{ justifyContent: 'center' }}
            display="flex"
            mt={window.innerWidth <= 700 ? 5 : 10}
            mb={window.innerWidth <= 700 ? 5 : 8}
          >
            <Button
              onClick={() => navigate(ROUTES_AUTHEN.MEMBER_BENEFITS)}
              onMouseOver={() => setHoverButton2(true)}
              onMouseOut={() => setHoverButton2(false)}
              style={{
                backgroundColor: hoverButton2
                  ? settings?.settings?.theme_color1 + 'cc'
                  : settings?.settings?.theme_color1
              }}
              className={clsx(
                classes.loginButtonMember,
                classes.buttonHover,
                classes.font18
              )}
            >
              新規登録はこちら
            </Button>
          </Box>
        </Box>
      </Container>
    </div>
  );
}

export default LoginView;
