import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'src/utils/API';

export const getYoutubeById = createAsyncThunk(
  'youtubes/id',
  async ({ id }, { rejectWithValue }) => {
    try {
      const response = await axios.get(`/youtubes/${id}`);

      if (response.status === 200) {
        return response.data.data;
      }
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getYoutube = createAsyncThunk(
  'youtubes',
  async ({ page, perPage, data }, { rejectWithValue }) => {
    try {
      const response =
        page == -1
          ? await axios.get(`/youtubes?perPage=${perPage}`)
          : await axios.get(`/youtubes?page=${page}&perPage=${perPage}`);
      if (response.status === 200) {
        return response.data.data;
      }

      return rejectWithValue(response.data);
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
