import clsx from 'clsx';
import moment from 'moment';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, Container, makeStyles, Typography } from '@material-ui/core';
import ReactPlayer from 'react-player';
import LoaderLayer from 'src/components/LoaderLayer';
import { youtubeSelector } from 'src/slices/youtubes';
import { getYoutubeById } from 'src/services/youtubeService';
import { ROUTES } from 'src/constants';

const useStyles = makeStyles((theme) => ({
  root: {
    justifyContent: 'center',
    display: 'flex',
    flexDirection: 'column',
    marginTop: '2rem',
    [theme.breakpoints.down(700)]: {
      padding: '0 2px 0'
    }
  },
  container: {
    maxWidth: 1040,
    padding: theme.spacing(0, 8),
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(0, 2)
    }
  },
  contentTitle: {
    color: '#000000',
    fontSize: '1.5rem',
    fontWeight: '600',
    [theme.breakpoints.down(700)]: {
      fontSize: '1rem'
    }
  },
  newContent: {
    margin: '1rem 0 3rem',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    [theme.breakpoints.down(700)]: {
      margin: '1rem 0 2rem',
      justifyContent: 'space-evenly'
    }
  },
  wrapPagination: {
    margin: '40px 0'
  },
  boxContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '4rem',
    width: '100%',
    [theme.breakpoints.down(700)]: {
      marginBottom: '1.5rem'
    }
  },
  boxContent: {
    cursor: 'pointer',
    '& > div': {
      maxWidth: '100%',
      width: '100% !important',
      aspectRatio: '4/2.5',
      maxHeight: '100% !important'
    }
  },
  iframe: {
    width: '100% !important',
    height: '100% !important'
  },
  youtubeTitle: {
    marginTop: '0.5rem',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    '& p': {
      fontSize: 18
    },
    [theme.breakpoints.down(700)]: {
      width: '100%',
      marginTop: '0.5rem'
    }
  },
  videoText: {
    fontSize: '1.1rem',
    margin: '0.2rem 0',
    [theme.breakpoints.down(700)]: {
      fontSize: '1rem'
    }
  },
  iconLike: {
    cursor: 'pointer'
  },
  likeText: {
    marginLeft: '0.5rem',
    [theme.breakpoints.down(700)]: {
      fontSize: '0.8rem'
    }
  },
  backButton: {
    width: '100%',
    textAlign: 'center',
    fontSize: 13,
    margin: '30px 0',
    cursor: 'pointer',
    padding: 10
  }
}));

function YoutubeDetail({ setting }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const youtube = useSelector(youtubeSelector);
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getYoutubeById({ id }));
  }, []);

  return (
    <div className={clsx(classes.root)}>
      {!youtube?.loading && (
        <Container className={classes.container}>
          <Box>
            <Typography className={clsx(classes.contentTitle)}>
              YOUTUBE
            </Typography>
          </Box>
          <Box className={clsx(classes.newContent)}>
            <Box className={clsx(classes.boxContainer)}>
              <Box className={clsx(classes.boxContent)}>
                <ReactPlayer
                  url={youtube?.youtubeDetail?.url}
                  playing={false}
                  controls={true}
                  className={classes.iframe}
                />
              </Box>
              <Box className={clsx(classes.youtubeTitle)}>
                <Box flexGrow={1}>
                  <Typography>
                    {moment(youtube?.youtubeDetail?.release_date).format(
                      'YYYY／MM／DD'
                    )}
                  </Typography>
                  <Typography>{youtube?.youtubeDetail?.title}</Typography>
                </Box>
              </Box>
            </Box>
          </Box>
          <div
            className={classes.backButton}
            onClick={() => {
              navigate(-1);
            }}
          >
            一覧へ戻る
          </div>
        </Container>
      )}
      <LoaderLayer loading={youtube?.loading} />
    </div>
  );
}

export default YoutubeDetail;
