import React, { useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import _ from 'lodash';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {
  Box,
  Container,
  Typography,
  makeStyles,
  Button,
  TextField
} from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { ROUTES, ROUTES_AUTHEN } from 'src/constants';
import LoaderLayer from 'src/components/LoaderLayer';
import { productSelector } from 'src/slices/product';
import { getProducts, payment } from 'src/services/productService';
import { submitPayment } from 'src/utils/payment';
import { getUserProfile } from 'src/services/authService';
import PaymentDialog from 'src/components/PaymentDialog';
import { sendMessageToReactNative } from 'src/components/WebReactNativeCommunicate';
import { settingSelector } from 'src/slices/settings';
import { checkNameTalent } from 'src/utils/auth';

const useStyles = makeStyles((theme) => ({
  root: {
    justifyContent: 'center',
    display: 'flex',
    flexDirection: 'column',
    padding: '100px 5px 0'
  },
  registerBorderBottom: {
    borderBottom: '1px solid #919090'
  },
  wrapTitle: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    [theme.breakpoints.down(700)]: {
      flexDirection: 'column'
    }
  },
  registerTitle: {
    fontWeight: 'bold',
    fontSize: 22,
    marginBottom: 5,
    [theme.breakpoints.down('700')]: {
      fontSize: 18
    }
  },
  registerTitleText: {
    fontWeight: 'bold',
    fontSize: 20,
    [theme.breakpoints.down(700)]: {
      fontSize: '1rem'
    }
  },
  wrapTitleRight: {
    display: 'flex',
    flexDirection: 'row',
    '& p': {
      fontSize: 16,
      opacity: 0.3
    },
    [theme.breakpoints.down(700)]: {
      '& p': {
        fontSize: '0.8rem',
        marginTop: 10
      }
    }
  },
  registrationTitleActive: {
    color: '#000000',
    borderBottom: '2px solid #5D7BAB',
    opacity: '1 !important',
    marginRight: 20,
    [theme.breakpoints.down(700)]: {
      marginRight: 10
    }
  },
  titleRightText: {
    marginRight: 20,
    [theme.breakpoints.down(700)]: {
      marginRight: 10
    }
  },
  displayFlex: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down(700)]: {
      flexDirection: 'column',
      alignItems: 'start',
      display: 'block'
    }
  },
  displayFlexSP: {
    width: '75%',
    [theme.breakpoints.down(700)]: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      width: '100%'
    }
  },
  wrapButton: {
    display: 'flex',
    justifyContent: 'center',
    margin: '5rem 0 2rem',
    fontSize: 18,
    [theme.breakpoints.down(700)]: {
      flexDirection: 'column-reverse',
      margin: '2rem 0'
    }
  },
  buttonBack: {
    backgroundColor: '#A7A7A7',
    color: '#fff !important',
    borderRadius: 0,
    padding: '11px 100px',
    marginRight: '2rem',
    fontSize: 18,
    '&:hover': {
      backgroundColor: '#5b5b5b'
    },
    [theme.breakpoints.down(700)]: {
      padding: '8px 0',
      fontSize: 16,
      width: '100%'
    }
  },
  buttonEdit: {
    color: '#fff !important',
    borderRadius: 0,
    padding: '11px 100px',
    fontSize: 18,
    [theme.breakpoints.down(700)]: {
      padding: '8px 0',
      fontSize: 16,
      width: '100%',
      marginBottom: '1rem'
    }
  },
  textColor: {
    color: 'red',
    [theme.breakpoints.down(700)]: {
      fontSize: '0.88rem'
    }
  },
  input25: {
    width: '18%',
    [theme.breakpoints.down(700)]: {
      width: '30%'
    }
  },
  input50: {
    width: '15%',
    [theme.breakpoints.down(700)]: {
      width: '35%'
    }
  },
  labelContainer: {
    width: '25%',
    justifyContent: 'flex-end',
    [theme.breakpoints.down(700)]: {
      width: '100%',
      justifyContent: 'flex-start',
      marginBottom: 14
    }
  },
  label: {
    fontWeight: 'bold',
    marginRight: 6
  },
  labelRequire: {
    backgroundColor: '#E02323',
    marginRight: 10,
    padding: '0 14px',
    color: '#ffffff',
    borderRadius: 12
  },
  wrapInput: {
    color: 'black',
    height: 38,
    backgroundColor: '#FFFFFF',
    '& input': {
      padding: '10px 0 10px 10px'
    }
  },
  inputContainer: {
    marginRight: 16
  },
  labelBirthday: {
    marginRight: 20,
    marginLeft: '0.8rem'
  },
  textError: {
    color: 'red',
    fontSize: '0.9rem',
    [theme.breakpoints.down(700)]: {
      fontSize: 12,
      marginTop: 8
    }
  },
  boxWrap: {
    textAlign: 'center',
    border: '1px solid',
    marginTop: '1rem',
    '& p': {
      padding: '0.5rem 1rem'
    }
  }
}));

function CreditCardInputInfo() {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { talentName } = checkNameTalent();

  const product = useSelector(productSelector);
  const settings = useSelector(settingSelector);

  const [hover, setHover] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [titleDialog, setTitleDialog] = useState('');
  const [disabledButton, setDisableButton] = useState(false);

  const number2 = useRef();
  const number3 = useRef();
  const number4 = useRef();
  const inputYear = useRef();
  const inputMonth = useRef();

  useEffect(() => {
    dispatch(getProducts('subscription'));
  }, []);

  const formik = useFormik({
    initialValues: {
      card_number_1: '',
      card_number_2: '',
      card_number_3: '',
      card_number_4: '',
      card_expire_year: '',
      card_expire_month: ''
    },
    validationSchema: Yup.object().shape({
      card_number_1: Yup.string()
        .matches(/^\d+$/, '半角数字を入力してください')
        .max(4, '4桁までです')
        .required('カード番号必須'),
      card_number_2: Yup.string()
        .matches(/^\d+$/, '半角数字を入力してください')
        .max(4, '4桁までです')
        .required('カード番号必須'),
      card_number_3: Yup.string()
        .matches(/^\d+$/, '半角数字を入力してください')
        .max(4, '4桁までです')
        .required('カード番号必須'),
      card_number_4: Yup.string()
        .matches(/^\d+$/, '半角数字を入力してください')
        .max(4, '4桁までです')
        .required('カード番号必須'),
      card_expire_year: Yup.string()
        .matches(/^\d+$/, '半角数字を入力してください')
        .required('カード期限必須'),
      card_expire_month: Yup.string()
        .matches(/^\d+$/, '半角数字を入力してください')
        .required('カード期限必須')
    }),
    onSubmit: async (values) => {
      setDisableButton(true);
      const response = await submitPayment(values);

      if (response?.data.result === '0000') {
        const data = {
          ...response?.data.tokenizedCardObject,
          hc: response?.data.hc,
          telegram_kind: '020',
          product_id: product?.products[0].id
        };

        const { payload } = await dispatch(payment(data));

        if (payload?.msg === 'Success') {
          dispatch(getUserProfile());
          sendMessageToReactNative('log_in');
          navigate(ROUTES_AUTHEN.PAYMENT_COMPLETE);
        } else {
          setDisableButton(false);
          setTitleDialog(payload?.msg);
          setOpenDialog(true);
        }
      } else {
        setDisableButton(false);
        setTitleDialog('カード情報が正しくありません');
        setOpenDialog(true);
      }
    }
  });

  return (
    <div className={classes.root}>
      <Container maxWidth="md">
        <Box mb={8} paddingBottom={window.innerWidth <= 700 ? 4 : 8}>
          <Box className={classes.wrapTitle}>
            <Typography className={classes.registerTitleText}>
              決済情報の入力
            </Typography>
            <Box className={classes.wrapTitleRight}>
              <Typography className={clsx(classes.titleRightText)}>
                会員情報入力
              </Typography>
              <Typography className={classes.titleRightText}>
                入力内容確認
              </Typography>
              <Typography className={classes.registrationTitleActive}>
                会員登録決済
              </Typography>
            </Box>
          </Box>

          <Box
            sx={{ justifyContent: 'center' }}
            mb={5}
            mt={8}
            display="flex"
            alignItems="center"
          >
            <Typography className={clsx(classes.registerTitle)}>
              カード情報を入力し、送信ボタンを押してください。
            </Typography>
          </Box>

          <Box>
            <Typography>
              決済手続き中にネットワークが切断されると、正常に処理ができない場合がございます。
              <br />
              移動中やネットワークが不安定な場所では手続きをされないようにお願いいたします。
            </Typography>
          </Box>

          <Box
            sx={{ justifyContent: 'center' }}
            mb={5}
            mt={8}
            display="flex"
            alignItems="center"
            className={clsx(classes.registerBorderBottom)}
          >
            <Typography className={clsx(classes.registerTitle)}>
              決済情報入力
            </Typography>
          </Box>
          <form onSubmit={formik.handleSubmit}>
            <Box pb={5} className={classes.registerBorderBottom}>
              <Box mb={1} className={clsx(classes.displayFlex)}>
                <Box display={'flex'} className={clsx(classes.labelContainer)}>
                  <Typography className={clsx(classes.label)}>
                    カード番号情報
                  </Typography>
                  <Typography className={clsx(classes.labelRequire)}>
                    必須
                  </Typography>
                </Box>
                <Box
                  display={'flex'}
                  flexGrow={1}
                  className={clsx(classes.displayFlexSP)}
                >
                  <TextField
                    name="card_number_1"
                    value={formik.values.card_number_1}
                    onChange={(event) => {
                      if (event.target.value.length <= 4) {
                        formik.handleChange(event);
                        if (event.target.value.length === 4) {
                          number2.current.focus();
                        }
                      }
                    }}
                    onBlur={formik.handleBlur}
                    type="tel"
                    error={Boolean(
                      formik.touched.card_number_1 &&
                        formik.errors.card_number_1
                    )}
                    className={clsx(classes.inputContainer, classes.input50)}
                    fullWidth
                    required
                    InputProps={{
                      disableUnderline: true,
                      className: classes.wrapInput
                    }}
                  />

                  <TextField
                    name="card_number_2"
                    value={formik.values.card_number_2}
                    onChange={(event) => {
                      if (event.target.value.length <= 4) {
                        formik.handleChange(event);
                        if (event.target.value.length === 4) {
                          number3.current.focus();
                        }
                      }
                    }}
                    inputRef={number2}
                    onBlur={formik.handleBlur}
                    type="tel"
                    error={Boolean(
                      formik.touched.card_number_2 &&
                        formik.errors.card_number_2
                    )}
                    className={clsx(classes.inputContainer, classes.input50)}
                    fullWidth
                    required
                    InputProps={{
                      disableUnderline: true,
                      className: classes.wrapInput
                    }}
                  />

                  <TextField
                    name="card_number_3"
                    value={formik.values.card_number_3}
                    onChange={(event) => {
                      if (event.target.value.length <= 4) {
                        formik.handleChange(event);
                        if (event.target.value.length === 4) {
                          number4.current.focus();
                        }
                      }
                    }}
                    inputRef={number3}
                    onBlur={formik.handleBlur}
                    type="tel"
                    error={Boolean(
                      formik.touched.card_number_3 &&
                        formik.errors.card_number_3
                    )}
                    className={clsx(classes.inputContainer, classes.input50)}
                    fullWidth
                    required
                    InputProps={{
                      disableUnderline: true,
                      className: classes.wrapInput
                    }}
                  />

                  <TextField
                    name="card_number_4"
                    value={formik.values.card_number_4}
                    onChange={(event) => {
                      if (event.target.value.length <= 4) {
                        formik.handleChange(event);
                        if (event.target.value.length === 4) {
                          inputYear.current.focus();
                        }
                      }
                    }}
                    inputRef={number4}
                    onBlur={formik.handleBlur}
                    type="tel"
                    error={Boolean(
                      formik.touched.card_number_4 &&
                        formik.errors.card_number_4
                    )}
                    className={clsx(classes.inputContainer, classes.input50)}
                    fullWidth
                    required
                    InputProps={{
                      disableUnderline: true,
                      className: classes.wrapInput
                    }}
                  />
                </Box>
              </Box>

              <Box mb={2} className={clsx(classes.displayFlex)}>
                <Box display={'flex'} className={clsx(classes.labelContainer)}>
                  <Typography className={clsx(classes.label)}></Typography>
                  <Typography
                    className={clsx(classes.labelRequire)}
                  ></Typography>
                </Box>
                <Box display={'flex'} flexGrow={1}>
                  <Box flexGrow={1}>
                    {formik.touched.card_number_1 &&
                    formik.errors.card_number_1 ? (
                      <Typography className={classes.textError}>
                        {formik.errors.card_number_1}
                      </Typography>
                    ) : formik.touched.card_number_2 &&
                      formik.errors.card_number_2 ? (
                      <Typography className={classes.textError}>
                        {formik.errors.card_number_2}
                      </Typography>
                    ) : formik.touched.card_number_3 &&
                      formik.errors.card_number_3 ? (
                      <Typography className={classes.textError}>
                        {formik.errors.card_number_3}
                      </Typography>
                    ) : formik.touched.card_number_4 &&
                      formik.errors.card_number_4 ? (
                      <Typography className={classes.textError}>
                        {formik.errors.card_number_4}
                      </Typography>
                    ) : (
                      ''
                    )}
                  </Box>
                </Box>
              </Box>

              <Box mb={1} className={clsx(classes.displayFlex)}>
                <Box display={'flex'} className={clsx(classes.labelContainer)}>
                  <Typography className={clsx(classes.label)}>
                    カード期限情報
                  </Typography>
                  <Typography className={clsx(classes.labelRequire)}>
                    必須
                  </Typography>
                </Box>
                <Box
                  className={clsx(classes.displayFlex, classes.displayFlexSP)}
                  flexGrow={1}
                >
                  <TextField
                    name="card_expire_year"
                    value={formik.values.card_expire_year}
                    onChange={(event) => {
                      if (event.target.value.length <= 2) {
                        formik.handleChange(event);
                        if (event.target.value.length === 2) {
                          inputMonth.current.focus();
                        }
                      }
                    }}
                    inputRef={inputYear}
                    onBlur={formik.handleBlur}
                    type="tel"
                    error={Boolean(
                      formik.touched.card_expire_year &&
                        formik.errors.card_expire_year
                    )}
                    fullWidth
                    required
                    className={clsx(classes.input25)}
                    InputProps={{
                      disableUnderline: true,
                      className: classes.wrapInput
                    }}
                  />
                  <Typography className={clsx(classes.labelBirthday)}>
                    年
                  </Typography>
                  <TextField
                    name="card_expire_month"
                    value={formik.values.card_expire_month}
                    onChange={(event) => {
                      if (event.target.value.length <= 2) {
                        formik.handleChange(event);
                      }
                    }}
                    onBlur={formik.handleBlur}
                    inputRef={inputMonth}
                    type="tel"
                    error={Boolean(
                      formik.touched.card_expire_month &&
                        formik.errors.card_expire_month
                    )}
                    className={clsx(classes.input25)}
                    fullWidth
                    required
                    InputProps={{
                      disableUnderline: true,
                      className: classes.wrapInput
                    }}
                  />
                  <Typography className={clsx(classes.labelBirthday)}>
                    月
                  </Typography>
                </Box>
              </Box>

              <Box mb={2} className={clsx(classes.displayFlex)}>
                <Box display={'flex'} className={clsx(classes.labelContainer)}>
                  <Typography className={clsx(classes.label)}></Typography>
                  <Typography
                    className={clsx(classes.labelRequire)}
                  ></Typography>
                </Box>
                <Box display={'flex'} flexGrow={1}>
                  <Box flexGrow={1}>
                    {formik.touched.card_expire_year &&
                    formik.errors.card_expire_year ? (
                      <Typography className={classes.textError}>
                        {formik.errors.card_expire_year}
                      </Typography>
                    ) : formik.touched.card_expire_month &&
                      formik.errors.card_expire_month ? (
                      <Typography className={classes.textError}>
                        {formik.errors.card_expire_month}
                      </Typography>
                    ) : (
                      ''
                    )}
                  </Box>
                </Box>
              </Box>

              <Box pt={2} className={clsx(classes.displayFlex)}>
                <Typography>
                  ※送信ボタンを押される前に必ず、お支払い金額と注意事項をご確認ください。
                  <br />
                  ※送信ボタンを押された時点で全ての内容に同意されたものとし、決済の取り消しはできませんのでご注意ください。
                </Typography>
              </Box>
            </Box>
            <Box display="flex" justifyContent="center">
              <Box className={clsx(classes.boxWrap)}>
                <Typography>
                  {talentName} ジャパン オフィシャル ファンクラブ
                </Typography>
                <Typography>金額：6,600円/年（税込）</Typography>
                <Box
                  onClick={() => {
                    navigate(ROUTES.ABOUT_PAYMENT + '#credit');
                  }}
                  style={{ cursor: 'pointer', textDecoration: 'underline' }}
                >
                  <Typography>
                    支払い時期・方法/サービス提供時期/解約について
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Box className={clsx(classes.wrapButton)}>
              <Button
                onClick={() =>
                  navigate(ROUTES_AUTHEN.UPDATE_PROFILE, {
                    state: {
                      paymentType: '1'
                    }
                  })
                }
                className={clsx(classes.buttonBack)}
              >
                登録情報を変更
              </Button>
              <Button
                type="submit"
                disabled={disabledButton}
                onMouseOver={() => setHover(true)}
                onMouseOut={() => setHover(false)}
                style={{
                  backgroundColor: hover
                    ? settings?.settings?.theme_color1 + 'cc'
                    : settings?.settings?.theme_color1
                }}
                className={clsx(classes.buttonEdit)}
              >
                同意して送信する
              </Button>
            </Box>
          </form>
        </Box>
      </Container>
      <LoaderLayer loading={product?.loading} />
      <PaymentDialog
        open={openDialog}
        onClose={setOpenDialog}
        title={titleDialog}
      />
    </div>
  );
}
export default CreditCardInputInfo;
