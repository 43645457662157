import { createSlice } from '@reduxjs/toolkit';

import { getDownloads } from 'src/services/download';

export const downloadSlice = createSlice({
  name: 'download',
  initialState: {
    loading: false,
    downloads: [],
    errorMessage: ''
  },
  reducers: {
    clearState: (state) => {
      state.loading = false;
      state.downloads = [];
      state.errorMessage = '';
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getDownloads.pending, (state) => {
      state.loading = true;
    }),
      builder.addCase(getDownloads.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.downloads = payload.data;
        return state;
      }),
      builder.addCase(getDownloads.rejected, (state, { payload }) => {
        state.errorMessage = payload?.error;
        state.loading = false;
      });
  }
});

export const downloadSelector = (state) => state.download;
export const reducer = downloadSlice.reducer;
export const { clearState } = downloadSlice.actions;
