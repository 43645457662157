import { createSlice } from '@reduxjs/toolkit';

import { getLoveLetters, sendLoveLetter } from 'src/services/loveLetter';

export const loveLetterSlice = createSlice({
  name: 'loveLetter',
  initialState: {
    loading: false,
    loveLetters: [],
    errorMessage: '',
    currentPage: 1,
    totalPage: 1
  },
  reducers: {
    clearState: (state) => {
      state.loading = false;
      state.loveLetters = [];
      state.errorMessage = '';
      state.currentPage = 1;
      state.totalPage = 1;
      return state;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getLoveLetters.pending, (state) => {
      state.loading = true;
    }),
      builder.addCase(getLoveLetters.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.loveLetters = payload.data;
        state.currentPage = payload.current_page;
        state.totalPage = payload.last_page;
        return state;
      }),
      builder.addCase(getLoveLetters.rejected, (state, { payload }) => {
        state.errorMessage = payload?.error;
        state.loading = false;
      });
    builder.addCase(sendLoveLetter.pending, (state) => {
      state.loading = true;
    }),
      builder.addCase(sendLoveLetter.fulfilled, (state, { payload }) => {
        state.loading = false;
        return state;
      }),
      builder.addCase(sendLoveLetter.rejected, (state, { payload }) => {
        state.errorMessage = payload?.error;
        state.loading = false;
      });
  }
});

export const loveLetterSelector = (state) => state.loveLetter;
export const reducer = loveLetterSlice.reducer;
export const { clearState } = loveLetterSlice.actions;
