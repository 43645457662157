import clsx from 'clsx';
import React, { useState } from 'react';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Box,
  Container,
  makeStyles,
  Typography,
  Button
} from '@material-ui/core';
import { settingSelector } from 'src/slices/settings';
import { postCommentBlog } from 'src/services/blogsService';
import { ROUTES } from 'src/constants';
import { blogSelector } from 'src/slices/blogs';

const useStyles = makeStyles((theme) => ({
  root: {
    justifyContent: 'center',
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down(700)]: {
      padding: '0 2px 0'
    }
  },
  container: {
    maxWidth: 1040,
    padding: theme.spacing(0, 8),
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(0, 2)
    }
  },
  newContent: {
    margin: '3rem 0',
    display: 'flex',
    flexWrap: 'wrap',
    gap: 8,
    justifyContent: 'space-between',
    [theme.breakpoints.down(700)]: {
      margin: '1rem 0 2rem'
    }
  },
  boxWrap: {
    backgroundColor: '#FFFFFF',
    padding: '1.5rem',
    paddingBottom: '3.625rem',
    width: '100%',
    wordBreak: 'break-word',
    '& p': {
      lineHeight: 1.8
    },
    [theme.breakpoints.down(700)]: {
      wordBreak: 'break-all',
      padding: '1rem'
    }
  },
  boxTitle: {
    fontSize: 18,
    marginBottom: theme.spacing(4)
  },
  boxContent: {
    lineHeight: 2.5,
    fontSize: '1rem',
    whiteSpace: 'break-spaces'
  },
  wrapButton: {
    display: 'flex',
    justifyContent: 'center',
    margin: '3.625rem 3.5rem 0 3.5rem',
    [theme.breakpoints.down(700)]: {
      flexDirection: 'column-reverse'
    }
  },
  button: {
    display: 'flex',
    borderRadius: 0,
    flex: 1,
    padding: '12px',
    color: 'white',
    [theme.breakpoints.down(700)]: {
      padding: '10px 0',
      width: '100%',
      margin: '0 0 1rem'
    }
  },
  buttonBack: {
    display: 'flex',
    flex: 1,
    padding: '12px',
    borderRadius: 0,
    backgroundColor: '#989898',
    marginRight: '3.375rem',
    color: 'white',
    '&:hover': {
      backgroundColor: '#989898'
    },
    [theme.breakpoints.down(700)]: {
      padding: '10px 0',
      width: '100%',
      margin: 0
    }
  }
}));

function TalentConfirm() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { settings } = useSelector(settingSelector);
  const { state } = useLocation();
  const navigate = useNavigate();
  const blogs = useSelector(blogSelector);
  const blog = blogs.tagsBlog;

  const [disableButton, setDisableButton] = useState(false);

  const handleSend = () => {
    setDisableButton(true);
    dispatch(postCommentBlog({ id: state.id, content: state.content }))
      .unwrap()
      .then(() => {
        navigate(ROUTES.TALENT_BLOG + '/' + blog?.id, {
          state: {
            ...blog,
            page: state?.page
          }
        });
      })
      .catch((err) => {
        alert(`❌ ${err?.response?.data?.msg}`);
      });
  };

  return (
    <div className={clsx(classes.root)}>
      <Container className={classes.container}>
        <Box className={clsx(classes.newContent)}>
          <Box className={clsx(classes.boxWrap)}>
            <Typography className={clsx(classes.boxTitle)}>
              以下の内容でコメントを投稿します
            </Typography>
            <span className={clsx(classes.boxContent)}>{state?.content}</span>
            <Box className={clsx(classes.wrapButton)}>
              <Button
                onClick={() =>
                  navigate(ROUTES.TALENT_BLOG + '/' + state?.id, {
                    state: {
                      content: state.content,
                      page: state?.page
                    }
                  })
                }
                className={clsx(classes.buttonBack)}
              >
                戻る
              </Button>
              <Button
                disabled={disableButton}
                onClick={handleSend}
                className={clsx(classes.button)}
                style={{ backgroundColor: settings?.theme_color2 }}
              >
                投稿する
              </Button>
            </Box>
          </Box>
        </Box>
      </Container>
    </div>
  );
}

export default TalentConfirm;
