import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
  Box,
  Button,
  Container,
  Typography,
  makeStyles
} from '@material-ui/core';
import Page from 'src/components/Page';
import { useSelector } from 'react-redux';
import { settingSelector } from 'src/slices/settings';

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: '100%',
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(3),
    paddingBottom: 80
  },
  button: {
    borderRadius: 0,
    color: '#ffffff',
    textAlign: 'center',
    width: '100%',
    padding: 15,
    maxWidth: 400,
    [theme.breakpoints.down('sm')]: {
      maxWidth: 200,
      padding: 10
    }
  }
}));

function Error404View() {
  const classes = useStyles();

  const settings = useSelector(settingSelector);

  const [hover, setHover] = useState(false);

  return (
    <Page className={classes.root}>
      <Container maxWidth="lg">
        <Typography align="center" variant="subtitle2">
          申し訳ございません。
          <br /> お探しのページは見つかりませんでした。
        </Typography>

        <Box mt={6} display="flex" justifyContent="center">
          <Button
            component={RouterLink}
            to="/"
            className={classes.button}
            onMouseOver={() => setHover(true)}
            onMouseOut={() => setHover(false)}
            style={{
              backgroundColor: hover
                ? settings?.settings?.theme_color1 + 'cc'
                : settings?.settings?.theme_color1
            }}
          >
            トップページへ戻る
          </Button>
        </Box>
      </Container>
    </Page>
  );
}

export default Error404View;
