import clsx from 'clsx';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Container,
  makeStyles,
  Typography,
  Button
} from '@material-ui/core';
import moment from 'moment';
import _ from 'lodash';

import PaginationButton from 'src/components/PaginationButton';
import LoaderLayer from 'src/components/LoaderLayer';
import { getLoveLetters } from 'src/services/loveLetter';
import { loveLetterSelector } from 'src/slices/loveLetter';
import { settingSelector } from 'src/slices/settings';
import { authSelector } from 'src/slices/auth';
import { getDomainTalent, getLoginToken } from 'src/utils/auth';
import CheckPaymentDialog from 'src/components/CheckPaymentDialog';
import { ROUTES } from 'src/constants';

const useStyles = makeStyles((theme) => ({
  root: {
    justifyContent: 'center',
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down(700)]: {
      padding: '0 2px 0'
    }
  },
  container: {
    maxWidth: 1040,
    padding: theme.spacing(0, 8),
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(0, 2)
    }
  },
  borderBottom: {
    borderBottom: '1px solid #919090'
  },
  borderDotted: {
    borderBottom: '1px dotted #919090'
  },
  contentTitle: {
    color: '#000000',
    fontSize: 25,
    fontWeight: '600',
    [theme.breakpoints.down(600)]: {
      fontSize: 18
    }
  },
  newContent: {
    margin: '3rem 0',
    display: 'flex',
    flexWrap: 'wrap',
    gap: 8,
    justifyContent: 'space-between',
    [theme.breakpoints.down(700)]: {
      margin: '1rem 0 2rem'
    }
  },
  wrapPagination: {
    margin: '40px 0'
  },
  boxWrap: {
    backgroundColor: '#fff',
    padding: '1.5rem',
    width: '100%',
    wordBreak: 'keep-word',
    '& p': {
      lineHeight: 1.8
    },
    [theme.breakpoints.down(700)]: {
      wordBreak: 'normal',
      padding: '1rem'
    }
  },
  boxTitle: {
    fontSize: 25,
    fontWeight: '700',
    display: 'flex',
    justifyContent: 'center',
    margin: '1rem 0 2rem',
    [theme.breakpoints.down(700)]: {
      fontSize: 18,
      margin: '1rem 0'
    }
  },
  textCenter: {
    textAlign: 'center'
  },
  boxContent: {
    [theme.breakpoints.down(700)]: {
      fontSize: '0.9rem'
    }
  },
  wrapButton: {
    display: 'flex',
    justifyContent: 'center',
    margin: '3rem 0',
    [theme.breakpoints.down(700)]: {
      margin: '1rem 0'
    }
  },

  button: {
    padding: '12px 80px',
    borderRadius: 0,
    color: 'white',
    [theme.breakpoints.down(700)]: {
      padding: '10px 0',
      width: '100%'
    }
  },
  subTitle: {
    fontSize: '1.3rem',
    fontWeight: '700',
    [theme.breakpoints.down(700)]: {
      fontSize: '1rem'
    }
  },
  displayFlex: {
    display: 'flex',
    padding: '0.5rem 0',
    [theme.breakpoints.down(700)]: {
      flexDirection: 'column',
      alignItems: 'start',
      display: 'block'
    }
  },
  displayNone: {
    [theme.breakpoints.down(700)]: {
      display: 'none'
    }
  },
  labelContainer: {
    width: '30%',
    justifyContent: 'flex-start',
    paddingLeft: '0.5rem',
    [theme.breakpoints.down(700)]: {
      width: '100%',
      justifyContent: 'flex-start',
      marginBottom: 14
    }
  },
  contentContainer: {
    width: '70%',
    [theme.breakpoints.down(700)]: {
      width: '100%'
    }
  },
  contentText: {
    whiteSpace: 'break-spaces'
  },
  label: {
    fontWeight: 'bold',
    marginRight: 70
  }
}));

function LoveLetterView() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const loveLetters = useSelector(loveLetterSelector);
  const { settings } = useSelector(settingSelector);
  const { profile } = useSelector(authSelector);
  const [page, setPage] = useState(1);
  const [isPayment, setIsPayment] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [statusProfile, setStatusProfile] = useState('');

  useEffect(() => {
    const { members } = profile;
    const domainTalent = getDomainTalent();

    const myProfile = _.find(
      members,
      (member) => member.talent.domain.split('//')[1] === domainTalent
    );

    if (myProfile?.member_type === 'lifetime' || myProfile?.member_type === 'existing_fan') {
      setIsPayment(true);
    } else if (
      myProfile?.role === 'talent' ||
      myProfile?.role === 'staff_view' ||
      myProfile?.role === 'staff_edit'
    ) {
      setIsPayment(true);
    } else if (
      myProfile?.end_datetime >= moment(new Date()).format('YYYY-MM-DD') &&
      myProfile?.role === 'fan'
    ) {
      setIsPayment(true);
    }
    setStatusProfile(myProfile?.status);
  }, [profile]);

  useEffect(() => {
    dispatch(getLoveLetters({ page: page, perPage: 10 }));
  }, [page]);

  return (
    <div className={clsx(classes.root)}>
      {getLoginToken() && (
        <Container className={classes.container}>
          <Box>
            <Typography className={clsx(classes.contentTitle)}>
              LOVE LETTER
            </Typography>
          </Box>
          <Box className={clsx(classes.newContent)}>
            <Box className={clsx(classes.boxWrap)}>
              <Typography
                className={clsx(classes.boxTitle, classes.textCenter)}
              >
                {`${settings?.name}さんへのラブレターについてお願い`}
              </Typography>
              <Typography
                className={clsx(classes.boxContent)}
                dangerouslySetInnerHTML={{
                  __html: settings?.love_letter_rule
                }}
              ></Typography>
            </Box>
          </Box>

          <Box className={clsx(classes.wrapButton)}>
            <Button
              onClick={() => {
                if (isPayment === true) {
                  navigate(ROUTES.LOVE_LETTER_WRITE);
                } else {
                  setOpenDialog(true);
                }
              }}
              disabled={statusProfile !== 'frozen' ? false : true}
              className={clsx(classes.button)}
              style={{ backgroundColor: settings?.theme_color2 }}
            >
              {`${settings?.name}さんにラブレターを送る`}
            </Button>
          </Box>
          <Box mt={8}>
            <Typography className={clsx(classes.subTitle)}>
              あなたが書いたラブレター
            </Typography>
          </Box>

          <Box mt={3} mb={3}>
            {loveLetters?.loveLetters.length > 0 && (
              <>
                <Box className={clsx(classes.displayFlex, classes.displayNone)}>
                  <Box className={clsx(classes.labelContainer)}>
                    <Typography className={clsx(classes.label)}>
                      Date
                    </Typography>
                  </Box>
                  <Box>
                    <Typography className={clsx(classes.label)}>
                      Message
                    </Typography>
                  </Box>
                </Box>
                <Box className={clsx(classes.borderBottom)} />
              </>
            )}

            {loveLetters?.loveLetters.length > 0 &&
              loveLetters?.loveLetters.map((loveLetter) => (
                <Box
                  key={loveLetter?.id}
                  className={clsx(classes.displayFlex, classes.borderDotted)}
                >
                  <Box className={clsx(classes.labelContainer)}>
                    <Typography>
                      {moment(loveLetter?.updated_at).format(
                        'YYYY/MM/DD hh:mm:ss'
                      )}
                    </Typography>
                  </Box>
                  <Box className={clsx(classes.contentContainer)}>
                    <Typography className={clsx(classes.contentText)}>
                      {loveLetter?.body}
                    </Typography>
                  </Box>
                </Box>
              ))}
          </Box>
          {loveLetters?.totalPage > 1 && (
            <Box className={clsx(classes.wrapPagination)}>
              <PaginationButton
                page={page}
                totalItem={loveLetters?.totalPage * 10}
                itemPerPage={10}
                paginate={setPage}
              />
            </Box>
          )}
        </Container>
      )}
      <LoaderLayer loading={loveLetters?.loading} />
      {openDialog && (
        <CheckPaymentDialog
          open={openDialog}
          onClose={setOpenDialog}
          pathName={ROUTES.LOVE_LETTER}
        />
      )}
    </div>
  );
}

export default LoveLetterView;
