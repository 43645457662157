import clsx from 'clsx';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import React, { useRef, useState } from 'react';
import {
  Box,
  Container,
  Typography,
  makeStyles,
  Button
} from '@material-ui/core';

import { ROUTES_AUTHEN } from 'src/constants';
// import googlePlay from 'src/assets/images/google-play-badge/google-play-badge.png';
// import appStore from 'src/assets/images/app-store/app-store.png';
import LoaderLayer from 'src/components/LoaderLayer';
import { settingSelector } from 'src/slices/settings';

import Benefit1 from 'src/assets/images/benefits/benefits-1.jpg';
import Benefit2 from 'src/assets/images/benefits/benefits-2.jpg';
import Benefit3 from 'src/assets/images/benefits/benefits-3.jpg';
import Benefit4 from 'src/assets/images/benefits/benefits-4.jpg';
import Benefit5 from 'src/assets/images/benefits/benefits-5.jpg';
import Benefit6 from 'src/assets/images/benefits/benefits-6.jpg';

// Benefit image YSH
import Benefit1_YSH from 'src/assets/images/benefits/ysh-benefits-1.jpg';
import Benefit2_YSH from 'src/assets/images/benefits/ysh-benefits-2.jpg';
import Benefit3_YSH from 'src/assets/images/benefits/ysh-benefits-3.jpg';
import Benefit4_YSH from 'src/assets/images/benefits/ysh-benefits-4.jpg';
import Benefit5_YSH from 'src/assets/images/benefits/ysh-benefits-5.jpg';
import Benefit6_YSH from 'src/assets/images/benefits/ysh-benefits-6.jpg';

// Benefit image ZIS
import Benefit1_ZIS from 'src/assets/images/benefits/ZIS_benefits_icon_1.jpg';
import Benefit2_ZIS from 'src/assets/images/benefits/ZIS_benefits_icon_2.jpg';
import Benefit3_ZIS from 'src/assets/images/benefits/ZIS_benefits_icon_3.jpg';
import Benefit4_ZIS from 'src/assets/images/benefits/ZIS_benefits_icon_4.jpg';
import Benefit5_ZIS from 'src/assets/images/benefits/ZIS_benefits_icon_5.jpg';
import Benefit6_ZIS from 'src/assets/images/benefits/ZIS_benefits_icon_6.jpg';

// Benefit image SJK
import Benefit1_SJK from 'src/assets/images/benefits/SJK_benefits_icon_1.jpg';
import Benefit2_SJK from 'src/assets/images/benefits/SJK_benefits_icon_2.jpg';
import Benefit3_SJK from 'src/assets/images/benefits/SJK_benefits_icon_3.jpg';
import Benefit4_SJK from 'src/assets/images/benefits/SJK_benefits_icon_4.jpg';
import Benefit5_SJK from 'src/assets/images/benefits/SJK_benefits_icon_5.jpg';
import Benefit6_SJK from 'src/assets/images/benefits/SJK_benefits_icon_6.jpg';

// Benefit image LJS
import Benefit1_LJS from 'src/assets/images/benefits/LJS_benefits_icon_01.jpg';
import Benefit2_LJS from 'src/assets/images/benefits/LJS_benefits_icon_02.jpg';
import Benefit3_LJS from 'src/assets/images/benefits/LJS_benefits_icon_03.jpg';
import Benefit4_LJS from 'src/assets/images/benefits/LJS_benefits_icon_04.jpg';
import Benefit5_LJS from 'src/assets/images/benefits/LJS_benefits_icon_05.jpg';
import Benefit6_LJS from 'src/assets/images/benefits/LJS_benefits_icon_06.jpg';

// Benefit image KJW
import Benefit1_KJW from 'src/assets/images/benefits/KJW_benefits_icon_1.jpg';
import Benefit2_KJW from 'src/assets/images/benefits/KJW_benefits_icon_2.jpg';
import Benefit3_KJW from 'src/assets/images/benefits/KJW_benefits_icon_3.jpg';
import Benefit4_KJW from 'src/assets/images/benefits/KJW_benefits_icon_4.jpg';
import Benefit5_KJW from 'src/assets/images/benefits/KJW_benefits_icon_5.jpg';
import Benefit6_KJW from 'src/assets/images/benefits/KJW_benefits_icon_6.jpg';

// Benefit image PMY
import Benefit1_PMY from 'src/assets/images/benefits/PMY_benefits_icon_1.jpg';
import Benefit2_PMY from 'src/assets/images/benefits/PMY_benefits_icon_2.jpg';
import Benefit3_PMY from 'src/assets/images/benefits/PMY_benefits_icon_3.jpg';
import Benefit4_PMY from 'src/assets/images/benefits/PMY_benefits_icon_4.jpg';
import Benefit5_PMY from 'src/assets/images/benefits/PMY_benefits_icon_5.jpg';
import Benefit6_PMY from 'src/assets/images/benefits/PMY_benefits_icon_6.jpg';

const useStyles = makeStyles((theme) => ({
  root: {
    justifyContent: 'center',
    display: 'flex',
    flexDirection: 'column',
    paddingTop: 100
  },
  registerBorderBottom: {
    borderBottom: '1px solid #919090'
  },
  wrapTitle: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '2rem',
    '& p': {
      fontWeight: 'bold',
      fontSize: 22,
      marginBottom: 5,
      [theme.breakpoints.down('700')]: {
        fontSize: 18
      }
    }
  },
  registerTitleText: {
    fontWeight: 'bold',
    fontSize: 22,
    [theme.breakpoints.down('700')]: {
      fontSize: 18
    }
  },
  wrapBox: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    flexWrap: 'wrap'
  },
  boxContainer: {
    padding: '2rem 1rem 1rem',
    marginBottom: '1rem',
    width: '18rem',
    backgroundColor: 'rgb(0 110 177 / 50%)',
    textAlign: 'center',
    color: 'white',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    [theme.breakpoints.between('700', '960')]: {
      width: '31%',
      marginBottom: '1rem',
      padding: '2rem 0 1rem',
      '& p': {
        fontSize: '0.85rem'
      }
    },
    [theme.breakpoints.down('700')]: {
      width: '10.5rem',
      marginBottom: '0.5rem',
      padding: '2rem 0 1rem',
      '& p': {
        fontSize: '0.7rem'
      }
    }
  },
  boxTitle: {
    fontWeight: '600'
  },
  line: {
    border: '1px solid',
    width: '3rem',
    margin: '1rem 0 2rem'
  },
  boxContent: {
    wordSpacing: '10rem'
  },
  button: {
    padding: '13px 0',
    borderRadius: 0,
    width: '90%',
    color: 'white',
    fontSize: 18,
    '&:hover': {
      backgroundColor: '#1175ae'
    },
    [theme.breakpoints.down(700)]: {
      padding: '12px 80px'
    }
  },
  containerShare: {
    display: 'flex',
    backgroundColor: '#ffffff',
    margin: '3rem 0',
    padding: '2rem 1rem 2rem 2rem',
    alignItems: 'center',
    width: '100%',
    [theme.breakpoints.down(700)]: {
      flexDirection: 'column'
    }
  },
  textShare: {
    flexGrow: 1,
    '& p': {
      fontSize: '5rem',
      paddingLeft: '2rem',
      [theme.breakpoints.down(700)]: {
        fontSize: '10vw',
        paddingLeft: 0
      }
    }
  },
  containerStoreLogo: {
    width: '30%',
    [theme.breakpoints.down(700)]: {
      width: '100%',
      textAlign: 'center',
      marginTop: '2rem',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center'
    }
  },
  googlePlayLogo: {
    cursor: 'pointer',
    [theme.breakpoints.down(700)]: {
      paddingLeft: 0,
      width: '10rem'
    }
  },
  appStoreLogo: {
    paddingLeft: '1rem',
    cursor: 'pointer',
    [theme.breakpoints.down(700)]: {
      paddingLeft: 0,
      width: '9rem'
    }
  },
  contentSpacing: {
    wordSpacing: '50rem'
  },
  borderBox: {
    border: '1px solid #707070',
    margin: '4rem 0',
    padding: '0 1.5rem'
  },
  linkTo: {
    color: '#006EB1',
    cursor: 'pointer'
  },
  benefitImg: {
    width: '30%',
    maxWidth: 240,
    minWidth: 150,
    margin: 5
  },
  addNewBox: {
    width: '100%',
    padding: '5px 20px',
    paddingBottom: '20px',
    color: '#fff',
    display: 'flex',
    flexDirection: 'column'
  },
  whiteTitle: {
    fontSize: 18,
    margin: '5px auto 20px auto',
    fontWeight: 600,
    [theme.breakpoints.down(700)]: {
      fontSize: 15
    }
  },
  borderWhite: {
    border: '1px solid #fff',
    display: 'flex',
    flexDirection: 'column',
    padding: '5px 20px',
    width: '45%',
    minWidth: 325,
    margin: 5
  },
  borderWhiteTitle: {
    fontSize: 16,
    margin: '0 auto 8px auto'
  },
  newBoxWrap: {
    display: 'flex',
    justifyContent: 'space-evenly',
    flexWrap: 'wrap'
  }
}));

function MemberBenefits() {
  const classes = useStyles();
  const navigate = useNavigate();
  const myRef = useRef();

  const settings = useSelector(settingSelector);

  const [hover, setHover] = useState(false);

  return (
    <div ref={myRef} className={classes.root}>
      <Container maxWidth="md">
        <Box className={clsx(classes.registerBorderBottom, classes.wrapTitle)}>
          <Typography>会員特典</Typography>
        </Box>

        {window.location.host ===
        process.env.REACT_APP_TALENT_ZO_IN_SUNG_DOMAIN ? (
          <Box className={clsx(classes.wrapBox)}>
            <img src={Benefit1_ZIS} alt="Frau" className={classes.benefitImg} />
            <img src={Benefit2_ZIS} alt="Frau" className={classes.benefitImg} />
            <img src={Benefit3_ZIS} alt="Frau" className={classes.benefitImg} />
            <img src={Benefit4_ZIS} alt="Frau" className={classes.benefitImg} />
            <img src={Benefit5_ZIS} alt="Frau" className={classes.benefitImg} />
            <img src={Benefit6_ZIS} alt="Frau" className={classes.benefitImg} />
          </Box>
        ) : window.location.host ===
          'www.' + process.env.REACT_APP_TALENT_ZO_IN_SUNG_DOMAIN ? (
          <Box className={clsx(classes.wrapBox)}>
            <img src={Benefit1_ZIS} alt="Frau" className={classes.benefitImg} />
            <img src={Benefit2_ZIS} alt="Frau" className={classes.benefitImg} />
            <img src={Benefit3_ZIS} alt="Frau" className={classes.benefitImg} />
            <img src={Benefit4_ZIS} alt="Frau" className={classes.benefitImg} />
            <img src={Benefit5_ZIS} alt="Frau" className={classes.benefitImg} />
            <img src={Benefit6_ZIS} alt="Frau" className={classes.benefitImg} />
          </Box>
        ) : window.location.host ===
          process.env.REACT_APP_TALENT_KIM_NAN_GIL_DOMAIN ? (
          'キム・ナムギル'
        ) : window.location.host ===
          process.env.REACT_APP_TALENT_LEE_JONG_SUK_DOMAIN ? (
          <Box className={clsx(classes.wrapBox)}>
            <img src={Benefit1_LJS} alt="Frau" className={classes.benefitImg} />
            <img src={Benefit2_LJS} alt="Frau" className={classes.benefitImg} />
            <img src={Benefit3_LJS} alt="Frau" className={classes.benefitImg} />
            <img src={Benefit4_LJS} alt="Frau" className={classes.benefitImg} />
            <img src={Benefit5_LJS} alt="Frau" className={classes.benefitImg} />
            <img src={Benefit6_LJS} alt="Frau" className={classes.benefitImg} />
          </Box>
        ) : window.location.host ===
          'www.' + process.env.REACT_APP_TALENT_LEE_JONG_SUK_DOMAIN ? (
          <Box className={clsx(classes.wrapBox)}>
            <img src={Benefit1_LJS} alt="Frau" className={classes.benefitImg} />
            <img src={Benefit2_LJS} alt="Frau" className={classes.benefitImg} />
            <img src={Benefit3_LJS} alt="Frau" className={classes.benefitImg} />
            <img src={Benefit4_LJS} alt="Frau" className={classes.benefitImg} />
            <img src={Benefit5_LJS} alt="Frau" className={classes.benefitImg} />
            <img src={Benefit6_LJS} alt="Frau" className={classes.benefitImg} />
          </Box>
        ) : window.location.host ===
          process.env.REACT_APP_TALENT_LEE_SEUNG_GI_DOMAIN ? (
          'イ・スンギ'
        ) : window.location.host ===
          process.env.REACT_APP_TALENT_SONG_JOON_KI_DOMAIN ? (
          <Box className={clsx(classes.wrapBox)}>
            <img src={Benefit1_SJK} alt="Frau" className={classes.benefitImg} />
            <img src={Benefit2_SJK} alt="Frau" className={classes.benefitImg} />
            <img src={Benefit3_SJK} alt="Frau" className={classes.benefitImg} />
            <img src={Benefit4_SJK} alt="Frau" className={classes.benefitImg} />
            <img src={Benefit5_SJK} alt="Frau" className={classes.benefitImg} />
            <img src={Benefit6_SJK} alt="Frau" className={classes.benefitImg} />
          </Box>
        ) : window.location.host ===
          process.env.REACT_APP_TALENT_KIM_YOUNG_DEA_DOMAIN ? (
          <Box className={clsx(classes.wrapBox)}>
            <img src={Benefit1} alt="Frau" className={classes.benefitImg} />
            <img src={Benefit2} alt="Frau" className={classes.benefitImg} />
            <img src={Benefit3} alt="Frau" className={classes.benefitImg} />
            <img src={Benefit4} alt="Frau" className={classes.benefitImg} />
            <img src={Benefit5} alt="Frau" className={classes.benefitImg} />
            <img src={Benefit6} alt="Frau" className={classes.benefitImg} />
          </Box>
        ) : window.location.host ===
          process.env.REACT_APP_TALENT_YOO_SEUNG_HO_DOMAIN ? (
          <Box className={clsx(classes.wrapBox)}>
            <img src={Benefit1_YSH} alt="Frau" className={classes.benefitImg} />
            <img src={Benefit2_YSH} alt="Frau" className={classes.benefitImg} />
            <img src={Benefit3_YSH} alt="Frau" className={classes.benefitImg} />
            <img src={Benefit4_YSH} alt="Frau" className={classes.benefitImg} />
            <img src={Benefit5_YSH} alt="Frau" className={classes.benefitImg} />
            <img src={Benefit6_YSH} alt="Frau" className={classes.benefitImg} />
          </Box>
        ) : window.location.host ===
          process.env.REACT_APP_TALENT_LEE_MIN_KI_DOMAIN ? (
          'イ・ミンギ'
        ) : window.location.host ===
          process.env.REACT_APP_TALENT_KIM_JI_WON_DOMAIN ? (
          <Box className={clsx(classes.wrapBox)}>
            <img src={Benefit1_KJW} alt="Frau" className={classes.benefitImg} />
            <img src={Benefit2_KJW} alt="Frau" className={classes.benefitImg} />
            <img src={Benefit3_KJW} alt="Frau" className={classes.benefitImg} />
            <img src={Benefit4_KJW} alt="Frau" className={classes.benefitImg} />
            <img src={Benefit5_KJW} alt="Frau" className={classes.benefitImg} />
            <img src={Benefit6_KJW} alt="Frau" className={classes.benefitImg} />
          </Box>
        ) : window.location.host ===
          process.env.REACT_APP_TALENT_PARK_MIN_YOUNG_DOMAIN ? (
          <Box className={clsx(classes.wrapBox)}>
            <img src={Benefit1_PMY} alt="Frau" className={classes.benefitImg} />
            <img src={Benefit2_PMY} alt="Frau" className={classes.benefitImg} />
            <img src={Benefit3_PMY} alt="Frau" className={classes.benefitImg} />
            <img src={Benefit4_PMY} alt="Frau" className={classes.benefitImg} />
            <img src={Benefit5_PMY} alt="Frau" className={classes.benefitImg} />
            <img src={Benefit6_PMY} alt="Frau" className={classes.benefitImg} />
          </Box>
        ) : (
          <Box className={clsx(classes.wrapBox)}>
            <img src={Benefit1} alt="Frau" className={classes.benefitImg} />
            <img src={Benefit2} alt="Frau" className={classes.benefitImg} />
            <img src={Benefit3} alt="Frau" className={classes.benefitImg} />
            <img src={Benefit4} alt="Frau" className={classes.benefitImg} />
            <img src={Benefit5} alt="Frau" className={classes.benefitImg} />
            <img src={Benefit6} alt="Frau" className={classes.benefitImg} />
          </Box>
        )}

        <Box
          mt={8}
          className={clsx(classes.registerBorderBottom, classes.wrapTitle)}
        >
          <Typography>ご注意</Typography>
        </Box>
        <Box mb={10}>
          <Typography style={{ fontSize: 16 }}>
            ※日本国外にお住まいの方はご入会できません。
            <br />
            ※会員特典は、諸般の事情により、事前の通知なく変更される場合がありますので、予めご了承ください。
            <br />
            　また、ご入会の時期によってはご利用いただけない場合もございます。
            <br />
            ※会員様へのご連絡、イベント等に関する情報のご提供、受付などは、会員専用サイトを通じてのみ行っています。
            <br />
            　インターネットを利用されないことによって生じる不利益に対し、ファンクラブは一切の責任を負いません。
            <br />
          </Typography>
        </Box>

        <Box
          mt={8}
          className={clsx(classes.registerBorderBottom, classes.wrapTitle)}
        >
          <Typography>会員制度について</Typography>
        </Box>
        <Box mb={10}>
          <Typography style={{ fontSize: 16 }}>
            会費：年会費6,600円（税込）
            <br />
            <br />
            お支払い方法：クレジットカード決済、コンビニ決済、ATM決済、銀行ネット決済
            <br />
            <br />
            有効期限：お客様がご入金された月から、1年間が会員有効期限となります。
            <br />
            <br />
            継続手続
            <br />
            更新を希望される場合、会員期間満了月の前月1日〜満了月の20日までにお手続きをお済ませください。
            <br />
            例）2024年8月にご入会
            <br />
            【会員期限】2025年7月31日
            <br />
            【更新期間】2025年6月1日～7月20日
            <br />
            ※クレジットカード決済をご利用のお客様は自動継続となります。
            <br />
            退会または決済方法の変更をご希望される方は、会員期間満了月の10日までに「MYPAGE」より各種手続きを行なってください。
          </Typography>
        </Box>
        {/* <Box className={clsx(classes.containerShare)}>
          <Box className={clsx(classes.textShare)}>
            <Typography>アプリの紹介</Typography>
          </Box>
          <Box className={clsx(classes.containerStoreLogo)}>
            <Link
              href="https://play.google.com/store/apps/details?id=com.instagram.android&hl=vi&gl=US"
              target="blank"
            >
              <Box
                className={clsx(classes.googlePlayLogo)}
                component="img"
                src={googlePlay}
              />
            </Link>
            <Link
              href="https://apps.apple.com/vn/app/messenger/id1480068668?mt=12"
              target="blank"
            >
              <Box
                className={clsx(classes.appStoreLogo)}
                component="img"
                src={appStore}
              />
            </Link>
          </Box>
        </Box> */}

        {/* <Box className={clsx(classes.registerBorderBottom, classes.wrapTitle)}>
          <Typography>ご注意</Typography>
        </Box> */}

        {/* <Box mb={8}>
          <Typography>
            ※ファンミーティングやイベント開催時期は、スケジュールによるため定期的な開催をお約束するものではございません。{' '}
          </Typography>
          <Typography>
            ※上記会員特典は、諸般の事情により事前の通知なく変更される場合がありますので、あらかじめご了承ください。{' '}
          </Typography>
          <Typography>
            ※チケット先行予約の対象会員に関するご案内は、先行予約の日程が確定した時点で発表します。
            またお知らせをする準備の都合上、ご入会の時期によってはご利用いただけない場合もございます。
            ご不明な点は事前にファンクラブまでお問い合わせのうえ、お手続きください。{' '}
          </Typography>
          <Typography>
            ※来日イベントやツアー等の優先案内及び先行予約はFRAU INTERNATIONAL
            CO.,LTD主催のものとし、他社で主催される場合はこの限りではございません。
          </Typography>
        </Box> */}

        {/* <Box
          mt={4}
          className={clsx(classes.registerBorderBottom, classes.wrapTitle)}
        >
          <Typography>注意事項</Typography>
        </Box>

        <Box>
          <Typography className={clsx(classes.contentSpacing)}>
            ※日本国外にお住まいの方はご入会できません。
            ※会員特典は、諸般の事情により、事前の通知なく変更される場合がありますので、予めご了承ください。
            また、ご入会の時期によってはご利用いただけない場合もございます。
            ※会員様へのご連絡、イベント等に関する情報のご提供、受付などは、会員専用サイトを通じてのみ行なっております。インターネットを利用されないことによって生じる不利益に対し、ファンクラプは一切の責任を負いません。
          </Typography>
        </Box> */}

        {/* <Box
          mt={8}
          className={clsx(classes.registerBorderBottom, classes.wrapTitle)}
        >
          <Typography>他のタレント様でご登録いただいている方</Typography>
        </Box>

        <Box>
          <Typography>
            下記ファンクラブサイトにすでにご入会いただいているお客様は、すでに「FRAU
            ID」をお持ちです。
            他ファンクラブにてご登録のID並びにパスワードを入力後、ログインを行い、有料会員登録を行ってください。
            <br />
            <br />
            ※「FRAU ID」とは
            <br />
            株式会社フラウ・インターナショナルが運営するファンクラブの一部で導入している共通IDです。
            「FRAU
            ID」を使用することにより、複数のファンクラブへ簡単にご登録いただくことが可能です。
            <br />
            <br />
            ＜FRAU IDを導入しているファンクラブ＞ <br />
            チョ・インソン、キム・ナムギル、イ・ジョンソク、イ・スンギ、ソンジュンギ、キム・ヨンデ
          </Typography>
        </Box> */}
        {/* 
        <Box mt={5} display="flex" justifyContent="center">
          <Button
            onClick={() => navigate(ROUTES_AUTHEN.PAYMENT_TYPE)}
            className={clsx(classes.button)}
          >
            決済情報登録
          </Button>
        </Box>

        <Box
          mt={8}
          className={clsx(classes.registerBorderBottom, classes.wrapTitle)}
        >
          <Typography>会員期限切れにより退会となったお客様</Typography>
        </Box>

        <Box>
          <Typography className={clsx(classes.contentSpacing)}>
            以前ご登録していたメールアドレスならびにパスワードにてログイン後、再度決済情報をご登録ください。
            退会後1か月以内であれば、会員番号ならびに購入済みコンテンツを引き継ぐことが可能です。期限が過ぎますと会員番号が変更となり、購入済みコンテンツもご利用いただけません。引き続きご利用を希望される場合は、必ず1か月以内にお手続きください。
          </Typography>
        </Box> */}

        {/* <Box
          mt={8}
          className={clsx(classes.registerBorderBottom, classes.wrapTitle)}
        >
          <Typography>マイページより退会手続きをされたお客様</Typography>
        </Box>

        <Box>
          <Typography className={clsx(classes.contentSpacing)}>
            新規会員登録ページより、再度ご登録をお願いいたします。
            会員番号ならびに購入済みコンテンツなどの引き継ぎはできませんので、ご注意ください。
          </Typography>
        </Box> */}

        <Box className={clsx(classes.borderBox)}>
          <Box mt={2} className={clsx(classes.wrapTitle)}>
            <Typography>入会はこちら</Typography>
          </Box>
          <Typography style={{ fontSize: 16, textAlign: 'center' }}>
            会員登録の為のメールアドレスをご準備の上、手続きにお進みください。
          </Typography>
          <Box mt={3} mb={4} display="flex" justifyContent="center">
            <Button
              onClick={() => navigate(ROUTES_AUTHEN.REGISTER)}
              className={clsx(classes.button)}
              onMouseOver={() => setHover(true)}
              onMouseOut={() => setHover(false)}
              style={{
                width: '95%',
                backgroundColor: hover
                  ? settings?.settings?.theme_color1 + 'cc'
                  : settings?.settings?.theme_color1
              }}
            >
              新規会員登録
            </Button>
          </Box>
        </Box>
        <Box
          className={classes.addNewBox}
          style={{ backgroundColor: settings?.settings?.theme_color1 }}
        >
          <Typography className={classes.whiteTitle}>
            ＜上記から新規会員登録が出来ない場合＞
          </Typography>
          <Box className={classes.newBoxWrap}>
            <Box className={classes.borderWhite}>
              <Typography className={classes.borderWhiteTitle}>
                有効期限が切れて1ヶ月以内の場合は
              </Typography>
              <Typography style={{ fontSize: 15 }}>
                ご登録されていたメールアドレスとパスワードでログインの上、
                決済情報を改めてご登録いただくことで、継続して本サイトをご利用いただけます。
                <br />
                <small>
                  ＊ただし1ヶ月を過ぎた場合は、再度新規会員登録を行なっていただく必要があります。
                </small>
              </Typography>
            </Box>
            <Box className={classes.borderWhite}>
              <Typography className={classes.borderWhiteTitle}>
                すでにFRAU IDをお持ちの方
              </Typography>
              <Typography style={{ fontSize: 15 }}>
                FRAU
                IDとは株式会社フラウ・インターナショナル（以下、弊社）が運営する
                ファンクラブの一部で導入しているシステムです。すでに弊社が運営するファンクラブに
                ご登録いただいている方は、同じメールアドレスとパスワードでログインしていただくことで、
                決済情報の登録のみで簡単に新たなファンクラブにご入会いただけます。
                <small>
                  ※弊社が運営しているファンクラブは
                  <a
                    href="https://www.frau-inter.co.jp/business/fanclub/"
                    target="_blank"
                  >
                    こちら
                  </a>
                </small>
              </Typography>
            </Box>
          </Box>
        </Box>
        <br />
        <br />
        <br />
      </Container>
      <LoaderLayer loading={settings?.loading} />
    </div>
  );
}

export default MemberBenefits;
