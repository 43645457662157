import { createSlice } from '@reduxjs/toolkit';
import {
  getVideoById,
  getVideo,
  postFavoriteVideo
} from 'src/services/videoService';

export const videoSlice = createSlice({
  name: 'videos',
  initialState: {
    loading: false,
    videos: [],
    tagsVideo: [],
    VideoDetail: '',
    errorMessage: '',
    currentPage: 1,
    totalPage: 1
  },
  reducers: {
    clearState: (state) => {
      state.videos = [];
      state.tagsVideo = [];
      state.VideoDetail = '';
      state.errorMessage = '';
      state.currentPage = 1;
      state.totalPage = 1;
      state.loading = false;
      return state;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getVideoById.pending, (state) => {
      state.loading = true;
    }),
    builder.addCase(getVideoById.fulfilled, (state, { payload }) => {
      state.tagsVideo = payload;
      state.loading = false;
      return state;
    }),
    builder.addCase(getVideoById.rejected, (state, { payload }) => {
      state.errorMessage = payload?.error;
      state.loading = false;
    }),
    builder.addCase(getVideo.pending, (state) => {
      state.loading = true;
    }),
    builder.addCase(getVideo.fulfilled, (state, { payload }) => {
      state.videos = payload.data;
      state.currentPage = payload.current_page;
      state.totalPage = payload.last_page;
      state.loading = false;
      return state;
    }),
    builder.addCase(getVideo.rejected, (state, { payload }) => {
      state.errorMessage = payload?.error;
      state.loading = false;
    })
    builder.addCase(postFavoriteVideo.pending, (state) => {
      state.loading = true;
    }),
    builder.addCase(postFavoriteVideo.fulfilled, (state, { payload }) => {
      state.tagsVideo = payload;
      state.videos = state.videos.map(item => {
        if (item?.id === payload?.id) {
          item = payload
        }
        return item;
      })
      state.loading = false;
      return state;
    }),
    builder.addCase(postFavoriteVideo.rejected, (state, { payload }) => {
      state.errorMessage = payload?.error;
      state.loading = false;
    });
  }
});

export const videoSelector = (state) => state.videos;
export const reducer = videoSlice.reducer;
export const { clearState } = videoSlice.actions;
