import React, { useState } from 'react';
import clsx from 'clsx';
import {
  Box,
  Container,
  Typography,
  makeStyles,
  Button,
  Checkbox,
  Grid
} from '@material-ui/core';
import { useLocation, useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { inquirySelector } from 'src/slices/inquiry';
import { postInquiry } from 'src/services/inquiryService';
import { ROUTES } from 'src/constants';
import LoaderLayer from 'src/components/LoaderLayer';
import { checkNameTalent } from 'src/utils/auth';

const useStyles = makeStyles((theme) => ({
  root: {
    justifyContent: 'center',
    display: 'flex',
    flexDirection: 'column',
    padding: '1rem 5px 0'
  },
  registerBorderBottom: {
    borderBottom: '1px solid #919090'
  },
  wrapTitle: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center'
  },
  registerTitle: {
    fontWeight: 'bold',
    fontSize: 22,
    marginBottom: 5,
    [theme.breakpoints.down('700')]: {
      fontSize: 18
    }
  },
  inquiryTitleText: {
    fontWeight: 'bold',
    fontSize: 20,
    borderBottom: '1px solid',
    [theme.breakpoints.down(700)]: {
      fontSize: '0.9rem'
    }
  },
  inquirySubTitle: {
    margin: '3rem 0 2rem',
    fontSize: '0.9rem',
    [theme.breakpoints.down(700)]: {
      margin: '2rem 0'
    }
  },
  inquiryList: {
    marginLeft: '1.5rem',
    fontSize: '0.9rem',
    fontFamily: 'sans-serif',
    marginBottom: '4rem',
    '& li': {
      padding: '0.1rem 0'
    }
  },
  inquiryTextRight: {
    flexGrow: 1,
    backgroundColor: '#737373',
    color: 'white',
    padding: '0.5rem'
  },
  registerDescription: {
    marginBottom: 5,
    fontSize: '0.9rem'
  },
  button: {
    backgroundColor: 'black',
    color: '#fff !important',
    borderRadius: 0,
    padding: '12px 140px',
    fontSize: 18,
    '&:hover': {
      backgroundColor: '#1175ae'
    },
    [theme.breakpoints.down(700)]: {
      fontSize: 16,
      padding: '10px 80px'
    }
  },
  displayFlex: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down(700)]: {
      flexDirection: 'column',
      alignItems: 'start',
      display: 'block'
    }
  },
  displayContent: {
    display: 'flex',
    [theme.breakpoints.down(700)]: {
      flexDirection: 'column',
      alignItems: 'start',
      display: 'block'
    }
  },
  contentRight: {
    width: '75%',
    [theme.breakpoints.down(700)]: {
      width: '100%'
    }
  },
  labelContainer: {
    width: '25%',
    justifyContent: 'flex-end',
    [theme.breakpoints.down(700)]: {
      width: '100%',
      justifyContent: 'flex-start',
      marginBottom: 14
    }
  },
  label: {
    fontWeight: 'bold',
    marginRight: 70
  },
  boxWrap: {
    backgroundColor: '#fff',
    height: 250,
    overflowX: 'scroll',
    padding: '1rem 1.5rem 1rem 1rem',
    margin: '4rem 0 2rem'
  },
  wrapCheckbox: {
    justifyContent: 'center',
    alignItems: 'center'
  },
  checkboxLabel: {
    [theme.breakpoints.down(700)]: {
      fontSize: '0.9rem'
    }
  },
  dFlex: {
    display: 'flex'
  },
  addSpace: {
    marginRight: 10,
    whiteSpace: 'nowrap'
  }
}));

function InquiryConfirmView() {
  const classes = useStyles();
  const navigate = useNavigate();
  const { state } = useLocation();
  const [checked, setChecked] = useState(false);
  const dispatch = useDispatch();
  const inquiry = useSelector(inquirySelector);
  const { nameLower } = checkNameTalent();

  const handleChecked = (event) => {
    setChecked(event.target.checked);
  };

  const typeInquiry = () => {
    switch (state.type) {
      case '入会について':
        return 1;
      case 'ログインについて':
        return 2;
      case 'ファンクラブについて':
        return 3;
      case 'コミュニティの機能について':
        return 4;
      case '動画の再生について':
        return 5;
      case '商品の購入について':
        return 6;
      case '登録案内及び入金メールが届かない':
        return 7;
      case '更新手続きについて':
        return 8;
      case 'その他システムに関するお問い合わせ':
        return 9;
      case 'その他お問い合わせ':
        return 10;
      default:
        break;
    }
  };

  const handleSendInquiry = () => {
    dispatch(
      postInquiry({
        first_name: state.first_name,
        last_name: state.last_name,
        email: state.email,
        type: typeInquiry(),
        body: state.body
      })
    )
      .unwrap()
      .then(() => {
        navigate(ROUTES.INQUIRY_FINISH);
      })
      .catch((err) => {
        alert(`❌ ${err?.response?.data?.msg}`);
      });
  };

  return (
    <div className={classes.root}>
      <Container maxWidth="md">
        <Box mb={8} paddingBottom={window.innerWidth <= 700 ? 4 : 8}>
          {/* <Box className={classes.wrapTitle}>
            <Typography className={classes.inquiryTitleText}>
              お問い合わせ前にご確認ください
            </Typography>
          </Box>
          <Typography className={classes.inquirySubTitle}>
            匿名でのお問い合わせについては返答できかねます。
            <br />{' '}
            必ず氏名、メールアドレス、お問い合わせ内容をご記入のうえ、「同意して確認ページへ」ボタンを押してください。
          </Typography>

          <ul className={clsx(classes.inquiryList)}>
            <li>
              来日予定や出演情報等、NOTICEに記載されていること以外はお問い合わせいただいてもお答えできません。
            </li>
            <li>
              お問い合わせには順次お答えしていきますが、返答までにお時間をいただく場合があります。
            </li>
            <li>
              省略語や機種依存文字（絵文字・半角カタカナ等）は使用しないでください。
            </li>
            <li>
              ファンクラブへのご意見には基本的にご返信しませんので、あらかじめご了承ください。
            </li>
            <li>ファンメールはこちらの窓口では受け付けておりません。</li>
          </ul> */}

          <Box className={classes.wrapTitle}>
            <Typography className={classes.inquiryTitleText}>
              ＜当事務局からの返信メールが届かない場合＞
            </Typography>
          </Box>

          <Typography className={classes.inquirySubTitle}>
            お客様のメーラーやWebメールサービスの設定により、「迷惑メール」フォルダに振り分けられる場合がございます。
            <br />
            【@{nameLower}.{nameLower === 'zo-insung' ? 'com' : 'jp'}
            】のドメインからのメールを受信できるように設定をお願いいたします。
            <br />
            お問い合わせ後、7営業日以上経っても返信メールが届かない場合には、お客様がご利用されているパソコンの受信設定をご確認いただき、再度お問い合わせくださいますようお願い申し上げます。
          </Typography>
          <Box
            sx={{ justifyContent: 'center' }}
            mb={5}
            mt={6}
            display="flex"
            alignItems="center"
            className={clsx(classes.registerBorderBottom)}
          >
            <Typography className={clsx(classes.registerTitle)}>
              お問い合わせ内容確認
            </Typography>
          </Box>

          <Box className={classes.registerBorderBottom}>
            <Box mb={3} className={clsx(classes.displayFlex)}>
              <Box display={'flex'} className={clsx(classes.labelContainer)}>
                <Typography className={clsx(classes.label)}>お名前</Typography>
              </Box>
              <Box display={'flex'}>
                <Box mr={3}>
                  <Typography>
                    {state?.first_name} {state?.last_name}
                  </Typography>
                </Box>
              </Box>
            </Box>

            <Box mb={3} className={clsx(classes.displayFlex)}>
              <Box display={'flex'} className={clsx(classes.labelContainer)}>
                <Typography
                  className={clsx(classes.label)}
                  style={{ textAlign: 'end' }}
                >
                  返信用 {window.innerWidth > 700 && <br />} メールアドレス
                </Typography>
              </Box>
              <Box display={'flex'} flexGrow={1}>
                <Box>
                  <Typography>{state?.email}</Typography>
                </Box>
              </Box>
            </Box>

            <Box mb={3} className={clsx(classes.displayFlex)}>
              <Box display={'flex'} className={clsx(classes.labelContainer)}>
                <Typography className={clsx(classes.label)}>
                  お問い合わせ項目
                </Typography>
              </Box>
              <Box display={'flex'} flexGrow={1}>
                <Box>
                  <Typography>{state?.type}</Typography>
                </Box>
              </Box>
            </Box>

            <Box mb={3} className={clsx(classes.displayContent)}>
              <Box display={'flex'} className={clsx(classes.labelContainer)}>
                <Typography className={clsx(classes.label)}>
                  お問い合わせ内容
                </Typography>
              </Box>
              <Box
                display={'flex'}
                flexGrow={1}
                className={clsx(classes.contentRight)}
              >
                <Box>
                  <Typography>{state?.body}</Typography>
                </Box>
              </Box>
            </Box>

            {/* <Box className={clsx(classes.boxWrap)}>
              <Typography>
                【個人情報の取り扱いについて】
                <br />
                当社では、お客様の個人情報を慎重に取扱い、プライバシーの保護に努めております。
                <br />
                当社がお客様にお伺いする個人情報はお客様のお名前、電話番号、メールアドレス等、当社のサービスを提供する際に必要となるお客様の個人情報が主なものになります。
              </Typography>
              <br />
              <Grid className={classes.dFlex}>
                <Typography className={classes.addSpace}>1.</Typography>

                <Typography>
                  ご提供いただいた個人情報は、以下の目的のみに使用いたします。
                  <br />
                  <Grid className={classes.dFlex}>
                    <Typography className={classes.addSpace}>1)</Typography>
                    お問い合わせへの回答
                  </Grid>
                  <Grid className={classes.dFlex}>
                    <Typography className={classes.addSpace}>2)</Typography>
                    統計処理
                    <br />
                    当社がホームページ内容、サービス、商品の改善を行うための統計資料として利用いたします。この場合、お客様個人を特定することはありません。
                  </Grid>
                  <Grid className={classes.dFlex}>
                    <Typography className={classes.addSpace}>3)</Typography>
                    その他ご連絡
                    <br />
                    当社がお客様へのご連絡が必要であると判断したとき、メール・電話・手紙にてご連絡を差し上げることがあります。
                    <br />
                  </Grid>
                </Typography>
              </Grid>

              <Box mt={2} />
              <Grid className={classes.dFlex}>
                <Typography className={classes.addSpace}>2.</Typography>

                <Typography>
                  個人情報は以下に該当する場合を除き第三者に提供することは一切ありません。
                  <Grid className={classes.dFlex}>
                    <Typography className={classes.addSpace}>1)</Typography>
                    法令及び警察、裁判所等の公的機関から開示の要求があった場合。
                  </Grid>
                  <Grid className={classes.dFlex}>
                    <Typography className={classes.addSpace}>2)</Typography>
                    情報開示について、お客様に同意をいただいた場合。
                  </Grid>
                </Typography>
              </Grid>

              <Box mt={2} />
              <Grid className={classes.dFlex}>
                <Typography className={classes.addSpace}>3.</Typography>
                <Typography>
                  当社では、１項に記載された目的を行うにあたり、ご提供いただいた個人情報の取り扱いをインターネットサーバー業者に委託いたします。この場合、孤児情報を適切に取り扱っている委託先を選定し、個人情報の適正管理や機密の保持に関して契約などを締結し、電子的方法で送付することにより適切な管理を実施します。また、利用目的の達成に必要な範囲内で弊社が適切に管理する委託先に取得した個人情報の取り扱いの全部または一部を委託する場合があります。
                </Typography>
              </Grid>
              <Box mt={2} />
              <Grid className={classes.dFlex}>
                <Typography className={classes.addSpace}>4.</Typography>
                <Typography>
                  ご提供いただいた個人情報の利用目的の通知、開示、訂正、追加、利用停止、消去及び第三者提供の停止をご希望される場合は、下記の個人情報相談窓口までご連絡ください。
                </Typography>
              </Grid>
              <Box mt={2} />
              <Grid className={classes.dFlex}>
                <Typography className={classes.addSpace}>5.</Typography>
                <Typography>
                  個人情報の提供は必須ではありません。ただし、ご提供いただけなかった方は、お問い合わせへの回答はできません。ご容赦ください。
                </Typography>
              </Grid>
              <Box mt={2} />
              <Grid className={classes.dFlex}>
                <Typography className={classes.addSpace}>6.</Typography>
                <Typography>
                  本ウェブサイトには、お客様に一層便利にご利用いただくため、一部のページで「Cookie」という技術を使用しています。Cookieにより、ウェブサイトはお客様のコンピュータを識別することができるようになりますが、お客様のお名前、ご住所、電話番号、メールアドレスといった個人情報を特定可能な情報を取得することは一切ありません。お客様は、ご利用いただくブラウザの設定によりCookieの受け取りを拒否したり、Cookieを受け取った時に警告を表示させたりすることができます。お客様がCookieの受け取りを拒否された場合も本ウェブサイトをご利用いただけますが、一部機能がご利用になれない場合があります。
                </Typography>
              </Grid>
              <Box mt={2} />
              <Typography>
                【お客様の個人情報の取り扱いに関するお問い合わせ】
                <br />
                株式会社フラウ・インターナショナル　お客様相談室
                <br />
                住所：大阪市中央区北浜東1-22 北浜東野村ビル8階
                <br />
                電話番号：06-4791-2400
                <br />
                受付時間：平日 10:00~18:00（土日祝／年末年始は休業）
                <br />
                担当者：個人情報保護管理責任者　お客様相談室長
                <br />
                <br />
                以上
              </Typography>
            </Box> */}

            {/* <Box mt={1} display="flex" justifyContent="center">
              <Typography>
                上記の「個人情報の取り扱いについて」をよくお読みいただき、同意した上で送信して下さい。
              </Typography>
            </Box>

            <Box mt={2} display="flex" className={clsx(classes.wrapCheckbox)}>
              <Checkbox onChange={handleChecked} color="default" />
              <Typography className={clsx(classes.checkboxLabel)}>
                個人情報の取り扱いの取り扱いについて同意する
              </Typography>
            </Box> */}
          </Box>
          <Box mt={8} display="flex" sx={{ justifyContent: 'center' }}>
            <Button
              onClick={handleSendInquiry}
              className={clsx(classes.button)}
            >
              送信する
            </Button>
          </Box>
        </Box>
        <LoaderLayer loading={inquiry?.loading} />
      </Container>
    </div>
  );
}
export default InquiryConfirmView;
