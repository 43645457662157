import React from 'react';
import { checkNameTalent } from 'src/utils/auth';

import LogoZIS from 'src/assets/images/logo_zis_black.png';
import LogoSJK from 'src/assets/images/logo_sjk_black.png';
import LogoKJW from 'src/assets/images/logo_kjw_black.png';
import LogoPMY from 'src/assets/images/logo_pmy_black.png';

function LogoMobile(props) {
  const { logo } = checkNameTalent();
  const domain = window.location.host;

  return (
    <>
      {(domain === 'www.' + process.env.REACT_APP_TALENT_ZO_IN_SUNG_DOMAIN ||
        domain === process.env.REACT_APP_TALENT_ZO_IN_SUNG_DOMAIN) &&
      window.innerWidth <= 700 ? (
        <img alt="Logo" src={LogoZIS} width="100" />
      ) : domain === process.env.REACT_APP_TALENT_SONG_JOON_KI_DOMAIN &&
        window.innerWidth <= 700 ? (
        <img alt="Logo" src={LogoSJK} width="164" />
      ) : domain ===
        'www' + process.env.REACT_APP_TALENT_LEE_JONG_SUK_DOMAIN ? (
        <img alt="Logo" src={logo} {...props} width="84" />
      ) : domain === process.env.REACT_APP_TALENT_KIM_JI_WON_DOMAIN &&
        window.innerWidth <= 700 ? (
        <img alt="Logo" src={LogoKJW} width="164" />
      ) : domain === process.env.REACT_APP_TALENT_PARK_MIN_YOUNG_DOMAIN &&
        window.innerWidth <= 700 ? (
        <img alt="Logo" src={LogoPMY} width="154" />
      ) : (
        <img alt="Logo" src={logo} {...props} width="84" />
      )}
    </>
  );
}

export default LogoMobile;
