import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import { Typography, Grid, Dialog, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { ROUTES, ROUTES_AUTHEN } from 'src/constants';
import { useSelector } from 'react-redux';
import { settingSelector } from 'src/slices/settings';
import { authSelector, clearState, logout } from 'src/slices/auth';
import { useDispatch } from 'react-redux';
import { sendMessageToReactNative } from 'src/components/WebReactNativeCommunicate';

const useStyles = makeStyles((theme) => ({
  customDialog: {
    '&>div>div': {
      backgroundColor: '#000000',
      padding: theme.spacing(4)
    }
  },
  profile: {
    textAlign: 'end'
  },
  iconClose: {
    width: '50px !important',
    height: '50px !important',
    color: '#fff'
  },
  footer: {
    margin: theme.spacing(5, 20),
    '& h2': {
      cursor: 'pointer'
    }
  },
  headerFCMenu: {
    marginBottom: theme.spacing(5)
  },
  menuFCName: {
    padding: '8px 0px !important',
    width: 'fit-content'
  },
  wrapFooter: {
    display: 'flex',
    gap: 30,
    marginTop: theme.spacing(5)
  },
  font: {
    fontFamily: 'futura-pt',
    fontWeight: '400',
    color: '#fff',
    position: 'relative',
    '&:hover': {
      color: '#000'
    },
    '&:after': {
      content: '""',
      position: 'absolute',
      bottom: 0,
      left: 0,
      width: '100%',
      height: '2px',
      background: '#fff',
      transition: 'all .3s',
      transform: 'scale(0, 1)',
      transformOrigin: 'left top'
    },
    '&:hover': {
      '&:after': {
        transform: 'scale(1, 1)'
      }
    }
  }
}));

const PopUpFCMenu = React.forwardRef(function PopUpFCMenu(open, ref) {
  const classes = useStyles();
  const navigate = useNavigate();
  const handleClose = () => {
    open.open.handleClosePopUp();
  };
  const dispatch = useDispatch();
  const setting = useSelector(settingSelector);
  const profile = useSelector(authSelector);
  const [searchParams] = useSearchParams();
  return (
    <div ref={ref}>
      <Dialog
        fullScreen
        classes={{ root: classes.customDialog }}
        open={open.open.openPopUpFCMenu}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        onClose={handleClose}
      >
        <div className={classes.profile}>
          <IconButton
            className={classes.menuButton}
            color="inherit"
            onClick={handleClose}
          >
            <CloseIcon className={classes.iconClose} />
          </IconButton>
        </div>
        <div className={classes.footer}>
          <Typography
            className={clsx(classes.headerFCMenu)}
            style={{
              fontFamily: 'futura-pt',
              fontStyle: 'normal',
              fontWeight: '400',
              color: '#fff'
            }}
          >
            FC MENU
          </Typography>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <Grid item xs={12} className={classes.menuFCName}>
              <Typography
                onClick={() => {
                  navigate(ROUTES_AUTHEN.MY_PAGE);
                  handleClose();
                }}
                className={classes.font}
                variant="h4"
              >
                MYPAGE
              </Typography>
            </Grid>
            {setting?.settings?.talent_settings?.menu_fromtalent_position ===
              2 && (
              <Grid
                item
                xs={12}
                className={classes.menuFCName}
                style={{
                  order:
                    setting?.settings?.talent_settings?.menu_fromtalent_number
                }}
              >
                <Typography
                  variant="h4"
                  onClick={() => {
                    navigate(ROUTES.TALENT_BLOG);
                    handleClose();
                  }}
                  className={classes.font}
                >
                  MESSAGE
                </Typography>
              </Grid>
            )}
            {setting?.settings?.talent_settings?.menu_staffblog_position ===
              2 && (
              <Grid
                item
                xs={12}
                className={classes.menuFCName}
                style={{
                  order:
                    setting?.settings?.talent_settings?.menu_staffblog_number
                }}
              >
                <Typography
                  onClick={() => {
                    navigate(ROUTES.STAFF_BLOG);
                    handleClose();
                  }}
                  variant="h4"
                  className={classes.font}
                >
                  STAFF BLOG
                </Typography>
              </Grid>
            )}
            {setting?.settings?.talent_settings?.menu_magazine_position ===
              2 && (
              <Grid
                item
                xs={12}
                className={classes.menuFCName}
                style={{
                  order:
                    setting?.settings?.talent_settings?.menu_magazine_number
                }}
              >
                <Typography
                  onClick={() => {
                    navigate(ROUTES.MAGAZINE);
                    handleClose();
                  }}
                  variant="h4"
                  className={classes.font}
                >
                  MAGAZINE
                </Typography>
              </Grid>
            )}
            {setting?.settings?.talent_settings?.menu_community_position ===
              2 && (
              <Grid
                item
                xs={12}
                className={classes.menuFCName}
                style={{
                  order:
                    setting?.settings?.talent_settings?.menu_community_number
                }}
              >
                <Typography
                  onClick={() => {
                    navigate(ROUTES.COMMUNITY);
                    handleClose();
                  }}
                  variant="h4"
                  className={classes.font}
                >
                  COMMUNITY
                </Typography>
              </Grid>
            )}
            {setting?.settings?.talent_settings?.menu_schedule_position ===
              2 && (
              <Grid
                item
                xs={12}
                className={classes.menuFCName}
                style={{
                  order:
                    setting?.settings?.talent_settings?.menu_schedule_number
                }}
              >
                <Typography
                  variant="h4"
                  onClick={() => {
                    navigate(ROUTES.SCHEDULE);
                    handleClose();
                  }}
                  className={classes.font}
                >
                  SCHEDULE
                </Typography>
              </Grid>
            )}
            {setting?.settings?.talent_settings?.menu_download_position ===
              2 && (
              <Grid item xs={12} className={classes.menuFCName}>
                <Typography
                  onClick={() => {
                    navigate(ROUTES.DOWNLOAD);
                    handleClose();
                  }}
                  variant="h4"
                  style={{
                    order:
                      setting?.settings?.talent_settings?.menu_download_number
                  }}
                  className={classes.font}
                >
                  DOWNLOAD
                </Typography>
              </Grid>
            )}
            {setting?.settings?.talent_settings?.menu_loveletter_position ===
              2 && (
              <Grid
                item
                xs={12}
                className={classes.menuFCName}
                style={{
                  order:
                    setting?.settings?.talent_settings?.menu_loveletter_number
                }}
              >
                <Typography
                  onClick={() => {
                    navigate(ROUTES.LOVE_LETTER);
                    handleClose();
                  }}
                  variant="h4"
                  className={classes.font}
                >
                  LOVE LETTER
                </Typography>
              </Grid>
            )}
            {setting?.settings?.talent_settings?.menu_notice_position === 2 && (
              <Grid
                item
                xs={12}
                className={classes.menuFCName}
                style={{
                  order: setting?.settings?.talent_settings?.menu_notice_number
                }}
                onClick={() => {
                  navigate(ROUTES.NOTICE);
                  handleClose();
                }}
              >
                <Typography variant="h4" className={classes.font}>
                  NOTICE
                </Typography>
              </Grid>
            )}
            {setting?.settings?.talent_settings?.menu_profile_position ===
              2 && (
              <Grid
                item
                xs={12}
                className={classes.menuFCName}
                style={{
                  order: setting?.settings?.talent_settings?.menu_profile_number
                }}
                onClick={() => {
                  navigate(ROUTES.PROFILE);
                  handleClose();
                }}
              >
                <Typography variant="h4" className={classes.font}>
                  PROFILE
                </Typography>
              </Grid>
            )}
            {setting?.settings?.talent_settings?.menu_discography_position ===
              2 && (
              <Grid
                item
                xs={12}
                className={classes.menuFCName}
                style={{
                  order:
                    setting?.settings?.talent_settings?.menu_discography_number
                }}
                onClick={() => {
                  navigate(ROUTES.DISCOGRAPHY);
                  handleClose();
                }}
              >
                <Typography variant="h4" className={classes.font}>
                  DISCOGRAPHY
                </Typography>
              </Grid>
            )}
            {setting?.settings?.talent_settings?.menu_youtube_position ===
              2 && (
              <Grid
                item
                xs={12}
                className={classes.menuFCName}
                style={{
                  order: setting?.settings?.talent_settings?.menu_youtube_number
                }}
                onClick={() => {
                  navigate(ROUTES.YOUTUBE);
                  handleClose();
                }}
              >
                <Typography variant="h4" className={classes.font}>
                  YOUTUBE
                </Typography>
              </Grid>
            )}
            {setting?.settings?.talent_settings?.menu_photo_position === 2 && (
              <Grid
                item
                xs={12}
                className={classes.menuFCName}
                style={{
                  order: setting?.settings?.talent_settings?.menu_photo_number
                }}
                onClick={() => {
                  navigate(ROUTES.PHOTO);
                  handleClose();
                }}
              >
                <Typography variant="h4" className={classes.font}>
                  PHOTO
                </Typography>
              </Grid>
            )}
            {setting?.settings?.talent_settings?.menu_video_position === 2 && (
              <Grid
                item
                xs={12}
                className={classes.menuFCName}
                style={{
                  order: setting?.settings?.talent_settings?.menu_video_number
                }}
                onClick={() => {
                  navigate(ROUTES.VIDEO);
                  handleClose();
                }}
              >
                <Typography variant="h4" className={classes.font}>
                  VIDEO
                </Typography>
              </Grid>
            )}
            {setting?.settings?.talent_settings?.menu_shop_position === 2 && (
              <Grid
                item
                xs={12}
                className={classes.menuFCName}
                style={{
                  order: setting?.settings?.talent_settings?.menu_shop_number
                }}
                onClick={() => {
                  window.open(
                    setting?.settings?.shop_url,
                    '_blank',
                    'noopener,noreferrer'
                  );
                  handleClose();
                }}
              >
                <Typography variant="h4" className={classes.font}>
                  SHOP
                </Typography>
              </Grid>
            )}
          </div>
          <div className={classes.wrapFooter}>
            <Typography
              onClick={() => {
                if (profile?.isAuthenticated) {
                  handleClose();
                } else {
                  navigate(ROUTES_AUTHEN.LOGIN);
                  handleClose();
                }
              }}
              style={{ cursor: 'pointer' }}
              className={classes.font}
            >
              {profile?.isAuthenticated ? 'ログイン済' : 'ログイン'}
            </Typography>
            <Typography
              onClick={() => {
                if (profile?.isAuthenticated) {
                  dispatch(logout());
                  dispatch(clearState());
                  sendMessageToReactNative('log_out');
                  navigate(ROUTES_AUTHEN.LOGIN);
                  handleClose();
                } else {
                  navigate(ROUTES_AUTHEN.REGISTER);
                  handleClose();
                }
              }}
              style={{ cursor: 'pointer' }}
              className={classes.font}
            >
              {!profile?.isAuthenticated
                ? '新規入会'
                : !searchParams.get('appacc') && 'ログアウト'}
            </Typography>
          </div>
        </div>
      </Dialog>
    </div>
  );
});
PopUpFCMenu.propTypes = {
  open: PropTypes.object,
  handleClose: PropTypes.func
};

export default PopUpFCMenu;
