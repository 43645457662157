import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import moment from 'moment';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Box,
  Container,
  Typography,
  makeStyles,
  Button,
  TextField,
  Checkbox,
  FormGroup,
  FormControlLabel
} from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';

import LoaderLayer from 'src/components/LoaderLayer';
import iconArrow from 'src/assets/images/arrow-down.png';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PaginationButton from 'src/components/PaginationButton';
import { ROUTES_AUTHEN } from 'src/constants';
import { getCategoriesById, searchThread } from 'src/services/communityService';
import { communitySelector } from 'src/slices/community';
import { authSelector } from 'src/slices/auth';
import { getDomainTalent, getLoginToken } from 'src/utils/auth';
import CheckPaymentDialog from 'src/components/CheckPaymentDialog';

const useStyles = makeStyles((theme) => ({
  root: {
    justifyContent: 'center',
    display: 'flex',
    flexDirection: 'column',
    paddingTop: 50,
    [theme.breakpoints.down('700')]: {
      paddingTop: 30
    }
  },
  borderBottom: {
    borderBottom: '1px solid #919090'
  },
  wrapTitle: {
    marginBottom: '3rem',
    [theme.breakpoints.down('700')]: {
      marginBottom: '1rem'
    }
  },
  registerTitleText: {
    fontWeight: 'bold',
    fontSize: 22,
    [theme.breakpoints.down('700')]: {
      fontSize: 18
    }
  },
  boxTile: {
    fontWeight: 'bold',
    fontSize: 22,
    margin: '2.5rem 0',
    [theme.breakpoints.down('700')]: {
      fontSize: 18
    }
  },
  registerButton: {
    backgroundColor: 'black',
    color: 'white !important',
    borderRadius: 0,
    padding: '12px 140px',
    '&:hover': {
      backgroundColor: '#1175ae'
    },
    [theme.breakpoints.down(700)]: {
      padding: '10px 80px',
      width: '100%'
    }
  },
  buttonSearch: {
    backgroundColor: 'black',
    color: '#fff !important',
    borderRadius: 0,
    padding: '8px 40px',
    '&:hover': {
      backgroundColor: '#1175ae'
    },
    [theme.breakpoints.down(700)]: {
      padding: '10px 0px',
      height: 'fit-content',
      width: '50%'
    }
  },
  inputContainer: {
    flexGrow: 1,
    [theme.breakpoints.down(700)]: {
      width: '100%',
      margin: '0.5rem 0 1rem'
    }
  },
  wrapInput: {
    color: 'black',
    height: 38,
    backgroundColor: '#FFFFFF',
    '& input': {
      padding: '10px 0 10px 10px',
      height: 28
    }
  },
  searchContainer: {
    display: 'flex',
    alignItems: 'center',
    paddingBottom: '2rem',
    [theme.breakpoints.down(700)]: {
      flexDirection: 'column',
      paddingBottom: '1rem'
    }
  },
  selectContent: {
    border: 'none',
    backgroundColor: 'transparent',
    fontSize: '1.1rem',
    padding: '0.5rem 1.3rem 0.5rem 0.5rem',
    margin: '0 1.5rem 0 1rem',
    outline: 'none',
    background: `transparent url(${iconArrow}) no-repeat center`,
    backgroundPosition: 'right',
    appearance: 'none',
    cursor: 'pointer',
    [theme.breakpoints.down(700)]: {
      display: 'none'
    }
  },
  optionContainer: {
    [theme.breakpoints.down(700)]: {
      display: 'flex',
      width: '100%'
    }
  },
  checkboxContainer: {
    display: 'none',
    [theme.breakpoints.down(700)]: {
      display: 'block',
      flexGrow: 1
    }
  },
  wrapButtonPost: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '6rem 0 2rem'
  },
  buttonPost: {
    backgroundColor: 'black',
    color: '#fff !important',
    borderRadius: 0,
    padding: '12px 80px',
    '&:hover': {
      backgroundColor: '#1175ae'
    },
    [theme.breakpoints.down(700)]: {
      padding: '10px 80px',
      width: '100%'
    }
  },
  sortContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '1rem 0',
    [theme.breakpoints.down(700)]: {
      flexDirection: 'column'
    }
  },
  wrapSortOption: {
    display: 'flex',
    [theme.breakpoints.down(700)]: {
      flexDirection: 'column',
      marginTop: '1rem'
    }
  },
  sortOption: {
    marginLeft: '1.5rem',
    display: 'flex',
    '& :hover': {
      cursor: 'pointer'
    },
    [theme.breakpoints.down(700)]: {
      marginLeft: 0
    }
  },
  lessIcon: {
    marginLeft: '-10px'
  },
  tableContainer: {
    backgroundColor: 'white',
    margin: '2rem 0'
  },
  tableTitleContainer: {
    display: 'flex',
    padding: '1rem 0.8rem'
  },
  tableContentContainer: {
    display: 'flex',
    padding: '1rem 0.8rem',
    cursor: 'pointer',
    borderBottom: '1px solid #EDEDED'
  },
  titleContent: {
    display: 'flex',
    [theme.breakpoints.down(700)]: {
      flexDirection: 'column'
    }
  },
  titleLeft: {
    width: '68%',
    [theme.breakpoints.down(700)]: {
      width: '70%'
    }
  },
  titleRight: {
    width: '100%',
    overflow: 'hidden'
  },
  rightContent: {
    width: '100%',
    overflow: 'hidden',
    [theme.breakpoints.down(700)]: {
      width: '60%'
    }
  },
  dateItem: {
    width: '43%',
    [theme.breakpoints.down(700)]: {
      width: '80%'
    }
  },
  dateContent: {
    width: '43%',
    [theme.breakpoints.down(700)]: {
      width: '75%'
    }
  },
  titleItem: {
    width: '55%'
  },
  nameItem: {
    width: '25%'
  },
  commentItem: {
    width: '75%'
  },
  leftContent: {
    width: '65%',
    [theme.breakpoints.down(700)]: {
      width: '41%'
    }
  },
  titleContentItem: {
    width: '42%',
    [theme.breakpoints.down(700)]: {
      width: '75%'
    }
  },
  textOver: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap'
  },
  nameContentItem: {
    width: '25%',
    paddingRight: '2rem',
    marginLeft: '0.5rem',
    [theme.breakpoints.down(700)]: {
      width: '70%',
      marginLeft: 0,
      paddingRight: 0
    }
  },
  commentContentItem: {
    width: '50%',
    [theme.breakpoints.down(700)]: {
      width: '100%'
    }
  },
  wrapPagination: {
    margin: '3rem 0 5rem'
  }
}));

function CommunityOnBoard() {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const community = useSelector(communitySelector);
  const { profile } = useSelector(authSelector);
  const { threads } = community;

  const [page, setPage] = useState(1);
  const [currentProfile, setCurrentProfile] = useState({});
  const [orderType, setOrderType] = useState(true);
  const [orderBy, setOrderBy] = useState('date');
  const [searchKeyword, setSearchKeyWord] = useState('');
  const [searchType, setSearchType] = useState('title');
  const [openDialog, setOpenDialog] = useState(false);
  const [isPayment, setIsPayment] = useState(false);
  const [pageTitle, setPageTitle] = useState('');

  useEffect(() => {
    dispatch(
      getCategoriesById({
        categoryId: id,
        page: page,
        order_by: orderBy,
        order_type: orderType === true ? 'desc' : 'asc'
      })
    );
  }, [page, orderBy, orderType]);

  useEffect(() => {
    const { members } = profile;
    const domainTalent = getDomainTalent();

    const myProfile = _.find(
      members,
      (member) => member.talent.domain.split('//')[1] === domainTalent
    );

    setCurrentProfile(myProfile);
    if (
      myProfile?.member_type === 'lifetime' ||
      myProfile?.member_type === 'existing_fan'
    ) {
      setIsPayment(true);
    } else if (
      myProfile?.role === 'talent' ||
      myProfile?.role === 'staff_view' ||
      myProfile?.role === 'staff_edit'
    ) {
      setIsPayment(true);
    } else if (
      myProfile?.end_datetime >= moment(new Date()).format('YYYY-MM-DD') &&
      myProfile?.role === 'fan'
    ) {
      setIsPayment(true);
    }
  }, []);

  useEffect(() => {
    const { categories } = community;

    categories?.map((category) => {
      if (category?.id == id) {
        setPageTitle(category?.title);
      }
    });
  }, [id]);

  const handleSearchThread = async () => {
    await dispatch(
      searchThread({
        categoryId: id,
        searchType: searchType,
        searchKeyword: searchKeyword
      })
    );
  };

  return (
    <div className={classes.root}>
      {getLoginToken() && (
        <Container maxWidth="md">
          <Box className={clsx(classes.wrapTitle)}>
            <Typography className={classes.registerTitleText}>
              {pageTitle}
            </Typography>
          </Box>
          <Box mb={1}>
            <Typography>投稿記事を検索する</Typography>
          </Box>

          <Box className={clsx(classes.searchContainer, classes.borderBottom)}>
            <Box className={clsx(classes.inputContainer)}>
              <TextField
                fullWidth
                value={searchKeyword}
                onChange={(event) => setSearchKeyWord(event.target.value)}
                InputProps={{
                  disableUnderline: true,
                  className: classes.wrapInput
                }}
              />
            </Box>
            <Box className={clsx(classes.optionContainer)}>
              <select
                value={searchType}
                onChange={(event) => setSearchType(event.target.value)}
                className={clsx(classes.selectContent)}
              >
                <option value="title">投稿タイトルで検索する</option>
                <option value="name">ユーザー名で検索する</option>
              </select>

              <FormGroup
                defaultValue={searchType}
                onChange={(event) => setSearchType(event.target.value)}
                className={clsx(classes.checkboxContainer)}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={searchType === 'title'}
                      value="title"
                      color="default"
                    />
                  }
                  label="投稿タイトルで検索する"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={searchType === 'name'}
                      value="name"
                      color="default"
                    />
                  }
                  label="ユーザー名で検索する"
                />
              </FormGroup>

              <Button
                onClick={handleSearchThread}
                disabled={searchKeyword.length > 0 ? false : true}
                className={clsx(classes.buttonSearch)}
              >
                検索
              </Button>
            </Box>
          </Box>

          <Box className={clsx(classes.wrapButtonPost, classes.borderBottom)}>
            <Button
              onClick={() => {
                if (isPayment === true) {
                  navigate(ROUTES_AUTHEN.POST_COMMUNITY + `/${id}`);
                } else {
                  setOpenDialog(true);
                }
              }}
              disabled={currentProfile?.status === 'frozen' ? true : false}
              className={clsx(classes.buttonPost)}
            >
              記事を投稿する
            </Button>
          </Box>

          <Box className={clsx(classes.sortContainer, classes.borderBottom)}>
            <Box>
              <Typography>{`［${
                community?.to !== null ? community?.to : 0
              } 件表示］全 ${community?.total} 件`}</Typography>
            </Box>
            <Box className={clsx(classes.wrapSortOption)}>
              <Box
                onClick={() => {
                  setOrderType(!orderType);
                  setOrderBy('date');
                }}
                className={clsx(classes.sortOption)}
              >
                <Typography>記事の投稿順</Typography>
                <Box>
                  <ExpandMoreIcon />
                  <ExpandLessIcon className={clsx(classes.lessIcon)} />
                </Box>
              </Box>
              <Box
                onClick={() => {
                  setOrderType(!orderType);
                  setOrderBy('latest_comment');
                }}
                className={clsx(classes.sortOption)}
              >
                <Typography>コメント投稿日順</Typography>
                <Box>
                  <ExpandMoreIcon />
                  <ExpandLessIcon className={clsx(classes.lessIcon)} />
                </Box>
              </Box>
              <Box
                onClick={() => {
                  setOrderType(!orderType);
                  setOrderBy('comment_count');
                }}
                className={clsx(classes.sortOption)}
              >
                <Typography>コメント投稿数順</Typography>
                <Box>
                  <ExpandMoreIcon />
                  <ExpandLessIcon className={clsx(classes.lessIcon)} />
                </Box>
              </Box>
            </Box>
          </Box>

          <Box className={clsx(classes.tableContainer)}>
            <Box
              className={clsx(
                classes.tableTitleContainer,
                classes.borderBottom
              )}
            >
              <Box className={clsx(classes.titleContent, classes.titleLeft)}>
                <Typography className={clsx(classes.dateItem)}>DATE</Typography>
                <Typography className={clsx(classes.titleContent)}>
                  TITLE
                </Typography>
              </Box>
              <Box className={clsx(classes.titleContent, classes.titleRight)}>
                <Typography className={clsx(classes.nameItem)}>NAME</Typography>
                <Typography className={clsx(classes.commentItem)}>
                  COMMENT
                </Typography>
              </Box>
            </Box>

            {threads?.map((thread) => (
              <Box
                key={thread?.id}
                onClick={() => {
                  if (isPayment === true) {
                    navigate(
                      ROUTES_AUTHEN.COMMUNITY_POST +
                        `/${thread.id}?category_id=${thread.community_category_id}`
                    );
                  } else {
                    setOpenDialog(true);
                  }
                }}
                className={clsx(classes.tableContentContainer)}
              >
                <Box
                  className={clsx(classes.titleContent, classes.leftContent)}
                >
                  <Typography className={clsx(classes.dateContent)}>
                    {moment(thread?.created_at).format('YYYY/MM/DD')}
                  </Typography>
                  <Typography
                    className={clsx(classes.titleContentItem, classes.textOver)}
                  >
                    {thread?.title}
                  </Typography>
                </Box>
                <Box
                  className={clsx(classes.titleContent, classes.rightContent)}
                >
                  <Typography
                    className={clsx(classes.nameContentItem, classes.textOver)}
                  >
                    {thread?.member?.nickname}
                  </Typography>
                  <Typography
                    className={clsx(
                      classes.commentContentItem,
                      classes.textOver
                    )}
                  >
                    {thread?.body}
                  </Typography>
                </Box>
              </Box>
            ))}
          </Box>

          {community?.totalPage > 1 && (
            <Box className={clsx(classes.wrapPagination)}>
              <PaginationButton
                page={page}
                totalItem={community?.totalPage * 10}
                itemPerPage={10}
                paginate={setPage}
              />
            </Box>
          )}

          <Box mt={6} mb={10} display="flex" sx={{ justifyContent: 'center' }}>
            <Button className={clsx(classes.registerButton)}>
              その他注意事項
            </Button>
          </Box>
        </Container>
      )}
      <LoaderLayer loading={community?.loading} />
      {getLoginToken() && openDialog && (
        <CheckPaymentDialog
          open={openDialog}
          onClose={setOpenDialog}
          pathName={ROUTES_AUTHEN.COMMUNITY_ON_BOARD + `/${id}`}
        />
      )}
    </div>
  );
}

export default CommunityOnBoard;
