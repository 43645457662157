import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'src/utils/API';
import FormData from 'form-data';

export const getCategories = createAsyncThunk(
  'community/categories',
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.get('/community-category');
      if (response.status === 200) {
        return response?.data?.data;
      }
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getCategoriesById = createAsyncThunk(
  'community/categories-by-id',
  async ({ categoryId, page, order_by, order_type }, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `/community-category/${categoryId}?page=${page}&order_by=${order_by}&order_type=${order_type}`
      );

      if (response.status === 200) {
        return response?.data?.data;
      }
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const postCommunityCategory = createAsyncThunk(
  'community/postCategory',
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `/community-category/${data.category_id}`,
        data.body
      );

      if (response.status === 200) {
        return response?.data;
      }
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getThreadById = createAsyncThunk(
  'community/threadDetail',
  async ({ threadId, page, categoryId }, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `/community/thread/${threadId}?page=${page}&thread_id=${categoryId}`
      );

      if (response.status === 200) {
        return response?.data?.data;
      }
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const postComment = createAsyncThunk(
  'community/comment',
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `/community/thread/${data.thread_id}/comment`,
        data.body
      );

      if (response.status === 200) {
        return response?.data;
      }
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const searchThread = createAsyncThunk(
  'community/search',
  async ({ categoryId, searchType, searchKeyword }, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `/community-category/${categoryId}?search_type=${searchType}&search_keyword=${searchKeyword}`
      );

      if (response.status === 200) {
        return response?.data?.data;
      }
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const uploadImage = createAsyncThunk(
  'community/postCategoryImage',
  async (image, { rejectWithValue }) => {
    try {
      let data = new FormData();
      data.append('image', image, image.name);
      const response = await axios.post('/uploads', data, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });

      if (response.status === 200) {
        return response?.data;
      }
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
