import { createSlice } from '@reduxjs/toolkit';
import { postInquiry, getFAQ } from 'src/services/inquiryService';

export const inquirySlice = createSlice({
  name: 'inquiry',
  initialState: {
    loading: false,
    inquiry: [],
    faqs: [],
    errorMessage: '',
    currentPage: 1,
    totalPage: 1
  },
  reducers: {
    clearState: (state) => {
      state.inquiry = [];
      state.faqs = [];
      state.errorMessage = '';
      state.currentPage = 1;
      state.totalPage = 1;
      state.loading = false;
      return state;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(postInquiry.pending, (state) => {
      state.loading = true;
    }),
      builder.addCase(postInquiry.fulfilled, (state, { payload }) => {
        state.inquiry = payload;
        state.loading = false;
        return state;
      }),
      builder.addCase(postInquiry.rejected, (state, { payload }) => {
        state.errorMessage = payload?.error;
        state.loading = false;
      });
    builder.addCase(getFAQ.pending, (state) => {
      state.loading = true;
    }),
      builder.addCase(getFAQ.fulfilled, (state, { payload }) => {
        state.faqs = payload.data;
        state.currentPage = payload.current_page;
        state.totalPage = payload.last_page;
        state.loading = false;
        return state;
      }),
      builder.addCase(getFAQ.rejected, (state, { payload }) => {
        state.loading = false;
      });
  }
});

export const inquirySelector = (state) => state.inquiry;
export const reducer = inquirySlice.reducer;
export const { clearState } = inquirySlice.actions;
