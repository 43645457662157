import { createSlice } from '@reduxjs/toolkit';

import { getTagsWithType } from 'src/services/tagsService';

export const tagsSlice = createSlice({
  name: 'tags',
  initialState: {
    loading: false,
    tagsNotice: [],
    tagsBiographies: [],
    tagsDiscographies: [],
    tagsProfiles: [],
    errorMessage: ''
  },
  reducers: {
    clearState: (state) => {
      state.loading = false;
      state.tagsNotice = [];
      state.tagsBiographies = [];
      state.tagsDiscographies = [];
      state.tagsProfiles = [];
      state.errorMessage = '';
      return state;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getTagsWithType.pending, (state) => {
      state.loading = true;
    }),
      builder.addCase(getTagsWithType.fulfilled, (state, { payload }) => {
        state.loading = false;

        switch (payload.type) {
          case 'notices':
            state.tagsNotice = payload.data;
            return state;
          case 'biography':
            state.tagsBiographies = payload.data;
            return state;
          case 'discographies':
            state.tagsDiscographies = payload.data;
            return state;
            case 'profiles':
            state.tagsProfiles = payload.data;
            return state;
          default:
            return state;
        }
      }),
      builder.addCase(getTagsWithType.rejected, (state, { payload }) => {
        state.errorMessage = payload?.error;
      });
  }
});

export const tagsNoticeSelector = (state) => state.tags.tagsNotice;
export const tagsBiographiesSelector = (state) => state.tags.tagsBiographies;
export const tagsDiscographiesSelector = (state) => state.tags.tagsDiscographies;
export const tagsProfilesSelector = (state) => state.tags.tagsProfiles;
export const reducer = tagsSlice.reducer;
export const { clearState } = tagsSlice.actions;
