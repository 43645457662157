import { createSlice } from '@reduxjs/toolkit';
import {
  getPhotoById,
  getPhoto
} from 'src/services/photoService';

export const photoSlice = createSlice({
  name: 'photos',
  initialState: {
    loading: false,
    photos: [],
    tagsPhoto: [],
    PhotoDetail: '',
    errorMessage: '',
    currentPage: 1,
    totalPage: 1
  },
  reducers: {
    clearState: (state) => {
      state.photos = [];
      state.tagsPhoto = [];
      state.PhotoDetail = '';
      state.errorMessage = '';
      state.currentPage = 1;
      state.totalPage = 1;
      state.loading = false;
      return state;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getPhotoById.pending, (state) => {
      state.loading = true;
    }),
      builder.addCase(getPhotoById.fulfilled, (state, { payload }) => {
        state.tagsPhoto = payload;
        state.loading = false;
        return state;
      }),
      builder.addCase(getPhotoById.rejected, (state, { payload }) => {
        state.errorMessage = payload?.error;
        state.loading = false;
      }),
      builder.addCase(getPhoto.pending, (state) => {
        state.loading = true;
      }),
      builder.addCase(
        getPhoto.fulfilled,
        (state, { payload }) => {
          state.photos = payload.data;
          state.currentPage = payload.current_page;
          state.totalPage = payload.last_page;
          state.loading = false;
          return state;
        }
      ),
      builder.addCase(
        getPhoto.rejected,
        (state, { payload }) => {
          state.errorMessage = payload?.error;
          state.loading = false;
        }
      );
  }
});

export const photoSelector = (state) => state.photos;
export const reducer = photoSlice.reducer;
export const { clearState } = photoSlice.actions;
