import React from 'react';
import { makeStyles, Typography, Grid, Box } from '@material-ui/core';
import LogoP from 'src/assets/images/logo_p.png';

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 1040,
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto',
    '& p': {
      fontSize: 16
    }
  },
  topBanner: {
    width: '100%',
    background: '#B1B1B1',
    display: 'flex',
    height: 80
  },
  borderBottom: {
    padding: '0 5%',
    paddingBottom: '20px',
    marginBottom: '20px',
    borderBottom: '1px solid #707070'
  },
  textBigSize: {
    fontSize: '1.3rem'
  },
  dFlex: {
    display: 'flex'
  },
  addSpace: {
    marginRight: 10,
    whiteSpace: 'nowrap'
  },
  heading: {
    fontSize: '2.8rem',
    lineHeight: 1,
    letterSpacing: 0,
    fontWeight: 400,
    marginBottom: '2.5rem',
    [theme.breakpoints.down(700)]: {
      fontSize: '2rem'
    },
    '&::before': {
      marginRight: '10px',
      content: '""',
      width: '30px',
      height: '30px',
      display: 'inline-block',
      backgroundColor: '#006eb1',
      [theme.breakpoints.down(700)]: {
        width: 22,
        height: 22
      }
    }
  },
  textWhite: {
    color: '#ffffff',
    marginTop: 'auto',
    marginLeft: 30,
    marginBottom: 'auto',
    fontSize: 25,
    [theme.breakpoints.up(700)]: {
      fontSize: 40
    }
  },
  dFlex: {
    display: 'flex'
  }
}));

function Privacy() {
  const classes = useStyles();

  return (
    <div className={classes.root} title="個人情報の取り扱いについて">
      <div className={classes.topBanner}>
        <Typography className={classes.textWhite}>
          <strong style={{ fontSize: 30 }}>個人情報の取り扱いについて</strong>
        </Typography>
      </div>
      <br />
      <br />
      <Box className={classes.borderBottom}>
        <Typography>
          <strong className={classes.textBigSize}>【個人情報保護方針】</strong>
          <br />
          <br />
          制定日：平成16年11月01日
          <br />
          改定日：令和04年08月01日
          <br />
          株式会社フラウ・インターナショナル
          <br />
          代表取締役　金沢 榮美
        </Typography>
      </Box>
      <Box className={classes.borderBottom}>
        <Typography>
          株式会社フラウ・インターナショナルは、韓国の芸能、文化を通じて韓国と日本の交流の架け橋となることをめざし、また、当社をご利用いただく皆様からのご要望にお応えするということをモットーに、旅行業務、韓国芸能イベント関連またそれらに付随するサービス全般に関する事業を行っております。これら事業を進めるにあたってお預かりした大切な個人情報について、当社が事業活動を行う上で、ご本人の権利を保護し、安全且つ適切に取り扱うことは社会的要請であるとともに当社の責務であると考えています。当社では、この責務を全うするために、以下の取り組みを実施します。
        </Typography>
        <br />
        <br />
        <Grid className={classes.dFlex}>
          <Typography className={classes.addSpace}>1.</Typography>
          <Typography>
            {/* <strong>【個人情報の取り扱いについて】</strong> */}
            個人情報の取得、利用及び提供について
            <br />
            当社の全ての事業遂行のために取り扱う個人情報について、適切な取得、利用及び提供を行い、特定した利用目的の達成に必要な範囲を超えて個人情報を取り扱うことはありません。利用目的を超えて個人情報の取り扱いを行う場合には、あらかじめご本人の同意を得ます。
          </Typography>
        </Grid>
        <br />
        <Grid className={classes.dFlex}>
          <Typography className={classes.addSpace}>2.</Typography>
          <Typography>
            {/* <strong>個人情報に関する法令や指針、規範について</strong> */}
            個人情報に関する法令や指針、規範について
            <br />
            個人情報に関する法令・国が定める指針その他の規範を遵守し、取り扱いの更なる見直しや改善に取り組みます。
          </Typography>
        </Grid>
        <br />
        <Grid className={classes.dFlex}>
          <Typography className={classes.addSpace}>3.</Typography>
          <Typography>
            {/* <strong>個人情報の安全管理について</strong> */}
            個人情報の安全管理について
            <br />
            個人情報への不正アクセスや、個人情報の漏えい、紛失、破壊、改ざん等を防止し、安全を管理するために、適正な安全管理措置を講じます。
            <br />
            <br />
            安全管理措置については 　
            <a
              target="_blank"
              href="https://www.frau-inter.co.jp/dev/wp-content/uploads/2022/09/privacy.pdf"
            >
              こちら
            </a>
          </Typography>
        </Grid>
        <br />
        <Grid className={classes.dFlex}>
          <Typography className={classes.addSpace}>4.</Typography>
          <Typography>
            {/* <strong>個人情報に関する苦情及び相談について</strong> */}
            個人情報に関する苦情及び相談について
            <br />
            個人情報に関する苦情及び相談に、速やかに対処します。
          </Typography>
        </Grid>
        <br />
        <Grid className={classes.dFlex}>
          <Typography className={classes.addSpace}>5.</Typography>
          <Typography>
            {/* <strong>
              個人情報保護の取り組み（個人情報保護マネジメントシステム）について
            </strong> */}
            個人情報保護の取り組み（個人情報保護マネジメントシステム）について
            <br />
            個人情報の保護を適切に行うため、継続的にその取り組みを見直し、改善します。また、個人情報保護に関する定期的な社員教育と監査を行っております。
            <br />
            <br />
            【個人情報保護方針に関するお問合せ】
            <br />
            株式会社フラウ・インターナショナル
            <br />
            電話番号：06-4791-2400
            <br />
            FAX 番号：06-4791-2403
            <br />E メール：info@frau-inter.co.jp
          </Typography>
        </Grid>
      </Box>
      <Box className={classes.borderBottom}>
        <Typography>
          <strong className={classes.textBigSize}>
            【個人情報の取り扱いについて】
          </strong>
        </Typography>
        <br />
        <Typography>
          株式会社フラウ・インターナショナル
          <br />
          個人情報保護管理者　山口 博美
          <br />
          <br />
          当社では、お客様の個人情報を適正に取扱い、プライバシーの保護に努めております。
        </Typography>
        <br />
        <Grid className={classes.dFlex}>
          <Typography className={classes.addSpace}>1.</Typography>
          <Typography>
            ご提供いただいた個人情報は、以下の目的のみに使用いたします。
            <br />
            <Grid className={classes.dFlex}>
              <Typography className={classes.addSpace}>1)</Typography>
              お問合せへの回答
            </Grid>
            <Grid className={classes.dFlex}>
              <Typography className={classes.addSpace}>2)</Typography>
              会員サービスの提供
              <br />
              会員の申込受付、会員特典グッズ等の発送をいたします。また、メールマガジン等の情報を不定期に配信いたします。
            </Grid>
            <Grid className={classes.dFlex}>
              <Typography className={classes.addSpace}>3)</Typography>
              商品の受注及び発送
              <br />
              商品の予約・購入、プレゼントなどの応募、その他の取引の申し込みをいただいた場合に、商品の配送、代金決済、お問い合わせへの対応、関連するアフターサービス、その他取引遂行にあたって必要な範囲で利用いたします。
            </Grid>
            <Grid className={classes.dFlex}>
              <Typography className={classes.addSpace}>4)</Typography>
              イベント申込
            </Grid>
            <Grid className={classes.dFlex}>
              <Typography className={classes.addSpace}>5)</Typography>
              統計処理
              <br />
              当社がホームページ内容、サービス、商品の改善を行うための統計資料として利用いたします。この場合お客様個人を特定することはありません。
            </Grid>
            <Grid className={classes.dFlex}>
              <Typography className={classes.addSpace}>6)</Typography>
              その他ご連絡
              <br />
              当社がお客様との取引、お問い合わせへの対応、当社サービスのご案内等のためにお客様へのご連絡が必要であると判断した場合、メール・電話・手紙にてご連絡を差し上げることがあります。
            </Grid>
          </Typography>
        </Grid>
        <br />
        <Grid className={classes.dFlex}>
          <Typography className={classes.addSpace}>2.</Typography>
          <Typography>
            個人情報は以下に該当する場合を除き第三者に提供することは一切ありません。
            <Grid className={classes.dFlex}>
              <Typography className={classes.addSpace}>1)</Typography>
              法令に基づく場合及び警察、裁判所等の公的機関から要請があった場合。
            </Grid>
            <Grid className={classes.dFlex}>
              <Typography className={classes.addSpace}>2)</Typography>
              ご本人の同意をいただいた場合。
            </Grid>
          </Typography>
        </Grid>
        <br />
        <Grid className={classes.dFlex}>
          <Typography className={classes.addSpace}>3.</Typography>
          <Typography>
            当社では、1
            項に記載する利用目的の達成に必要な範囲で、ご提供いただいた個人情報の全部又は一部の取り扱いを他社に委託する場合があります。この場合、個人情報を適切に取り扱っている委託先を選定し、個人情報の適正管理や機密の保持に関して契約などを締結し適切な管理を実施します。
          </Typography>
        </Grid>
        <br />
        <Grid className={classes.dFlex}>
          <Typography className={classes.addSpace}>4.</Typography>
          <Typography>
            ご提供いただいた個人情報の利用目的の通知、開示（第三者提供記録の開示を含みます。）、訂正・追加・削除、利用停止・消去・第三者提供の停止をご希望される場合は、下記の個人情報相談窓口までご連絡ください。
          </Typography>
        </Grid>
        <br />
        <Grid className={classes.dFlex}>
          <Typography className={classes.addSpace}>5.</Typography>
          <Typography>
            当社に対する個人情報の提供は必須ではありません。ただし、ご提供いただけなかった方は、1
            項のサービスをご利用いただけない場合がございます。ご容赦くださいませ。
          </Typography>
        </Grid>
        <br />
        <Grid className={classes.dFlex}>
          <Typography className={classes.addSpace}>6.</Typography>
          <Typography>
            本ウェブサイトには、お客様に一層便利にご利用いただくため、一部のページで「Cookie」という技術を使用しています。Cookie
            により、ウェブサイトはお客様のコンピュータを識別することができるようになりますが、お客様のお名前、ご住所、電話番号、メールアドレスといった個人を特定可能な情報を取得することは一切ありません。お客様は、ご利用いただくブラウザの設定により
            Cookie の受け取りを拒否したり、Cookie
            を受け取ったときに警告を表示させたりすることができます。お客様が
            Cookie
            の受け取りを拒否された場合も本ウェブサイトをご利用いただけますが、一部機能がご利用になれない場合があります。
            <br />
            <br />
            <strong className={classes.textBigSize}>
              【お客様個人情報の取り扱いに関するお問合せ】
            </strong>
            <br />
            株式会社フラウ・インターナショナル　お客様相談室
            <br />
            住所：大阪市中央区北浜東 1-22 北浜東野村ビル8階
            <br />
            電話番号：06-4791-2400
            <br />
            受付時間：平日 10:00～18:00（土日祝/年末年始は休業）
            <br />
            担当者：個人情報保護管理責任者 お客様相談室長
          </Typography>
        </Grid>
      </Box>
      <Box className={classes.borderBottom} style={{ borderBottom: 'none' }}>
        {/* <Box> */}
        <Typography>
          <strong className={classes.textBigSize}>
            【開示対象個人情報に関する周知について】
          </strong>
          <br />
          当社は、当社の保有個人データ（以下、「開示対象個人情報」といいます。）に関する当社での取り扱いについて、以下のように通知いたします。
        </Typography>
        <br />
        <br />
        <Grid className={classes.dFlex}>
          <Typography className={classes.addSpace}>1.</Typography>
          <Typography>
            個人情報を取り扱う事業者について
            <br />
            大阪市中央区北浜東 1-22 北浜東野村ビル8階
            <br />
            株式会社フラウ･インターナショナル
            <br />
            代表取締役 金沢 榮美
          </Typography>
        </Grid>
        <br />
        <Grid className={classes.dFlex}>
          <Typography className={classes.addSpace}>2.</Typography>
          <Typography>
            個人情報保護管理者
            <br />
            株式会社フラウ･インターナショナル　山口 博美
          </Typography>
        </Grid>
        <br />
        <Grid className={classes.dFlex}>
          <Typography className={classes.addSpace}>3.</Typography>
          <Typography>
            開示対象個人情報の利用目的について
            <Grid className={classes.dFlex}>
              <Typography className={classes.addSpace}>1)</Typography>
              お問い合わせへの回答のため
            </Grid>
            <Grid className={classes.dFlex}>
              <Typography className={classes.addSpace}>2)</Typography>
              イベント申込のため
            </Grid>
            <Grid className={classes.dFlex}>
              <Typography className={classes.addSpace}>3)</Typography>
              会員管理のため
            </Grid>
            <Grid className={classes.dFlex}>
              <Typography className={classes.addSpace}>4)</Typography>
              商品発送のため
            </Grid>
            <Grid className={classes.dFlex}>
              <Typography className={classes.addSpace}>5)</Typography>
              受注管理のため
            </Grid>
          </Typography>
        </Grid>
        <br />
        <Grid className={classes.dFlex}>
          <Typography className={classes.addSpace}>4.</Typography>
          <Typography>
            開示対象個人情報の取扱に対する苦情の申し出先
            <br />
            開示対象個人情報の取り扱いに関する苦情・相談は、以下の＜個人情報に関する連絡先＞に記載する連絡先までご連絡ください。
            <br />
            <br />
            <strong>＜個人情報に関する連絡先＞</strong>
            <br />
            株式会社フラウ･インターナショナルお客様相談室
            <br />
            電話番号：06-4791-2400
          </Typography>
        </Grid>
        <br />
        <Grid className={classes.dFlex}>
          <Typography className={classes.addSpace}>5.</Typography>
          <Typography>
            認定個人情報保護団体について
            <br />
            <strong>
              ＜当社が所属する「認定個人情報保護団体」の名称及び連絡先＞
            </strong>
            <br />
            一般財団法人日本情報経済社会推進協会 JIPDEC
            <br />
            苦情の解決の申し出先 消費者相談室
            <br />
            〒106-0032 東京都港区六本木 1 丁目9番9号六本木ファーストビル内
            <br />
            電話番号: 03-5860-7565
            <br />
            フリーダイヤル: 0120-700-779
            <br />
            ※当社の商品・サービスに関するお問い合わせ先ではございません。
            <br />
            商品・サービスに関するお問い合わせは当社に直接ご連絡ください。
          </Typography>
        </Grid>
        <br />
        <Grid className={classes.dFlex}>
          <Typography className={classes.addSpace}>6.</Typography>
          <Typography>
            開示等の請求について
            <br />
            当社で取り扱っている開示対象個人情報について、当社への個人情報の利用目的の通知、開示（第三者提供記録の開示を含みます。）、内容の訂正・追加・削除、利用の停止・消去・第三者への提供の停止を求める場合、以下に記載してある連絡先までご連絡ください。
            <br />
            <br />
            <Grid className={classes.dFlex}>
              <Typography className={classes.addSpace}>1)</Typography>
              開示等の申出先
              <br />
              株式会社フラウ･インターナショナル　お客様相談室
              <br />
              住所：大阪市中央区北浜東1-22　北浜東野村ビル8階
              <br />
              電話番号：06-4791-2400
            </Grid>
            <Grid className={classes.dFlex}>
              <Typography className={classes.addSpace}>2)</Typography>
              開示等のご請求の受付
              <br />
              開示等の対応に関しましては、お電話または直接来訪いただく方法により、ご請求いただきますようお願いいたします。
            </Grid>
            <Grid className={classes.dFlex}>
              <Typography className={classes.addSpace}>3)</Typography>
              本人確認方法について
              <br />
              免許証、住民基本台帳ネットワークカード（写真付き）等の確認などの方法による本人確認を行わせていただきます。
            </Grid>
          </Typography>
        </Grid>
        <br />
        <br />
        <Typography>以上</Typography>
        <br />
      </Box>
      <br />
      <img alt="logo" src={LogoP} width="100" style={{ marginLeft: 15 }} />
      <br />
      <br />
    </div>
  );
}

export default Privacy;
