import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Container, makeStyles, Typography } from '@material-ui/core';
import _ from 'lodash';
import PaginationButton from 'src/components/PaginationButton';
import PhotoList from './PhotoList';
import { getPhoto } from 'src/services/photoService';
import { photoSelector } from 'src/slices/photos';
import LoaderLayer from 'src/components/LoaderLayer';
import { authSelector } from 'src/slices/auth';
import { getDomainTalent, getLoginToken } from 'src/utils/auth';
import CheckPaymentDialog from 'src/components/CheckPaymentDialog';
import { ROUTES } from 'src/constants';
import moment from 'moment';
import { useSearchParams } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  root: {
    justifyContent: 'center',
    display: 'flex',
    flexDirection: 'column',
    paddingTop: 50,
    [theme.breakpoints.down(700)]: {
      padding: '50px 6px 0'
    }
  },
  container: {
    maxWidth: 1040,
    padding: theme.spacing(0, 8),
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(0, 2)
    }
  },
  textUppercase: {
    fontFamily: 'futura-pt',
    fontWeight: 600,
    fontSize: 25,
    textTransform: 'uppercase'
  },
  newContent: {
    margin: '50px 0'
  },
  wrapPagination: {
    margin: '40px 0'
  }
}));

function Photo({ setting }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const photos = useSelector(photoSelector);
  const { profile } = useSelector(authSelector);
  const [searchParams, setSearchParams] = useSearchParams();
  const ncrp = parseInt(searchParams.get('page'));
  const [page, setPage] = useState(ncrp ? ncrp : 1);
  const [isPayment, setIsPayment] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);

  useEffect(() => {
    const { members } = profile;
    const domainTalent = getDomainTalent();

    const myProfile = _.find(
      members,
      (member) => member.talent.domain.split('//')[1] === domainTalent
    );

    if (
      myProfile?.member_type === 'lifetime' ||
      myProfile?.member_type === 'existing_fan'
    ) {
      setIsPayment(true);
    } else if (
      myProfile?.role === 'talent' ||
      myProfile?.role === 'staff_view' ||
      myProfile?.role === 'staff_edit'
    ) {
      setIsPayment(true);
    } else if (
      myProfile?.end_datetime >= moment(new Date()).format('YYYY-MM-DD') &&
      myProfile?.role === 'fan'
    ) {
      setIsPayment(true);
    }
  }, []);

  useEffect(() => {
    dispatch(
      getPhoto({ page: page, perPage: window.innerWidth <= 900 ? 10 : 9 })
    );
  }, [page]);

  useEffect(() => {
    const searchPage = parseInt(window.location.search.split('=')[1]);
    if (!ncrp && !window.location.search) {
      setPage(1);
    } else if (searchPage !== page) {
      setPage(searchPage);
    }
  }, [window.location.search]);

  return (
    <div className={clsx(classes.root)}>
      <Container className={classes.container}>
        <Box mb={5} display="flex">
          <Box>
            <Typography className={classes.textUppercase}>Photo</Typography>
          </Box>
        </Box>
        <Box className={clsx(classes.newContent)}>
          <PhotoList
            photos={photos}
            setting={setting}
            onOpen={setOpenDialog}
            isPayment={isPayment}
          />
        </Box>
        {photos?.totalPage > 1 && (
          <Box className={clsx(classes.wrapPagination)}>
            <PaginationButton
              page={page}
              totalItem={
                photos?.totalPage * (window.innerWidth <= 900 ? 10 : 9)
              }
              itemPerPage={window.innerWidth <= 900 ? 10 : 9}
              paginate={setPage}
            />
          </Box>
        )}
      </Container>

      <LoaderLayer loading={photos?.loading} />
      {openDialog && (
        <CheckPaymentDialog
          open={openDialog}
          onClose={setOpenDialog}
          pathName={ROUTES.PHOTO}
        />
      )}
    </div>
  );
}

export default Photo;
