import { createSlice } from '@reduxjs/toolkit';
import { getSettings } from 'src/services/settingService';

export const settingSlice = createSlice({
  name: 'settings',
  initialState: {
    loading: false,
    settings: [],
    errorMessage: ''
  },
  reducers: {
    clearState: (state) => {
      state.settings = []
      state.loading = false;
      state.errorMessage = '';
      return state;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getSettings.rejected, (state, { payload }) => {
      state.errorMessage = payload?.error;
      state.loading = false;
    }),
    builder.addCase(getSettings.pending, (state) => {
      state.loading = true;
    }),
    builder.addCase(getSettings.fulfilled, (state, { payload }) => {
      state.settings = payload;
      state.loading = false;
      return state;
    })
  }
});

export const settingSelector = (state) => state.settings;
export const reducer = settingSlice.reducer;
export const { clearState } = settingSlice.actions;
