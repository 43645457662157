import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'src/utils/API';

export const getMagazines = createAsyncThunk(
  'magazine',
  async ({ page, perPage }, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `/magazines?page=${page}&perPage=${perPage}`
      );

      if (response.status === 200) {
        return response?.data?.data;
      }
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getMagazineId = createAsyncThunk(
  'magazine/id',
  async ({ id }, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `/magazines/${id}`
      );

      if (response.status === 200) {
        return response?.data?.data;
      }
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
