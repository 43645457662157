import React from 'react';
import { makeStyles, Button } from '@material-ui/core';
import PropTypes from 'prop-types';

const useStyles = makeStyles(() => ({
  root: {},
  button_radius: {
    fontFamily: 'futura-pt',
    fontSize: 16,
    fontWeight: 500,
    width: 150,
    borderRadius: 28,
    overflow: 'hidden',
    transition: 'ease .2s',
    isolation: 'isolate',
    '&:hover': {
      borderColor: '#fff'
    },
    '&:hover:before': {
      transformOrigin: 'left top',
      transform: 'scale(1, 1)'
    },
    '&:before': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      zIndex: 0,
      backgroundColor: '#fff',
      width: '100%',
      height: '100%',
      transition: 'transform .6s cubic-bezier(0.8, 0, 0.2, 1) 0s',
      transform: 'scale(0, 1)',
      transformOrigin: 'right top'
    },
    '& span': {
      zIndex: 3
    }
  }
}));

export default function ButtonRadius({
  buttonName,
  backgroundColor,
  color,
  width,
  onClick
}) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Button
        color="inherit"
        variant="outlined"
        className={classes.button_radius}
        style={{
          backgroundColor: backgroundColor,
          color: color,
          width: width
        }}
        onClick={onClick}
      >
        {buttonName}
      </Button>
    </div>
  );
}

ButtonRadius.propTypes = {
  buttonName: PropTypes.string,
  backgroundColor: PropTypes.string,
  color: PropTypes.string,
  onClick: PropTypes.func
};
