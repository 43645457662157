import './styles.css';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import SwiperCore, { Autoplay, Pagination, Navigation } from 'swiper/core';
import { useState } from 'react';
SwiperCore.use([Autoplay, Pagination, Navigation]);

const breakpoints = {
  0: {
    slidesPerView: 1.2
  },
  600: {
    slidesPerView: 2.2
  }
};

const TopBanner = ({ imageList }) => {
  const [currentPage, setCurrentPage] = useState(1);

  return (
    <>
      <Swiper
        spaceBetween={10}
        centeredSlides={true}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
          pauseOnMouseEnter: true
        }}
        pagination={{
          type: 'progressbar'
        }}
        loop
        className="mySwiper"
        breakpoints={breakpoints}
        onSlideChange={(element) => setCurrentPage(element.realIndex + 1)}
      >
        {imageList.map((image, index) => {
          return (
            <SwiperSlide
              key={index}
              className={image.external_url ? 'open__newtab' : ''}
              onClick={() => {
                if (image.external_url) {
                  window.open(image.external_url, '_blank');
                }
              }}
            >
              <img
                draggable={false}
                style={{ width: '100%', height: '100%', aspectRatio: '16/9' }}
                src={image.image_url}
                className={image.external_url ? 'open__newtab' : ''}
              />
            </SwiperSlide>
          );
        })}
      </Swiper>
      <div className="bannerSlideNumber">
        {currentPage}／{imageList.length}
      </div>
    </>
  );
};

export default TopBanner;
