import { createSlice } from '@reduxjs/toolkit';
import {
  getBlogById,
  getBlog,
  postFavoriteBlog,
  postCommentBlog,
  deleteCommentBlog
} from 'src/services/blogsService';

export const blogSlice = createSlice({
  name: 'blogs',
  initialState: {
    loading: false,
    blogs: [],
    tagsBlog: [],
    BlogDetail: '',
    errorMessage: '',
    currentPage: 1,
    totalPage: 1,
    favoriteLoading: false
  },
  reducers: {
    clearState: (state) => {
      state.blogs = [];
      state.tagsBlog = [];
      state.BlogDetail = '';
      state.errorMessage = '';
      state.currentPage = 1;
      state.totalPage = 1;
      state.loading = false;
      state.favoriteLoading = false;
      return state;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getBlogById.pending, (state) => {
      state.loading = true;
    }),
      builder.addCase(getBlogById.fulfilled, (state, { payload }) => {
        state.tagsBlog = payload;
        state.loading = false;
        return state;
      }),
      builder.addCase(getBlogById.rejected, (state, { payload }) => {
        state.errorMessage = payload?.error;
        state.loading = false;
      }),
      builder.addCase(getBlog.pending, (state) => {
        state.loading = true;
      }),
      builder.addCase(getBlog.fulfilled, (state, { payload }) => {
        state.blogs = payload.data;
        state.currentPage = payload.current_page;
        state.totalPage = payload.last_page;
        state.loading = false;
        return state;
      }),
      builder.addCase(getBlog.rejected, (state, { payload }) => {
        state.errorMessage = payload?.error;
        state.loading = false;
      }),
      builder.addCase(postFavoriteBlog.pending, (state) => {
        state.favoriteLoading = false;
      }),
      builder.addCase(postFavoriteBlog.fulfilled, (state, { payload }) => {
        state.tagsBlog = payload;
        state.blogs = state?.blogs?.map((item) => {
          if (item?.id === payload?.id) {
            item = payload;
          }
          return item;
        });
        state.favoriteLoading = false;
        return state;
      }),
      builder.addCase(postFavoriteBlog.rejected, (state, { payload }) => {
        state.errorMessage = payload?.error;
        state.favoriteLoading = false;
      });

    builder.addCase(postCommentBlog.pending, (state) => {
      state.loading = true;
    }),
      builder.addCase(postCommentBlog.fulfilled, (state, { payload }) => {
        state.tagsBlog = payload;
        state.loading = false;
        return state;
      }),
      builder.addCase(postCommentBlog.rejected, (state, { payload }) => {
        state.errorMessage = payload?.error;
        state.loading = false;
      });

    builder.addCase(deleteCommentBlog.pending, (state) => {
      state.loading = true;
    }),
      builder.addCase(deleteCommentBlog.fulfilled, (state, { payload }) => {
        state.tagsBlog.comments.data = state.tagsBlog.comments.data.filter(
          (obj) => obj.id != payload
        );
        state.loading = false;
        return state;
      }),
      builder.addCase(deleteCommentBlog.rejected, (state, { payload }) => {
        state.errorMessage = payload?.error;
        state.loading = false;
      });
  }
});

export const blogSelector = (state) => state.blog;
export const reducer = blogSlice.reducer;
export const { clearState } = blogSlice.actions;
