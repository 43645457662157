import _ from 'lodash';
import clsx from 'clsx';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, Container, Grid, Typography, CardMedia } from '@material-ui/core';

import { PopUpSlidePhoto } from '../PopUpSlidePhoto';
import { getPhotoById } from 'src/services/photoService';
import { photoSelector } from 'src/slices/photos';
import LoaderLayer from 'src/components/LoaderLayer';
import { authSelector } from 'src/slices/auth';
import { getDomainTalent, getLoginToken } from 'src/utils/auth';
import CheckPaymentDialog from 'src/components/CheckPaymentDialog';
import { ROUTES } from 'src/constants';
import AuthDialog from 'src/components/AuthDialog';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingBottom: 50
  },
  container: {
    maxWidth: 1040,
    padding: theme.spacing(0, 8),
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(0, 2)
    }
  },
  media: {
    height: 220,
    cursor: 'pointer',
    backgroundPosition: 'top !important',
    [theme.breakpoints.down(700)]: {
      height: 168
    }
  },
  textUppercase: {
    fontWeight: 'bold',
    fontSize: 18,
    textTransform: 'uppercase'
  },
  backButton: {
    width: '100%',
    textAlign: 'center',
    fontSize: 13,
    margin: '30px 0',
    cursor: 'pointer',
    padding: 10
  }
}));

function PhotoDetails({ className }) {
  const classes = useStyles();
  const [openPopUpSlidePhoto, setOpenPopUpSlidePhoto] = useState(false);
  const dispatch = useDispatch();
  const photos = useSelector(photoSelector);
  const { profile } = useSelector(authSelector);
  const { id } = useParams();
  const [openDialog, setOpenDialog] = useState(false);
  const [indexImage, setIndexImage] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getPhotoById({ id }));

    const { members } = profile;
    const domainTalent = getDomainTalent();

    const myProfile = _.find(
      members,
      (member) => member.talent.domain.split('//')[1] === domainTalent
    );

    if (
      myProfile?.member_type === 'lifetime' ||
      myProfile?.member_type === 'existing_fan'
    ) {
      return;
    } else if (
      myProfile?.role === 'talent' ||
      myProfile?.role === 'staff_view' ||
      myProfile?.role === 'staff_edit'
    ) {
      return;
    } else if (
      myProfile?.end_datetime >= moment(new Date()).format('YYYY-MM-DD') &&
      myProfile?.role === 'fan'
    ) {
      return;
    } else {
      setOpenDialog(true);
    }
  }, []);

  const handleOpenSlidePhoto = (index) => {
    setOpenPopUpSlidePhoto(true);
    setIndexImage(index);
  };

  return (
    <div className={clsx(classes.root)}>
      {getLoginToken() && !openDialog && !photos?.loading && (
        <Container className={classes.container}>
          <Box mb={5} display="flex">
            <Box>
              <Typography className={classes.textUppercase}>Photo</Typography>
            </Box>
          </Box>
          <Box className={clsx(classes.newContent)}>
            <div className={clsx(classes.root, className)}>
              <Container style={{ padding: 0 }}>
                <Grid container spacing={3}>
                  {photos?.tagsPhoto?.photo_imgs?.map((photoDetail, index) => (
                    <Grid item md={4} sm={6} xs={6} key={photoDetail.id}>
                      <Box mt={3} textAlign="center">
                        <div>
                          <CardMedia
                            className={classes.media}
                            image={photoDetail?.s3_url}
                            onClick={() => {
                              handleOpenSlidePhoto(index);
                            }}
                          />
                        </div>
                      </Box>
                    </Grid>
                  ))}
                </Grid>
              </Container>
              <PopUpSlidePhoto
                photos={photos}
                indexImage={indexImage}
                setIndexImage={setIndexImage}
                open={{
                  openPopUpSlidePhoto,
                  handleClosePopUp: () => {
                    setOpenPopUpSlidePhoto(false);
                    setIndexImage(0);
                  }
                }}
              />
            </div>
          </Box>
          <div
            className={classes.backButton}
            onClick={() => {
              navigate(-1);
            }}
          >
            一覧へ戻る
          </div>
        </Container>
      )}
      <LoaderLayer loading={photos?.loading} />
      {!getLoginToken() ? (
        <AuthDialog open={true} onClose={() => {}} path={ROUTES.PHOTO} />
      ) : (
        openDialog && (
          <CheckPaymentDialog
            open={openDialog}
            onClose={setOpenDialog}
            pathName={ROUTES.PHOTO}
          />
        )
      )}
    </div>
  );
}

PhotoDetails.propTypes = {
  className: PropTypes.string,
  noticeList: PropTypes.array
};

export default PhotoDetails;
