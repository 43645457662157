import { createSlice } from '@reduxjs/toolkit';
import {
  getCategories,
  postCommunityCategory,
  uploadImage,
  getCategoriesById,
  getThreadById,
  postComment,
  searchThread
} from 'src/services/communityService';
export const communitySlice = createSlice({
  name: 'community',
  initialState: {
    loading: false,
    categories: [],
    threads: [],
    thread: {},
    postCommunity: {},
    errorMessage: '',
    currentPage: 1,
    totalPage: 1,
    total: 0,
    to: 0
  },
  reducers: {
    clearState: (state) => {
      state.categories = [];
      state.threads = [];
      state.thread = {};
      state.postCommunity = {};
      state.currentPage = 1;
      state.errorMessage = '';
      state.totalPage = 1;
      state.total = 0;
      state.to = 0;
      state.loading = false;
      return state;
    },
    setPostCommunityInfo: (state, action) => {
      state.postCommunity = action.payload;
    },
    clearPostCommunityInfo: (state) => {
      state.postCommunity = {};
      return state;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getCategories.pending, (state) => {
      state.loading = true;
    }),
      builder.addCase(getCategories.fulfilled, (state, { payload }) => {
        state.categories = payload.data;
        state.currentPage = payload.current_page;
        state.totalPage = payload.last_page;
        state.loading = false;
        return state;
      }),
      builder.addCase(getCategories.rejected, (state, { payload }) => {
        state.errorMessage = payload?.error;
        state.loading = false;
      });
    builder.addCase(getCategoriesById.pending, (state) => {
      state.loading = true;
    }),
      builder.addCase(getCategoriesById.fulfilled, (state, { payload }) => {
        state.threads = payload.data;
        state.currentPage = payload.current_page;
        state.totalPage = payload.last_page;
        state.total = payload.total;
        state.to = payload.to;
        state.loading = false;
        return state;
      }),
      builder.addCase(getCategoriesById.rejected, (state, { payload }) => {
        state.errorMessage = payload?.error;
        state.loading = false;
      });
    builder.addCase(postCommunityCategory.pending, (state) => {
      state.loading = true;
    }),
      builder.addCase(postCommunityCategory.fulfilled, (state) => {
        state.loading = false;
        return state;
      }),
      builder.addCase(postCommunityCategory.rejected, (state, { payload }) => {
        state.errorMessage = payload?.error;
        state.loading = false;
      });
    builder.addCase(uploadImage.pending, (state) => {
      state.loading = true;
    }),
      builder.addCase(uploadImage.fulfilled, (state) => {
        state.loading = false;
        return state;
      }),
      builder.addCase(uploadImage.rejected, (state, { payload }) => {
        state.errorMessage = payload?.error;
        state.loading = false;
      });
    builder.addCase(getThreadById.pending, (state) => {
      state.loading = true;
    }),
      builder.addCase(getThreadById.fulfilled, (state, { payload }) => {
        state.thread = payload;
        state.loading = false;
        return state;
      }),
      builder.addCase(getThreadById.rejected, (state, { payload }) => {
        state.errorMessage = payload?.error;
        state.loading = false;
      });
    builder.addCase(postComment.pending, (state) => {
      state.loading = true;
    }),
      builder.addCase(postComment.fulfilled, (state) => {
        state.loading = false;
        return state;
      }),
      builder.addCase(postComment.rejected, (state, { payload }) => {
        state.errorMessage = payload?.error;
        state.loading = false;
      });
    builder.addCase(searchThread.pending, (state) => {
      state.loading = true;
    }),
      builder.addCase(searchThread.fulfilled, (state, { payload }) => {
        state.threads = payload.data;
        state.currentPage = payload.current_page;
        state.totalPage = payload.last_page;
        state.total = payload.total;
        state.to = payload.to;
        state.loading = false;
        return state;
      }),
      builder.addCase(searchThread.rejected, (state, { payload }) => {
        state.errorMessage = payload?.error;
        state.loading = false;
      });
  }
});
export const communitySelector = (state) => state.community;
export const reducer = communitySlice.reducer;
export const { clearState, setPostCommunityInfo, clearPostCommunityInfo } =
  communitySlice.actions;
