import clsx from 'clsx';
import { forwardRef } from 'react';
import PropTypes from 'prop-types';
import Slide from '@mui/material/Slide';
import Dialog from '@mui/material/Dialog';
import { useNavigate } from 'react-router';
import { Box, makeStyles, Typography, Button } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  dialogContainer: {
    padding: '1.5rem 4rem',
    [theme.breakpoints.down(700)]: {
      padding: '1.5rem 3rem'
    }
  },
  title: {
    margin: '1rem 0 3rem',
    padding: '0 3rem',
    '& h5': {
      fontWeight: '600'
    },
    [theme.breakpoints.down(700)]: {
      padding: '0 2rem'
    }
  },
  wrapButton: {
    display: 'flex',
    justifyContent: 'center',
    margin: '5rem 0',
    [theme.breakpoints.down(700)]: {
      flexDirection: 'column',
      margin: '2rem 0'
    }
  },
  buttonBack: {
    backgroundColor: '#000000 !important',
    color: '#fff !important'
  },
  buttonDelete: {
    backgroundColor: '#A7A7A7 !important',
    color: '#fff !important'
  },
  button: {
    padding: '12px 0 !important',
    width: '100%',
    borderRadius: 0,
    color: '#fff !important',
    '&:hover': {
      backgroundColor: '#1175ae'
    }
  }
}));

export default function NotiDialog({
  open,
  onClose,
  path,
  message,
  buttonName
}) {
  const classes = useStyles();
  const navigate = useNavigate();

  const handleClose = () => {
    onClose(false);
  };

  return (
    <Dialog
      TransitionComponent={Transition}
      open={open}
      aria-describedby="alert-dialog-slide-description"
    >
      <Box className={clsx(classes.dialogContainer)}>
        <Box className={clsx(classes.title)}>
          <Typography variant="h6">{message}</Typography>
        </Box>
        <Box mt={2}>
          <Button
            onClick={() => {
              handleClose();
              navigate(path);
            }}
            className={clsx(classes.button, classes.buttonBack)}
          >
            {buttonName}
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
}

NotiDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  path: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  buttonName: PropTypes.string.isRequired
};

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
