import React, { useEffect } from 'react';
import {
  Link as RouterLink,
  useNavigate,
  useSearchParams
} from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
  Drawer,
  Grid,
  Hidden,
  IconButton,
  Typography
} from '@material-ui/core';
import LogoMobile from 'src/components/LogoMobile';
import CloseIcon from '@mui/icons-material/Close';
import XIcon from '@mui/icons-material/X';
import InstagramIcon from '@mui/icons-material/Instagram';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLine, faYoutube } from '@fortawesome/free-brands-svg-icons';
import { ROUTES, ROUTES_AUTHEN } from 'src/constants';
import { useDispatch } from 'react-redux';
import { clearState, logout } from 'src/slices/auth';
import { sendMessageToReactNative } from 'src/components/WebReactNativeCommunicate';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  mobileDrawer: {
    width: '100%'
  },
  desktopDrawer: {
    width: 265,
    top: 50,
    height: 'calc(100% - 50px)'
  },
  profile: {
    padding: theme.spacing(3),
    display: 'flex',
    alignItems: 'center'
  },
  logoMobile: {
    width: 450,
    maxWidth: '100%'
  },
  iconClose: {
    width: '50px !important',
    height: '50px !important',
    color: '#707070'
  },
  wrapMenu: {
    padding: theme.spacing(0, 5)
  },
  wrapSNS: {
    padding: '20px 40px',
    display: 'flex'
  },
  headerMenu: {
    color: '#000000',
    fontSize: '0.8rem',
    marginBottom: theme.spacing(5)
  },
  menuName: {
    color: '#000000',
    fontFamily: 'futura-pt',
    fontWeight: '600',
    '& h2': {
      fontFamily: 'futura-pt',
      fontWeight: '600'
    },
    [theme.breakpoints.down(700)]: {
      '& h2': {
        fontSize: '1rem'
      },
      fontSize: '1rem'
    }
  },
  iconButton: {
    padding: 0,
    marginRight: 10
  },
  iconSNS: {
    color: '#000000',
    fontSize: '38px !important'
  },
  footer: {
    backgroundColor: '#000000',
    height: '100%',
    padding: theme.spacing(5)
  },
  headerFCMenu: {
    color: '#ffffff',
    fontFamily: 'futura-pt',
    fontWeight: '400',
    fontSize: '0.8rem',
    marginBottom: theme.spacing(5)
  },
  menuFCName: {
    color: '#ffffff',
    fontFamily: 'futura-pt',
    fontWeight: '600',
    [theme.breakpoints.down(700)]: {
      fontSize: '1rem'
    }
  },
  wrapFooter: {
    display: 'flex',
    gap: 100,
    marginTop: theme.spacing(5)
  },
  font: {
    fontFamily: 'futura-pt',
    fontWeight: '400'
  }
}));

function NavBar({
  openMobile,
  onMobileClose,
  setting,
  profile,
  className,
  ...rest
}) {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);

  const content = (
    <div {...rest} className={clsx(classes.root, className)}>
      <div>
        <div className={classes.profile}>
          <RouterLink to="/" style={{ width: '90%' }}>
            <LogoMobile className={classes.logoMobile} />
          </RouterLink>
          <IconButton
            className={classes.menuButton}
            color="inherit"
            onClick={onMobileClose}
          >
            <CloseIcon className={classes.iconClose} />
          </IconButton>
        </div>
        <div className={classes.wrapMenu}>
          <Typography
            variant="h4"
            className={clsx(classes.headerMenu, classes.font)}
          >
            MENU
          </Typography>
          <Grid container spacing={2}>
            {setting?.settings?.talent_settings?.menu_notice_position === 1 && (
              <Grid item xs={6}>
                <Typography
                  variant="h2"
                  className={classes.menuName}
                  style={{
                    order:
                      setting?.settings?.talent_settings?.menu_notice_number
                  }}
                  onClick={() => navigate(ROUTES.NOTICE)}
                >
                  NOTICE
                </Typography>
              </Grid>
            )}
            {setting?.settings?.talent_settings?.menu_profile_position ===
              1 && (
              <Grid item xs={6}>
                <Typography
                  variant="h2"
                  className={classes.menuName}
                  style={{
                    order:
                      setting?.settings?.talent_settings?.menu_profile_number
                  }}
                  onClick={() => navigate(ROUTES.PROFILE)}
                >
                  PROFILE
                </Typography>
              </Grid>
            )}
            {setting?.settings?.talent_settings?.menu_discography_position ===
              1 && (
              <Grid item xs={6}>
                <Typography
                  variant="h2"
                  className={classes.menuName}
                  style={{
                    order:
                      setting?.settings?.talent_settings
                        ?.menu_discography_number
                  }}
                  onClick={() => navigate(ROUTES.DISCOGRAPHY)}
                >
                  DISCOGRAPHY
                </Typography>
              </Grid>
            )}
            {setting?.settings?.talent_settings?.menu_youtube_position ===
              1 && (
              <Grid item xs={6}>
                <Typography
                  variant="h2"
                  className={classes.menuName}
                  style={{
                    order:
                      setting?.settings?.talent_settings?.menu_youtube_number
                  }}
                  onClick={() => navigate(ROUTES.YOUTUBE)}
                >
                  YOUTUBE
                </Typography>
              </Grid>
            )}
            {setting?.settings?.talent_settings?.menu_photo_position === 1 && (
              <Grid item xs={6}>
                <Typography
                  variant="h2"
                  className={classes.menuName}
                  style={{
                    order: setting?.settings?.talent_settings?.menu_photo_number
                  }}
                  onClick={() => navigate(ROUTES.PHOTO)}
                >
                  PHOTO
                </Typography>
              </Grid>
            )}
            {setting?.settings?.talent_settings?.menu_video_position === 1 && (
              <Grid item xs={6}>
                <Typography
                  variant="h2"
                  className={classes.menuName}
                  style={{
                    order: setting?.settings?.talent_settings?.menu_video_number
                  }}
                  onClick={() => navigate(ROUTES.VIDEO)}
                >
                  VIDEO
                </Typography>
              </Grid>
            )}
            {setting?.settings?.talent_settings?.menu_shop_position === 1 && (
              <Grid item xs={6}>
                <Typography
                  variant="h2"
                  className={classes.menuName}
                  style={{
                    order: setting?.settings?.talent_settings?.menu_shop_number
                  }}
                  onClick={() =>
                    window.open(
                      setting?.settings?.shop_url,
                      '_blank',
                      'noopener,noreferrer'
                    )
                  }
                >
                  SHOP
                </Typography>
              </Grid>
            )}
            {setting?.settings?.talent_settings?.menu_fromtalent_position ===
              1 && (
              <Grid
                item
                xs={6}
                className={classes.menuName}
                style={{
                  order:
                    setting?.settings?.talent_settings?.menu_fromtalent_number
                }}
              >
                <Typography
                  variant="h2"
                  onClick={() => {
                    navigate(ROUTES.TALENT_BLOG);
                    handleClose();
                  }}
                >
                  MESSAGE
                </Typography>
              </Grid>
            )}
            {setting?.settings?.talent_settings?.menu_staffblog_position ===
              1 && (
              <Grid
                item
                xs={6}
                className={classes.menuName}
                style={{
                  order:
                    setting?.settings?.talent_settings?.menu_staffblog_number
                }}
              >
                <Typography
                  onClick={() => {
                    navigate(ROUTES.STAFF_BLOG);
                    handleClose();
                  }}
                  variant="h2"
                >
                  STAFF BLOG
                </Typography>
              </Grid>
            )}
            {setting?.settings?.talent_settings?.menu_magazine_position ===
              1 && (
              <Grid
                item
                xs={6}
                className={classes.menuName}
                style={{
                  order:
                    setting?.settings?.talent_settings?.menu_magazine_number
                }}
              >
                <Typography
                  onClick={() => {
                    navigate(ROUTES.MAGAZINE);
                    handleClose();
                  }}
                  variant="h2"
                >
                  MAGAZINE
                </Typography>
              </Grid>
            )}
            {setting?.settings?.talent_settings?.menu_community_position ===
              1 && (
              <Grid
                item
                xs={6}
                className={classes.menuName}
                style={{
                  order:
                    setting?.settings?.talent_settings?.menu_community_number
                }}
              >
                <Typography
                  onClick={() => {
                    navigate(ROUTES.COMMUNITY);
                    handleClose();
                  }}
                  variant="h2"
                >
                  COMMUNITY
                </Typography>
              </Grid>
            )}
            {setting?.settings?.talent_settings?.menu_schedule_position ===
              1 && (
              <Grid
                item
                xs={6}
                className={classes.menuName}
                style={{
                  order:
                    setting?.settings?.talent_settings?.menu_schedule_number
                }}
              >
                <Typography
                  variant="h2"
                  onClick={() => {
                    navigate(ROUTES.SCHEDULE);
                    handleClose();
                  }}
                >
                  SCHEDULE
                </Typography>
              </Grid>
            )}
            {setting?.settings?.social_infos?.menu_download_position === 1 && (
              <Grid item xs={6} className={classes.menuName}>
                <Typography
                  onClick={() => {
                    navigate(ROUTES.DOWNLOAD);
                    handleClose();
                  }}
                  variant="h2"
                  style={{
                    order: setting?.settings?.social_infos?.menu_download_number
                  }}
                >
                  DOWNLOAD
                </Typography>
              </Grid>
            )}
            {setting?.settings?.social_infos?.menu_loveletter_position ===
              1 && (
              <Grid
                item
                xs={6}
                className={classes.menuName}
                style={{
                  order: setting?.settings?.social_infos?.menu_loveletter_number
                }}
              >
                <Typography
                  onClick={() => {
                    navigate(ROUTES.LOVE_LETTER);
                    handleClose();
                  }}
                  variant="h2"
                >
                  LOVE LETTER
                </Typography>
              </Grid>
            )}
          </Grid>
        </div>
        <div className={classes.wrapSNS}>
          {setting?.settings?.social_infos?.twitter_active_web === 1 && (
            <IconButton
              color="inherit"
              className={classes.iconButton}
              onClick={() => {
                window.open(
                  setting?.settings?.social_infos?.twitter_url,
                  '_blank',
                  'noopener,noreferrer'
                );
              }}
              style={{
                order: setting?.settings?.social_infos?.twitter_number
              }}
            >
              <XIcon className={classes.iconSNS} />
            </IconButton>
          )}
          {setting?.settings?.social_infos?.instagram_active_web === 1 && (
            <IconButton
              color="inherit"
              className={classes.iconButton}
              onClick={() => {
                window.open(
                  setting?.settings?.social_infos?.instagram_url,
                  '_blank',
                  'noopener,noreferrer'
                );
              }}
              style={{
                order: setting?.settings?.social_infos?.instagram_number
              }}
            >
              <InstagramIcon className={classes.iconSNS} />
            </IconButton>
          )}
          {setting?.settings?.social_infos?.youtube_active_web === 1 && (
            <IconButton
              color="inherit"
              className={classes.iconButton}
              onClick={() => {
                window.open(
                  setting?.settings?.social_infos?.youtube_url,
                  '_blank',
                  'noopener,noreferrer'
                );
              }}
              style={{
                order: setting?.settings?.social_infos?.youtube_number
              }}
            >
              <FontAwesomeIcon icon={faYoutube} className={classes.iconSNS} />
            </IconButton>
          )}
          {setting?.settings?.social_infos?.line_active_web === 1 && (
            <IconButton
              color="inherit"
              className={classes.iconButton}
              onClick={() => {
                window.open(
                  setting?.settings?.social_infos?.line_url,
                  '_blank',
                  'noopener,noreferrer'
                );
              }}
              style={{
                order: setting?.settings?.social_infos?.line_number
              }}
            >
              <FontAwesomeIcon icon={faLine} className={classes.iconSNS} />
            </IconButton>
          )}
        </div>
      </div>
      <div
        className={classes.footer}
        style={{
          backgroundColor:
            window.innerWidth <= 700 ? setting?.settings?.theme_color1 : 'black'
        }}
      >
        <div>
          <Typography variant="h4" className={classes.headerFCMenu}>
            FC MENU
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Typography
                variant="h2"
                className={classes.menuFCName}
                style={{
                  order: setting?.settings?.talent_settings?.menu_notice_number
                }}
                onClick={() => navigate(ROUTES_AUTHEN.MY_PAGE)}
              >
                MYPAGE
              </Typography>
            </Grid>
            {setting?.settings?.talent_settings?.menu_fromtalent_position ===
              2 && (
              <Grid item xs={6}>
                <Typography
                  variant="h2"
                  className={classes.menuFCName}
                  style={{
                    order:
                      setting?.settings?.talent_settings?.menu_fromtalent_number
                  }}
                  onClick={() => navigate(ROUTES.TALENT_BLOG)}
                >
                  MESSAGE
                </Typography>
              </Grid>
            )}
            {setting?.settings?.talent_settings?.menu_staffblog_position ===
              2 && (
              <Grid item xs={6}>
                <Typography
                  variant="h2"
                  className={classes.menuFCName}
                  style={{
                    order:
                      setting?.settings?.talent_settings?.menu_staffblog_number
                  }}
                  onClick={() => navigate(ROUTES.STAFF_BLOG)}
                >
                  STAFF BLOG
                </Typography>
              </Grid>
            )}
            {setting?.settings?.talent_settings?.menu_magazine_position ===
              2 && (
              <Grid item xs={6}>
                <Typography
                  variant="h2"
                  className={classes.menuFCName}
                  style={{
                    order:
                      setting?.settings?.talent_settings?.menu_magazine_number
                  }}
                  onClick={() => navigate(ROUTES.MAGAZINE)}
                >
                  MAGAZINE
                </Typography>
              </Grid>
            )}
            {setting?.settings?.talent_settings?.menu_community_position ===
              2 && (
              <Grid item xs={6}>
                <Typography
                  variant="h2"
                  className={classes.menuFCName}
                  style={{
                    order:
                      setting?.settings?.talent_settings?.menu_community_number
                  }}
                  onClick={() => navigate(ROUTES_AUTHEN.COMMUNITY)}
                >
                  COMMUNITY
                </Typography>
              </Grid>
            )}
            {setting?.settings?.talent_settings?.menu_schedule_position ===
              2 && (
              <Grid item xs={6}>
                <Typography
                  variant="h2"
                  className={classes.menuFCName}
                  style={{
                    order:
                      setting?.settings?.talent_settings?.menu_schedule_number
                  }}
                  onClick={() => navigate(ROUTES.SCHEDULE)}
                >
                  SCHEDULE
                </Typography>
              </Grid>
            )}
            {setting?.settings?.talent_settings?.menu_download_position ===
              2 && (
              <Grid item xs={6}>
                <Typography
                  variant="h2"
                  className={classes.menuFCName}
                  style={{
                    order:
                      setting?.settings?.talent_settings?.menu_download_number
                  }}
                  onClick={() => {
                    navigate(ROUTES.DOWNLOAD);
                  }}
                >
                  DOWNLOAD
                </Typography>
              </Grid>
            )}
            {setting?.settings?.talent_settings?.menu_loveletter_position ===
              2 && (
              <Grid item xs={6}>
                <Typography
                  variant="h2"
                  className={classes.menuFCName}
                  style={{
                    order:
                      setting?.settings?.talent_settings?.menu_loveletter_number
                  }}
                  onClick={() => {
                    navigate(ROUTES.LOVE_LETTER);
                  }}
                >
                  LOVE LETTER
                </Typography>
              </Grid>
            )}
            {setting?.settings?.talent_settings?.menu_notice_position === 2 && (
              <Grid item xs={6}>
                <Typography
                  variant="h2"
                  className={classes.menuFCName}
                  style={{
                    order:
                      setting?.settings?.talent_settings?.menu_notice_number
                  }}
                  onClick={() => navigate(ROUTES.NOTICE)}
                >
                  NOTICE
                </Typography>
              </Grid>
            )}
            {setting?.settings?.talent_settings?.menu_profile_position ===
              2 && (
              <Grid item xs={6}>
                <Typography
                  variant="h2"
                  className={classes.menuFCName}
                  style={{
                    order:
                      setting?.settings?.talent_settings?.menu_profile_number
                  }}
                  onClick={() => navigate(ROUTES.PROFILE)}
                >
                  PROFILE
                </Typography>
              </Grid>
            )}
            {setting?.settings?.talent_settings?.menu_discography_position ===
              2 && (
              <Grid item xs={6}>
                <Typography
                  variant="h2"
                  className={classes.menuFCName}
                  style={{
                    order:
                      setting?.settings?.talent_settings
                        ?.menu_discography_number
                  }}
                  onClick={() => navigate(ROUTES.DISCOGRAPHY)}
                >
                  DISCOGRAPHY
                </Typography>
              </Grid>
            )}
            {setting?.settings?.talent_settings?.menu_youtube_position ===
              2 && (
              <Grid item xs={6}>
                <Typography
                  variant="h2"
                  className={classes.menuFCName}
                  style={{
                    order:
                      setting?.settings?.talent_settings?.menu_youtube_number
                  }}
                  onClick={() => navigate(ROUTES.YOUTUBE)}
                >
                  YOUTUBE
                </Typography>
              </Grid>
            )}
            {setting?.settings?.talent_settings?.menu_photo_position === 2 && (
              <Grid item xs={6}>
                <Typography
                  variant="h2"
                  className={classes.menuFCName}
                  style={{
                    order: setting?.settings?.talent_settings?.menu_photo_number
                  }}
                  onClick={() => navigate(ROUTES.PHOTO)}
                >
                  PHOTO
                </Typography>
              </Grid>
            )}
            {setting?.settings?.talent_settings?.menu_video_position === 2 && (
              <Grid item xs={6}>
                <Typography
                  variant="h2"
                  className={classes.menuFCName}
                  style={{
                    order: setting?.settings?.talent_settings?.menu_video_number
                  }}
                  onClick={() => navigate(ROUTES.VIDEO)}
                >
                  VIDEO
                </Typography>
              </Grid>
            )}
            {setting?.settings?.talent_settings?.menu_shop_position === 2 && (
              <Grid item xs={6}>
                <Typography
                  variant="h2"
                  className={classes.menuFCName}
                  style={{
                    order: setting?.settings?.talent_settings?.menu_shop_number
                  }}
                  onClick={() =>
                    window.open(
                      setting?.settings?.shop_url,
                      '_blank',
                      'noopener,noreferrer'
                    )
                  }
                >
                  SHOP
                </Typography>
              </Grid>
            )}
          </Grid>
          <div className={classes.wrapFooter}>
            <Typography
              variant="h5"
              className={classes.menuFCName}
              onClick={() => {
                if (profile?.isAuthenticated) {
                  return;
                }
                navigate(ROUTES_AUTHEN.LOGIN);
                onMobileClose();
              }}
            >
              {profile?.isAuthenticated ? 'ログイン済' : 'ログイン'}
            </Typography>
            <Typography
              variant="h5"
              className={classes.menuFCName}
              onClick={() => {
                if (profile?.isAuthenticated) {
                  dispatch(logout());
                  dispatch(clearState());
                  sendMessageToReactNative('log_out');
                  navigate(ROUTES_AUTHEN.LOGIN);
                } else {
                  navigate(ROUTES_AUTHEN.MEMBER_BENEFITS);
                  onMobileClose();
                }
              }}
            >
              {!profile?.isAuthenticated
                ? '入会'
                : !searchParams.get('appacc') && 'ログアウト'}
            </Typography>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{
            paper: classes.mobileDrawer
          }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
}

NavBar.propTypes = {
  className: PropTypes.string,
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
  setting: PropTypes.object,
  profile: PropTypes.object
};

export default NavBar;
