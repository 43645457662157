import React, { useState } from 'react';
import clsx from 'clsx';
import _ from 'lodash';
import {
  Box,
  Container,
  Typography,
  makeStyles,
  Button
} from '@material-ui/core';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { authSelector } from 'src/slices/auth';
import { ROUTES_AUTHEN } from 'src/constants';
import LoaderLayer from 'src/components/LoaderLayer';
import { getLoginToken } from 'src/utils/auth';
import { settingSelector } from 'src/slices/settings';

const useStyles = makeStyles((theme) => ({
  root: {
    justifyContent: 'center',
    display: 'flex',
    flexDirection: 'column',
    padding: '100px 5px 0'
  },
  registerBorderBottom: {
    borderBottom: '1px solid #919090'
  },
  wrapTitle: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    [theme.breakpoints.down(700)]: {
      flexDirection: 'column'
    }
  },
  registerTitle: {
    fontWeight: 'bold',
    fontSize: 22,
    marginBottom: 5,
    [theme.breakpoints.down('700')]: {
      fontSize: 18
    }
  },
  registerTitleText: {
    fontWeight: 'bold',
    fontSize: 20,
    [theme.breakpoints.down(700)]: {
      fontSize: '1rem'
    }
  },
  wrapTitleRight: {
    display: 'flex',
    flexDirection: 'row',
    '& p': {
      fontSize: 16,
      opacity: 0.3
    },
    [theme.breakpoints.down(700)]: {
      '& p': {
        fontSize: '0.8rem',
        marginTop: 10
      }
    }
  },
  registrationTitleActive: {
    color: '#000000',
    borderBottom: '2px solid #5D7BAB',
    opacity: '1 !important',
    marginRight: 20,
    [theme.breakpoints.down(700)]: {
      marginRight: 10
    }
  },
  titleRightText: {
    marginRight: 20,
    [theme.breakpoints.down(700)]: {
      marginRight: 10
    }
  },
  displayFlex: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down(700)]: {
      flexDirection: 'column',
      alignItems: 'start',
      display: 'block'
    }
  },
  wrapButton: {
    display: 'flex',
    justifyContent: 'center',
    margin: '5rem 0 2rem',
    [theme.breakpoints.down(700)]: {
      flexDirection: 'column-reverse',
      margin: '2rem 0'
    }
  },
  buttonBack: {
    backgroundColor: '#A7A7A7',
    padding: '11px 100px',
    color: '#fff !important',
    borderRadius: 0,
    marginRight: '2rem',
    '&:hover': {
      backgroundColor: '#5b5b5b'
    },
    [theme.breakpoints.down(700)]: {
      padding: '8px 0',
      fontSize: 16,
      width: '100%'
    }
  },
  buttonEdit: {
    backgroundColor: 'black',
    padding: '11px 100px',
    color: '#fff !important',
    borderRadius: 0,
    '&:hover': {
      backgroundColor: '#1175ae'
    },
    [theme.breakpoints.down(700)]: {
      padding: '8px 0',
      fontSize: 16,
      width: '100%',
      marginBottom: '1rem'
    }
  },
  textColor: {
    color: 'red',
    [theme.breakpoints.down(700)]: {
      fontSize: '0.88rem'
    }
  },
  textCenter: {
    display: 'flex',
    [theme.breakpoints.down(700)]: {
      justifyContent: 'start'
    }
  }
}));

function ChooseCredit() {
  const classes = useStyles();
  const navigate = useNavigate();
  const auth = useSelector(authSelector);
  const { settings } = useSelector(settingSelector);

  const [hover, setHover] = useState(false);

  return (
    <div className={classes.root}>
      {getLoginToken() && (
        <Container maxWidth="md">
          <Box mb={8} paddingBottom={window.innerWidth <= 700 ? 4 : 8}>
            <Box className={classes.wrapTitle}>
              <Typography className={classes.registerTitleText}>
                決済方法の確認
              </Typography>
              <Box className={classes.wrapTitleRight}>
                <Typography className={classes.titleRightText}>
                  決済方法選択
                </Typography>
                <Typography className={clsx(classes.registrationTitleActive)}>
                  選択内容確認
                </Typography>
                <Typography className={classes.titleRightText}>
                  決済方法確認
                </Typography>
                <Typography>年会費決済</Typography>
              </Box>
            </Box>

            <Box
              mb={5}
              mt={8}
              alignItems="center"
              className={clsx(classes.textCenter)}
            >
              <Typography className={clsx(classes.registerTitle)}>
                クレジットカードを選択されました。
              </Typography>
            </Box>

            <Box>
              <Typography>
                決済手続き中にネットワークが切断されると、正常に処理ができない場合がございます。
                <br />
                移動中やネットワークが不安定な場所では手続きをされないようにお願いいたします。
              </Typography>
            </Box>

            <Box
              sx={{ justifyContent: 'center' }}
              mb={5}
              mt={8}
              display="flex"
              alignItems="center"
              // className={clsx(classes.registerBorderBottom)}
            >
              <Typography className={clsx(classes.registerTitle)}>
                クレジットカード申し込み
              </Typography>
            </Box>

            {/* <Box pb={5} className={classes.registerBorderBottom}> */}
            {/* <Box className={clsx(classes.displayFlex)}>
                <Typography className={clsx(classes.textColor)}>
                  次ページでカード情報を入力して決済してください。
                </Typography> */}
            {/* </Box> */}
            {/* </Box> */}
            <Box className={clsx(classes.wrapButton)}>
              <Button
                onClick={() => navigate(ROUTES_AUTHEN.PAYMENT_TYPE)}
                className={clsx(classes.buttonBack)}
              >
                決済方法を変更
              </Button>
              <Button
                onClick={() => navigate(ROUTES_AUTHEN.CREDIT_INFO)}
                onMouseOver={() => setHover(true)}
                onMouseOut={() => setHover(false)}
                style={{
                  backgroundColor: hover
                    ? settings?.theme_color1 + 'cc'
                    : settings?.theme_color1
                }}
                className={clsx(classes.buttonEdit)}
              >
                カード情報を入力する
              </Button>
            </Box>
          </Box>
        </Container>
      )}
      <LoaderLayer loading={auth?.isFetching} />
    </div>
  );
}
export default ChooseCredit;
