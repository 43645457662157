 /**
  * Send data string to react native 
 */
export const sendMessageToReactNative = async (data) => {
  if(typeof(data) !== 'string'){
    return
  }
  try {
    await window.ReactNativeWebView.postMessage(data);
  } catch (error) {
  }
}
export const listenerMessageFromReactNative = (onCallBack)=>{
  window.addEventListener('message',(event) => {
    if(event?.data){
      onCallBack(event.data)
    }
  },false);
}