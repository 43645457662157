import { createSlice } from '@reduxjs/toolkit';
import {
  getProfileTalent,
  getTagsProfile,
  getProfilesByTagId
} from 'src/services/profileService';

export const profileSlice = createSlice({
  name: 'profiles',
  initialState: {
    loading: false,
    profiles: [],
    tagsProfile: [],
    profileDetail: {},
    errorMessage: '',
    currentPage: 1,
    totalPage: 1
  },
  reducers: {
    clearState: (state) => {
      state.profiles = [];
      state.tagsProfile = [];
      state.profileDetail = '';
      state.errorMessage = '';
      state.currentPage = 1;
      state.totalPage = 1;
      state.loading = false;
      return state;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getTagsProfile.pending, (state) => {
      state.loading = true;
    }),
      builder.addCase(getTagsProfile.fulfilled, (state, { payload }) => {
        state.tagsProfile = payload;
        state.loading = false;
        return state;
      }),
      builder.addCase(getTagsProfile.rejected, (state, { payload }) => {
        state.errorMessage = payload?.error;
        state.loading = false;
      });
    builder.addCase(getProfilesByTagId.pending, (state) => {
      state.loading = true;
    }),
      builder.addCase(getProfilesByTagId.fulfilled, (state, { payload }) => {
        state.profiles = payload.data;
        state.currentPage = payload.current_page;
        state.totalPage = payload.last_page;
        state.loading = false;
        return state;
      }),
      builder.addCase(getProfilesByTagId.rejected, (state, { payload }) => {
        state.errorMessage = payload.error;
        state.loading = false;
      });
    builder.addCase(getProfileTalent.rejected, (state, { payload }) => {
      state.errorMessage = payload?.error;
      state.loading = false;
    }),
      builder.addCase(getProfileTalent.pending, (state) => {
        state.loading = true;
      }),
      builder.addCase(getProfileTalent.fulfilled, (state, { payload }) => {
        state.profileDetail = payload.data;
        state.loading = false;
        return state;
      });
  }
});

export const profileSelector = (state) => state.profiles;
export const reducer = profileSlice.reducer;
export const { clearState } = profileSlice.actions;
