import { Fragment, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router';
import AuthDialog from './components/AuthDialog';
import { getUserProfile } from './services/authService';
import { getLoginToken } from './utils/auth';
import { authPagePath, ROUTES } from 'src/constants';

const Auth = ({ children }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const accessToken = getLoginToken();
  const [openDialog, setOpenDialog] = useState(false);

  useEffect(() => {
    if (accessToken) {
      dispatch(getUserProfile());
    } else {
      const pathName = location.pathname.endsWith('/')
        ? location.pathname.slice(0, -1)
        : location.pathname;

      if (authPagePath.indexOf(pathName) >= 0) {
        setOpenDialog(true);
      }
    }

    if (window.location.pathname.split('/')[1] !== 'notice') {
      localStorage.removeItem('currentTag');
    }
  }, [location]);

  return (
    <>
      <Fragment>{children}</Fragment>
      {openDialog && (
        <AuthDialog
          open={openDialog}
          onClose={setOpenDialog}
          path={ROUTES.HOMEVIEW}
        />
      )}
    </>
  );
};

export default Auth;
