import React, { useState } from 'react';
import clsx from 'clsx';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Container,
  Typography,
  makeStyles,
  Grid,
  TextField,
  Button
} from '@material-ui/core';
import { ROUTES, ROUTES_AUTHEN } from 'src/constants';
import { useDispatch, useSelector } from 'react-redux';

import { sendCode } from 'src/services/authService';
import { clearState } from 'src/slices/auth';
import PaymentDialog from 'src/components/PaymentDialog';
import { settingSelector } from 'src/slices/settings';

const useStyles = makeStyles((theme) => ({
  root: {
    justifyContent: 'center',
    display: 'flex',
    flexDirection: 'column',
    paddingTop: 100
  },
  registerBorderBottom: {
    borderBottom: '1px solid #919090'
  },
  registerTitleText: {
    fontWeight: 'bold',
    marginBottom: 35,
    fontSize: 22,
    [theme.breakpoints.down('700')]: {
      fontSize: 18
    }
  },
  registerTitle: {
    fontWeight: 'bold',
    fontSize: 22,
    marginBottom: 5,
    [theme.breakpoints.down('700')]: {
      fontSize: 18
    }
  },
  registerSubTitle: {
    fontSize: 16,
    fontWeight: 'bold',
    marginBottom: 16
  },
  registerDescription: {
    marginBottom: 20
  },
  textBorderBottom: {
    borderBottom: '1px solid #707070',
    width: 'fit-content'
  },
  buttonHover: {
    color: '#fff !important',
    '&:hover': {
      backgroundColor: 'rgb(34 152 221);'
    }
  },
  registerLabel: {
    width: '50%',
    fontWeight: 'bold',
    lineHeight: 2,
    [theme.breakpoints.down('700')]: {
      fontSize: '0.8rem',
      lineHeight: 2.2
    }
  },
  registerSubLabel: {
    width: '8%',
    fontWeight: 'bold',
    lineHeight: 2,
    marginLeft: 4
  },
  wrapTextField: {
    display: 'flex',
    justifyContent: 'center',
    borderBottom: '1px solid #919090'
  },
  wrapInput: {
    color: 'black',
    height: window.innerWidth <= 700 ? 28 : 38,
    backgroundColor: '#FFFFFF',
    '& input': {
      padding: '10px 0 10px 10px'
    }
  },
  registerButton: {
    color: 'white !important',
    borderRadius: 0,
    padding: '12px 140px',
    fontSize: 18,
    [theme.breakpoints.down(700)]: {
      fontSize: 16,
      padding: '10px 80px'
    }
  }
}));

function LoginView() {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const settings = useSelector(settingSelector);

  const [hover, setHover] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [titleDialog, setTitleDialog] = useState('');

  return (
    <div className={classes.root}>
      <Container maxWidth="md">
        <Box mb={10}>
          <Typography className={classes.registerTitleText}>
            メールアドレス登録
          </Typography>
          <Typography className={classes.registerSubTitle}>
            メールアドレスを登録してください。
          </Typography>
          <Typography className={classes.registerDescription}>
            「送信する」ボタンを押されますと、ご登録いただいたメールアドレスに自動送信メールが届きますので、メールの本文内に記載されている認証コードをご入力の上、会員登録画面にお進みください。
          </Typography>

          {/* <Typography className={classes.registerSubTitle}>
            メールアドレス登録の利用目的について
          </Typography>
          <Typography className={classes.registerDescription}>
            1.当社では、本フォームで取得した情報は以下の目的のみに使用いたします。
            <br />
            「新規会員登録に関わる目的達成の為」
          </Typography>
          <Typography className={classes.registerDescription}>
            2.当社では、前項に記された目的を行うにあたり、ご提供いただいた個人情報の取り扱いをインターネットサーバー業者に委託いたします。この場合、個人情報を適切に取り扱っている委託先を選定の上、個人情報の適正管理や機密の保持に関する契約などを締結、電子的方法で送付することにより適切な管理を実施いたします。
            <br />
            当ファンクラブサイトの個人情報の取り扱いについては、下記の「個人情報の取り扱いについて」をご確認ください
          </Typography>
          <Typography
            onClick={() => navigate(ROUTES.TERMS)}
            className={clsx(
              classes.registerDescription,
              classes.textBorderBottom
            )}
            style={{ cursor: 'pointer' }}
          >
            会員規約はこちら
          </Typography>
          <Typography
            onClick={() =>
              window.open(
                'https://www.frau-inter.co.jp/register_privacypolicy/'
              )
            }
            className={clsx(
              classes.registerDescription,
              classes.textBorderBottom
            )}
            style={{ cursor: 'pointer' }}
          >
            個人情報の取り扱いについて
          </Typography> */}
        </Box>

        <Box
          sx={{ justifyContent: 'center' }}
          mb={6}
          display="flex"
          alignItems="center"
          className={clsx(classes.registerBorderBottom)}
        >
          <Typography className={clsx(classes.registerTitle)}>
            メールアドレス登録
          </Typography>
        </Box>

        <Box mb={10} display="flex" sx={{ justifyContent: 'center' }}>
          <Formik
            initialValues={{
              email: ''
            }}
            validationSchema={Yup.object().shape({
              email: Yup.string()
                .matches(
                  /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/,
                  'メールアドレスを半角英数字で入力してください'
                )
                .email('メールアドレスを半角英数字で入力してください')
                .max(255)
                .required('メールアドレス必須')
            })}
            onSubmit={async (
              values,
              { setErrors, setStatus, setSubmitting }
            ) => {
              dispatch(clearState());
              try {
                const { payload } = await dispatch(sendCode(values));

                if (payload?.data?.msg === 'Success') {
                  return navigate(ROUTES_AUTHEN.REGISTRATION);
                } else {
                  setTitleDialog(payload?.msg);
                  setOpenDialog(true);
                }
              } catch (error) {
                setStatus({ success: false });
                setErrors({ submit: error.message });
                setSubmitting(false);
                setTitleDialog(error);
                setOpenDialog(true);
              }
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values
            }) => (
              <form
                noValidate
                onSubmit={handleSubmit}
                style={{ width: '-webkit-fill-available' }}
              >
                <Box className={classes.wrapTextField}>
                  <Grid item xs={12} sm={7}>
                    <Box display="flex" mb={6}>
                      <Typography className={clsx(classes.registerLabel)}>
                        メールアドレス
                      </Typography>
                      <TextField
                        name="email"
                        error={Boolean(touched.email && errors.email)}
                        helperText={touched.email && errors.email}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.email}
                        type="email"
                        variant="standard"
                        fullWidth
                        InputProps={{
                          disableUnderline: true,
                          className: classes.wrapInput
                        }}
                      />
                    </Box>
                  </Grid>
                </Box>

                <Box mt={8} display="flex" sx={{ justifyContent: 'center' }}>
                  <Button
                    type="submit"
                    disabled={isSubmitting}
                    className={clsx(classes.registerButton)}
                    onMouseOver={() => setHover(true)}
                    onMouseOut={() => setHover(false)}
                    style={{
                      backgroundColor: hover
                        ? settings?.settings?.theme_color1 + 'cc'
                        : settings?.settings?.theme_color1
                    }}
                  >
                    送信する
                  </Button>
                </Box>
              </form>
            )}
          </Formik>
        </Box>
        <PaymentDialog
          open={openDialog}
          onClose={setOpenDialog}
          title={titleDialog}
        />
      </Container>
    </div>
  );
}

export default LoginView;
