import clsx from 'clsx';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useMemo, useState } from 'react';
import { Box, Container, Grid, makeStyles } from '@material-ui/core';

import MenuBar from 'src/components/MenuBar';
import PaginationButton from 'src/components/PaginationButton';
import {
  getDiscographiesByTagId,
  getTagsDiscography
} from 'src/services/discographyService';
import { discographySelector } from 'src/slices/discography';
import DiscographyDialog from './DiscographyDialog';
import LoaderLayer from 'src/components/LoaderLayer';

const useStyles = makeStyles((theme) => ({
  root: {
    justifyContent: 'center',
    display: 'flex',
    flexDirection: 'column',
    paddingTop: 50,
    paddingBottom: 40,
    [theme.breakpoints.down(700)]: {
      padding: '50px 6px 30px'
    }
  },
  container: {
    maxWidth: 1040,
    padding: theme.spacing(0, 8),
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(0, 2)
    }
  },
  wrapPagination: {
    margin: '40px 0'
  },
  boxContent: {
    width: 290,
    height: 290,
    backgroundColor: '#B1B1B1',
    marginBottom: 10,
    cursor: 'pointer',
    [theme.breakpoints.down(700)]: {
      width: 160,
      height: 160
    }
  },
  imageContainer: {
    width: 'inherit',
    height: 'inherit',
    objectFit: 'cover'
  },
  containerItem: {
    padding: '0 !important',
    '&:first-child': {
      borderTop: '1px solid #cccccc'
    }
  },
  wrapItem: {
    display: 'flex',
    padding: '10px 0',
    borderBottom: '1px solid #cccccc',
    [theme.breakpoints.down(767)]: {
      fontSize: 12
    }
  },
  itemDate: {
    width: 200,
    [theme.breakpoints.down(767)]: {
      width: 120
    }
  },
  itemTitle: {
    flex: 1
  }
}));

function DiscographyView({ setting }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const discography = useSelector(discographySelector);
  const [page, setPage] = useState(1);
  const [showDialog, setShowDialog] = useState(false);
  const [discographyInfo, setDiscographyInfo] = useState({});
  const [currentTag, setCurrentTag] = useState(null);

  const handleChange = (data) => {
    setDiscographyInfo(data);
    setShowDialog(true);
  };

  useEffect(() => {
    dispatch(
      getDiscographiesByTagId({
        page: page,
        perPage: 9,
        tagId: currentTag?.id
      })
    );
  }, [page, currentTag]);

  useEffect(() => {
    dispatch(getTagsDiscography());
  }, []);

  useEffect(() => {
    if (discography?.tagsDiscography) {
      setCurrentTag(discography?.tagsDiscography[0]);
    }
  }, [discography?.tagsDiscography]);

  useMemo(() => {
    const searchPage = parseInt(window.location.search.split('=')[1]);

    if (!window.location.search) {
      setPage(1);
    } else if (searchPage !== page) {
      setPage(searchPage);
    }
  }, [currentTag, window.location.search]);

  return (
    <div className={clsx(classes.root)}>
      <Container className={classes.container}>
        {discography?.tagsDiscography.length > 0 && (
          <Box
            sx={{ justifyContent: 'center' }}
            mb={5}
            display="flex"
            alignItems="center"
          >
            <MenuBar
              listMenu={discography?.tagsDiscography}
              typeMenu="discographies"
              currentTag={currentTag}
              setCurrentTag={setCurrentTag}
              all={false}
              borderBottom={'2px solid' + setting?.theme_color2}
            />
          </Box>
        )}
        <Grid container spacing={1}>
          {discography?.discographies.map((item, index) => (
            <Grid
              key={index}
              item
              md={item?.img_display === 1 ? 4 : 12}
              sm={item?.img_display === 1 ? 6 : 12}
              xs={item?.img_display === 1 ? 6 : 12}
              className={clsx(
                item?.img_display === 1 ? '' : classes.containerItem
              )}
            >
              {item?.img_display === 1 ? (
                <Box
                  onClick={() => handleChange(item)}
                  className={clsx(classes.boxContent)}
                >
                  <Box
                    className={clsx(classes.imageContainer)}
                    component="img"
                    src={item.img}
                  />
                </Box>
              ) : (
                <dl className={clsx(classes.wrapItem)}>
                  <dt className={clsx(classes.itemDate)}>
                    {moment(item?.release_date).format('YYYY/MM/DD')}
                  </dt>
                  <dt className={clsx(classes.itemTitle)}>{item?.title}</dt>
                </dl>
              )}
            </Grid>
          ))}
        </Grid>
        {discography?.totalPage > 1 && (
          <Box className={clsx(classes.wrapPagination)}>
            <PaginationButton
              page={page}
              totalItem={discography?.totalPage * 9}
              itemPerPage={9}
              paginate={setPage}
            />
          </Box>
        )}
      </Container>

      {showDialog && (
        <DiscographyDialog
          setShowDialog={setShowDialog}
          discographyInfo={discographyInfo}
        />
      )}

      <LoaderLayer loading={discography?.loading} />
    </div>
  );
}

export default DiscographyView;
