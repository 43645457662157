import { createSlice } from '@reduxjs/toolkit';
import { getMagazines, getMagazineId } from 'src/services/magazine';

export const magazineSlice = createSlice({
  name: 'magazine',
  initialState: {
    loading: false,
    magazines: [],
    tagsMagazine: [],
    errorMessage: '',
    currentPage: 1,
    totalPage: 1
  },
  reducers: {
    clearState: (state) => {
      state.loading = false;
      state.magazines = [];
      state.tagsMagazine = [];
      state.errorMessage = '';
      state.currentPage = 1;
      state.totalPage = 1;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getMagazines.pending, (state) => {
      state.loading = true;
    }),
      builder.addCase(getMagazines.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.magazines = payload.data;
        state.currentPage = payload.current_page;
        state.totalPage = payload.last_page;
        return state;
      }),
      builder.addCase(getMagazines.rejected, (state, { payload }) => {
        state.errorMessage = payload?.error;
        state.loading = false;
      });
      builder.addCase(getMagazineId.pending, (state) => {
        state.loading = true;
      }),
      builder.addCase(getMagazineId.fulfilled, (state, { payload }) => {
        state.tagsMagazine = payload;
        state.loading = false;
        return state;
      }),
      builder.addCase(getMagazineId.rejected, (state, { payload }) => {
        state.errorMessage = payload?.error;
        state.loading = false;
      });
  }
});

export const magazineSelector = (state) => state.magazine;
export const reducer = magazineSlice.reducer;
export const { clearState } = magazineSlice.actions;
