import clsx from 'clsx';
import { useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Container, Grid, makeStyles } from '@material-ui/core';
import React, { useState, useMemo, useLayoutEffect, useEffect } from 'react';

import Drama from './Drama';
import Award from './Award';
import Member from './Member';
import Profile from './Profile';
import MenuBar from 'src/components/MenuBar';
import {
  getTagsProfile,
  getProfilesByTagId
} from 'src/services/profileService';
import { profileSelector } from 'src/slices/profiles';
import LoaderLayer from 'src/components/LoaderLayer';
import PaginationButton from 'src/components/PaginationButton';

const useStyles = makeStyles((theme) => ({
  root: {
    justifyContent: 'center',
    display: 'flex',
    flexDirection: 'column',
    paddingTop: 50,
    [theme.breakpoints.down(700)]: {
      padding: '50px 6px 0'
    }
  },
  container: {
    maxWidth: 1040,
    padding: theme.spacing(0, 8),
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(0, 2)
    }
  },
  newContent: {
    [theme.breakpoints.down(700)]: {
      margin: '50px 0 0'
    }
  },
  wrapPagination: {
    margin: '0 0 40px'
  }
}));

function ProfileView({ setting }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const profiles = useSelector(profileSelector);
  const [page, setPage] = useState(1);
  const [currentTag, setCurrentTag] = useState(null);
  const [searchParams, setSearchParams] = useSearchParams();

  const ncrp = parseInt(searchParams.get('page'));

  useEffect(() => {
    const searchPage = parseInt(window.location.search.split('=')[1]);
    if (!ncrp && !window.location.search) {
      setPage(1);
    } else if (searchPage !== page) {
      setPage(searchPage);
    }
  }, [currentTag, window.location.search]);

  useMemo(() => {
    if (currentTag) {
      dispatch(
        getProfilesByTagId({ page: 1, perPage: 12, tagId: currentTag.id })
      );
    }
  }, [currentTag]);

  useMemo(() => {
    if (currentTag) {
      dispatch(
        getProfilesByTagId({ page: page, perPage: 12, tagId: currentTag.id })
      );
    }
  }, [page]);

  useLayoutEffect(() => {
    const callback = async () => {
      const { payload } = await dispatch(getTagsProfile());

      setCurrentTag({
        id: payload[0].id,
        title: payload[0]?.title
      });
    };

    callback();
  }, []);

  return (
    <div className={clsx(classes.root)}>
      <Container className={classes.container}>
        {profiles?.tagsProfile && (
          <Box
            sx={{ justifyContent: 'center' }}
            mb={5}
            display="flex"
            alignItems="center"
            onClick={() => setSearchParams('')}
          >
            <MenuBar
              listMenu={profiles?.tagsProfile}
              all={false}
              currentTag={currentTag}
              setCurrentTag={setCurrentTag}
              borderBottom={'2px solid' + setting.theme_color1}
            />
          </Box>
        )}
        {profiles?.profiles && profiles?.loading === false && (
          <Box className={clsx(classes.newContent)}>
            {currentTag?.title?.toUpperCase() === 'PROFILE' ? (
              <Profile profiles={profiles} />
            ) : currentTag?.title?.toUpperCase() === 'MEMBER' ? (
              <Member setting={setting} profiles={profiles} member={profiles} />
            ) : currentTag?.title?.toUpperCase() === 'DRAMA' ||
              currentTag?.title?.toUpperCase() === 'MOVIE' ? (
              <Drama profiles={profiles} />
            ) : (
              <Award profiles={profiles} />
            )}
          </Box>
        )}

        {currentTag?.title?.toUpperCase() === 'DRAMA' ||
        currentTag?.title?.toUpperCase() === 'MOVIE' ? (
          <Grid container>
            <Grid item xs={12} md={6}></Grid>
            <Grid item xs={12} md={6}>
              {profiles?.totalPage > 1 && profiles?.loading === false && (
                <Box className={clsx(classes.wrapPagination)}>
                  <PaginationButton
                    totalItem={profiles?.totalPage * 12}
                    itemPerPage={12}
                    paginate={setPage}
                    page={page}
                  />
                </Box>
              )}
            </Grid>
          </Grid>
        ) : (
          <>
            {profiles?.totalPage > 1 && profiles?.loading === false && (
              <Box className={clsx(classes.wrapPagination)}>
                <PaginationButton
                  totalItem={profiles?.totalPage * 12}
                  itemPerPage={12}
                  paginate={setPage}
                  page={page}
                />
              </Box>
            )}
          </>
        )}
      </Container>
      <LoaderLayer loading={profiles?.loading} />
    </div>
  );
}

export default ProfileView;
