import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import _ from 'lodash';
import {
  Box,
  Container,
  Typography,
  makeStyles,
  Button,
  Link
} from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import iconArrow from 'src/assets/images/arrow-down.png';

import { ROUTES, ROUTES_AUTHEN } from 'src/constants';
import LoaderLayer from 'src/components/LoaderLayer';
import { productSelector } from 'src/slices/product';
import { getProducts, payment } from 'src/services/productService';
import { authSelector } from 'src/slices/auth';
import PaymentDialog from 'src/components/PaymentDialog';
import { checkNameTalent, getLoginToken } from 'src/utils/auth';
import { settingSelector } from 'src/slices/settings';

const useStyles = makeStyles((theme) => ({
  root: {
    justifyContent: 'center',
    display: 'flex',
    flexDirection: 'column',
    padding: '100px 5px 0'
  },
  registerBorderBottom: {
    borderBottom: '1px solid #919090'
  },
  wrapTitle: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    [theme.breakpoints.down(700)]: {
      flexDirection: 'column'
    }
  },
  registerTitle: {
    fontWeight: 'bold',
    fontSize: 22,
    marginBottom: 5,
    [theme.breakpoints.down('700')]: {
      fontSize: 18
    }
  },
  registerTitleText: {
    fontWeight: 'bold',
    fontSize: 20,
    [theme.breakpoints.down(700)]: {
      fontSize: '1rem'
    }
  },
  wrapTitleRight: {
    display: 'flex',
    flexDirection: 'row',
    '& p': {
      fontSize: 16,
      opacity: 0.3
    },
    [theme.breakpoints.down(700)]: {
      '& p': {
        fontSize: '0.8rem',
        marginTop: 10
      }
    }
  },
  registrationTitleActive: {
    color: '#000000',
    borderBottom: '2px solid #5D7BAB',
    opacity: '1 !important',
    marginRight: 20,
    [theme.breakpoints.down(700)]: {
      marginRight: 10
    }
  },
  titleRightText: {
    marginRight: 20,
    [theme.breakpoints.down(700)]: {
      marginRight: 10
    }
  },
  displayFlex: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down(700)]: {
      alignItems: 'baseline',
      flexDirection: 'column'
    }
  },
  displayFlexSP: {
    width: '75%',
    [theme.breakpoints.down(700)]: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      width: '100%'
    }
  },
  wrapButton: {
    display: 'flex',
    justifyContent: 'center',
    margin: '5rem 0 2rem',
    [theme.breakpoints.down(700)]: {
      flexDirection: 'column-reverse',
      margin: '2rem 0'
    }
  },
  buttonBack: {
    backgroundColor: '#A7A7A7',
    color: '#fff !important',
    borderRadius: 0,
    padding: '11px 100px',
    marginRight: '2rem',
    '&:hover': {
      backgroundColor: '#5b5b5b'
    },
    [theme.breakpoints.down(700)]: {
      padding: '8px 0',
      fontSize: 16,
      width: '100%'
    }
  },
  buttonEdit: {
    backgroundColor: 'black',
    color: '#fff !important',
    borderRadius: 0,
    padding: '11px 70px',
    fontSize: 18,
    '&:hover': {
      backgroundColor: '#1175ae'
    },
    [theme.breakpoints.down(700)]: {
      padding: '8px 0',
      fontSize: 16,
      width: '100%',
      marginBottom: '1rem'
    }
  },
  textColor: {
    color: 'red',
    [theme.breakpoints.down(700)]: {
      fontSize: '0.88rem'
    }
  },
  labelContainer: {
    width: '25%',
    justifyContent: 'flex-end',
    [theme.breakpoints.down(700)]: {
      width: '75%',
      justifyContent: 'flex-start',
      marginBottom: 14
    }
  },
  label: {
    fontWeight: 'bold',
    marginRight: 6
  },
  labelRequire: {
    backgroundColor: '#E02323',
    marginRight: 10,
    padding: '0 14px',
    color: '#ffffff',
    borderRadius: 12
  },
  subTitle: {
    margin: '5rem 0 0',
    '& p': {
      fontSize: '1.1rem',
      fontWeight: '600',
      marginBottom: '1rem'
    }
  },
  selectContent: {
    border: 'none',
    backgroundColor: '#fff',
    fontSize: '1rem',
    padding: '0.5rem 2rem 0.5rem 0.5rem',
    outline: 'none',
    background: `#fff url(${iconArrow}) no-repeat center`,
    backgroundPosition: '95%',
    width: '30%',
    appearance: 'none',
    cursor: 'pointer',
    [theme.breakpoints.down(700)]: {
      width: '90%'
    }
  },
  linkTo: {
    color: 'black',
    '&:hover': {
      textDecoration: 'none'
    }
  },
  boxWrap: {
    textAlign: 'center',
    border: '1px solid',
    marginTop: '1rem',
    '& p': {
      padding: '0.5rem 1rem'
    }
  }
}));

function MyPagePaymentStore() {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { talentName, nameLower } = checkNameTalent();
  const product = useSelector(productSelector);
  const { profile } = useSelector(authSelector);
  const { settings } = useSelector(settingSelector);

  const [hover, setHover] = useState(false);
  const [storeId, setStoreId] = useState('00C002');
  const [openDialog, setOpenDialog] = useState(false);
  const [titleDialog, setTitleDialog] = useState('');

  useEffect(() => {
    dispatch(getProducts('subscription'));
  }, []);

  const handleChange = (event) => {
    setStoreId(event.target.value);
  };

  const handlePaymentByStore = async () => {
    const data = {
      telegram_kind: '030',
      product_id: product?.products[0].id,
      customer_tel: profile?.tel,
      cvs_company_id: storeId,
      customer_name: profile?.first_furigana,
      customer_family_name: profile?.family_furigana
    };

    const { payload } = await dispatch(payment(data));

    if (payload?.msg === 'Success') {
      navigate(ROUTES_AUTHEN.MY_PAGE_PAYMENT_STORE_FINISH, {
        state: {
          ...payload?.data,
          cost: product?.products[0].cost,
          store_id: storeId
        }
      });
    } else {
      setTitleDialog(payload?.msg);
      setOpenDialog(true);
    }
  };

  return (
    <div className={classes.root}>
      {getLoginToken() && (
        <Container maxWidth="md">
          <Box mb={8} paddingBottom={window.innerWidth <= 700 ? 4 : 8}>
            <Box className={classes.wrapTitle}>
              <Typography className={classes.registerTitleText}>
                決済方法の確認
              </Typography>
              <Box className={classes.wrapTitleRight}>
                <Typography className={classes.titleRightText}>
                  決済方法選択
                </Typography>
                <Typography className={clsx(classes.registrationTitleActive)}>
                  選択内容確認
                </Typography>
                <Typography className={classes.titleRightText}>
                  決済方法確認
                </Typography>
                <Typography className={classes.titleRightText}>
                  年会費決済
                </Typography>
              </Box>
            </Box>

            <Box
              sx={{ justifyContent: 'center' }}
              mb={5}
              mt={8}
              display="flex"
              alignItems="center"
            >
              <Typography className={clsx(classes.registerTitle)}>
                コンビニ決済を選択されました。
              </Typography>
            </Box>

            <Box>
              <Typography>
                下記にあるフォームより、ご利用されるコンビニエンスストアをご選択ください。
                <br />
                決済手続き中にネットワークが切断されると、正常に処理ができない場合がございます。
                <br />
                移動中やネットワークが不安定な場所では手続きをされないようにお願いいたします。
                <br />
                <br />
                コンビニ決済は全国の次の店舗でご利用いただけます。
                <br />
              </Typography>
            </Box>

            <Box className={clsx(classes.subTitle)}>
              <Typography>ローソン・ミニストップでのお支払い</Typography>
            </Box>

            <Box>
              <Typography>
                商品購入時にECサイトより通知される「お客様番号」と「確認番号」をメモして、ローソン・ミニストップ店舗へ行きます。店内に設置
                されているマルチメディア端末Loppiに番号を入力し、発券される申込券でレジにてお支払いください。　　　　　
              </Typography>
            </Box>

            <Link
              href="https://www.paygent.co.jp/payment_service/way_to_pay/cvs/lawson"
              target="_blank"
              className={clsx(classes.linkTo)}
            >
              <Box mt={1} display="flex" style={{ cursor: 'pointer' }}>
                <ArrowForwardIosIcon sx={{ width: '0.8em' }} />
                <Typography>詳細を見る</Typography>
              </Box>
            </Link>

            {nameLower !== 'lee-jongsuk' && nameLower !== 'zo-insung' && (
              <>
                <Box
                  className={clsx(classes.subTitle)}
                  style={{ margin: '3rem 0 0' }}
                >
                  <Typography>ファミリーマートでのお支払い</Typography>
                </Box>

                <Box mb={1}>
                  <Typography style={{ fontWeight: 600, fontSize: '0.9rem' }}>
                    マルチコピー機でのお支払い
                  </Typography>
                </Box>

                <Box>
                  <Typography>
                    商品購入時にECサイトより通知される収納番号をメモして、ファミリーマート店舗へ行きます。店内に設置されているマルチコピー機（旧Famiポート）に番号を入力し、発券される申込券でレジにてお支払いください。
                  </Typography>
                </Box>

                <Link
                  href="https://www.paygent.co.jp/payment_service/way_to_pay/cvs/familymart"
                  target="_blank"
                  className={clsx(classes.linkTo)}
                >
                  <Box mt={1} display="flex" style={{ cursor: 'pointer' }}>
                    <ArrowForwardIosIcon sx={{ width: '0.8em' }} />
                    <Typography>詳細を見る</Typography>
                  </Box>
                </Link>

                <Box mb={1} mt={3}>
                  <Typography style={{ fontWeight: 600, fontSize: '0.9rem' }}>
                    スマホバーコード決済でのお支払い
                  </Typography>
                </Box>

                <Box>
                  <Typography>
                    マルチコピー機（旧Famiポート）を介さず、お客様がお持ちのスマートフォンに表示されたファミリーマート専用バーコードでお支払いが可能です。
                  </Typography>
                </Box>

                <Link
                  href="https://www.paygent.co.jp/payment_service/way_to_pay/cvs/familymart/barcodepay.html"
                  target="_blank"
                  className={clsx(classes.linkTo)}
                >
                  <Box mt={1} display="flex" style={{ cursor: 'pointer' }}>
                    <ArrowForwardIosIcon sx={{ width: '0.8em' }} />
                    <Typography>詳細を見る</Typography>
                  </Box>
                </Link>
              </>
            )}

            <Box
              className={clsx(classes.subTitle)}
              style={{ margin: '3rem 0 0' }}
            >
              <Typography>デイリーヤマザキでのお支払い</Typography>
            </Box>

            <Box>
              <Typography>
                商品購入時にECサイトより通知される「オンライン決済番号／決済番号」をメモして、各コンビニ店舗へ行きます。店舗レジにて「オン
                ライン決済」と伝え、レジのお客様用画面に「オンライン決済番号」を入力し、代金をお支払いください。
              </Typography>
            </Box>

            <Link
              href="https://www.paygent.co.jp/payment_service/way_to_pay/cvs/yamazaki"
              target="_blank"
              className={clsx(classes.linkTo)}
            >
              <Box mt={2} display="flex" style={{ cursor: 'pointer' }}>
                <ArrowForwardIosIcon sx={{ width: '0.8em' }} />
                <Typography>詳細を見る</Typography>
              </Box>
            </Link>

            {nameLower !== 'lee-jongsuk' && (
              <>
                <Box
                  className={clsx(classes.subTitle)}
                  style={{ margin: '3rem 0 0' }}
                >
                  <Typography>セイコーマートでのお支払い</Typography>
                </Box>

                <Box>
                  <Typography>
                    商品購入時にECサイトより通知されているお支払い受付番号とお客様電話番号をメモしてセイコーマート店舗へ行きます。店舗レジにて「インターネット支払い」と伝え、レジのお客様用画面にお支払い受付番号を入力し、代金をお支払いください。
                  </Typography>
                </Box>

                <Link
                  href="https://www.paygent.co.jp/payment_service/way_to_pay/cvs/seicomart"
                  target="_blank"
                  className={clsx(classes.linkTo)}
                >
                  <Box mt={2} display="flex" style={{ cursor: 'pointer' }}>
                    <ArrowForwardIosIcon sx={{ width: '0.8em' }} />
                    <Typography>詳細を見る</Typography>
                  </Box>
                </Link>
              </>
            )}

            <Box
              sx={{ justifyContent: 'center' }}
              mb={5}
              mt={8}
              display="flex"
              alignItems="center"
              className={clsx(classes.registerBorderBottom)}
            >
              <Typography className={clsx(classes.registerTitle)}>
                コンビニ情報選択
              </Typography>
            </Box>

            <Box pb={5} className={classes.registerBorderBottom}>
              <Box mb={1} className={clsx(classes.displayFlex)}>
                <Box display={'flex'} className={clsx(classes.labelContainer)}>
                  <Typography className={clsx(classes.label)}>
                    ご利用店舗を選択
                  </Typography>
                  <Typography className={clsx(classes.labelRequire)}>
                    必須
                  </Typography>
                </Box>
                <Box
                  display={'flex'}
                  flexGrow={1}
                  className={clsx(classes.displayFlexSP)}
                >
                  <select
                    id="store"
                    value={storeId}
                    onChange={handleChange}
                    className={clsx(classes.selectContent)}
                  >
                    <option value="00C002">ローソン</option>
                    <option value="00C004">ミニストップ</option>
                    {nameLower !== 'lee-jongsuk' &&
                      nameLower !== 'zo-insung' && (
                        <option value="00C005">ファミリーマート</option>
                      )}
                    <option value="00C014">デイリーヤマザキ</option>
                    {nameLower !== 'lee-jongsuk' && (
                      <option value="00C016">セイコーマート</option>
                    )}
                  </select>
                </Box>
              </Box>

              <Box className={clsx(classes.displayFlex)}>
                {/* <Typography className={clsx(classes.textColor)}>
                  ページを進めますと決済のお手続きを開始します。申込み後決済の取り消しはできませんのでご注意ください。
                </Typography> */}
              </Box>
            </Box>

            <Box mb={2} mt={2} display="flex" alignItems="center">
              <Typography>
                ※申し込み完了後、期限内にお支払いください。
                <br />
                ※ご入金完了後はお客様のご都合によるキャンセルはお取り扱いいたしません。
                <br />
                申し込むボタンを押される前に必ず、お支払い金額と注意事項を十分にご確認ください。
              </Typography>
            </Box>

            <Box display="flex" justifyContent="center">
              <Box className={clsx(classes.boxWrap)}>
                <Typography>
                  {talentName} ジャパン オフィシャル ファンクラブ
                </Typography>
                <Typography>金額：6,600円/年（税込）</Typography>
                <Box
                  onClick={() => {
                    navigate(ROUTES.ABOUT_PAYMENT + '#another');
                  }}
                  style={{ cursor: 'pointer', textDecoration: 'underline' }}
                >
                  <Typography>
                    支払い時期・方法/サービス提供時期/解約について
                  </Typography>
                </Box>
              </Box>
            </Box>

            <Box className={clsx(classes.wrapButton)}>
              <Button
                onClick={() => navigate(ROUTES_AUTHEN.EDIT_PROFILE)}
                className={clsx(classes.buttonBack)}
              >
                登録情報を変更
              </Button>
              <Button
                onClick={handlePaymentByStore}
                onMouseOver={() => setHover(true)}
                onMouseOut={() => setHover(false)}
                style={{
                  backgroundColor: hover
                    ? settings?.theme_color1 + 'cc'
                    : settings?.theme_color1
                }}
                className={clsx(classes.buttonEdit)}
              >
                コンビニ決済を申し込む
              </Button>
            </Box>
          </Box>
        </Container>
      )}
      <LoaderLayer loading={product?.loading} />
      <PaymentDialog
        open={openDialog}
        onClose={setOpenDialog}
        title={titleDialog}
      />
    </div>
  );
}
export default MyPagePaymentStore;
