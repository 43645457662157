import clsx from 'clsx';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  Box,
  Container,
  makeStyles,
  Typography,
  Button
} from '@material-ui/core';

import { ROUTES, ROUTES_AUTHEN } from 'src/constants';
import { authSelector } from 'src/slices/auth';
import { getDomainTalent, getLoginToken } from 'src/utils/auth';
import {
  clearPostCommunityInfo,
  communitySelector
} from 'src/slices/community';
import {
  postCommunityCategory,
  uploadImage
} from 'src/services/communityService';
import LoaderLayer from 'src/components/LoaderLayer';
import AuthDialog from 'src/components/AuthDialog';

const useStyles = makeStyles((theme) => ({
  root: {
    justifyContent: 'center',
    display: 'flex',
    flexDirection: 'column',
    paddingTop: 100,
    [theme.breakpoints.down('700')]: {
      paddingTop: 60
    }
  },
  registerButton: {
    backgroundColor: 'black',
    color: '#fff !important',
    padding: '12px 140px',
    '&:hover': {
      backgroundColor: '#1175ae'
    },
    [theme.breakpoints.down(700)]: {
      padding: '10px 80px',
      width: '100%'
    }
  },
  containerProfile: {
    padding: '2rem',
    [theme.breakpoints.down(700)]: {
      padding: '0.5rem'
    }
  },
  containerProfileDetail: {
    display: 'flex'
  },
  profileLeft: {
    width: '20%',
    backgroundColor: '#C5C5C5',
    textAlign: 'center',
    borderBottom: '1px solid #AFAFAF',
    '& p': {
      padding: '0.5rem 0',
      height: '100%',
      fontWeight: '600',
      [theme.breakpoints.down(700)]: {
        fontSize: '0.9rem'
      }
    },
    [theme.breakpoints.down(700)]: {
      width: '30%'
    }
  },
  profileRight: {
    flexGrow: 1,
    width: '80%',
    borderBottom: '1px solid #AFAFAF',
    '& p': {
      padding: '0.5rem 0 0.5rem 2rem',
      height: '100%',
      [theme.breakpoints.down(700)]: {
        padding: '0.5rem 0 0.5rem 0.8rem',
        fontSize: '0.9rem'
      }
    },
    [theme.breakpoints.down(700)]: {
      width: '70%'
    }
  },
  image: {
    width: '40vw',
    height: '30vw',
    margin: '1rem 0 1rem 2rem',
    objectFit: 'cover',
    [theme.breakpoints.down(700)]: {
      margin: '0.5rem 0 0.5rem 0.8rem',
      width: '58vw',
      height: '50vw'
    }
  },
  wrapButton: {
    display: 'flex',
    justifyContent: 'center',
    margin: '2rem 0 4rem',
    [theme.breakpoints.down(700)]: {
      flexDirection: 'column-reverse',
      margin: '2rem 0'
    }
  },
  buttonBack: {
    backgroundColor: '#A7A7A7',
    color: '#fff !important',
    borderRadius: 0,
    padding: '11px 130px',
    marginRight: '2rem',
    '&:hover': {
      backgroundColor: '#5b5b5b'
    },
    [theme.breakpoints.down(700)]: {
      padding: '12px 0',
      width: '100%'
    }
  },
  buttonEdit: {
    backgroundColor: 'black',
    color: '#fff !important',
    borderRadius: 0,
    padding: '11px 120px',
    '&:hover': {
      backgroundColor: '#1175ae'
    },
    [theme.breakpoints.down(700)]: {
      padding: '12px 0',
      width: '100%',
      marginBottom: '1rem'
    }
  }
}));

function CommunityPostConfirm({ setting }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  const { profile } = useSelector(authSelector);
  const { categories, loading, postCommunity } = useSelector(communitySelector);

  const [currentProfile, setCurrentProfile] = useState({});
  const [category, setCategory] = useState('');

  useEffect(() => {
    const { members } = profile;
    const domainTalent = getDomainTalent();

    const myProfile = _.find(
      members,
      (member) => member.talent.domain.split('//')[1] === domainTalent
    );

    categories?.map((item) => {
      if (item.id == id) {
        setCategory(item.title);
      }
    });

    setCurrentProfile(myProfile);
  }, [profile]);

  const handlePostCommunity = async () => {
    if (postCommunity?.image) {
      const { payload } = await dispatch(uploadImage(postCommunity?.image));

      if (payload?.msg === 'Success') {
        const data = {
          category_id: id,
          body: {
            title: postCommunity?.title,
            body: postCommunity?.body,
            img: payload?.data
          }
        };
        const response = await dispatch(postCommunityCategory(data));

        if (response?.payload?.msg === 'Success') {
          dispatch(clearPostCommunityInfo());
          navigate(
            `${ROUTES_AUTHEN.COMMUNITY_POST}/${response?.payload?.data.id}?category_id=${id}`
          );
        } else {
          alert(response?.payload?.msg);
        }
      } else {
        alert(payload?.msg);
      }
    } else {
      const data = {
        category_id: id,
        body: {
          title: postCommunity?.title,
          body: postCommunity?.body
        }
      };
      const { payload } = await dispatch(postCommunityCategory(data));

      if (payload?.msg === 'Success') {
        dispatch(clearPostCommunityInfo());
        navigate(
          `${ROUTES_AUTHEN.COMMUNITY_POST}/${payload?.data.id}?category_id=${id}`
        );
      } else {
        alert(payload?.msg);
      }
    }
  };

  return (
    <div className={classes.root}>
      {getLoginToken() && (
        <Container maxWidth="md">
          <Box className={clsx(classes.containerProfile)}>
            <Box>
              <Box className={clsx(classes.containerProfileDetail)}>
                <Box className={clsx(classes.profileLeft)}>
                  <Typography>ニックネーム</Typography>
                </Box>

                <Box className={clsx(classes.profileRight)}>
                  <Typography>{currentProfile?.nickname}</Typography>
                </Box>
              </Box>

              {/* Category  */}
              <Box className={clsx(classes.containerProfileDetail)}>
                <Box className={clsx(classes.profileLeft)}>
                  <Typography>カテゴリ</Typography>
                </Box>

                <Box className={clsx(classes.profileRight)}>
                  <Typography>{category}</Typography>
                </Box>
              </Box>

              {/* Title */}
              <Box className={clsx(classes.containerProfileDetail)}>
                <Box className={clsx(classes.profileLeft)}>
                  <Typography>タイトル</Typography>
                </Box>

                <Box className={clsx(classes.profileRight)}>
                  <Typography>{postCommunity?.title}</Typography>
                </Box>
              </Box>

              {/* body */}
              <Box className={clsx(classes.containerProfileDetail)}>
                <Box className={clsx(classes.profileLeft)}>
                  <Typography>内容</Typography>
                </Box>

                <Box className={clsx(classes.profileRight)}>
                  <Typography style={{ whiteSpace: 'break-spaces' }}>
                    {postCommunity?.body}
                  </Typography>
                </Box>
              </Box>

              {/* Image */}
              <Box className={clsx(classes.containerProfileDetail)}>
                <Box
                  className={clsx(classes.profileLeft)}
                  sx={{ borderBottom: 'none !important' }}
                >
                  <Typography>画像</Typography>
                </Box>

                <Box
                  className={clsx(classes.profileRight)}
                  sx={{ borderBottom: 'none !important' }}
                >
                  {postCommunity?.image && (
                    <Box
                      component="img"
                      src={postCommunity?.imagePreview}
                      alt="FRAU INTERNATIONAL"
                      className={clsx(classes.image)}
                    />
                  )}
                </Box>
              </Box>
            </Box>
          </Box>

          <Box className={clsx(classes.wrapButton)}>
            <Button
              onClick={() => navigate(-1)}
              className={clsx(classes.buttonBack)}
            >
              戻る
            </Button>
            <Button
              onClick={handlePostCommunity}
              className={clsx(classes.buttonEdit)}
            >
              投稿する
            </Button>
          </Box>

          <Box mt={6} mb={10} display="flex" sx={{ justifyContent: 'center' }}>
            <Button
              onClick={() => navigate(ROUTES.COMMUNITY)}
              className={clsx(classes.registerButton)}
            >
              その他注意事項
            </Button>
          </Box>
        </Container>
      )}
      <LoaderLayer loading={loading} />
      {!getLoginToken() && (
        <AuthDialog open={true} onClose={() => {}} path={ROUTES.HOMEVIEW} />
      )}
    </div>
  );
}

export default CommunityPostConfirm;
