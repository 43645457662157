import React, { useEffect } from 'react';
import moment from 'moment';
import clsx from 'clsx';
import {
  Box,
  Container,
  Typography,
  makeStyles,
  Link,
  Button
} from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import LoaderLayer from 'src/components/LoaderLayer';
import { productSelector } from 'src/slices/product';
import { getProducts } from 'src/services/productService';
import { ROUTES_AUTHEN } from 'src/constants';
import { sendMessageToReactNative } from 'src/components/WebReactNativeCommunicate';
import { settingSelector } from 'src/slices/settings';

const useStyles = makeStyles((theme) => ({
  root: {
    justifyContent: 'center',
    display: 'flex',
    flexDirection: 'column',
    padding: '100px 5px 0'
  },
  registerBorderBottom: {
    borderBottom: '1px solid #919090'
  },
  wrapTitle: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    [theme.breakpoints.down(700)]: {
      flexDirection: 'column'
    }
  },
  registerTitle: {
    fontWeight: 'bold',
    fontSize: 22,
    marginBottom: 5,
    [theme.breakpoints.down('700')]: {
      fontSize: 18
    }
  },
  registerTitleText: {
    fontWeight: 'bold',
    fontSize: 20,
    [theme.breakpoints.down(700)]: {
      fontSize: '1rem'
    }
  },
  wrapTitleRight: {
    display: 'flex',
    flexDirection: 'row',
    '& p': {
      fontSize: 16,
      opacity: 0.3
    },
    [theme.breakpoints.down(700)]: {
      '& p': {
        fontSize: '0.7rem',
        marginTop: 10
      }
    }
  },
  registrationTitleActive: {
    color: '#000000',
    borderBottom: '2px solid #5D7BAB',
    opacity: '1 !important',
    marginRight: 20,
    [theme.breakpoints.down(700)]: {
      marginRight: 10
    }
  },
  titleRightText: {
    marginRight: 20,
    [theme.breakpoints.down(700)]: {
      marginRight: 10
    }
  },
  containerProfile: {
    backgroundColor: '#ffffff',
    padding: '2rem',
    margin: '3rem 0 2rem',
    [theme.breakpoints.down(700)]: {
      padding: '0.5rem'
    }
  },
  containerProfileDetail: {
    display: 'flex'
  },
  profileLeft: {
    width: '20%',
    backgroundColor: '#C5C5C5',
    textAlign: 'center',
    borderRight: '1px solid #707070',
    borderBottom: '1px dashed #AFAFAF',
    '& p': {
      padding: '0.5rem 0',
      height: '100%',
      [theme.breakpoints.down(700)]: {
        fontSize: '0.9rem'
      }
    },
    [theme.breakpoints.down(700)]: {
      width: '40%'
    }
  },
  profileRight: {
    flexGrow: 1,
    width: '80%',
    borderBottom: '1px dashed #AFAFAF',
    '& p': {
      padding: '0.5rem 0 0.5rem 2rem',
      height: '100%',
      [theme.breakpoints.down(700)]: {
        padding: '0.5rem 0 0.5rem 0.8rem',
        fontSize: '0.9rem'
      }
    },
    [theme.breakpoints.down(700)]: {
      width: '60%'
    }
  },
  button: {
    backgroundColor: 'black',
    padding: '11px 100px',
    color: 'white',
    fontSize: 18,
    '&:hover': {
      backgroundColor: 'black'
    },
    [theme.breakpoints.down(700)]: {
      padding: '12px 0',
      width: '100%'
    }
  }
}));
function PaymentATMFinish() {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { state } = useLocation();
  const product = useSelector(productSelector);
  const setting = useSelector(settingSelector);
  useEffect(() => {
    dispatch(getProducts('subscription'));
  }, []);
  return (
    <div className={classes.root}>
      <Container maxWidth="md">
        <Box mb={2} paddingBottom={window.innerWidth <= 700 ? 4 : 8}>
          <Box className={classes.wrapTitle}>
            <Typography className={classes.registerTitleText}>
              会員登録決済
            </Typography>
            <Box className={classes.wrapTitleRight}>
              <Typography className={clsx(classes.titleRightText)}>
                会員情報入力
              </Typography>
              <Typography className={classes.titleRightText}>
                入力内容確認
              </Typography>
              <Typography className={classes.registrationTitleActive}>
                会員登録決済
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{ justifyContent: 'center' }}
            mb={5}
            mt={8}
            display="flex"
            alignItems="center"
          >
            <Typography className={clsx(classes.registerTitle)}>
              ATM決済申し込み完了
            </Typography>
          </Box>
          <Box>
            <Typography>ATM決済受付を完了いたしました。</Typography>
            <Typography>お支払い情報は下記となります。</Typography>
            <Typography>
              なお、ご登録いただいたメールアドレス宛にもお支払い情報を送信いたしましたのでご確認ください。
            </Typography>
            <Typography>
              ※しばらくたってもメールが届かない場合は、携帯キャリアによりメール受信がブロックされている可能性が
            </Typography>
            <Typography style={{ marginLeft: 16 }}>
              ございます。{setting.settings.email}{' '}
              からのメールが受信できるよう、受信設定を行ってください。
            </Typography>
            <Typography>
              ※一部のメールクライアントの場合、迷惑メールフォルダに自動で振り分けられる場合がございます。
            </Typography>
            <Typography style={{ marginLeft: 16 }}>
              迷惑メールフォルダを一度ご確認ください。
            </Typography>
            <br />
            <Typography>
              「お支払い情報リンク」をクリックしてお支払い方法を確認の上、お手続きください。
              <br />
              年会費をお振込いただくまで、入会手続きは完了いたしませんのでご注意ください。
            </Typography>
          </Box>
          <Box
            sx={{ justifyContent: 'center' }}
            mb={2}
            mt={8}
            display="flex"
            alignItems="center"
            className={clsx(classes.registerBorderBottom)}
          >
            <Typography className={clsx(classes.registerTitle)}>
              決済情報の確認
            </Typography>
          </Box>
          <Box className={clsx(classes.containerProfile)}>
            <Box className={clsx(classes.containerProfileDetail)}>
              <Box className={clsx(classes.profileLeft)}>
                <Typography>申し込み受付番号</Typography>
              </Box>
              <Box className={clsx(classes.profileRight)}>
                <Typography>{state?.trading_id}</Typography>
              </Box>
            </Box>
            <Box className={clsx(classes.containerProfileDetail)}>
              <Box className={clsx(classes.profileLeft)}>
                <Typography>収納機関番号</Typography>
              </Box>
              <Box className={clsx(classes.profileRight)}>
                <Typography>{state?.pay_center_number}</Typography>
              </Box>
            </Box>
            <Box className={clsx(classes.containerProfileDetail)}>
              <Box className={clsx(classes.profileLeft)}>
                <Typography>お客様番号</Typography>
              </Box>
              <Box className={clsx(classes.profileRight)}>
                <Typography>{state?.customer_number}</Typography>
              </Box>
            </Box>
            <Box className={clsx(classes.containerProfileDetail)}>
              <Box className={clsx(classes.profileLeft)}>
                <Typography>確認番号</Typography>
              </Box>
              <Box className={clsx(classes.profileRight)}>
                <Typography>{state?.conf_number}</Typography>
              </Box>
            </Box>
            <Box className={clsx(classes.containerProfileDetail)}>
              <Box className={clsx(classes.profileLeft)}>
                <Typography>お支払い金額</Typography>
              </Box>
              <Box className={clsx(classes.profileRight)}>
                <Typography>{state?.cost}円</Typography>
              </Box>
            </Box>
            <Box className={clsx(classes.containerProfileDetail)}>
              <Box className={clsx(classes.profileLeft)}>
                <Typography>お支払い期限</Typography>
              </Box>
              <Box className={clsx(classes.profileRight)}>
                <Typography>
                  {moment(state?.payment_limit_date).format('YYYY/MM/DD')}
                </Typography>
              </Box>
            </Box>
            <Box className={clsx(classes.containerProfileDetail)}>
              <Box className={clsx(classes.profileLeft)}>
                <Typography>お支払方法の説明</Typography>
              </Box>
              <Box className={clsx(classes.profileRight)}>
                <Typography>
                  <Link
                    className={clsx(classes.link)}
                    href="https://www.paygent.co.jp/payment_service/way_to_pay/atm_pay-easy/"
                    target="_blank"
                    rel="noopener"
                    color="inherit"
                    underline="none"
                    style={{
                      fontSize: '0.9rem',
                      padding: 0
                    }}
                  >
                    https://www.paygent.co.jp/payment_service/way_to_pay/atm_pay-easy/
                  </Link>
                </Typography>
              </Box>
            </Box>
          </Box>
          {/* <Box mt={8} display="flex" justifyContent="center">
            <Button
              onClick={() => {
                navigate(ROUTES_AUTHEN.LOGIN);
                sendMessageToReactNative('log_in');
              }}
              className={clsx(classes.button)}
            >
              ログイン画面へ移動
            </Button>
          </Box> */}
        </Box>
      </Container>
      <LoaderLayer loading={product?.loading} />
    </div>
  );
}
export default PaymentATMFinish;
