import React from 'react';
import moment from 'moment';
import { makeStyles, Grid, Typography, Container } from '@material-ui/core';

import { ROUTES } from 'src/constants';
import { checkNameTalent, getDomainTalent } from 'src/utils/auth';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '0 0 50px',
    maxWidth: 900,
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto',
    '& p': {
      fontSize: 16
    }
  },
  topBanner: {
    width: '100%',
    background: '#B1B1B1',
    display: 'flex',
    height: 80
  },
  bgWhite: {
    backgroundColor: '#ffffff',
    padding: 30,
    [theme.breakpoints.down(700)]: {
      padding: '1rem'
    }
  },
  bgGray: {
    backgroundColor: '#C5C5C5',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: 50,
    textAlign: 'center'
  },
  addSpace: {
    padding: '10px 0 10px 20px',
    display: 'flex',
    alignItems: 'center'
  },
  borderBottom: {
    borderBottom: '1px dotted #AFAFAF'
  },
  heading: {
    fontSize: '2.8rem',
    lineHeight: 1,
    letterSpacing: 0,
    fontWeight: 400,
    marginBottom: '2.5rem',
    [theme.breakpoints.down(700)]: {
      fontSize: '2rem'
    },
    '&::before': {
      marginRight: '10px',
      content: '""',
      width: '30px',
      height: '30px',
      display: 'inline-block',
      backgroundColor: '#006eb1',
      [theme.breakpoints.down(700)]: {
        width: 22,
        height: 22
      }
    }
  },
  textWhite: {
    color: '#ffffff',
    marginTop: 'auto',
    marginLeft: 30,
    marginBottom: 'auto',
    fontSize: 25,
    [theme.breakpoints.up(700)]: {
      fontSize: 40
    }
  }
}));

function Law() {
  const classes = useStyles();
  const { name, nameLower } = checkNameTalent();

  const showPriceKYD = () => {
    const date = new Date();
    const domainTalent = getDomainTalent();

    if (
      domainTalent === process.env.REACT_APP_TALENT_KIM_YOUNG_DEA_DOMAIN &&
      moment(date).format('YYYY/MM/DD') <=
        moment('2023/01/31').format('YYYY/MM/DD')
    ) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <div className={classes.root} title="特定商取引法に基づく表示">
      <div className={classes.topBanner}>
        <Typography className={classes.textWhite}>
          <strong style={{ fontSize: 30 }}>特定商取引法に基づく表示</strong>
        </Typography>
      </div>
      <br />
      <br />
      <Container style={{ padding: 20 }}>
        <Grid container className={classes.bgWhite}>
          <Grid
            item
            xs={4}
            className={classes.bgGray + ' ' + classes.borderBottom}
          >
            {window.innerWidth <= 486 ? (
              <Typography>
                業務提供
                <br />
                事業者名
              </Typography>
            ) : (
              <Typography>業務提供事業者名</Typography>
            )}
          </Grid>
          <Grid
            item
            xs={8}
            className={classes.addSpace + ' ' + classes.borderBottom}
          >
            <Typography>
              株式会社フラウ・インターナショナル
              <br />
              （FRAU INTERNATIONAL CO.,LTD）
            </Typography>
          </Grid>
          <Grid
            item
            xs={4}
            className={classes.bgGray + ' ' + classes.borderBottom}
          >
            <Typography>業務の責任者</Typography>
          </Grid>
          <Grid
            item
            xs={8}
            className={classes.addSpace + ' ' + classes.borderBottom}
          >
            <Typography>{name} ジャパンオフィシャルファンクラブ</Typography>
          </Grid>
          <Grid
            item
            xs={4}
            className={classes.bgGray + ' ' + classes.borderBottom}
          >
            <Typography>事務局長</Typography>
          </Grid>
          <Grid
            item
            xs={8}
            className={classes.addSpace + ' ' + classes.borderBottom}
          >
            <Typography>石土 菜美子</Typography>
          </Grid>
          <Grid
            item
            xs={4}
            className={classes.bgGray + ' ' + classes.borderBottom}
          >
            <Typography>所在地</Typography>
          </Grid>
          <Grid
            item
            xs={8}
            className={classes.addSpace + ' ' + classes.borderBottom}
          >
            <Typography>
              〒540-0031　大阪市中央区北浜東1-22 北浜東野村ビル8Ｆ
            </Typography>
          </Grid>
          <Grid
            item
            xs={4}
            className={classes.bgGray + ' ' + classes.borderBottom}
          >
            <Typography>電話番号</Typography>
          </Grid>
          <Grid
            item
            xs={8}
            className={classes.addSpace + ' ' + classes.borderBottom}
          >
            <Typography>
              TEL：06‐4791‐2400
              <br />
              （平日 10:00～18:00 土日祝/年末年始は休業）
            </Typography>
          </Grid>
          <Grid
            item
            xs={4}
            className={classes.bgGray + ' ' + classes.borderBottom}
          >
            <Typography>メールアドレス</Typography>
          </Grid>
          <Grid
            item
            xs={8}
            className={classes.addSpace + ' ' + classes.borderBottom}
          >
            <Typography>
              info@{nameLower}.{nameLower === 'zo-insung' ? 'com' : 'jp'}
            </Typography>
          </Grid>
          <Grid
            item
            xs={4}
            className={classes.bgGray + ' ' + classes.borderBottom}
          >
            <Typography>役務内容</Typography>
          </Grid>
          <Grid
            item
            xs={8}
            className={classes.addSpace + ' ' + classes.borderBottom}
          >
            <Typography>
              「<a href={ROUTES.TERMS}>規約</a>
              」に記載のファンクラブ会員へのサービス提供
            </Typography>
          </Grid>
          <Grid
            item
            xs={4}
            className={classes.bgGray + ' ' + classes.borderBottom}
          >
            <Typography>役務対価</Typography>
          </Grid>
          <Grid
            item
            xs={8}
            className={classes.addSpace + ' ' + classes.borderBottom}
          >
            <Typography>
              年会費：6,600円（税込）
              <br />
              ポイントの購入料金は、購入手続きの際に画面に表示されます。
            </Typography>
          </Grid>
          <Grid
            item
            xs={4}
            className={classes.bgGray + ' ' + classes.borderBottom}
          >
            <Typography>支払時期</Typography>
          </Grid>
          <Grid
            item
            xs={8}
            className={classes.addSpace + ' ' + classes.borderBottom}
          >
            <Typography>
              [年会費等]
              <br />
              <Typography style={{ paddingLeft: 10 }}>
                入会時：随時（年会費をお支払いください。）
                <br />
                継続時：会員期限満了月の20日までに年会費をお支払いください。
                <br />
                なお、前年度クレジットカードで決済され、会員期限満了月の10日までに退会手続きが行われない場合は自動更新され、11日までに自動更新後の1年分の年会費をお支払いいただきます。
                <br />
              </Typography>
              <br />
              [ポイント]
              <br />
              <Typography style={{ paddingLeft: 10 }}>随時</Typography>
            </Typography>
          </Grid>
          <Grid
            item
            xs={4}
            className={classes.bgGray + ' ' + classes.borderBottom}
          >
            <Typography>支払方法</Typography>
          </Grid>
          <Grid
            item
            xs={8}
            className={classes.addSpace + ' ' + classes.borderBottom}
          >
            <Typography>
              年会費：
              <br />
              クレジットカード決済
              <br />
              コンビニ決済
              <br />
              銀行ネットバンキング決済
              <br />
              ATM決済
              <br />
              ※決済の代行は株式会社ペイジェントに委託しております。
              <br />
              <br />
              ポイント：
              <br />
              ・Google.Inc が提供する決済手段による支払方法
              <br />
              （Google Playでダウンロードしたアプリケーションのみ）
              <br />
              ・Apple.Inc
              またはその子会社が提供する決済手段による支払方法（iOS用アプリケーションのみ）
              <br />
            </Typography>
          </Grid>
          <Grid
            item
            xs={4}
            className={classes.bgGray + ' ' + classes.borderBottom}
          >
            <Typography>役務提供時期</Typography>
          </Grid>
          <Grid
            item
            xs={8}
            className={classes.addSpace + ' ' + classes.borderBottom}
          >
            <Typography>
              代金入金確認後、直ちに会員専用サイトの閲覧その他所定のサービスを提供します。
            </Typography>
          </Grid>
          <Grid
            item
            xs={4}
            className={classes.bgGray + ' ' + classes.borderBottom}
          >
            <Typography>役務対価以外の手数料等</Typography>
          </Grid>
          <Grid
            item
            xs={8}
            className={classes.addSpace + ' ' + classes.borderBottom}
          >
            <Typography>
              ウェブページの閲覧、デジタルコンテンツやソフトウェアのダウンロード等に必要となるインターネット接続料金、通信料金等はお客様の負担となります。それぞれの料金は、お客様がご利用のインターネットプロバイダーまたは携帯電話会社にお問い合わせください。
            </Typography>
          </Grid>
          <Grid
            item
            xs={4}
            className={classes.bgGray + ' ' + classes.borderBottom}
          >
            <Typography>動作環境</Typography>
          </Grid>
          <Grid
            item
            xs={8}
            className={classes.addSpace + ' ' + classes.borderBottom}
          >
            <Typography>
              アプリケーションによって利用環境・対応機種が異なります。アプリケーションのダウンロードの前に、必ずアプリケーションの詳細ページで利用環境・対応機種をご確認ください。
            </Typography>
          </Grid>
          <Grid
            item
            xs={4}
            className={classes.bgGray + ' ' + classes.borderBottom}
          >
            <Typography>その他</Typography>
          </Grid>
          <Grid
            item
            xs={8}
            className={classes.addSpace + ' ' + classes.borderBottom}
          >
            <Typography>
              入会申込後会員資格期間の途中において解約手続を行った場合でも、お支払い済みの料金は返金されません。
            </Typography>
          </Grid>
        </Grid>
      </Container>
      <br />
      <br />
    </div>
  );
}

export default Law;
