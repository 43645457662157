import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { IconButton } from '@material-ui/core';
import XIcon from '@mui/icons-material/X';
import InstagramIcon from '@mui/icons-material/Instagram';
import YouTubeIcon from '@mui/icons-material/YouTube';
import { faLine } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const useStyles = makeStyles((theme) => ({
  wrapSNS: {
    display: 'flex',
    flexDirection: 'column',
    position: 'fixed',
    zIndex: '99999',
    height: 'fit-content',
    top: '20rem',
    bottom: 0,
    left: '0.6rem',
    [theme.breakpoints.down(700)]: {
      display: 'none'
    }
  },
  iconButton: {
    padding: '2px !important'
  },
  iconButtonYT: {
    padding: '0px !important'
  },
  iconSNS: {
    width: '44px !important',
    height: '30px !important'
  }
}));

function SNSButton({ settings }) {
  const classes = useStyles();

  return (
    <div className={classes.wrapSNS}>
      {settings?.social_infos?.twitter_active_web === 1 && (
        <IconButton
          color="inherit"
          className={classes.iconButton}
          style={{ order: settings?.social_infos?.twitter_number }}
          onClick={() =>
            window.open(
              settings?.social_infos?.twitter_url,
              '_blank',
              'noopener,noreferrer'
            )
          }
        >
          <XIcon
            className={classes.iconSNS}
            sx={{ color: settings?.theme_color2 }}
          />
        </IconButton>
      )}
      {settings?.social_infos?.instagram_active_web === 1 && (
        <IconButton
          color="inherit"
          className={classes.iconButton}
          style={{ order: settings?.social_infos?.instagram_number }}
          onClick={() =>
            window.open(
              settings?.social_infos?.instagram_url,
              '_blank',
              'noopener,noreferrer'
            )
          }
        >
          <InstagramIcon
            className={classes.iconSNS}
            sx={{ color: settings?.theme_color2 }}
          />
        </IconButton>
      )}
      {settings?.social_infos?.line_active_web === 1 && (
        <IconButton
          color="inherit"
          className={classes.iconButton}
          style={{ order: settings?.social_infos?.line_number }}
          onClick={() =>
            window.open(
              settings?.social_infos?.line_url,
              '_blank',
              'noopener,noreferrer'
            )
          }
        >
          <FontAwesomeIcon
            icon={faLine}
            className={classes.iconSNS}
            color={settings?.theme_color2}
          />
        </IconButton>
      )}
      {settings?.social_infos?.youtube_active_web === 1 && (
        <IconButton
          color="inherit"
          className={classes.iconButtonYT}
          style={{ order: settings?.social_infos?.youtube_number }}
          onClick={() =>
            window.open(
              settings?.social_infos?.youtube_url,
              '_blank',
              'noopener,noreferrer'
            )
          }
        >
          <YouTubeIcon
            className={classes.iconSNS}
            sx={{ color: settings?.theme_color2 }}
          />
        </IconButton>
      )}
    </div>
  );
}

SNSButton.propTypes = {
  settings: PropTypes.object.isRequired
};

export default SNSButton;
