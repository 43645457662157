import clsx from 'clsx';
import moment from 'moment';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart } from '@fortawesome/free-solid-svg-icons';
import { faHeart as heart } from '@fortawesome/free-regular-svg-icons';
import {
  Box,
  Container,
  makeStyles,
  Typography,
  Grid
} from '@material-ui/core';

import { getVideo, postFavoriteVideo } from 'src/services/videoService';
import { videoSelector } from 'src/slices/videos';
import PaginationButton from 'src/components/PaginationButton';
import { ROUTES } from 'src/constants';
import LoaderLayer from 'src/components/LoaderLayer';
import { getDomainTalent, getLoginToken } from 'src/utils/auth';
import AuthDialog from 'src/components/AuthDialog';
import { authSelector } from 'src/slices/auth';

const useStyles = makeStyles((theme) => ({
  root: {
    justifyContent: 'center',
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down(600)]: {
      padding: '0 2px 0'
    }
  },
  container: {
    maxWidth: 1040,
    padding: theme.spacing(0, 8),
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(0, 2)
    }
  },
  contentTitle: {
    color: '#000000',
    fontSize: '1.5rem',
    marginTop: 15,
    fontWeight: '600',
    [theme.breakpoints.down(600)]: {
      fontSize: '1rem'
    }
  },
  newContent: {
    margin: '3rem 0',
    display: 'flex',
    flexWrap: 'wrap',
    gap: 8,
    justifyContent: 'space-between',
    [theme.breakpoints.down(600)]: {
      margin: '1rem 0 2rem',
      justifyContent: 'space-evenly'
    }
  },
  wrapPagination: {
    margin: '40px 0'
  },
  boxContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '4rem',
    [theme.breakpoints.down(600)]: {
      marginBottom: '1.5rem'
    }
  },
  boxContent: {
    height: 230,
    cursor: 'pointer',
    [theme.breakpoints.down(600)]: {
      height: 172
    }
  },
  imageContainer: {
    width: '100%',
    height: 'inherit',
    objectFit: 'cover'
  },
  videoTitle: {
    marginTop: '1rem',
    [theme.breakpoints.down(600)]: {
      marginTop: '0.5rem'
    }
  },
  wrapDate: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  videoDate: {
    fontSize: 18,
    [theme.breakpoints.down(767)]: {
      fontSize: 16
    }
  },
  videoText: {
    fontSize: 18,
    margin: '0.5rem 0',
    [theme.breakpoints.down(600)]: {
      margin: '0.2rem 0'
    }
  },
  wrapIcon: {
    display: 'flex',
    paddingRight: '1rem',
    [theme.breakpoints.down(767)]: {
      paddingRight: '0.2rem'
    }
  },
  iconLike: {
    cursor: 'pointer'
  },
  likeText: {
    marginLeft: '0.5rem',
    [theme.breakpoints.down(600)]: {
      fontSize: '0.8rem'
    }
  }
}));

function VideoView({ setting }) {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const videos = useSelector(videoSelector);
  const { profile } = useSelector(authSelector);
  const [searchParams, setSearchParams] = useSearchParams();
  const ncrp = parseInt(searchParams.get('page'));
  const [page, setPage] = useState(ncrp ? ncrp : 1);
  const [openDialog, setOpenDialog] = useState(false);
  const [isPayment, setIsPayment] = useState(false);

  useEffect(() => {
    dispatch(getVideo({ page: page, perPage: 6 }));
  }, [page]);

  useEffect(() => {
    const { members } = profile;
    const domainTalent = getDomainTalent();

    const myProfile = _.find(
      members,
      (member) => member.talent.domain.split('//')[1] === domainTalent
    );

    if (
      myProfile?.member_type === 'lifetime' ||
      myProfile?.member_type === 'existing_fan'
    ) {
      setIsPayment(true);
      return;
    } else if (
      myProfile?.role === 'talent' ||
      myProfile?.role === 'staff_view' ||
      myProfile?.role === 'staff_edit'
    ) {
      setIsPayment(true);
      return;
    } else if (
      myProfile?.end_datetime >= moment(new Date()).format('YYYY-MM-DD') &&
      myProfile?.role === 'fan'
    ) {
      setIsPayment(true);
      return;
    }
  }, [profile]);

  useEffect(() => {
    const searchPage = parseInt(window.location.search.split('=')[1]);
    if (!ncrp && !window.location.search) {
      setPage(1);
    } else if (searchPage !== page) {
      setPage(searchPage);
    }
  }, [window.location.search]);

  return (
    <div className={clsx(classes.root)}>
      <Container className={classes.container}>
        <Box>
          <Typography className={clsx(classes.contentTitle)}>VIDEO</Typography>
        </Box>
        <Box className={clsx(classes.newContent)}>
          <Grid container spacing={1}>
            {videos?.videos?.map((video, index) => (
              <Grid item md={4} sm={6} xs={6} key={index}>
                <Box className={clsx(classes.boxContainer)}>
                  <Box
                    onClick={() => {
                      if (!getLoginToken()) {
                        setOpenDialog(true);
                      } else {
                        navigate(ROUTES.VIDEO + '/' + video?.id);
                      }
                    }}
                    className={clsx(classes.boxContent)}
                  >
                    <Box
                      className={clsx(classes.imageContainer)}
                      component="img"
                      src={video?.thumbnail}
                    />
                  </Box>
                  <Box className={clsx(classes.videoTitle)}>
                    <Box className={classes.wrapDate}>
                      <Typography className={clsx(classes.videoDate)}>
                        {moment(video?.release_date).format('YYYY/MM/DD')}
                      </Typography>
                      {getLoginToken() && isPayment && (
                        <Box className={classes.wrapIcon}>
                          <FontAwesomeIcon
                            className={clsx(classes.iconLike)}
                            icon={video?.is_favorite ? faHeart : heart}
                            size="lg"
                            onClick={() => {
                              if (!getLoginToken()) {
                                setOpenDialog(true);
                              } else {
                                dispatch(postFavoriteVideo({ id: video.id }));
                              }
                            }}
                            style={{ color: setting.theme_color2 }}
                          />
                          <Typography
                            className={clsx(classes.likeText)}
                            style={{ color: setting.theme_color2 }}
                          >
                            {video.favorites_count}
                          </Typography>
                        </Box>
                      )}
                    </Box>
                    <Typography className={clsx(classes.videoText)}>
                      {video.title}
                    </Typography>
                  </Box>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Box>
        {videos?.totalPage > 1 && (
          <Box className={clsx(classes.wrapPagination)}>
            <PaginationButton
              page={page}
              totalItem={videos?.totalPage * 6}
              itemPerPage={6}
              paginate={setPage}
            />
          </Box>
        )}
      </Container>

      <LoaderLayer loading={videos?.loading} />
      <AuthDialog
        open={openDialog}
        onClose={setOpenDialog}
        path={ROUTES.VIDEO}
      />
    </div>
  );
}

export default VideoView;
