import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import moment from 'moment';
import {
  makeStyles,
  Container,
  Grid,
  Card,
  CardContent,
  CardMedia,
  Divider
} from '@material-ui/core';
import 'react-alice-carousel/lib/alice-carousel.css';
import Notice from '../notice/index.js';
import Twitter from '../Twitter';
import ReactPlayer from 'react-player';
import { ROUTES } from 'src/constants';
import { getLoginToken } from 'src/utils/auth';
import AuthDialog from 'src/components/AuthDialog';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(6)
  },
  container: {
    maxWidth: 1920,
    padding: theme.spacing(0, 8),
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(0, 2)
    }
  },
  gridContainer: {
    textAlign: '-webkit-center',
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(1)
    },
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column'
    }
  },
  wrapMedia: {
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100% !important',
      margin: 'auto',
      padding: '12px 0px !important'
    }
  },
  wrapMediaHalfHeight: {
    height: '50%',
    '& > div': {
      maxWidth: '100%',
      height: 'auto !important',
      width: 'auto !important',
      maxHeight: '100%',
      aspectRatio: 16 / 9
    },
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(3)
    }
  },
  wrapMediaHalfHeight2: {
    height: '50%',
    '& > div': {
      maxWidth: '100%',
      height: 'auto !important',
      width: 'auto !important',
      maxHeight: '100%',
      aspectRatio: 16 / 9
    }
  },
  card: {
    maxWidth: 1040,
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto',
    color: '#000',
    boxShadow: 'none',
    borderRadius: 0,
    backgroundColor: 'rgb(255,255,255, 50%)',
    '@media(max-width: 960px)': {
      backgroundColor: 'inherit'
    }
  },
  media: {
    width: 250,
    margin: 'auto',
    [theme.breakpoints.down(700)]: {
      width: 220
    }
  },
  divider: {
    margin: '10px auto',
    width: '40px',
    border: '1px solid'
  },
  image: {
    maxWidth: '100%',
    height: '35vh',
    width: 'auto',
    maxHeight: '100%',
    aspectRatio: 16 / 9
  }
}));

function Layout2({ className, setting, layout }) {
  const classes = useStyles();
  const navigate = useNavigate();
  const [openDialog, setOpenDialog] = useState(false);
  const id = layout?.items[2]?.content.id;
  const lastId = layout?.items[3]?.content.id;

  const handleVideoDetail = () => {
    if (!getLoginToken()) {
      setOpenDialog(true);
    } else {
      if (layout?.items[2]?.type === 'video') {
        navigate(ROUTES.VIDEO + '/' + id);
      } else if (layout?.items[3]?.type === 'video') {
        navigate(ROUTES.VIDEO + '/' + lastId);
      }
    }
  };

  return (
    <div className={clsx(classes.root, className)}>
      <Container
        className={classes.container}
        style={{ backgroundColor: layout?.background_color }}
      >
        <Card className={classes.card}>
          <img
            className={classes.media}
            src={`${layout?.title}?v=${moment().toDate().getTime()}`}
          />
          <Divider
            className={classes.divider}
            style={{ color: setting.theme_color2 }}
          />
          <CardContent className={classes.content}>
            <Grid container spacing={3} className={classes.gridContainer}>
              <Grid item xs={6} md={6} className={classes.wrapMedia}>
                {layout?.items[1]?.type === 'notice' ? (
                  <Notice setting={setting} />
                ) : (
                  <Twitter />
                )}
              </Grid>
              <Grid item xs={6} md={6} className={classes.wrapMedia}>
                <div className={classes.wrapMediaHalfHeight}>
                  {layout?.items[2]?.type === 'image' ? (
                    <CardMedia
                      className={classes.image}
                      image={`${layout?.items[2]?.content}?v=${moment()
                        .toDate()
                        .getTime()}`}
                    />
                  ) : layout?.items[2]?.type === 'video' ? (
                    <CardMedia
                      className={classes.image}
                      image={`${
                        layout?.items[2]?.content.thumbnail
                      }?v=${moment().toDate().getTime()}`}
                      onClick={handleVideoDetail}
                    />
                  ) : (
                    <ReactPlayer
                      url={layout?.items[2]?.content.url}
                      playing={false}
                      controls={true}
                      className={classes.iframe}
                      config={{
                        youtube: {
                          playerVars: { modestbranding: 1, controls: 1 }
                        }
                      }}
                    />
                  )}
                </div>
                <div className={classes.wrapMediaHalfHeight2}>
                  {layout?.items[3]?.type === 'image' ? (
                    <CardMedia
                      className={classes.image}
                      image={`${layout?.items[3]?.content}?v=${moment()
                        .toDate()
                        .getTime()}`}
                    />
                  ) : layout?.items[3]?.type === 'video' ? (
                    <CardMedia
                      className={classes.image}
                      image={`${
                        layout?.items[3]?.content.thumbnail
                      }?v=${moment().toDate().getTime()}`}
                      onClick={handleVideoDetail}
                    />
                  ) : (
                    <ReactPlayer
                      url={layout?.items[3]?.content.url}
                      playing={false}
                      controls={true}
                      className={classes.iframe}
                      config={{
                        youtube: {
                          playerVars: { modestbranding: 1, controls: 1 }
                        }
                      }}
                    />
                  )}
                </div>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Container>
      <AuthDialog
        open={openDialog}
        onClose={setOpenDialog}
        path={ROUTES.HOMEVIEW}
      />
    </div>
  );
}

Layout2.propTypes = {
  className: PropTypes.string,
  items: PropTypes.array,
  isPause: PropTypes.bool
};

export default Layout2;
