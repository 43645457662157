import clsx from 'clsx';
import { useLocation } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Container, makeStyles } from '@material-ui/core';
import MenuBar from 'src/components/MenuBar';
import PaginationButton from 'src/components/PaginationButton';
import {
  getNoticesAll,
  getNoticesByTagId,
  getTagsNotice
} from 'src/services/noticeService';
import NoticeNews from './NoticeNews';
import { noticeSelector } from 'src/slices/notices';
import LoaderLayer from 'src/components/LoaderLayer';
import { useSearchParams } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  root: {
    justifyContent: 'center',
    display: 'flex',
    flexDirection: 'column',
    paddingTop: 50,
    [theme.breakpoints.down(700)]: {
      padding: '50px 6px 0'
    }
  },
  container: {
    maxWidth: 1040,
    padding: theme.spacing(0, 8),
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(0, 2)
    }
  },
  newContent: {
    margin: '50px 0'
  },
  wrapPagination: {
    margin: '40px 0'
  }
}));

function NoticeView({ setting }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const notices = useSelector(noticeSelector);
  const { state } = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const ncrp = parseInt(searchParams.get('page'));

  const tag = localStorage.getItem('currentTag');

  const [page, setPage] = useState(ncrp ? ncrp : 1);
  const [currentTag, setCurrentTag] = useState(
    state?.id ? state : tag ? JSON.parse(tag) : { id: '', title: 'ALL' }
  );

  useEffect(() => {
    dispatch(getTagsNotice());

    if (currentTag.id === '') {
      dispatch(getNoticesAll({ page: page, perPage: 10 }));
    } else {
      dispatch(
        getNoticesByTagId({ page: page, perPage: 10, tagId: currentTag.id })
      );
    }
  }, [page, currentTag]);

  useEffect(() => {
    const searchPage = parseInt(window.location.search.split('=')[1]);

    if (!ncrp && !window.location.search) {
      setPage(1);
    } else if (searchPage !== page) {
      setPage(searchPage);
    }
  }, [currentTag, window.location.search]);

  return (
    <div className={clsx(classes.root)}>
      <Container className={classes.container}>
        {notices?.tagsNotice && (
          <Box
            sx={{ justifyContent: 'center' }}
            mb={5}
            display="flex"
            alignItems="center"
            onClick={() => setSearchParams('')}
          >
            <MenuBar
              listMenu={notices?.tagsNotice}
              all={true}
              currentTag={currentTag}
              setCurrentTag={setCurrentTag}
              borderBottom={'2px solid' + setting?.theme_color1}
            />
          </Box>
        )}
        <Box className={clsx(classes.newContent)}>
          {notices?.notices?.map((item, index) => (
            <NoticeNews
              key={item.id}
              id={item.id}
              tagName={
                currentTag.id === '' ? item?.tag?.title : currentTag.title
              }
              date={item.release_date}
              title={item.title}
              currentTag={currentTag}
              setting={setting}
              indexItem={index}
            />
          ))}
        </Box>
        {notices?.totalPage > 1 && (
          <Box className={clsx(classes.wrapPagination)}>
            <PaginationButton
              totalItem={notices?.totalPage * 10}
              itemPerPage={10}
              paginate={setPage}
              page={page}
            />
          </Box>
        )}
      </Container>
      <LoaderLayer loading={notices?.loading} />
    </div>
  );
}

export default NoticeView;
