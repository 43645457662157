/* eslint-disable import/prefer-default-export */

import { getDomainTalent } from 'src/utils/auth';

export const THEMES = {
  LIGHT: 'LIGHT',
  ONE_DARK: 'ONE_DARK',
  UNICORN: 'UNICORN'
};

export const TOPBAR = ['#000000', '#F11A1A'];
export const FCMENU = ['#006EB1', '#277F45'];
export const SNSICON = ['#006EB1', '#277F45'];
export const BUTTONCLICKEVENT = ['#006EB1', '#277F45'];

const domainTalent = getDomainTalent();

export const ROUTES = {
  HOMEVIEW: '/',
  NOTICE: '/notice',
  PROFILE: '/profile',
  DISCOGRAPHY: '/discography',
  YOUTUBE: '/youtube',
  PHOTO: '/photo',
  VIDEO: '/video',
  SHOP: '/shop',
  TERMS: '/terms',
  SITE: '/about',
  PRIVACY: '/privacy',
  LAW: '/law',
  MAGAZINE: '/magazine',
  DOWNLOAD: '/download',
  STAFF_BLOG: '/staff-blog',
  STAFF_BLOG_CONFIRM: '/staff-blog/confirm',
  TALENT_BLOG: '/message',
  TALENT_BLOG_CONFIRM: '/message/confirm',
  FAQ: '/faq',
  INQUIRY: '/inquiry',
  INQUIRY_CONFIRM: '/inquiry-confirm',
  INQUIRY_FINISH: '/inquiry-finish',
  LOVE_LETTER: '/loveletter',
  LOVE_LETTER_WRITE: '/loveletter/write',
  SEND_LETTER: '/loveletter/send',
  SEND_LETTER_SUCCESS: '/loveletter/success',
  SCHEDULE: '/schedule',
  COMMUNITY: '/community',
  ABOUT_PAYMENT: '/about-payment'
};

export const ROUTES_AUTHEN = {
  LOGIN: '/login',
  FORGOT_PASSWORD: '/forgot-password',
  LOGIN_UNPROTECTED: '/login-unprotected',
  REGISTER: '/register',
  REGISTRATION: '/registration',
  REGISTRATION_INFO: '/registration-info',
  REGISTER_INFO_CONFIRM: '/register-info-confirm',
  REGISTER_UNPROTECTED: '/register-unprotected',
  PAYMENT_CREDIT_INFO: '/payment-credit-info',
  PAYMENT_COMPLETE: '/payment-complete',
  VIDEO_DETAIL: '/video/detail',
  MY_PAGE: '/mypage',
  MY_PAGE_PAYMENT_ATM: '/mypage/payment-atm',
  MY_PAGE_PAYMENT_ATM_FINISH: '/mypage/payment-atm-finish',
  MY_PAGE_PAYMENT_STORE: '/mypage/payment-store',
  MY_PAGE_PAYMENT_STORE_FINISH: '/mypage/payment-store-finish',
  MY_PAGE_PAYMENT_BANKING: '/mypage/payment-banking',
  MY_PAGE_PAYMENT_BANKING_FINISH: '/mypage/payment-banking-finish',
  EDIT_PROFILE: '/edit-profile',
  UPDATE_PROFILE: '/update-profile',
  MAGAZINE_DETAIL: '/magazine/detail',
  FORGOT_PASSWORD_OTP: '/password-forgot-otp',
  RESET_PASSWORD: '/reset-password',
  DELETE_ACCOUNT: '/delete-account',
  CHANGE_EMAIL: '/change-email',
  CHANGE_EMAIL_OTP: '/change-email/code-confirm',
  UPDATE_PASSWORD: '/update-password',
  PAYMENT_TYPE: '/payment-type',
  CHOOSE_CREDIT: '/credit-card',
  PAYMENT_ATM: '/payment-atm',
  PAYMENT_ATM_FINISH: '/payment-atm-finish',
  CREDIT_INFO: '/credit-info',
  PAYMENT_FINISH: '/payment-finish',
  PAYMENT_STORE: '/payment-store',
  PAYMENT_STORE_FINISH: '/payment-store-finish',
  PAYMENT_BANKING: '/payment-banking',
  PAYMENT_BANKING_FINISH: '/payment-banking-finish',
  COMMUNITY_BOARD: '/community-board',
  COMMUNITY_ON_BOARD: '/community-on-board',
  COMMUNITY_POST: '/community-post',
  POST_COMMUNITY: '/post-community',
  COMMUNITY_POST_CONFIRM: '/community-post-confirm',
  MEMBER_BENEFITS: '/member-benefits'
};

export const EVENTS = {
  HELLO_AGAIN: '/event/2023_helloagain'
};

export const authPagePath = [
  '/fromtarent',
  '/mypage',
  '/edit-profile',
  '/update-profile',
  '/delete-account',
  '/change-email',
  '/change-email/code-confirm',
  '/update-password',
  '/payment-type',
  '/mypage/payment-atm',
  '/mypage/payment-atm-finish',
  '/mypage/payment-store',
  '/mypage/payment-store-finish',
  '/mypage/payment-banking',
  '/mypage/payment-banking-finish',
  '/credit-card',
  '/credit-info',
  '/payment-finish',
  '/staff-blog/detail',
  '/from-talent',
  '/from-talent/detail',
  '/magazine',
  '/schedule',
  '/download',
  '/loveletter',
  '/loveletter/write',
  '/loveletter/send',
  '/magazine',
  '/magazine/detail',
  '/community',
  '/community-board',
  '/community-on-board',
  '/community-post',
  '/post-community',
  '/community-post-confirm'
];

export const ENABLE_REDUX_DEV_TOOLS = true;
export const REGISTER_TOKEN = 'registerToken';
export const LOGIN_TOKEN = 'loginToken';
export const TOKEN_EXPIRED = 'セッションが切れました';
export const TOKEN_NOT_PROVIDED = 'セッションが切れました';
export const FORGOT_TOKEN = 'forgotToken';
