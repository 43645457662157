/* eslint-disable react/display-name */
import React, { useEffect, forwardRef } from 'react';
import clsx from 'clsx';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import { Card, Typography } from '@material-ui/core';
import { getScheduleById } from 'src/services/scheduleService';
import { scheduleSelector } from 'src/slices/schedules';
import iconNote from 'src/assets/images/schedule/note.svg';
import iconTimer from 'src/assets/images/schedule/timer.svg';
import iconJapan from 'src/assets/images/schedule/japan.svg';
import iconKorean from 'src/assets/images/schedule/korean.svg';
import iconMedia from 'src/assets/images/schedule/media-icon-black.png';
import iconOther from 'src/assets/images/schedule/other-icon-black.png';
import iconEvent from 'src/assets/images/schedule/event-icon-black.png';
import iconRelease from 'src/assets/images/schedule/release-icon-black.png';
import iconSchedule from 'src/assets/images/schedule/schedule-icon-black.png';
import iconAnniversary from 'src/assets/images/schedule/anniversary-icon-black.png';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    outline: 'none',
    boxShadow: theme.shadows[20],
    width: 700,
    maxHeight: '100%',
    overflowY: 'auto',
    maxWidth: '100%',
    backgroundColor: '#fff',
    padding: theme.spacing(4)
  },
  form: {
    marginBottom: theme.spacing(5),
    borderBottom: '1px solid',
    '&:last-child': {
      border: 'none'
    }
  },
  wrapImageSchedule: {
    display: 'flex',
    marginBottom: theme.spacing(4)
  },
  imageSchedule1: {
    width: 45,
    [theme.breakpoints.down(768)]: {
      width: 40
    }
  },
  imageSchedule2: {
    width: 45,
    marginLeft: 16,
    [theme.breakpoints.down(768)]: {
      width: 40
    }
  },
  imageSchedule: {
    width: 40,
    [theme.breakpoints.down(768)]: {
      width: 36
    }
  },
  imageSchedule3: {
    width: 100,
    marginLeft: 16,
    [theme.breakpoints.down(768)]: {
      width: 86
    }
  },
  imageSchedule4: {
    width: 65,
    marginLeft: 16,
    [theme.breakpoints.down(768)]: {
      width: 56
    }
  },
  textSchedule: {
    alignSelf: 'center',
    marginLeft: theme.spacing(4),
    color: '#000',
    fontWeight: 600,
    fontSize: 16,
    [theme.breakpoints.down(768)]: {
      fontSize: 14
    }
  }
}));

const AddEditEvent = forwardRef((props, ref) => {
  const { event, open, className, ...rest } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const schedules = useSelector(scheduleSelector);

  useEffect(() => {
    dispatch(getScheduleById({ date: event }));
  }, [event]);

  return (
    <Card {...rest} className={clsx(classes.root, className)} ref={ref}>
      {schedules?.tagsSchedule?.slice(0, 2)?.map((schedule, index) => (
        <form className={classes.form} key={index}>
          <div className={classes.wrapImageSchedule}>
            <img
              className={classes.imageSchedule1}
              alt="FRAU INTERNATIONAL"
              src={schedule.national_flag === 'jp' ? iconJapan : iconKorean}
            />
            <img
              alt="FRAU INTERNATIONAL"
              className={clsx(
                schedule.type === 'anniversary'
                  ? classes.imageSchedule3
                  : schedule.type === 'schedule'
                  ? classes.imageSchedule4
                  : classes.imageSchedule2
              )}
              src={
                schedule.type === 'media'
                  ? iconMedia
                  : schedule.type === 'schedule'
                  ? iconSchedule
                  : schedule.type === 'other'
                  ? iconOther
                  : schedule.type === 'anniversary'
                  ? iconAnniversary
                  : schedule.type === 'release'
                  ? iconRelease
                  : iconEvent
              }
            />
          </div>
          <div className={classes.wrapImageSchedule}>
            <img
              className={classes.imageSchedule}
              alt="FRAU INTERNATIONAL"
              src={iconTimer}
            />
            <Typography
              className={classes.textSchedule}
              style={{ marginLeft: 19 }}
            >
              {schedule?.date_from === schedule?.date_to
                ? schedule?.date_from
                : `${moment(schedule?.date_from).format(
                    'YYYY/MM/DD'
                  )} - ${moment(schedule?.date_to).format('YYYY/MM/DD')}`}
            </Typography>
          </div>
          <div className={classes.wrapImageSchedule}>
            <img
              className={classes.imageSchedule}
              alt="FRAU INTERNATIONAL"
              src={iconNote}
            />
            <Typography
              className={clsx(classes.textSchedule, 'ck_content')}
              dangerouslySetInnerHTML={{
                __html: schedule.description
              }}
            ></Typography>
          </div>
        </form>
      ))}
    </Card>
  );
});

AddEditEvent.propTypes = {
  className: PropTypes.string,
  event: PropTypes.string,
  open: PropTypes.bool
};

export default AddEditEvent;
