import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'src/utils/API';

export const getTopLayouts = createAsyncThunk(
  'top-layouts',
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.get('/top-layouts');

      if (response.status === 200) {
        return response?.data.data;
      }
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
