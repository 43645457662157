/* eslint-disable */
import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'src/utils/API';
import { REGISTER_TOKEN, LOGIN_TOKEN, FORGOT_TOKEN } from 'src/constants';

const setTokenToLocalStorage = (nameToken, token) => {
  localStorage.setItem(nameToken, token);
};

const setTokenToAxiosHeader = (token) => {
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
};

const clearToken = (nameToken) => {
  localStorage.removeItem(nameToken);
};

const setRegisterToken = (response) => {
  const token = response.data.data.code;

  setTokenToLocalStorage(REGISTER_TOKEN, token);
};

const setAccessTokenLogin = (response) => {
  const loginToken = response.data.data.token;

  setTokenToAxiosHeader(loginToken);
  setTokenToLocalStorage(LOGIN_TOKEN, loginToken);
};

const setTokenForgot = (response) => {
  const token = response?.data.data.token;
  setTokenToLocalStorage(FORGOT_TOKEN, token);
};

export const loginUser = createAsyncThunk(
  'user/login',
  async ({ email, password }, { rejectWithValue }) => {
    try {
      const response = await axios.post('/user/login', {
        email,
        password
      });

      if (response.status === 200) {
        setAccessTokenLogin(response);
        return response.data;
      }
      return rejectWithValue(response.data);
    } catch (e) {
      return rejectWithValue(e.response.data);
    }
  }
);

export const registerUser = createAsyncThunk(
  'user/register',
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post('/user/register', data);

      if (response?.data.msg === 'Success') {
        clearToken(REGISTER_TOKEN);
        setAccessTokenLogin(response);
        return response.data;
      } else {
        return rejectWithValue(response.msg);
      }
    } catch (e) {
      return rejectWithValue(e.response.data);
    }
  }
);

export const verifyUser = createAsyncThunk(
  'user/verify',
  async (data, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.post('/user/verify-email', data);

      if (response.status === 200) {
        setRegisterToken(response);
        return response.data;
      }

      return rejectWithValue(response.data);
    } catch (e) {
      return rejectWithValue(e.response.data);
    }
  }
);

export const sendCode = createAsyncThunk(
  'user/sendCode',
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post('/user/send-verify-email', data);

      if (response.data.msg === 'Success') {
        return { data: response.data, email: data?.email };
      } else {
        return rejectWithValue(response.msg);
      }
    } catch (e) {
      return rejectWithValue(e.response.data);
    }
  }
);

export const sendForgotEmail = createAsyncThunk(
  'user/sendForgotEmail',
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post('/user/forgot-password', data);

      if (response.status === 200) {
        return {
          data: response.data,
          info: { email: data?.email, birthday: data?.birthday }
        };
      }
      return rejectWithValue(response.data);
    } catch (e) {
      return rejectWithValue(e.response.data);
    }
  }
);

export const applyForgotCode = createAsyncThunk(
  'user/applyForgotCode',
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post('/user/apply-forgot-code', data);

      if (response.status === 200) {
        setTokenForgot(response);
        return response.data;
      }

      return rejectWithValue(response.data);
    } catch (e) {
      return rejectWithValue(e.response.data);
    }
  }
);

export const resetPassword = createAsyncThunk(
  'user/resetPassword',
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post('/user/reset-password', data);
      if (response.status === 200) {
        clearToken(FORGOT_TOKEN);
        return response.data;
      }
      return rejectWithValue(response.data);
    } catch (e) {
      return rejectWithValue(e.response.data);
    }
  }
);

export const getUserProfile = createAsyncThunk(
  'user/profile',
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.get('/user/profile');

      if (response.status === 200) {
        return response.data.data.user;
      }
    } catch (error) {
      if (error.response.status === 401) {
        clearToken(LOGIN_TOKEN);
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const getAvatar = createAsyncThunk(
  'user/avatars',
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.get('/user/avatars');

      if (response.status === 200) {
        return response.data.data;
      }
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateProfile = createAsyncThunk(
  'user/profile-update',
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.put('/user/profile', data);

      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteAccount = createAsyncThunk(
  'user/member/delete',
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.delete(
        '/user/member/delete?password=' +
          data.password +
          '&reason=' +
          data.reason +
          '&members_member_id=' +
          data.member_id +
          '&name=' +
          data.name
      );

      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const sendVerifyUpdateEmail = createAsyncThunk(
  'user/sendVerifyUpdateEmail',
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        '/user/profile/send-verify-update-email',
        data
      );

      if (response.status === 200) {
        return { data: response.data, new_email: data?.email };
      } else {
        return response;
      }
    } catch (e) {
      return rejectWithValue(e.response.data);
    }
  }
);

export const verifyEmailUpdate = createAsyncThunk(
  'user/verifyEmailUpdate',
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post('/user/profile/verify-email', data);

      if (response.status === 200) {
        return response.data;
      }
    } catch (e) {
      return rejectWithValue(e.response.data);
    }
  }
);

export const checkDuplicateNickname = createAsyncThunk(
  'user/duplicateNickname',
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post('/user/profile/is-valid-form', data);
      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
