import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import { useLocation } from 'react-router';

import { ROUTES } from 'src/constants';
import './styles.css';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    minHeight: 600
  },
  wrapper: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden'
  },
  contentContainer: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden'
  },
  content: {
    flex: '1 1 auto',
    height: '100%',
    overflow: 'auto',
    overflowX: 'hidden'
  },
  contentBottom: {
    paddingTop: '4rem',
    flex: '1 1 auto',
    height: '100%',
    overflow: 'auto',
    overflowX: 'hidden'
  },
  banner: {
    width: '100%'
  },
  // headerTop: {
  //   paddingTop: '4rem'
  // },
  bannerWrap: {
    position: 'relative',
    maxWidth: 2560,
    margin: '0 auto'
  },
  titlePage: {
    position: 'absolute',
    bottom: 44,
    left: '5.5rem',
    textTransform: 'uppercase',
    color: '#ffffff',
    fontFamily: 'sans-serif',
    letterSpacing: '0.2rem',
    fontSize: 30,
    [theme.breakpoints.down(700)]: {
      left: '1.4rem',
      bottom: '0.8rem',
      fontSize: '1.2rem'
    }
  },
  w100: {
    width: '100%',
    maxHeight: 1080
  }
}));

function SubLayout({ children, setting }) {
  const classes = useStyles();
  const data = useLocation();
  const titlePage = data.pathname.split('/');

  const checkActiveImage = () => {
    if (
      titlePage[1] == ROUTES.NOTICE.substring(1) &&
      setting?.settings?.talent_settings?.img_notice_active === 1
    ) {
      return true;
    } else if (
      titlePage[1] == ROUTES.DISCOGRAPHY.substring(1) &&
      setting?.settings?.talent_settings?.img_discography_active === 1
    ) {
      return true;
    } else if (
      titlePage[1] == ROUTES.PROFILE.substring(1) &&
      setting?.settings?.talent_settings?.img_profile_active === 1
    ) {
      return true;
    } else if (
      titlePage[1] == ROUTES.YOUTUBE.substring(1) &&
      setting?.settings?.talent_settings?.img_youtube_active === 1
    ) {
      return true;
    } else if (
      titlePage[1] == ROUTES.PHOTO.substring(1) &&
      setting?.settings?.talent_settings?.img_photo_active === 1
    ) {
      return true;
    } else if (
      titlePage[1] == ROUTES.VIDEO.substring(1) &&
      setting?.settings?.talent_settings?.img_video_active === 1
    ) {
      return true;
    } else if (
      titlePage[1] == ROUTES.MAGAZINE.substring(1) &&
      setting?.settings?.talent_settings?.img_magazine_active === 1
    ) {
      return true;
    } else if (
      titlePage[1] == ROUTES.DOWNLOAD.substring(1) &&
      setting?.settings?.talent_settings?.img_download_active === 1
    ) {
      return true;
    } else if (
      titlePage[1] == ROUTES.TALENT_BLOG.substring(1) &&
      setting?.settings?.talent_settings?.img_from_talent_active === 1
    ) {
      return true;
    } else if (
      titlePage[1] == ROUTES.STAFF_BLOG.substring(1) &&
      setting?.settings?.talent_settings?.img_staffblog_active === 1
    ) {
      return true;
    } else if (
      titlePage[1] == ROUTES.COMMUNITY.substring(1) &&
      setting?.settings?.talent_settings?.img_community_active === 1
    ) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <div className={classes.root} key={titlePage[1]}>
      <div
        className={clsx(
          classes.banner,
          setting?.settings?.talent_settings?.menu_type === 1 &&
            classes.headerTop
        )}
        style={{
          backgroundColor: setting?.settings?.base_color,
          marginTop:
            checkActiveImage &&
            window.location.pathname !== ROUTES.HOMEVIEW &&
            setting?.settings?.talent_settings?.menu_type === 1
              ? 70
              : 0
        }}
      >
        <div className={classes.bannerWrap + ' slideAnimeLeftRight'}>
          {titlePage[1] == ROUTES.NOTICE.substring(1) &&
            setting?.settings?.talent_settings?.img_notice_active === 1 && (
              <img
                src={setting?.settings?.talent_settings?.img_notice}
                alt="FRAU"
                className={classes.w100}
              />
            )}
          {titlePage[1] == ROUTES.DISCOGRAPHY.substring(1) &&
            setting?.settings?.talent_settings?.img_discography_active ===
              1 && (
              <img
                src={setting?.settings?.talent_settings?.img_discography}
                alt="FRAU"
                className={classes.w100}
              />
            )}
          {titlePage[1] == ROUTES.PROFILE.substring(1) &&
            setting?.settings?.talent_settings?.img_profile_active === 1 && (
              <img
                src={setting?.settings?.talent_settings?.img_profile}
                alt="FRAU"
                className={classes.w100}
              />
            )}
          {titlePage[1] == ROUTES.YOUTUBE.substring(1) &&
            setting?.settings?.talent_settings?.img_youtube_active === 1 && (
              <img
                src={setting?.settings?.talent_settings?.img_youtube}
                alt="FRAU"
                className={classes.w100}
              />
            )}
          {titlePage[1] == ROUTES.PHOTO.substring(1) &&
            setting?.settings?.talent_settings?.img_photo_active === 1 && (
              <img
                src={setting?.settings?.talent_settings?.img_photo}
                alt="FRAU"
                className={classes.w100}
              />
            )}
          {titlePage[1] == ROUTES.VIDEO.substring(1) &&
            !titlePage[2] &&
            setting?.settings?.talent_settings?.img_video_active === 1 && (
              <img
                src={setting?.settings?.talent_settings?.img_video}
                alt="FRAU"
                className={classes.w100}
              />
            )}
          {titlePage[1] == ROUTES.MAGAZINE.substring(1) &&
            setting?.settings?.talent_settings?.img_magazine_active === 1 && (
              <img
                src={setting?.settings?.talent_settings?.img_magazine}
                alt="FRAU"
                className={classes.w100}
              />
            )}
          {titlePage[1] == ROUTES.DOWNLOAD.substring(1) &&
            setting?.settings?.talent_settings?.img_download_active === 1 && (
              <img
                src={setting?.settings?.talent_settings?.img_download}
                alt="FRAU"
                className={classes.w100}
              />
            )}
          {titlePage[1] == ROUTES.TALENT_BLOG.substring(1) &&
            setting?.settings?.talent_settings?.img_from_talent_active ===
              1 && (
              <img
                src={setting?.settings?.talent_settings?.img_from_talent}
                alt="FRAU"
                className={classes.w100}
              />
            )}
          {titlePage[1] == ROUTES.STAFF_BLOG.substring(1) &&
            setting?.settings?.talent_settings?.img_staffblog_active === 1 && (
              <img
                src={setting?.settings?.talent_settings?.img_staffblog}
                alt="FRAU"
                className={classes.w100}
              />
            )}
          {titlePage[1] == ROUTES.COMMUNITY.substring(1) &&
            setting?.settings?.talent_settings?.img_community_active === 1 && (
              <img
                src={setting?.settings?.talent_settings?.img_community}
                alt="FRAU"
                className={classes.w100}
              />
            )}
          <h2 className={classes.titlePage + ' flowing'}>{titlePage[1]}</h2>
        </div>
      </div>
      <div className={classes.wrapper}>
        <div
          className={classes.contentContainer}
          style={{ backgroundColor: setting?.settings?.base_color }}
        >
          <div
            className={
              setting?.settings?.talent_settings?.menu_type === 1
                ? classes.content
                : classes.contentBottom
            }
            style={
              checkActiveImage() || titlePage[1] === ''
                ? { paddingTop: 0 }
                : { paddingTop: window.innerWidth >= 767 ? '2rem' : '1rem' }
            }
          >
            {children}
          </div>
        </div>
      </div>
    </div>
  );
}

SubLayout.propTypes = {
  children: PropTypes.any
};

export default SubLayout;
