import './styles.css';
import clsx from 'clsx';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import SwiperCore, { Autoplay, Pagination, Navigation } from 'swiper/core';
SwiperCore.use([Autoplay, Pagination, Navigation]);
import { CardMedia } from '@material-ui/core';
import { settingSelector } from 'src/slices/settings';
import { useSelector } from 'react-redux';
const breakpoints = {
  0: {
    slidesPerView: 1
  }
};

const MainSlide = ({ imageList }) => {
  const setting = useSelector(settingSelector);
  return (
    <>
      <Swiper
        spaceBetween={30}
        centeredSlides={true}
        autoplay={
          imageList?.length > 1
            ? { delay: 5000, disableOnInteraction: false }
            : false
        }
        loop
        speed={800}
        allowTouchMove={imageList?.length > 1 ? true : false}
        className="main__slider"
        breakpoints={breakpoints}
      >
        {imageList.map((image, index) => {
          return (
            <SwiperSlide
              key={index}
              className={image.external_url ? 'open__newtab' : ''}
              onClick={() => {
                if (image.external_url) {
                  window.open(image.external_url, '_blank');
                }
              }}
            >
              <CardMedia
                draggable={false}
                image={image.image_url}
                className={clsx(
                  image.external_url
                    ? 'main_slide__image open__newtab'
                    : 'main_slide__image',
                  'main__slide'
                )}
                // style={setting?.settings?.talent_settings?.menu_type === 1 ? {height: "calc(100vh - 65px)"} : {height: "calc(100vh - 90px)"}}
              />
            </SwiperSlide>
          );
        })}
      </Swiper>
    </>
  );
};

export default MainSlide;
