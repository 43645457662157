import clsx from 'clsx';
import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%'
  },
  wrap: {
    display: 'flex',
    justifyContent: 'space-between',
    '& p': {
      fontSize: 16
    },
    [theme.breakpoints.down(700)]: {
      flexDirection: 'column'
    }
  },
  wrapLeft: {
    '& p': {
      fontSize: 25,
      fontFamily: 'futura-pt',
      fontWeight: 500
    },
    [theme.breakpoints.up(700)]: {
      width: '30%'
    }
  },
  wrapRight: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'end',
    '& p': {
      textAlign: 'center',
      fontSize: 16,
      cursor: 'pointer',
      padding: '0 1.2rem',
      marginBottom: '1rem',
      fontFamily: 'futura-pt',
      fontWeight: 500
    },
    [theme.breakpoints.down(700)]: {
      flexWrap: 'wrap',
      marginTop: 30,
      justifyContent: 'flex-start',
      padding: '0 8px',
      '& p': {
        marginBottom: 20,
        fontSize: 15,
        padding: 0,
        marginLeft: '1rem'
      }
    }
  },
  itemActive: {
    borderBottom: '2px solid #006EB1'
  },
  itemHover: {
    position: 'relative',
    '&:hover': {
      color: '#000'
    },
    '&:after': {
      content: '""',
      position: 'absolute',
      bottom: 0,
      left: 0,
      width: '100%',
      height: '2px',
      background: '#333',
      transition: 'all .3s',
      transform: 'scale(0, 1)',
      transformOrigin: 'left top'
    },
    '&:hover': {
      '&:after': {
        transform: 'scale(1, 1)'
      }
    }
  },
  textUppercase: {
    textTransform: 'uppercase'
  }
}));

function MenuBar({
  listMenu,
  all = false,
  currentTag,
  setCurrentTag,
  borderBottom
}) {
  const classes = useStyles();

  const handleCurrentItem = (item) => {
    if (item?.title === 'ALL' && localStorage.getItem('currentTag')) {
      localStorage.removeItem('currentTag');
    }

    setCurrentTag(item);
  };

  return (
    <div className={clsx(classes.root)}>
      <Box className={clsx(classes.wrap)}>
        <Box className={clsx(classes.wrapLeft)}>
          <Typography
            className={clsx(classes.textUppercase, classes.tagCurrentText)}
          >
            {currentTag?.title}
          </Typography>
        </Box>
        <Box className={clsx(classes.wrapRight)}>
          {all && (
            <Typography
              className={clsx(
                'ALL' === currentTag?.title
                  ? classes.itemActive
                  : classes.itemHover,
                classes.textUppercase
              )}
              onClick={() => handleCurrentItem({ id: '', title: 'ALL' })}
              style={{
                borderBottom: 'ALL' === currentTag?.title ? borderBottom : ''
              }}
            >
              ALL
            </Typography>
          )}

          {listMenu.map((item) => (
            <Typography
              key={item.id}
              className={clsx(
                item.title === currentTag?.title
                  ? classes.itemActive
                  : classes.itemHover,
                classes.textUppercase
              )}
              onClick={() => handleCurrentItem(item)}
              style={{
                borderBottom:
                  item.title === currentTag?.title ? borderBottom : '',
                marginLeft: 10
              }}
            >
              {item.title}
            </Typography>
          ))}
        </Box>
      </Box>
    </div>
  );
}

MenuBar.propTypes = {
  listMenu: PropTypes.array,
  all: PropTypes.bool,
  setCurrentTag: PropTypes.func,
  currentTag: PropTypes.object,
  borderBottom: PropTypes.string
};

export default MenuBar;
