import { createSlice } from '@reduxjs/toolkit';

import { getTopLayouts } from 'src/services/topLayoutService';

export const topLayoutSlice = createSlice({
  name: 'layout',
  initialState: {
    loading: false,
    layout: [],
    errorMessage: ''
  },
  reducers: {
    clearState: (state) => {
      state.loading = false;
      state.layout = [];
      state.errorMessage = '';
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getTopLayouts.pending, (state) => {
      state.loading = true;
    }),
      builder.addCase(getTopLayouts.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.layout = payload;
        return state;
      }),
      builder.addCase(getTopLayouts.rejected, (state, { payload }) => {
        state.errorMessage = payload?.error;
        state.loading = false;
      });
  }
});

export const topLayoutSelector = (state) => state.layout;
export const reducer = topLayoutSlice.reducer;
export const { clearState } = topLayoutSlice.actions;
