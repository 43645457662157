import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'src/utils/API';

export const postInquiry = createAsyncThunk(
  'inquiry',
  async ({ first_name, last_name, email, type, body }, { rejectWithValue }) => {
    try {
      const response = await axios.post(`/inquiry`, {
        headers: {
          Authorization: axios.defaults.headers.common.Authorization
        },
        first_name,
        last_name,
        email,
        type,
        body
      });
      if (response.status === 200) {
        return response.data.data;
      }
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getFAQ = createAsyncThunk(
  'faq',
  async ({ page }, { rejectWithValue }) => {
    try {
      const response = await axios.get(`/faqs?page=${page}`);

      if (response.status === 200) {
        return response.data.data;
      }
    } catch (error) {
      return rejectWithValue(error?.response?.data);
    }
  }
);
