import clsx from 'clsx';
import moment from 'moment';
import _ from 'lodash';
import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import {
  Box,
  Container,
  makeStyles,
  Typography,
  Grid,
  FormControl,
  NativeSelect,
  Button
} from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart } from '@fortawesome/free-solid-svg-icons';
import {
  faHeart as heart,
  faMessage
} from '@fortawesome/free-regular-svg-icons';
import PaginationButton from 'src/components/PaginationButton';
import { ROUTES } from 'src/constants';
import { blogSelector } from 'src/slices/blogs';
import { getBlog, postFavoriteBlog } from 'src/services/blogsService';
import LoaderLayer from 'src/components/LoaderLayer';
import { authSelector } from 'src/slices/auth';
import { getDomainTalent, getLoginToken } from 'src/utils/auth';
import CheckPaymentDialog from 'src/components/CheckPaymentDialog';
import AuthDialog from 'src/components/AuthDialog';

const useStyles = makeStyles((theme) => ({
  root: {
    justifyContent: 'center',
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down(600)]: {
      padding: '0 2px 0'
    }
  },
  container: {
    maxWidth: 1040,
    padding: theme.spacing(0, 8),
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(0, 2)
    }
  },
  wrapTitle: {
    display: 'flex',
    justifyContent: 'center',
    paddingBottom: '0.5rem',
    borderBottom: 'solid 1px #707070'
  },
  contentTitle: {
    color: '#000000',
    fontSize: '1.5rem',
    fontWeight: '600',
    [theme.breakpoints.down(600)]: {
      fontSize: '1rem',
      padding: '0 1.5rem'
    }
  },
  newContent: {
    margin: '4rem 0 3rem',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    [theme.breakpoints.between(600, 1023)]: {
      justifyContent: 'space-evenly'
    },
    [theme.breakpoints.down(600)]: {
      margin: '2rem 0 2rem',
      justifyContent: 'center',
      padding: '0 16px'
    }
  },
  wrapPagination: {
    margin: '40px 0'
  },
  boxContent: {
    height: 255,
    position: 'relative',
    cursor: 'pointer'
  },
  imageContainer: {
    width: '100%',
    height: 'inherit',
    objectFit: 'cover'
  },
  formControl: {
    '& > div': {
      color: '#000000',
      '&:before': {
        borderBottomColor: '#000000'
      },
      '& > svg': {
        color: '#000000'
      },
      '&:after': {
        borderBottomColor: '#000000 !important'
      }
    }
  },
  blogDate: {
    fontFamily: 'futura-pt',
    fontWeight: '600',
    textAlign: 'left'
  },
  fontTitle: {
    fontSize: '1.5rem',
    fontWeight: 'bold',
    textAlign: 'left',
    [theme.breakpoints.down(700)]: {
      fontSize: 18
    }
  },
  buttonViewMore: {
    color: '#efefef',
    fontSize: '17px',
    fontFamily: 'futura-pt',
    borderRadius: 0,
    padding: '8px 0',
    marginTop: 10,
    width: '50%',
    minWidth: 320,
    padding: '10px 0',
    [theme.breakpoints.down('xs')]: {
      textAlign: 'center',
      minWidth: 0,
      width: '100%'
    }
  },
  iconLike: {
    cursor: 'pointer'
  },
  wrapDivider: {
    margin: '80px 0px'
  },
  divider: {},
  wrapHtmlCode: {
    display: 'inline-block',
    height: '4.6rem',
    overflow: 'hidden',
    [theme.breakpoints.down(767)]: {
      width: '100%'
    }
  },
  htmlCode: {
    display: '-webkit-box',
    '-webkit-box-orient': 'vertical',
    '-webkit-line-clamp': 2,
    overflow: 'hidden',
    // whiteSpace: 'pre-line',
    textAlign: 'start',
    fontSize: '1rem',
    lineHeight: '1.5rem',
    // height: '4.5rem',
    textOverflow: 'ellipsis',
    '& *': {
      padding: 'revert'
    },
    '& img': {
      display: 'none'
    },
    '& audio': {
      display: 'none'
    },
    '& iframe': {
      display: 'none'
    },
    '& p': {
      textAlign: 'start !important'
    }
  },
  displaySP: {
    width: '95%',
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto',
    '@media(min-width: 700px)': {
      display: 'none !important'
    }
  },
  displayPc: {
    display: 'block',
    '@media(max-width: 700px)': {
      display: 'none'
    }
  },
  oneRow: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%'
  }
}));

function StaffBlogView({ setting }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const blogs = useSelector(blogSelector);
  const { profile } = useSelector(authSelector);
  const [searchParams, setSearchParams] = useSearchParams();
  const ncrp = parseInt(searchParams.get('page'));
  const [page, setPage] = useState(ncrp ? ncrp : 1);
  const [isPayment, setIsPayment] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [openAuthDialog, setOpenAuthDialog] = useState(false);
  const [lang, setLang] = React.useState('1');
  const handleChange = (e) => {
    setLang(e.target.value);
  };

  useEffect(() => {
    const { members } = profile;
    const domainTalent = getDomainTalent();

    const myProfile = _.find(
      members,
      (member) => member.talent.domain.split('//')[1] === domainTalent
    );

    if (
      myProfile?.member_type === 'lifetime' ||
      myProfile?.member_type === 'existing_fan'
    ) {
      setIsPayment(true);
    } else if (
      myProfile?.role === 'talent' ||
      myProfile?.role === 'staff_view' ||
      myProfile?.role === 'staff_edit'
    ) {
      setIsPayment(true);
    } else if (
      myProfile?.end_datetime >= moment(new Date()).format('YYYY-MM-DD') &&
      myProfile?.role === 'fan'
    ) {
      setIsPayment(true);
    }
  }, [profile]);

  useEffect(() => {
    dispatch(getBlog({ page: page, perPage: 3, type: 'staff' }));
  }, [page]);

  useEffect(() => {
    const searchPage = parseInt(window.location.search.split('=')[1]);
    if (!ncrp && !window.location.search) {
      setPage(1);
    } else if (searchPage !== page) {
      setPage(searchPage);
    }
  }, [window.location.search]);

  return (
    <div className={clsx(classes.root)}>
      <Container className={classes.container}>
        <Box className={clsx(classes.wrapTitle)} mt={3}>
          <Typography className={clsx(classes.contentTitle)}>
            STAFF BLOG
          </Typography>
        </Box>
        <Box className={clsx(classes.newContent)}>
          <Grid container spacing={3}>
            {blogs?.blogs?.map((blog) => (
              <Grid
                container
                spacing={3}
                key={blog.id}
                style={{ marginBottom: '80px' }}
              >
                <div className={classes.displaySP}>
                  <div className={classes.oneRow}>
                    <Typography className={clsx(classes.blogDate)}>
                      {moment(blog?.release_date).format('YYYY/MM/DD')}
                    </Typography>
                    <div>
                      <FormControl
                        fullWidth
                        className={classes.formControl}
                        color="secondary"
                      >
                        <NativeSelect value={lang} onChange={handleChange}>
                          <option value={1}>日本語</option>
                          <option value={2}>English</option>
                          <option value={3}>한국어</option>
                          <option value={4}>中文</option>
                        </NativeSelect>
                      </FormControl>
                    </div>
                  </div>
                  <Typography
                    className={clsx(classes.fontTitle)}
                    style={{ margin: '5px 0' }}
                    dangerouslySetInnerHTML={{
                      __html:
                        lang === '1'
                          ? blog?.title
                          : lang === '2'
                          ? blog?.title_en
                          : lang === '3'
                          ? blog.title_kr
                          : blog?.title_cn
                    }}
                  ></Typography>
                  <Box display="flex" justifyContent="flex-end" gridGap={25}>
                    <Box display="flex" alignItems="center" gridGap={5}>
                      <FontAwesomeIcon
                        className={clsx(classes.iconLike)}
                        icon={blog?.is_favorite ? faHeart : heart}
                        size="lg"
                        onClick={() => {
                          if (getLoginToken()) {
                            dispatch(postFavoriteBlog({ id: blog.id }));
                          } else {
                            setOpenAuthDialog(true);
                          }
                        }}
                        style={{ color: setting?.theme_color1 }}
                      />
                      <Typography
                        className={clsx(classes.likeText)}
                        style={{ color: setting?.theme_color1 }}
                      >
                        {blog?.favorites_count}
                      </Typography>
                    </Box>
                    <Box display="flex" alignItems="center" gridGap={5}>
                      <FontAwesomeIcon
                        className={clsx(classes.iconLike)}
                        icon={faMessage}
                        size="lg"
                        onClick={() => {
                          if (getLoginToken()) {
                            if (isPayment === true) {
                              navigate(ROUTES.STAFF_BLOG + '/' + blog?.id, {
                                state: {
                                  ...blog,
                                  goComment: true
                                }
                              });
                            } else {
                              setOpenDialog(true);
                            }
                          } else {
                            setOpenAuthDialog(true);
                          }
                        }}
                        style={{ color: setting?.theme_color1 }}
                      />
                      <Typography
                        className={clsx(classes.likeText)}
                        style={{ color: setting?.theme_color1 }}
                      >
                        {blog?.comments_count}
                      </Typography>
                    </Box>
                  </Box>
                </div>

                <Grid item md={4} style={{ width: '100%' }}>
                  <Box className={clsx(classes.boxContent)}>
                    <Box
                      className={clsx(classes.imageContainer)}
                      component="img"
                      src={blog?.thumbnail}
                    />
                  </Box>
                </Grid>

                <Grid item md={8} xs={12}>
                  <Grid container spacing={3}>
                    <Grid className={classes.displayPc} item md={9} xs={8}>
                      <Box textAlign="center">
                        <Typography className={clsx(classes.blogDate)}>
                          {moment(blog?.release_date).format('YYYY/MM/DD')}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid className={classes.displayPc} item md={3} xs={4}>
                      <Box textAlign="center">
                        <FormControl
                          fullWidth
                          className={classes.formControl}
                          color="secondary"
                        >
                          <NativeSelect value={lang} onChange={handleChange}>
                            <option value={1}>日本語</option>
                            <option value={2}>English</option>
                            <option value={3}>한국어</option>
                            <option value={4}>中文</option>
                          </NativeSelect>
                        </FormControl>
                      </Box>
                    </Grid>

                    <Grid className={classes.displayPc} item xs={8}>
                      <Box textAlign="center">
                        <Typography
                          className={clsx(classes.fontTitle)}
                          dangerouslySetInnerHTML={{
                            __html:
                              lang === '1'
                                ? blog?.title
                                : lang === '2'
                                ? blog?.title_en
                                : lang === '3'
                                ? blog.title_kr
                                : blog?.title_cn
                          }}
                        ></Typography>
                      </Box>
                    </Grid>
                    <Grid className={classes.displayPc} item xs={4}>
                      <Box display="flex" justifyContent="center" gridGap={25}>
                        <Box display="flex" alignItems="center" gridGap={5}>
                          <FontAwesomeIcon
                            className={clsx(classes.iconLike)}
                            icon={blog?.is_favorite ? faHeart : heart}
                            size="lg"
                            onClick={() => {
                              if (getLoginToken()) {
                                dispatch(postFavoriteBlog({ id: blog.id }));
                              } else {
                                setOpenAuthDialog(true);
                              }
                            }}
                            style={{ color: setting?.theme_color1 }}
                          />
                          <Typography
                            className={clsx(classes.likeText)}
                            style={{ color: setting?.theme_color1 }}
                          >
                            {blog?.favorites_count}
                          </Typography>
                        </Box>
                        <Box display="flex" alignItems="center" gridGap={5}>
                          <FontAwesomeIcon
                            className={clsx(classes.iconLike)}
                            icon={faMessage}
                            size="lg"
                            onClick={() => {
                              if (getLoginToken()) {
                                if (isPayment === true) {
                                  navigate(ROUTES.STAFF_BLOG + '/' + blog?.id, {
                                    state: {
                                      ...blog,
                                      page,
                                      goComment: true
                                    }
                                  });
                                } else {
                                  setOpenDialog(true);
                                }
                              } else {
                                setOpenAuthDialog(true);
                              }
                            }}
                            style={{ color: setting?.theme_color1 }}
                          />
                          <Typography
                            className={clsx(classes.likeText)}
                            style={{ color: setting?.theme_color1 }}
                          >
                            {blog?.comments_count}
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>

                    <Grid item xs={12}>
                      <Box>
                        <div className={classes.wrapHtmlCode}>
                          <div
                            className={clsx(classes.htmlCode, 'ck-content')}
                            dangerouslySetInnerHTML={{
                              __html:
                                lang === '1'
                                  ? blog?.body
                                  : lang === '2'
                                  ? blog?.body_en
                                  : lang === '3'
                                  ? blog.body_kr
                                  : blog?.body_cn
                            }}
                          />
                        </div>
                      </Box>
                    </Grid>

                    <Grid item xs={12} md={12}>
                      <Box style={{ width: '100%', textAlign: 'end' }}>
                        <Button
                          variant="text"
                          color="inherit"
                          className={classes.buttonViewMore}
                          style={{ backgroundColor: setting?.theme_color2 }}
                          onClick={() => {
                            if (getLoginToken()) {
                              if (isPayment === true) {
                                navigate(ROUTES.STAFF_BLOG + '/' + blog?.id, {
                                  state: {
                                    ...blog,
                                    page
                                  }
                                });
                              } else {
                                setOpenDialog(true);
                              }
                            } else {
                              setOpenAuthDialog(true);
                            }
                          }}
                        >
                          READ MORE
                        </Button>
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            ))}
          </Grid>
        </Box>

        {blogs?.totalPage > 1 && (
          <Box className={clsx(classes.wrapPagination)}>
            <PaginationButton
              page={page}
              totalItem={blogs?.totalPage * 3}
              itemPerPage={3}
              paginate={setPage}
            />
          </Box>
        )}
      </Container>

      {blogs?.loading && <LoaderLayer loading={blogs?.loading} />}
      {blogs?.favoriteLoading && (
        <LoaderLayer loading={blogs?.favoriteLoading} />
      )}
      {openDialog && (
        <CheckPaymentDialog
          open={openDialog}
          onClose={setOpenDialog}
          pathName={ROUTES.STAFF_BLOG}
        />
      )}

      {openAuthDialog && (
        <AuthDialog
          open={openAuthDialog}
          onClose={setOpenAuthDialog}
          path={ROUTES.STAFF_BLOG}
        />
      )}
    </div>
  );
}

export default StaffBlogView;
