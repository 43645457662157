import clsx from 'clsx';
import moment from 'moment';
import React, { useState, useEffect } from 'react';
import { Box, Container, makeStyles, Typography } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { getYoutube } from 'src/services/youtubeService';
import { youtubeSelector } from 'src/slices/youtubes';
import PaginationButton from 'src/components/PaginationButton';
import LoaderLayer from 'src/components/LoaderLayer';
import { useNavigate } from 'react-router';
import { ROUTES } from 'src/constants';
import { useSearchParams } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  root: {
    justifyContent: 'center',
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down(700)]: {
      padding: '0 2px 0'
    }
  },
  container: {
    maxWidth: 1040,
    padding: theme.spacing(0, 8),
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(0, 2)
    }
  },
  newContent: {
    margin: '1.5rem 0',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    [theme.breakpoints.down(947)]: {
      margin: '1rem 0 1rem',
      justifyContent: 'center'
    }
  },
  wrapPagination: {
    margin: '40px 0'
  },
  boxContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '4rem',
    [theme.breakpoints.down(947)]: {
      marginBottom: '3rem'
    },
    [theme.breakpoints.down(450)]: {
      width: '100%'
    }
  },
  boxContent: {
    width: 420,
    maxWidth: '100%',
    aspectRatio: '4/3',
    backgroundColor: '#B1B1B1',
    cursor: 'pointer'
  },
  imageContainer: {
    width: 'inherit',
    height: 'inherit',
    objectFit: 'cover'
  },
  iframe: {
    maxWidth: '100%',
    height: '100% !important'
  },
  videoTitle: {
    maxWidth: 420,
    marginTop: '0.8rem',
    '& p': {
      fontSize: 16
    }
  },
  videoText: {
    [theme.breakpoints.down(700)]: {
      fontSize: '0.8rem'
    }
  }
}));

function YoutubeView() {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const youtubes = useSelector(youtubeSelector);
  const [searchParams, setSearchParams] = useSearchParams();
  const ncrp = parseInt(searchParams.get('page'));
  const [page, setPage] = useState(ncrp ? ncrp : 1);

  useEffect(() => {
    dispatch(getYoutube({ page: page, perPage: 4 }));
  }, [page]);

  useEffect(() => {
    const searchPage = parseInt(window.location.search.split('=')[1]);
    if (!ncrp && !window.location.search) {
      setPage(1);
    } else if (searchPage !== page) {
      setPage(searchPage);
    }
  }, [window.location.search]);

  return (
    <div className={clsx(classes.root)}>
      <Container className={classes.container}>
        <Box className={clsx(classes.newContent)}>
          {youtubes?.youtubes?.map((youtube) => (
            <Box key={youtube?.id} className={clsx(classes.boxContainer)}>
              <Box
                onClick={() => navigate(ROUTES.YOUTUBE + '/' + youtube?.id)}
                className={clsx(classes.boxContent)}
              >
                <LazyLoadImage
                  width="100%"
                  height="100%"
                  component="img"
                  loading="lazy"
                  alt={youtube?.title}
                  src={`${youtube?.thumbnail}?v=${moment().toDate().getTime()}`}
                />
              </Box>
              <Box className={clsx(classes.videoTitle)}>
                <Typography>
                  {moment(youtube?.release_date).format('YYYY／MM／DD')}
                </Typography>
                <Typography>{youtube?.title}</Typography>
              </Box>
            </Box>
          ))}
        </Box>
        {youtubes?.totalPage > 1 && (
          <Box className={clsx(classes.wrapPagination)}>
            <PaginationButton
              page={page}
              totalItem={youtubes?.totalPage * 4}
              itemPerPage={4}
              paginate={setPage}
            />
          </Box>
        )}
      </Container>

      <LoaderLayer loading={youtubes?.loading} />
    </div>
  );
}

export default YoutubeView;
