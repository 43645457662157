import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'src/utils/API';

export const getPhotoById = createAsyncThunk(
  'photos/id',
  async ({ id }, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `/photos/${id}`
      );

      if (response.status === 200) {
        return response.data.data;
      }
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getPhoto = createAsyncThunk(
  'photos',
  async ({ page, perPage, data }, { rejectWithValue }) => {
    try {
      const response = await axios.get(`/photos?page=${page}&perPage=${perPage}`);

      if (response.status === 200) {
        return response.data.data;
      }

      return rejectWithValue(response.data);
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
