import clsx from 'clsx';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Container, makeStyles, Typography } from '@material-ui/core';

import MenuBar from 'src/components/MenuBar';
import TagNotice from '../TagNotice';
import LoaderLayer from 'src/components/LoaderLayer';
import { noticeSelector } from 'src/slices/notices';
import { getTagsWithType } from 'src/services/tagsService';
import { tagsNoticeSelector } from 'src/slices/tags';
import { getNoticeDetail } from 'src/services/noticeService';
import { ROUTES } from 'src/constants';

const useStyles = makeStyles((theme) => ({
  root: {
    justifyContent: 'center',
    display: 'flex',
    flexDirection: 'column',
    paddingTop: 50,
    [theme.breakpoints.down(700)]: {
      padding: '50px 6px 0'
    }
  },
  container: {
    maxWidth: 1040,
    padding: theme.spacing(0, 8),
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(0, 2)
    }
  },
  header: {
    display: 'flex',
    flexDirection: 'column',
    borderBottom: '1px solid black',
    [theme.breakpoints.down(700)]: {
      '& p': {
        marginRight: 22
      }
    }
  },
  headerTag: {
    display: 'flex',
    alignItems: 'center'
  },
  title: {
    margin: '12px 0 22px',
    fontSize: 18,
    fontWeight: 'bold',
    '& p': {
      lineHeight: 2
    },
    [theme.breakpoints.down(700)]: {
      margin: '8px 0 12px',
      '& p': {
        lineHeight: 1.3
      }
    }
  },
  titleDate: {
    marginRight: 32,
    marginLeft: 32,
    fontSize: 16,
    fontFamily: 'futura-pt',
    fontWeight: 600
  },
  content: {
    margin: '50px 0'
  },
  htmlCode: {
    '& *': {
      maxWidth: '100%',
      padding: 'revert',
      fontSize: 16
    }
  },
  backButton: {
    width: '100%',
    textAlign: 'center',
    fontSize: 13,
    margin: '30px 0',
    cursor: 'pointer',
    padding: 10
  }
}));

function NoticeDetail({ setting }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { id } = useParams();
  const { state } = useLocation();
  const navigate = useNavigate();
  const notice = useSelector(noticeSelector);
  const tagsNotice = useSelector(tagsNoticeSelector);
  const [currentTag, setCurrentTag] = useState(state);

  useEffect(() => {
    dispatch(getTagsWithType({ type: 'notices' }));
    if (currentTag) {
      localStorage.setItem('currentTag', JSON.stringify(currentTag));
    }
  }, []);

  useEffect(() => {
    dispatch(getNoticeDetail({ id }));
  }, []);

  useMemo(() => {
    navigate(ROUTES.NOTICE, {
      state: {
        ...currentTag
      }
    });
  }, [currentTag]);

  return (
    <div className={clsx(classes.root)}>
      {!notice.loading && (
        <Container className={classes.container}>
          {tagsNotice && (
            <Box
              sx={{ justifyContent: 'center' }}
              mb={5}
              display="flex"
              alignItems="center"
            >
              <MenuBar
                listMenu={tagsNotice}
                typeMenu="notices"
                all={true}
                currentTag={currentTag}
                setCurrentTag={setCurrentTag}
                borderBottom={'2px solid' + setting?.theme_color1}
              />
            </Box>
          )}
          <Box className={clsx(classes.header)}>
            <Box className={clsx(classes.headerTag)}>
              <TagNotice tagName={notice?.noticeDetail?.tag?.title} />
              <Typography className={clsx(classes.titleDate)}>
                {moment(notice?.noticeDetail?.release_date).format(
                  'YYYY/MM/DD'
                )}
              </Typography>
            </Box>
            <br />
            <Typography
              className={clsx(classes.title)}
              dangerouslySetInnerHTML={{
                __html: notice?.noticeDetail?.title
              }}
            ></Typography>
          </Box>
          <Box className={clsx(classes.content, 'ck-content')}>
            <Typography>
              <span
                className={clsx(classes.htmlCode)}
                dangerouslySetInnerHTML={{ __html: notice?.noticeDetail?.body }}
              />
            </Typography>
          </Box>
          <div
            className={classes.backButton}
            onClick={() => {
              navigate(-1);
            }}
          >
            一覧へ戻る
          </div>
        </Container>
      )}

      <LoaderLayer loading={notice?.loading} />
    </div>
  );
}

export default NoticeDetail;
