import { combineReducers } from '@reduxjs/toolkit';
import { reducer as formReducer } from 'redux-form';
import { reducer as authReducer } from 'src/slices/auth';
import { reducer as noticeReducer } from 'src/slices/notices';
import { reducer as tagReducer } from 'src/slices/tags';
import { reducer as discographyReducer } from 'src/slices/discography';
import { reducer as photosReducer } from 'src/slices/photos';
import { reducer as youtubesReducer } from 'src/slices/youtubes';
import { reducer as settingsReducer } from 'src/slices/settings';
import { reducer as profilesReducer } from 'src/slices/profiles';
import { reducer as videosReducer } from 'src/slices/videos';
import { reducer as magazineReducer } from 'src/slices/magazine';
import { reducer as downloadReducer } from 'src/slices/download';
import { reducer as blogReducer } from 'src/slices/blogs';
import { reducer as loveLetterReducer } from 'src/slices/loveLetter';
import { reducer as scheduleReducer } from 'src/slices/schedules';
import { reducer as productReducer } from 'src/slices/product';
import { reducer as topLayoutReducer } from 'src/slices/topLayout';
import { reducer as communityReducer } from 'src/slices/community';
import { reducer as inquiryReducer } from 'src/slices/inquiry';

const rootReducer = combineReducers({
  form: formReducer,
  settings: settingsReducer,
  auth: authReducer,
  notice: noticeReducer,
  discography: discographyReducer,
  tags: tagReducer,
  photos: photosReducer,
  youtubes: youtubesReducer,
  profiles: profilesReducer,
  videos: videosReducer,
  magazine: magazineReducer,
  download: downloadReducer,
  blog: blogReducer,
  loveLetter: loveLetterReducer,
  schedule: scheduleReducer,
  product: productReducer,
  layout: topLayoutReducer,
  community: communityReducer,
  inquiry: inquiryReducer
});

export default rootReducer;
