import clsx from 'clsx';
import moment from 'moment';
import _ from 'lodash';
import React, { useEffect, useState, useRef, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  Box,
  Container,
  makeStyles,
  Typography,
  Grid,
  FormControl,
  NativeSelect,
  Button,
  TextareaAutosize
} from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart } from '@fortawesome/free-solid-svg-icons';
import {
  faHeart as heart,
  faMessage
} from '@fortawesome/free-regular-svg-icons';
import PaginationButton from 'src/components/PaginationButton';
import noSetAvatar from 'src/assets/images/noSetAvatar.svg';
import { ROUTES } from 'src/constants';
import { blogSelector } from 'src/slices/blogs';
import {
  getBlogById,
  postFavoriteBlog,
  deleteCommentBlog
} from 'src/services/blogsService';
import LoaderLayer from 'src/components/LoaderLayer';
import { authSelector } from 'src/slices/auth';
import CheckPaymentDialog from 'src/components/CheckPaymentDialog';
import { getDomainTalent, getLoginToken } from 'src/utils/auth';

const useStyles = makeStyles((theme) => ({
  root: {
    justifyContent: 'center',
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down(600)]: {
      padding: '20px 16px 0'
    }
  },
  container: {
    maxWidth: 1040,
    padding: theme.spacing(0, 8),
    [theme.breakpoints.down('xs')]: {
      padding: '0 10px'
    }
  },
  wrapTitle: {
    display: 'flex',
    justifyContent: 'center',
    paddingBottom: '0.5rem',
    borderBottom: '1px solid #707070'
  },
  contentTitle: {
    color: '#000000',
    fontSize: '1.5rem',
    fontWeight: '600',
    [theme.breakpoints.down(600)]: {
      fontSize: '1rem',
      padding: '0 1.5rem'
    }
  },
  newContent: {
    margin: '4rem 0 3rem',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    [theme.breakpoints.between(600, 1023)]: {
      justifyContent: 'space-evenly'
    },
    [theme.breakpoints.down(600)]: {
      margin: '1rem 0 2rem',
      justifyContent: 'center'
    }
  },
  wrapBoxComment: {
    padding: 24,
    backgroundColor: '#fff',
    width: '100%',
    margin: '40px 0px',
    '& > div': {
      paddingTop: 0,
      [theme.breakpoints.down(768)]: {
        paddingBottom: 16
      }
    },
    [theme.breakpoints.down(768)]: {
      padding: 16
    }
  },
  avatar: {
    width: 60,
    height: 60,
    borderRadius: '50%',
    border: '1px solid #000'
  },
  avatarCover: {
    objectFit: 'cover'
  },
  wrapPagination: {
    margin: '40px 0'
  },
  formControl: {
    '& > div': {
      color: '#000000',
      '&:before': {
        borderBottomColor: '#000000'
      },
      '& > svg': {
        color: '#000000'
      },
      '&:after': {
        borderBottomColor: '#000000 !important'
      }
    }
  },
  blogDate: {
    width: '161px',
    fontWeight: '600',
    textAlign: 'left',
    [theme.breakpoints.down(700)]: {
      fontSize: 15
    }
  },
  titleDetail: {
    fontWeight: '600',
    textAlign: 'left',
    [theme.breakpoints.down(700)]: {
      width: '100%'
    }
  },
  blogDateComment: {
    marginTop: '7px'
  },
  blogNickname: {
    fontWeight: 'bold',
    fontSize: 15
  },
  blogBody: {
    whiteSpace: 'pre-line',
    textAlign: 'start',
    [theme.breakpoints.down(700)]: {
      marginTop: 10
    }
  },
  displayComment: {
    padding: '16px 0px',
    borderBottom: '1px solid #ddd',
    '&:last-child': {
      borderBottom: 'none'
    }
  },
  button: {
    backgroundColor: 'black',
    padding: '12px 130px',
    borderRadius: 0,
    color: 'white !important',
    '&:hover': {
      backgroundColor: '#24292d'
    },
    [theme.breakpoints.down(700)]: {
      padding: '10px 0',
      width: '100%',
      marginTop: 20
    }
  },
  iconLike: {
    cursor: 'pointer'
  },
  htmlCode: {
    '& *': {
      maxWidth: '100%',
      padding: 'revert'
    }
  },
  marginTop: {
    margin: '5rem 0 0'
  },
  wrapButtonComment: {
    margin: '16px 0 0',
    [theme.breakpoints.down(700)]: {
      margin: '0 0 10px'
    }
  },
  likeText: {
    fontSize: 16,
    whiteSpace: 'nowrap'
  },
  backButton: {
    width: '100%',
    textAlign: 'center',
    fontSize: 13,
    marginTop: 30,
    cursor: 'pointer',
    padding: 10
  },
  textArea: {
    [theme.breakpoints.down(486)]: {
      height: '150px !important'
    },
    [theme.breakpoints.down(375)]: {
      height: '120px !important'
    }
  },
  wrapButtonDelete: {
    textAlign: 'end',
    margin: '2rem 0 0'
  },
  buttonDelete: {
    color: 'red',
    borderColor: 'red',
    padding: '1px 34px'
  }
}));

function BlogDetail({ setting }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { state } = useLocation();
  const blog = useSelector(blogSelector);
  const { id } = useParams();
  const [content, setContent] = useState(state?.content ? state?.content : '');
  const { profile } = useSelector(authSelector);
  const [page, setPage] = useState(1);
  const inputRef = useRef();
  const commentRef = useRef();
  const [currentProfile, setCurrentProfile] = useState({});
  const [openDialog, setOpenDialog] = useState(false);
  const [lang, setLang] = React.useState('1');
  const handleChange = (e) => {
    setLang(e.target.value);
  };

  const focusComment = () => {
    commentRef?.current?.scrollIntoView({
      behavior: 'smooth'
    });
  };

  useEffect(() => {
    if (state?.content) {
      setTimeout(function () {
        inputRef?.current?.scrollIntoView({
          behavior: 'smooth'
        });
      }, 100);
    }

    if (state?.commented || state?.goComment) {
      setTimeout(function () {
        commentRef?.current?.scrollIntoView({
          behavior: 'smooth'
        });
      }, 1200);
    }
  }, [state]);

  const handleComment = (value) => {
    setContent(value);
  };

  useEffect(() => {
    if (!state?.content) {
      dispatch(
        getBlogById({ page: page ? page : 1, perPage: 4, id: parseInt(id) })
      );
    }
  }, [page]);

  useEffect(() => {
    const searchPage = parseInt(window.location.search.split('=')[1]);
    if (!window.location.search) {
      setPage(1);
    } else if (searchPage !== page) {
      setPage(searchPage);
    }
  }, [window.location.search]);

  useEffect(() => {
    const { members } = profile;
    const domainTalent = getDomainTalent();

    const myProfile = _.find(
      members,
      (member) => member.talent.domain.split('//')[1] === domainTalent
    );

    setCurrentProfile(myProfile);

    if (
      myProfile?.member_type === 'lifetime' ||
      myProfile?.member_type === 'existing_fan'
    ) {
      return;
    } else if (
      myProfile?.role === 'talent' ||
      myProfile?.role === 'staff_view' ||
      myProfile?.role === 'staff_edit'
    ) {
      return;
    } else if (
      myProfile?.end_datetime >= moment(new Date()).format('YYYY-MM-DD') &&
      myProfile?.role === 'fan'
    ) {
      return;
    } else {
      setOpenDialog(true);
    }
  }, []);

  return (
    <div className={clsx(classes.root)}>
      {getLoginToken() && !openDialog && !blog?.loading && (
        <Container className={classes.container}>
          <Box className={clsx(classes.newContent)}>
            <Grid container spacing={3} style={{ gridGap: 24 }}>
              <Grid container>
                <Grid item md={12}>
                  <Grid container spacing={3}>
                    <Grid item md={10} xs={8}>
                      <Box textAlign="center">
                        <Typography className={clsx(classes.blogDate)}>
                          {moment(blog?.tagsBlog.release_date).format(
                            'YYYY/MM/DD'
                          )}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item md={2} xs={4}>
                      <Box textAlign="center">
                        <FormControl
                          fullWidth
                          className={classes.formControl}
                          color="secondary"
                        >
                          <NativeSelect
                            defaultValue={1}
                            onChange={handleChange}
                          >
                            <option value={1}>日本語</option>
                            <option value={2}>English</option>
                            <option value={3}>한국어</option>
                            <option value={4}>中文</option>
                          </NativeSelect>
                        </FormControl>
                      </Box>
                    </Grid>

                    <Grid item md={10} xs={12} style={{ paddingTop: 0 }}>
                      <Box textAlign="center" mt={2}>
                        <Typography
                          className={clsx(classes.titleDetail)}
                          dangerouslySetInnerHTML={{
                            __html:
                              lang === '1'
                                ? blog?.tagsBlog?.title
                                : lang === '2'
                                ? blog?.tagsBlog?.title_en
                                : lang === '3'
                                ? blog?.tagsBlog.title_kr
                                : blog?.tagsBlog.title_cn
                          }}
                        ></Typography>
                      </Box>
                    </Grid>
                    <Grid item md={2} xs={12} style={{ paddingTop: 0 }}>
                      <Box
                        display="flex"
                        justifyContent="flex-end"
                        gridGap={25}
                        mt={2}
                      >
                        <Box display="flex" alignItems="center" gridGap={5}>
                          <FontAwesomeIcon
                            className={clsx(classes.iconLike)}
                            icon={blog?.tagsBlog?.is_favorite ? faHeart : heart}
                            size="xl"
                            onClick={() =>
                              dispatch(postFavoriteBlog({ id: id }))
                            }
                            style={{ color: setting?.theme_color1 }}
                          />
                          <Typography
                            className={clsx(classes.likeText)}
                            style={{ color: setting?.theme_color1 }}
                          >
                            {blog?.tagsBlog?.favorites_count}
                          </Typography>
                        </Box>
                        <Box display="flex" alignItems="center" gridGap={5}>
                          <FontAwesomeIcon
                            className={clsx(classes.iconLike)}
                            icon={faMessage}
                            size="xl"
                            onClick={focusComment}
                            style={{ color: setting?.theme_color1 }}
                          />
                          <Typography
                            className={clsx(classes.likeText)}
                            style={{ color: setting?.theme_color1 }}
                          >
                            {blog?.tagsBlog?.comments_count}
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>

                    <Grid item xs={12}>
                      <Box mt={2}>
                        <span
                          className={clsx(classes.htmlCode, 'ck-content')}
                          dangerouslySetInnerHTML={{
                            __html:
                              lang === '1'
                                ? blog?.tagsBlog?.body
                                : lang === '2'
                                ? blog?.tagsBlog?.body_en
                                : lang === '3'
                                ? blog?.tagsBlog?.body_kr
                                : blog?.tagsBlog?.body_cn
                          }}
                        />
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>

                <div
                  className={classes.backButton}
                  onClick={() => {
                    navigate(ROUTES.STAFF_BLOG + '?page=' + state?.page);
                  }}
                  ref={inputRef}
                >
                  一覧へ戻る
                </div>

                {currentProfile?.status !== 'frozen' && (
                  <Box className={classes.wrapBoxComment}>
                    <Box display="flex" py={3} gridGap="16px">
                      <Box
                        className={clsx(
                          classes.avatar,
                          currentProfile?.avatar && classes.avatarCover
                        )}
                        component="img"
                        src={
                          currentProfile?.avatar
                            ? currentProfile?.avatar
                            : noSetAvatar
                        }
                      />
                      <Box flexDirection="column" alignSelf="center">
                        <Typography className={clsx(classes.blogNickname)}>
                          {currentProfile?.nickname}
                        </Typography>
                        <Typography className={clsx(classes.blogDate)}>
                          {moment().format('YYYY/MM/DD')}
                        </Typography>
                      </Box>
                    </Box>
                    <TextareaAutosize
                      className={clsx(classes.textArea)}
                      value={content}
                      maxLength={400}
                      onChange={(event) => handleComment(event.target.value)}
                      type="text"
                      required
                      style={{
                        maxWidth: '100%',
                        width: '100%',
                        padding: '0.5rem',
                        fontSize: '1rem'
                      }}
                      minRows={8}
                    />

                    <Grid container className={clsx(classes.wrapButtonComment)}>
                      <Grid item xs={12} sm={4}>
                        <Box mt={1}>
                          <Typography className={clsx(classes.blogDate)}>
                            {`のこり ${400 - content.length} 文字`}
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item xs={12} sm={8} style={{ textAlign: 'end' }}>
                        <Box
                          className={clsx(classes.wrapButton)}
                          ref={commentRef}
                        >
                          <Button
                            onClick={() => {
                              navigate(ROUTES.STAFF_BLOG_CONFIRM, {
                                state: {
                                  content,
                                  id,
                                  page: state?.page
                                }
                              });
                            }}
                            disabled={content.length === 0 ? true : false}
                            className={clsx(classes.button)}
                            style={{ backgroundColor: setting?.theme_color2 }}
                          >
                            投稿を確認する
                          </Button>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                )}

                {blog?.tagsBlog?.comments?.data?.map((blogComment) => (
                  <Grid
                    className={clsx(
                      classes.displayComment,
                      currentProfile?.status === 'frozen'
                        ? classes.marginTop
                        : ''
                    )}
                    container
                    key={blogComment?.id}
                  >
                    <Box
                      className={clsx(
                        classes.avatar,
                        classes.avatarComment,
                        currentProfile?.avatar && classes.avatarCover
                      )}
                      component="img"
                      src={
                        blogComment?.member?.avatar
                          ? blogComment?.member?.avatar
                          : noSetAvatar
                      }
                    />
                    <Box flexDirection="column" ml={3}>
                      <Typography className={clsx(classes.blogDate)}>
                        {blogComment?.member?.nickname}
                      </Typography>
                      <Typography
                        className={clsx(
                          classes.blogDate,
                          classes.blogDateComment
                        )}
                      >
                        {moment(blogComment?.updated_at).format('YYYY/MM/DD')}
                      </Typography>
                    </Box>
                    <Grid item md={8} xs={12}>
                      <Typography className={clsx(classes.blogBody)}>
                        {blogComment?.content}
                      </Typography>
                      {(currentProfile?.role === 'talent' ||
                        currentProfile?.role === 'staff_edit') && (
                        <Box className={classes.wrapButtonDelete}>
                          <Button
                            onClick={() => {
                              dispatch(
                                deleteCommentBlog({
                                  comment_id: blogComment?.id
                                })
                              );
                            }}
                            className={classes.buttonDelete}
                            variant="outlined"
                          >
                            削除
                          </Button>
                        </Box>
                      )}
                    </Grid>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Box>
          {blog?.tagsBlog?.comments?.last_page > 1 && (
            <Box className={clsx(classes.wrapPagination)}>
              <PaginationButton
                page={page ? page : blog?.tagsBlog?.comments?.current_page}
                totalItem={blog?.tagsBlog?.comments?.last_page * 4}
                itemPerPage={4}
                paginate={setPage}
                pageDetail={true}
              />
            </Box>
          )}
        </Container>
      )}
      {blog && <LoaderLayer loading={blog?.loading} />}
      {blog && <LoaderLayer loading={blog?.favoriteLoading} />}
      {openDialog && (
        <CheckPaymentDialog
          open={openDialog}
          onClose={setOpenDialog}
          pathName={ROUTES.STAFF_BLOG}
        />
      )}
    </div>
  );
}

export default BlogDetail;
