import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import {
  Box,
  Button,
  TextField,
  makeStyles,
  Typography
} from '@material-ui/core';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { authSelector } from 'src/slices/auth';
import { loginUser } from 'src/services/authService';
import { ROUTES } from 'src/constants';
import LoaderLayer from 'src/components/LoaderLayer';
import { settingSelector } from 'src/slices/settings';

// eslint-disable-next-line arrow-parens
const useStyles = makeStyles((theme) => ({
  root: {
    '& input': {
      '-webkit-user-select': 'text'
    }
  },
  loginLabel: {
    width: '50%',
    fontWeight: 'bold',
    lineHeight: 2,
    fontSize: 18,
    [theme.breakpoints.down(700)]: {
      fontSize: '0.8rem'
    }
  },
  loginButton: {
    padding: '9px 140px',
    color: '#fff !important',
    borderRadius: 0,
    fontSize: 18,
    [theme.breakpoints.down(700)]: {
      padding: '8px 0',
      width: '80%',
      fontSize: 16
    }
  }
}));

function LoginForm({ className, onSubmitSuccess, ...rest }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { isError } = useSelector(authSelector);
  const settings = useSelector(settingSelector);

  const [hover, setHover] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [searchParam] = useSearchParams();

  return (
    <>
      <Formik
        initialValues={{
          email: '',
          password: ''
        }}
        validationSchema={Yup.object().shape({
          email: Yup.string().required('メールアドレス必須'),
          password: Yup.string().required('パスワード必須')
        })}
        onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
          try {
            setIsLoading(true);
            const { payload } = await dispatch(loginUser(values));

            if (payload?.msg === 'Success') {
              setIsLoading(false);
              if (searchParam.get('current')) {
                navigate('/' + searchParam.get('current'));
              } else {
                navigate(ROUTES.HOMEVIEW);
              }
              setStatus({ success: true });
              setSubmitting(false);
            } else {
              setIsLoading(false);
              alert(payload?.msg);
            }
            if (isError) {
              setIsLoading(false);
              setStatus({ success: false });
              setSubmitting(false);
            }
          } catch (err) {
            setIsLoading(false);
            setErrors(err.response?.data);
            setStatus({ success: false });
            setSubmitting(false);
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values
        }) => (
          <form
            noValidate
            className={clsx(classes.root, className)}
            onSubmit={handleSubmit}
            {...rest}
          >
            <Box display="flex" mb={window.innerWidth <= 700 ? 3 : 5}>
              <Typography className={clsx(classes.loginLabel)}>
                メールアドレス
              </Typography>
              <TextField
                name="email"
                error={Boolean(touched.email && errors.email)}
                helperText={touched.email && errors.email}
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.email}
                type="email"
                variant="standard"
                fullWidth
                InputProps={{
                  disableUnderline: true,
                  style: {
                    color: 'black',
                    height: 38,
                    backgroundColor: '#FFFFFF',
                    paddingLeft: 8
                  }
                }}
              />
            </Box>
            <Box display="flex">
              <Typography className={clsx(classes.loginLabel)}>
                パスワード
              </Typography>
              <TextField
                name="password"
                error={Boolean(touched.password && errors.password)}
                helperText={touched.password && errors.password}
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.password}
                type="password"
                variant="standard"
                fullWidth
                InputProps={{
                  disableUnderline: true,
                  style: {
                    color: 'black',
                    height: 38,
                    backgroundColor: '#FFFFFF',
                    paddingLeft: 8,
                    fontSize: window.innerWidth <= 767 ? '0.6rem' : '1rem',
                    letterSpacing: window.innerWidth <= 767 ? 1 : -6
                  }
                }}
              />
            </Box>

            <Box
              mt={window.innerWidth <= 700 ? 6 : 10}
              display="flex"
              sx={{ justifyContent: 'center' }}
            >
              <Button
                type="submit"
                disabled={isSubmitting}
                className={clsx(classes.loginButton)}
                onMouseOver={() => setHover(true)}
                onMouseOut={() => setHover(false)}
                style={{
                  backgroundColor: hover
                    ? settings?.settings?.theme_color1 + 'cc'
                    : settings?.settings?.theme_color1
                }}
              >
                ログインする
              </Button>
            </Box>
          </form>
        )}
      </Formik>
      <LoaderLayer loading={isLoading} />
    </>
  );
}

LoginForm.propTypes = {
  className: PropTypes.string,
  onSubmitSuccess: PropTypes.func
};

export default LoginForm;
