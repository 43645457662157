/* eslint-disable */
import {
  useDispatch as useReduxDispatch,
  useSelector as useReduxSelector
} from 'react-redux';
import thunk from 'redux-thunk';
import { configureStore } from '@reduxjs/toolkit';
import { ENABLE_REDUX_DEV_TOOLS } from 'src/constants';
import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';
import * as Sentry from '@sentry/react';
import rootReducer from './rootReducer';

const sentryReduxEnhancer = Sentry.createReduxEnhancer({
  // Optionally pass options
});

const persistConfig = {
  key: 'root',
  storage,
  blacklist: ['property', 'setting', 'files']
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  devTools: ENABLE_REDUX_DEV_TOOLS,
  middleware: [thunk],
  sentryReduxEnhancer
});

export const useSelector = useReduxSelector;

export const useDispatch = () => useReduxDispatch();

export default store;
