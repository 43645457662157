import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'src/utils/API';

export const getProfilesByTagId = createAsyncThunk(
  'profiles/tag-id',
  async ({ page, perPage, tagId }, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `/get-content-by-tag-id/profiles/${tagId}?page=${page}&perPage=${perPage}`
      );

      if (response.status === 200) {
        return response.data.data;
      }
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getTagsProfile = createAsyncThunk(
  'profiles/tags',
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.get('/tags?type=profiles');

      if (response.status === 200) {
        return response.data.data.data;
      }

      return rejectWithValue(response.data);
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getProfileTalent = createAsyncThunk(
  'profile/talent',
  async ({ id }, { rejectWithValue }) => {
    try {
      const response = await axios.get('/profiles/' + id);

      if (response.status === 200) {
        return response?.data.data;
      }
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
