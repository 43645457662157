import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Box, Typography, makeStyles } from '@material-ui/core';
import { useNavigate } from 'react-router';
import { ROUTES } from 'src/constants';
import TagNotice from '../TagNotice';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    '@media(max-width: 600px)': {
      marginBottom: theme.spacing(4)
    }
  },
  wrapHeader: {
    display: 'flex',
    alignItems: 'center',
    '& p': {
      marginRight: theme.spacing(4),
      fontWeight: 'bold'
    },
    [theme.breakpoints.down(700)]: {
      '& p': {
        marginRight: 22
      }
    },
    '@media(max-width: 400px)': {
      alignItems: 'start'
    }
  },
  textNew: {
    color: '#D17878',
    fontFamily: 'futura-pt',
    fontSize: 16
  },
  newTitle: {
    margin: '12px 0 22px',
    cursor: 'pointer',
    textAlign: 'start',
    fontSize: 18,
    width: 'fit-content',
    marginRight: 'auto',
    '& p': {
      lineHeight: 2,
      marginRight: 'auto'
    },
    [theme.breakpoints.down(700)]: {
      '& p': {
        lineHeight: 1.3
      }
    }
  },
  addUnderLine: {
    position: 'relative',
    fontSize: 16,
    '&:hover': {
      color: '#000'
    },
    '&:after': {
      content: '""',
      position: 'absolute',
      bottom: 0,
      left: 0,
      width: '100%',
      height: '2px',
      background: '#000',
      transition: 'all .3s',
      transform: 'scale(0, 1)',
      transformOrigin: 'left top'
    },
    '&:hover': {
      '&:after': {
        transform: 'scale(1, 1)'
      }
    }
  }
}));

function NoticeNews({ id, tagName, date, title, currentTag, indexItem }) {
  const classes = useStyles();
  const navigate = useNavigate();

  return (
    <div className={clsx(classes.root)}>
      <Box className={clsx(classes.wrapHeader)}>
        <TagNotice tagName={tagName} indexItem={indexItem} />
        <Typography style={{ fontFamily: 'futura-pt', fontSize: 16 }}>
          {moment(date).format('YYYY/MM/DD')}
        </Typography>
        <Typography className={clsx(classes.textNew)}>
          {moment().diff(moment(date.slice(0, 10)), 'days') > 3 ? '' : 'NEW'}
        </Typography>
      </Box>
      <Box className={clsx(classes.newTitle)}>
        <Typography
          className={clsx(classes.addUnderLine)}
          onClick={() =>
            navigate(ROUTES.NOTICE + '/' + id, {
              state: {
                ...currentTag
              }
            })
          }
          dangerouslySetInnerHTML={{
            __html: title
          }}
        ></Typography>
      </Box>
    </div>
  );
}

NoticeNews.propTypes = {
  id: PropTypes.number.isRequired,
  tagName: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  currentTag: PropTypes.object.isRequired
};

export default NoticeNews;
