/* eslint-disable */
// First we need to import axios.js
import axios from 'axios';
// First we need to import axios.js
import store from 'src/store';
import { apiCatch } from './apiCatch';
import { getLoginToken } from './auth';
import { logout } from 'src/slices/auth';
import { LOGIN_TOKEN, TOKEN_EXPIRED, TOKEN_NOT_PROVIDED } from 'src/constants';

const domain = window.location.host;
// Next we make an 'instance' of it
const instance = axios.create({
  // .. where we make our configurations
  baseURL:
    domain === process.env.REACT_APP_TALENT_JANG_KEUN_SUK_DOMAIN
      ? process.env.REACT_APP_BASE_JKS_URL
      : process.env.REACT_APP_BASE_URL
  // `timeout` specifies the number of milliseconds before the request times out
  // timeout: 60000 // default is `0` (no timeout)
});

instance.defaults.headers.common.domain = 'https://' + domain;

switch (domain) {
  case process.env.REACT_APP_TALENT_ZO_IN_SUNG_DOMAIN:
    instance.defaults.headers.common.talent = 1;
    break;
  case 'www.' + process.env.REACT_APP_TALENT_ZO_IN_SUNG_DOMAIN:
    instance.defaults.headers.common.talent = 1;
    break;
  case process.env.REACT_APP_TALENT_KIM_NAN_GIL_DOMAIN:
    instance.defaults.headers.common.talent = 2;
    break;
  case process.env.REACT_APP_TALENT_LEE_JONG_SUK_DOMAIN:
    instance.defaults.headers.common.talent = 3;
    break;
  case 'www.' + process.env.REACT_APP_TALENT_LEE_JONG_SUK_DOMAIN:
    instance.defaults.headers.common.talent = 3;
    break;
  case process.env.REACT_APP_TALENT_LEE_SEUNG_GI_DOMAIN:
    instance.defaults.headers.common.talent = 4;
    break;
  case process.env.REACT_APP_TALENT_SONG_JOON_KI_DOMAIN:
    instance.defaults.headers.common.talent = 5;
    break;
  case process.env.REACT_APP_TALENT_KIM_YOUNG_DEA_DOMAIN:
    instance.defaults.headers.common.talent = 6;
    break;
  case process.env.REACT_APP_TALENT_YOO_SEUNG_HO_DOMAIN:
    instance.defaults.headers.common.talent = 7;
    break;
  case process.env.REACT_APP_TALENT_KIM_JI_WON_DOMAIN:
    instance.defaults.headers.common['talent-id'] = 9;
    break;
  case process.env.REACT_APP_TALENT_PARK_MIN_YOUNG_DOMAIN:
    instance.defaults.headers.common['talent-id'] = 10;
    break;
  case process.env.REACT_APP_TALENT_LEE_MIN_KI_DOMAIN:
    instance.defaults.headers.common.talent = 8;
    break;
  case process.env.REACT_APP_TALENT_JANG_KEUN_SUK_DOMAIN:
    instance.defaults.headers.common['talent-id'] = 1;
    break;
  default:
    break;
}

// LOGIN BY APP START
const params = new URL(document.location).searchParams;
if (params.get('appacc')) {
  localStorage.setItem(LOGIN_TOKEN, params.get('appacc'));
}
// LOGIN BY APP END

// Where you would set stuff like your 'Authorization' header, etc ...
const accessToken = localStorage.getItem(LOGIN_TOKEN);
if (accessToken)
  instance.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
// Add a request interceptor
instance.interceptors.request.use(
  (config) =>
    // Do something before request is sent
    config,
  (error) =>
    // Do something with request error
    window.Promise.reject(error)
);

// Add a response interceptor
instance.interceptors.response.use(
  (response) =>
    // Do something with response data
    response,
  (error) => {
    // Do something with response error
    apiCatch(error);

    if (axios.isCancel(error)) {
      console.debug('Request canceled', error?.message);
    }
    if (error.response?.status === 400 || error.response?.status === 401) {
      if (
        error?.response?.data?.msg === TOKEN_NOT_PROVIDED ||
        error?.response?.data?.msg === TOKEN_EXPIRED
      ) {
        let token = getLoginToken();
        if (token) {
          alert('セッションがきれています。再度ログインしてください。');
          store.dispatch(logout());
          window.location = '/login';
        } else {
          store.dispatch(logout());
        }
      }
    }

    if (error.response?.status === 500) {
      console.debug('Request response', error.response);
      // window.alert(`Error 500: ${error.response.statusText}`);

      // if (process.env.NODE_ENV === 'production') {
      //   window.location.reload();
      // }
    }

    return window.Promise.reject(error);
  }
);

export default instance;
