import { createAsyncThunk } from '@reduxjs/toolkit';

import axios from 'src/utils/API';

export const getProducts = createAsyncThunk(
  'products',
  async (type, { rejectWithValue }) => {
    try {
      const response = await axios.get(`/payment/products?type=${type}`);

      if (response.status === 200) {
        return response?.data?.data;
      }
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const autoPayment = createAsyncThunk(
  'auto-payment',
  async ({ autoContinuation, talentId, domain }, { rejectWithValue }) => {
    try {
      const response = await axios.put(
        `/user/member/update-auto-continuation?auto_continuation=${autoContinuation}&talent_id=${talentId}&domain=${domain}`
      );

      if (response.status === 200) {
        return response?.data;
      }
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const payment = createAsyncThunk(
  'payment',
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post('/payment', data);

      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
