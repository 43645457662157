import clsx from 'clsx';
import moment from 'moment';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import {
  Box,
  Container,
  makeStyles,
  Button,
  Typography
} from '@material-ui/core';
import 'react-alice-carousel/lib/alice-carousel.css';
import { authSelector } from 'src/slices/auth';
import { getDomainTalent, getLoginToken } from 'src/utils/auth';
import CheckPaymentDialog from 'src/components/CheckPaymentDialog';
import { ROUTES } from 'src/constants';
import { magazineSelector } from 'src/slices/magazine';
import { getMagazineId } from 'src/services/magazine';
import { Document, Page, pdfjs } from 'react-pdf';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
import 'react-pdf/dist/esm/Page/TextLayer.css';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import LoaderLayer from 'src/components/LoaderLayer';

const useStyles = makeStyles((theme) => ({
  root: {
    justifyContent: 'center',
    display: 'flex',
    flexDirection: 'column',
    marginTop: '5rem',
    [theme.breakpoints.down(700)]: {
      padding: '0 2px 0'
    }
  },
  newContent: {
    margin: '1rem 0 3rem',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    [theme.breakpoints.down(700)]: {
      margin: '1rem 0 2rem'
    }
  },
  boxContainer: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '4rem',
    [theme.breakpoints.down(700)]: {
      marginBottom: '1.5rem',
      padding: '0 1.2rem'
    }
  },
  buttonPrev: {
    position: 'absolute',
    top: '40%',
    left: 0,
    cursor: 'pointer',
    width: '1.4rem'
  },
  buttonNext: {
    position: 'absolute',
    top: '40%',
    right: 0,
    cursor: 'pointer',
    width: '1.4rem'
  },
  canvasPage: {
    '& > canvas': {
      width: '100% !important',
      height: '100% !important'
    }
  },
  icon: {
    fontSize: '50px !important'
  },
  numberPage: {
    marginTop: theme.spacing(3),
    display: 'flex',
    listStyle: 'none'
  },
  pageNum: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 25,
    height: 25
  },
  pageNumActive: {
    border: '2px solid #006EB1',
    borderRadius: '50%'
  },
  smallText: {
    fontSize: '0.9em'
  },
  backButton:{
    width: "100%",
    textAlign: "center",
    fontSize: 13,
    margin: "30px 0",
    cursor: "pointer",
    padding: 10
  }
}));

function MagazineDetail() {
  const classes = useStyles();
  const { state } = useLocation();
  const navigate = useNavigate();
  const { profile } = useSelector(authSelector);
  const [openDialog, setOpenDialog] = useState(false);
  const magazine = useSelector(magazineSelector);
  const dispatch = useDispatch();
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  useEffect(() => {
    dispatch(getMagazineId({ id: state?.id }));
  }, []);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    setPageNumber(1);
  }

  function changePage(offset) {
    setPageNumber((prevPageNumber) => prevPageNumber + offset);
  }

  function previousPage() {
    changePage(-1);
  }

  function nextPage() {
    changePage(1);
  }

  useEffect(() => {
    const { members } = profile;
    const domainTalent = getDomainTalent();

    const myProfile = _.find(
      members,
      (member) => member.talent.domain.split('//')[1] === domainTalent
    );

    if (myProfile?.member_type === 'lifetime' || myProfile?.member_type === 'existing_fan') {
      return;
    } else if (
      myProfile?.role === 'talent' ||
      myProfile?.role === 'staff_view' ||
      myProfile?.role === 'staff_edit'
    ) {
      return;
    } else if (
      myProfile?.end_datetime >= moment(new Date()).format('YYYY-MM-DD') &&
      myProfile?.role === 'fan'
    ) {
      return;
    } else {
      setOpenDialog(true);
    }
  }, []);

  return (
    <div className={clsx(classes.root)}>
      {getLoginToken() && !openDialog && !magazine?.loading && (
        <Container maxWidth={false} style={{ maxWidth: '1040px', padding: 0 }}>
          <Box className={clsx(classes.newContent)}>
            <Box className={clsx(classes.boxContainer)}>
              <Document
                file={magazine?.tagsMagazine?.content_url}
                onLoadSuccess={onDocumentLoadSuccess}
                options={{
                  cMapUrl: `https://unpkg.com/pdfjs-dist@${pdfjs.version}/cmaps/`,
                  cMapPacked: true,
                  standardFontDataUrl: `https://unpkg.com/pdfjs-dist@${pdfjs.version}/standard_fonts`
                }}
              >
                <Page className={classes.canvasPage} pageNumber={pageNumber} />
              </Document>
              <Button
                disabled={pageNumber <= 1}
                onClick={previousPage}
                color="inherit"
                className={classes.buttonPrev}
              >
                <ArrowBackIosIcon className={classes.icon} />
              </Button>
              <Button
                disabled={pageNumber >= numPages}
                onClick={nextPage}
                color="inherit"
                className={classes.buttonNext}
              >
                <ArrowForwardIosIcon className={classes.icon} />
              </Button>
              <ul className={classes.numberPage}>
                {_.times(numPages, (i) => (
                  <li
                    key={i}
                    className={clsx(
                      classes.pageNum,
                      pageNumber === i + 1 && classes.pageNumActive
                    )}
                  >
                    <Typography className={classes.smallText}>
                      {i + 1}
                    </Typography>
                  </li>
                ))}
              </ul>
            </Box>
          </Box>
          <div
            className={classes.backButton}
            onClick={()=>{navigate(-1)}}
          >一覧へ戻る</div>
        </Container>
      )}
      <LoaderLayer loading={magazine?.loading} />

      {getLoginToken() && openDialog && (
        <CheckPaymentDialog
          open={openDialog}
          onClose={setOpenDialog}
          pathName={ROUTES.MAGAZINE}
        />
      )}
    </div>
  );
}

export default MagazineDetail;
