import React from 'react';
import clsx from 'clsx';
import _ from 'lodash';
import { Box, Container, Typography, makeStyles } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { authSelector } from 'src/slices/auth';
import LoaderLayer from 'src/components/LoaderLayer';
import { getLoginToken } from 'src/utils/auth';
import { settingSelector } from 'src/slices/settings';

const useStyles = makeStyles((theme) => ({
  root: {
    justifyContent: 'center',
    display: 'flex',
    flexDirection: 'column',
    padding: '100px 5px 0'
  },
  registerBorderBottom: {
    borderBottom: '1px solid #919090'
  },
  wrapTitle: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    [theme.breakpoints.down(700)]: {
      flexDirection: 'column'
    }
  },
  registerTitle: {
    fontWeight: 'bold',
    fontSize: 22,
    marginBottom: 5,
    [theme.breakpoints.down('700')]: {
      fontSize: 18
    }
  },
  registerTitleText: {
    fontWeight: 'bold',
    fontSize: 20,
    [theme.breakpoints.down(700)]: {
      fontSize: '1rem'
    }
  },
  wrapTitleRight: {
    display: 'flex',
    flexDirection: 'row',
    '& p': {
      fontSize: 16,
      opacity: 0.3
    },
    [theme.breakpoints.down(700)]: {
      '& p': {
        fontSize: '0.8rem',
        marginTop: 10
      }
    }
  },
  registrationTitleActive: {
    color: '#000000',
    borderBottom: '2px solid #5D7BAB',
    opacity: '1 !important',
    marginRight: 20,
    [theme.breakpoints.down(700)]: {
      marginRight: 10
    }
  },
  titleRightText: {
    marginRight: 20,
    [theme.breakpoints.down(700)]: {
      marginRight: 10
    }
  },
  displayFlex: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down(700)]: {
      flexDirection: 'column',
      alignItems: 'start',
      display: 'block'
    }
  }
}));

function PaymentFinish() {
  const classes = useStyles();
  const navigate = useNavigate();
  const auth = useSelector(authSelector);
  const setting = useSelector(settingSelector);

  return (
    <div className={classes.root}>
      {getLoginToken() && (
        <Container maxWidth="md">
          <Box mb={8} paddingBottom={window.innerWidth <= 700 ? 4 : 8}>
            <Box className={classes.wrapTitle}>
              <Typography className={classes.registerTitleText}>
                会員登録決済
              </Typography>
              <Box className={classes.wrapTitleRight}>
                <Typography className={classes.titleRightText}>
                  決済方法選択
                </Typography>
                <Typography className={clsx(classes.titleRightText)}>
                  選択内容確認
                </Typography>
                <Typography className={classes.titleRightText}>
                  決済方法確認
                </Typography>
                <Typography className={classes.registrationTitleActive}>
                  年会費決済
                </Typography>
              </Box>
            </Box>

            <Box
              sx={{ justifyContent: 'center' }}
              mb={5}
              mt={8}
              display="flex"
              alignItems="center"
            >
              <Typography className={clsx(classes.registerTitle)}>
                決済完了
              </Typography>
            </Box>

            <Box>
              <Typography>
                クレジットカードによる決済が完了いたしました
              </Typography>
              <Typography>
                ご登録いただいたメールアドレス宛に決済完了のご案内メールを送信いたしましたのでご確認ください。
                <br />
                また、登録情報が正しく反映されているか「MYPAGE」よりご確認ください。
              </Typography>
              <Typography>
                ※メールが届かない場合は、ご入力ミスや、携帯キャリアの設定によりブロックされている可能性が
              </Typography>
              <Typography style={{ marginLeft: 16 }}>
                ございます。{setting.settings.email}{' '}
                からのメールが受信できるよう、受信設定を行っていただきますようお願いいたします。
              </Typography>
              <Typography>
                ※一部のメールクライアントの場合、迷惑メールフォルダに自動で振り分けられている場合がございます。
              </Typography>
              <Typography style={{ marginLeft: 16 }}>
                迷惑メールフォルダにメールが届いていないかご確認ください。
              </Typography>
            </Box>
          </Box>
        </Container>
      )}
      <LoaderLayer loading={auth?.isFetching} />
    </div>
  );
}
export default PaymentFinish;
