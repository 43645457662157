import React from 'react';
import AliceCarousel from 'react-alice-carousel';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/styles';
import 'react-alice-carousel/lib/alice-carousel.css';
import { Dialog, IconButton } from '@material-ui/core';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

const useStyles = makeStyles((theme) => ({
  customDialog: {
    '&>div>div': {
      padding: theme.spacing(4),
      height: 'auto',
      padding: theme.spacing(4, 0),
      backgroundColor: 'transparent',
      boxShadow: 'none'
    }
  },
  menuButton: {
    marginRight: '2rem',
    textAlign: 'end',
    '@media(max-width: 767px)': {
      marginRight: '0'
    }
  },
  iconClose: {
    color: 'white',
    width: '50px !important',
    height: '50px !important',
    '@media(max-width: 767px)': {
      width: '40px !important',
      height: '40px !important'
    }
  },
  textUppercase: {
    fontWeight: 'bold'
  },
  imageSlide: {
    padding: '0 8px 0 4px',
    maxHeight: '85vh',
    width: '90%',
    cursor: 'pointer',
    objectFit: 'contain',
    '@media(max-width: 767px)': {
      width: '100%'
    }
  },
  footer: {
    margin: '0.5rem 0 1rem 0',
    textAlign: 'center',
    width: '100%',
    '& > div > div:nth-child(2n)': {
      position: 'absolute',
      top: '45%',
      left: 40,
      width: 50,
      '@media(max-width: 767px)': {
        left: 0
      },
      '& > svg': {
        color: '#ffffff',
        fontSize: 60,
        cursor: 'pointer',
        '@media(max-width: 767px)': {
          fontSize: '2rem'
        }
      }
    },
    '& > div > div:nth-child(3n)': {
      position: 'absolute',
      top: '45%',
      right: 40,
      width: 50,
      '@media(max-width: 767px)': {
        right: 5
      },
      '& > svg': {
        color: '#ffffff',
        fontSize: 60,
        cursor: 'pointer',
        '@media(max-width: 767px)': {
          fontSize: '2rem'
        }
      }
    }
  },
  disableIcon: {
    display: 'none !important'
  },
  wrapImage: {
    height: '85vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
}));

export const PopUpSlidePhoto = ({
  photos,
  open,
  ref,
  indexImage,
  setIndexImage
}) => {
  const classes = useStyles();

  const handleClose = () => {
    open.handleClosePopUp();
  };

  const items = photos?.tagsPhoto?.photo_imgs?.map((photoDetail) => (
    <div className={classes.wrapImage}>
      <img
        className={classes.imageSlide}
        key={photoDetail.id}
        src={photoDetail.s3_url}
      />
    </div>
  ));

  return (
    <div ref={ref}>
      <Dialog
        fullScreen
        classes={{ root: classes.customDialog }}
        open={open.openPopUpSlidePhoto}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        onClose={handleClose}
      >
        <div className={classes.menuButton}>
          <IconButton color="inherit" onClick={handleClose}>
            <CloseIcon className={classes.iconClose} />
          </IconButton>
        </div>

        <div className={classes.footer}>
          <AliceCarousel
            mouseTracking
            touchTracking
            items={items}
            responsive
            disableDotsControls
            activeIndex={indexImage}
            onInitialized={(e) => setIndexImage(e.item)}
            onSlideChanged={(e) => setIndexImage(e.item)}
            renderPrevButton={() => {
              return (
                <ArrowBackIosIcon
                  className={indexImage === 0 ? classes.disableIcon : ''}
                />
              );
            }}
            renderNextButton={() => {
              return (
                <ArrowForwardIosIcon
                  className={
                    indexImage === items?.length - 1 ? classes.disableIcon : ''
                  }
                />
              );
            }}
          />
        </div>
      </Dialog>
    </div>
  );
};
