import React from 'react';
import { makeStyles, CardMedia } from '@material-ui/core/';
import PropTypes from 'prop-types';

const useStyles = makeStyles(() => ({
  root: {
    position: 'relative'
  },
  image: {
    cursor: 'pointer'
  },
  buttonClickEvent: {
    fontSize: 24,
    fontWeight: 600,
    color: '#006EB1',
    padding: '6px 30px',
    position: 'absolute',
    top: '50%',
    left: '50%',
    width: 200,
    height: 78,
    '@media(max-width: 960px)': {
      left: '40%'
    }
  },
  underline1: {
    '&:before': {
      borderLeft: '2px solid #006EB1',
      position: 'absolute',
      right: 14,
      width: 20,
      height: 19,
      content: '""',
      display: 'block',
      transform: 'rotate(140deg)'
    }
  },
  underline2: {
    '&:before': {
      borderBottom: '2px solid #006EB1',
      position: 'absolute',
      right: 10,
      width: 180,
      height: 10,
      content: '""',
      display: 'block'
    }
  }
}));

export default function SliderMedia({ urlMedia, type, id, external_url }) {
  const classes = useStyles();

  const handleClickEvent = () => {
    window.open(external_url, '_blank');
  };

  return (
    <div className={classes.root} onClick={external_url && handleClickEvent}>
      <CardMedia
        key={id}
        style={{ height: 400 }}
        image={urlMedia}
        component={type}
        id={id}
        className={external_url ? classes.image : ''}
        autoPlay
      />
    </div>
  );
}

SliderMedia.propTypes = {
  urlMedia: PropTypes.string,
  type: PropTypes.string,
  id: PropTypes.string,
  clickMedia: PropTypes.func
};
