import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import Dialog from '@mui/material/Dialog';
import {
  Box,
  makeStyles,
  Grid,
  CardMedia,
  IconButton,
  Typography
} from '@material-ui/core';
import CloseIcon from '@mui/icons-material/Close';

const useStyles = makeStyles((theme) => ({
  dialogContainer: {
    padding: 0,
    overflow: 'hidden'
  },
  wrapImages: {
    margin: '2rem',
    [theme.breakpoints.down('700')]: {
      margin: '0 1rem 1rem'
    }
  },
  menuButton: {
    float: 'right'
  },
  media: {
    height: 500,
    maxWidth: '100%',
    width: 400,
    margin: 'auto',
    [theme.breakpoints.down('700')]: {
      height: 350
    }
  },
  htmlCode: {
    '& *': {
      padding: 'revert'
    }
  }
}));

export default function DialogMember({ open, onClose, profile }) {
  const classes = useStyles();

  const handleClose = () => {
    onClose(false);
  };

  return (
    <Dialog
      onClose={handleClose}
      open={open}
      aria-describedby="alert-dialog-slide-description"
      maxWidth="md"
    >
      <Box className={clsx(classes.dialogContainer)}>
        <IconButton
          className={classes.menuButton}
          color="inherit"
          onClick={handleClose}
        >
          <CloseIcon className={classes.iconClose} />
        </IconButton>
        <Box className={clsx(classes.wrapImages)}>
          <Grid container spacing={0}>
            <Grid item xs={12} md={6}>
              <Box mt={3} textAlign="center">
                <CardMedia className={classes.media} image={profile.img} />
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box textAlign="center" width="100%">
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Box mt={3}>
                      <Typography
                        className={clsx(classes.htmlCode)}
                        dangerouslySetInnerHTML={{ __html: profile.body }}
                      />
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Dialog>
  );
}

DialogMember.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  profile: PropTypes.object.isRequired
};
