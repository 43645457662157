import React from 'react';
import clsx from 'clsx';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Container,
  Typography,
  makeStyles,
  Grid
} from '@material-ui/core';
import ForgotForm from './ForgotForm';
import { settingSelector } from 'src/slices/settings';
import { useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  root: {
    justifyContent: 'center',
    display: 'flex',
    flexDirection: 'column',
    paddingTop: 100,
    [theme.breakpoints.down(700)]: {
      padding: '100px 5px 0'
    }
  },
  loginBorderBottom: {
    borderBottom: '1px solid #919090'
  },
  resetTitleText: {
    fontWeight: 'bold',
    marginBottom: 6,
    fontSize: '1.2rem'
  },
  resetDescription: {
    padding: '2px 0'
  },
  buttonHover: {
    color: '#fff !important',
    '&:hover': {
      backgroundColor: 'rgb(34 152 221);'
    }
  }
}));

function ForgotView() {
  const classes = useStyles();
  const navigate = useNavigate();
  const handleSubmitSuccess = () => {
    // navigate(ROUTES_AUTHEN.REGISTER);
  };
  const setting = useSelector(settingSelector);

  return (
    <div className={classes.root}>
      <Container maxWidth="md">
        <Box
          sx={{ justifyContent: 'center' }}
          mb={5}
          display="flex"
          alignItems="center"
          className={clsx(classes.loginBorderBottom)}
        >
          <Typography className={clsx(classes.resetTitleText)}>
            認証コード送信
          </Typography>
        </Box>

        <Box display="flex" sx={{ justifyContent: 'center' }}>
          <Grid item xs={12} sm={7}>
            <ForgotForm onSubmitSuccess={handleSubmitSuccess} />
          </Grid>
        </Box>

        <Box mt={15} mb={10}>
          <Typography className={classes.resetTitleText}>
            パスワードの再設定について
          </Typography>
          <Typography className={classes.resetDescription}>
            ファンクラブに登録されているメールアドレス宛に、認証コードをお送りします。次の画面でご入力いただき、パスワードの変更をお願いいたします。
          </Typography>
          <Typography className={classes.resetDescription}>
            *メールが受信できない場合は、キャリア設定によりブロックされているか、迷惑フォルダに自動で振り分けられている可能性が考えられます。
            {setting?.settings?.email}
            からのメールが受信できるよう設定していただいた後、『パスワードの再設定』をお試しください。
          </Typography>
        </Box>
      </Container>
    </div>
  );
}

export default ForgotView;
