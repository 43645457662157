import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'src/utils/API';

export const getScheduleById = createAsyncThunk(
  'schedules/date',
  async ({ date }, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `/schedules-by-date?date=${date}`
      );

      if (response.status === 200) {
        return response.data.data;
      }
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getSchedule = createAsyncThunk(
  'schedules',
  async ({ page, perPage, date_from, date_to }, { rejectWithValue }) => {
    try {
      const response = await axios.get(`/schedules?page=${page}&perPage=${perPage}&date_from=${date_from}&date_to=${date_to}`);

      if (response.status === 200) {
        return response.data.data;
      }

      return rejectWithValue(response.data);
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
