import clsx from 'clsx';
import OtpInput from 'react-otp-input';
import React, { useState } from 'react';
import {
  Box,
  Container,
  Typography,
  makeStyles,
  Button
} from '@material-ui/core';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { ROUTES_AUTHEN } from 'src/constants';
import { authSelector } from 'src/slices/auth';
import LoaderLayer from 'src/components/LoaderLayer';
import { settingSelector } from 'src/slices/settings';
import PaymentDialog from 'src/components/PaymentDialog';
import { verifyUser, sendCode } from 'src/services/authService';

const useStyles = makeStyles((theme) => ({
  root: {
    justifyContent: 'center',
    display: 'flex',
    flexDirection: 'column',
    paddingTop: 100
  },
  registerBorderBottom: {
    borderBottom: '1px solid #919090'
  },
  wrapTitle: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    [theme.breakpoints.down(700)]: {
      flexDirection: 'column'
    }
  },
  registerTitleText: {
    fontWeight: 'bold',
    marginBottom: 20,
    fontSize: 20,
    [theme.breakpoints.down(700)]: {
      fontSize: '1rem'
    }
  },
  wrapTitleRight: {
    display: 'flex',
    flexDirection: 'row',
    '& p': {
      fontSize: 16,
      opacity: 0.3
    },
    [theme.breakpoints.down(700)]: {
      '& p': {
        fontSize: '0.8rem'
      }
    }
  },
  titleRightText: {
    marginRight: 20,
    [theme.breakpoints.down(700)]: {
      marginRight: 10
    }
  },
  registerSubTitle: {
    fontSize: 20,
    fontWeight: 'bold',
    textAlign: 'center',
    margin: '70px 0'
  },
  registerDescription: {
    marginBottom: 5,
    fontSize: 14
  },
  buttonHover: {
    color: '#fff !important',
    '&:hover': {
      backgroundColor: 'rgb(34 152 221);'
    }
  },
  registerLabel: {
    fontWeight: 'bold',
    marginRight: 100,
    [theme.breakpoints.down('700')]: {
      fontSize: '0.8rem',
      lineHeight: 2.2
    }
  },
  email: {
    fontWeight: '600'
  },
  otpInput: {
    margin: '0 5px',
    width: '4em',
    height: '4em'
  },
  spViewEmail: {
    [theme.breakpoints.down(700)]: {
      flexDirection: 'column'
    }
  },
  registerButton: {
    color: 'white !important',
    borderRadius: 0,
    padding: '12px 140px',
    fontSize: 18,
    [theme.breakpoints.down(700)]: {
      padding: '10px 80px'
    }
  },
  otpInputContainer: {
    justifyContent: 'center',
    [theme.breakpoints.down(700)]: {
      justifyContent: 'space-around'
    }
  },
  otpInputStyle: {
    width: '3em !important',
    height: '3em',
    margin: '0 6px !important',
    fontSize: '1.2rem',
    textTransform: 'uppercase',
    [theme.breakpoints.down(700)]: {
      margin: '0 !important'
    }
  },
  textResend: {
    cursor: 'pointer'
  },
  textResendDisable: {
    cursor: 'not-allowed',
    opacity: 0.5
  },
  textColorRed: {
    color: 'red',
    fontSize: 14
  }
}));

function RegistrationView() {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const auth = useSelector(authSelector);
  const setting = useSelector(settingSelector);

  const [hover, setHover] = useState(false);
  const [otp, setOtp] = useState('');
  const [disableResend, setDisableResend] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [titleDialog, setTitleDialog] = useState('');

  const handleChange = (newValue) => {
    setOtp(newValue);
  };

  const handleResendEmail = async () => {
    if (!disableResend) {
      const { payload } = await dispatch(
        sendCode({ email: auth?.emailSendVerify })
      );
      setDisableResend(true);

      if (payload?.data?.msg === 'Success') {
        setTitleDialog('送信しました');
        setOpenDialog(true);
      } else {
        setTitleDialog(payload?.data?.msg);
        setOpenDialog(true);
      }
    }

    setTimeout(() => {
      setDisableResend(false);
    }, 15 * 60 * 1000);
  };

  const handleSubmitCodeVerify = async () => {
    const { payload } = await dispatch(
      verifyUser({ email: auth.emailSendVerify, token: otp.toUpperCase() })
    );

    if (payload?.msg === 'Success') {
      navigate(ROUTES_AUTHEN.REGISTRATION_INFO);
    } else {
      setTitleDialog(payload?.msg);
      setOpenDialog(true);
    }
  };

  return (
    <div className={classes.root}>
      <Container maxWidth="md">
        <Box mb={8} paddingBottom={8} className={classes.registerBorderBottom}>
          <Box className={classes.wrapTitle}>
            <Typography className={classes.registerTitleText}>
              新規会員登録
            </Typography>

            <Box className={classes.wrapTitleRight}>
              <Typography className={classes.titleRightText}>
                会員情報入力
              </Typography>
              <Typography className={classes.titleRightText}>
                入力内容確認
              </Typography>
              <Typography>会員登録決済</Typography>
            </Box>
          </Box>
          <Typography className={classes.registerSubTitle}>
            ご登録されたメールアドレス宛に認証コードを送信いたしました。
            <br />
            メールをご確認いただき、下の認証コード入力フォームにご入力ください。
          </Typography>

          <Typography style={{ fontSize: 14 }}>
            ※メールが届かない場合は、ご入力ミスや、携帯キャリアの設定によりブロックされている可能性がございます。
          </Typography>
          <Typography style={{ fontSize: 14 }}>
            　{setting?.settings?.email}
            からのメールが受信できるよう設定して頂いた後、再度ご登録をお願いいたします。
            <br />
            ※一部のメールクライアントの場合、迷惑メールフォルダに自動で振り分けられる場合がございます。
            <br />
            　迷惑メールフォルダを一度ご確認ください。
          </Typography>
        </Box>

        <Box
          mb={10}
          paddingBottom={8}
          paddingLeft={window.innerWidth <= 700 ? 0 : 3}
          className={classes.registerBorderBottom}
        >
          <Box
            display="flex"
            className={clsx(classes.spViewEmail)}
            sx={{ justifyContent: 'flex-start' }}
          >
            <Typography className={classes.registerLabel}>
              ご登録メールアドレス
            </Typography>
            <Typography className={classes.email}>
              {auth?.emailSendVerify}
            </Typography>
          </Box>
          <br />
          <br />
          {/* <Box mt={8}>
            <Typography className={classes.registerDescription}>
              この規約（以下「本規約」といいます）は、株式会社フラウ・インターナショナル（以下「当社」といいます）が提供するスマートフォン（以下「端末機」といいます）向けの公式アプリ（以下「本アプリ」といいます）のすべてのサービス（以下「本サービス」といいます）の利用に関する条件を、本サービスを利用するお客様（以下「お客様」といいます）と当社との間で定めるものです。
              お客様は、本規約内容に同意いただいた場合にのみ、本サービスをご利用いただけます。
            </Typography>
          </Box>
          <Box mt={2}>
            <Typography className={classes.registerDescription}>
              本アプリのダウンロード後ユーザー登録の際に、本規約に同意する旨のチェックボックスをチェックした場合は、その時点で本利用規約の内容に同意いただいたものとみなします。
              本規約に同意頂けない場合は、直ちに本アプリをお客様の端末機から削除してください。
            </Typography>
          </Box>
          <Box mt={2} mb={8}>
            <Typography className={classes.registerDescription}>
              なお、14歳以下の方は、親権者等の法定代理人の事前の同意のもとに本アプリへの登録および本サービスの利用を行っていただけますようお願いいたします。
            </Typography>
          </Box> */}

          <Box>
            <OtpInput
              containerStyle={clsx(classes.otpInputContainer)}
              value={otp}
              onChange={handleChange}
              numInputs={5}
              inputStyle={clsx(classes.otpInputStyle)}
              isInputNum
            />
          </Box>
          <Box mt={1} display="flex" justifyContent="center">
            <Typography className={classes.textColorRed}>
              ※半角英数字でご入力ください
            </Typography>
          </Box>
          <Box
            mt={4}
            onClick={handleResendEmail}
            display="flex"
            justifyContent="center"
            className={clsx(
              disableResend ? classes.textResendDisable : classes.textResend
            )}
          >
            <Typography>認証コードを再送信する</Typography>
          </Box>

          <Box mt={8} display="flex" sx={{ justifyContent: 'center' }}>
            <Button
              onClick={handleSubmitCodeVerify}
              onMouseOver={() => setHover(true)}
              onMouseOut={() => setHover(false)}
              style={{
                backgroundColor: hover
                  ? setting?.settings?.theme_color1 + 'cc'
                  : setting?.settings?.theme_color1
              }}
              disabled={otp.length === 5 ? false : true}
              className={clsx(classes.registerButton)}
            >
              認証コード確認
            </Button>
          </Box>
        </Box>
        <PaymentDialog
          open={openDialog}
          onClose={setOpenDialog}
          title={titleDialog}
        />

        <LoaderLayer loading={auth?.isFetching} />
      </Container>
    </div>
  );
}

export default RegistrationView;
