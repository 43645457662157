import clsx from 'clsx';
import * as Yup from 'yup';
import { Formik } from 'formik';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Container,
  Typography,
  makeStyles,
  Button,
  TextField
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';

import { ROUTES_AUTHEN, FORGOT_TOKEN } from 'src/constants';
import { resetPassword } from 'src/services/authService';
import { authSelector, clearInfoSendForgot } from 'src/slices/auth';
import { settingSelector } from 'src/slices/settings';
import NotiDialog from 'src/components/NotiDialog';
import LoaderLayer from 'src/components/LoaderLayer';

const useStyles = makeStyles((theme) => ({
  root: {
    justifyContent: 'center',
    display: 'flex',
    flexDirection: 'column',
    paddingTop: 100
  },
  loginBorderBottom: {
    borderBottom: '1px solid #919090'
  },
  loginTitleText: {
    fontWeight: 'bold',
    marginBottom: 6
  },
  containerForm: {
    width: '50%',
    [theme.breakpoints.down(700)]: {
      width: '100%'
    }
  },
  resetLabel: {
    width: '50%',
    fontWeight: 'bold',
    lineHeight: 2,
    [theme.breakpoints.down(700)]: {
      fontSize: '0.8rem'
    }
  },
  resetButton: {
    padding: '12px 140px',
    color: '#fff !important',
    borderRadius: 0,
    fontSize: 18,
    [theme.breakpoints.down(700)]: {
      padding: '12px 0',
      width: '80%'
    }
  }
}));

function ResetPasswordView() {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const auth = useSelector(authSelector);
  const settings = useSelector(settingSelector);

  const [hover, setHover] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);

  return (
    <div className={classes.root}>
      <Container maxWidth="md">
        <Box
          sx={{ justifyContent: 'center' }}
          mb={5}
          display="flex"
          alignItems="center"
          className={clsx(classes.loginBorderBottom)}
        >
          <Typography className={clsx(classes.loginTitleText)}>
            パスワードを再設定する
          </Typography>
        </Box>

        <Box display="flex" sx={{ justifyContent: 'center', width: '100%' }}>
          <Box className={clsx(classes.containerForm)}>
            <Formik
              initialValues={{
                password: '',
                password_confirmation: ''
              }}
              validationSchema={Yup.object().shape({
                password: Yup.string()
                  .min(6, '半角英数字6文字以上')
                  .max(45, '半角英数字45文字以内')
                  .required('パスワード必須'),
                password_confirmation: Yup.string()
                  .oneOf([Yup.ref('password')], 'パスワードが一致しません')
                  .required('必須')
              })}
              onSubmit={async (values) => {
                try {
                  const { payload } = await dispatch(
                    resetPassword({
                      email: auth?.infoSendForgot.email,
                      token: localStorage.getItem(FORGOT_TOKEN),
                      ...values
                    })
                  );

                  if (payload?.msg === 'Success') {
                    dispatch(clearInfoSendForgot());
                    setOpenDialog(true);
                  } else {
                    alert(payload?.msg);
                  }
                } catch (err) {
                  alert(err);
                }
              }}
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                touched,
                values
              }) => (
                <form noValidate onSubmit={handleSubmit}>
                  <Box display="flex" mb={window.innerWidth <= 700 ? 3 : 5}>
                    <Typography className={clsx(classes.resetLabel)}>
                      パスワード
                    </Typography>
                    <TextField
                      name="password"
                      error={Boolean(touched.password && errors.password)}
                      helperText={touched.password && errors.password}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.password}
                      type="password"
                      variant="standard"
                      fullWidth
                      InputProps={{
                        disableUnderline: true,
                        style: {
                          color: 'black',
                          height: 38,
                          backgroundColor: '#FFFFFF',
                          paddingLeft: 10,
                          fontSize:
                            window.innerWidth <= 767 ? '0.6rem' : '1rem',
                          letterSpacing: window.innerWidth <= 767 ? 1 : -6
                        }
                      }}
                    />
                  </Box>
                  <Box display="flex">
                    <Typography className={clsx(classes.resetLabel)}>
                      パスワード確認用
                    </Typography>
                    <TextField
                      name="password_confirmation"
                      error={Boolean(
                        touched.password_confirmation &&
                          errors.password_confirmation
                      )}
                      helperText={
                        touched.password_confirmation &&
                        errors.password_confirmation
                      }
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.password_confirmation}
                      type="password"
                      variant="standard"
                      fullWidth
                      InputProps={{
                        disableUnderline: true,
                        style: {
                          color: 'black',
                          height: 38,
                          backgroundColor: '#FFFFFF',
                          paddingLeft: 10,
                          fontSize:
                            window.innerWidth <= 767 ? '0.6rem' : '1rem',
                          letterSpacing: window.innerWidth <= 767 ? 1 : -6
                        }
                      }}
                    />
                  </Box>

                  <Box
                    mt={window.innerWidth <= 700 ? 6 : 8}
                    mb={10}
                    display="flex"
                    sx={{ justifyContent: 'center' }}
                  >
                    <Button
                      type="submit"
                      disabled={isSubmitting}
                      className={clsx(classes.resetButton)}
                      onMouseOver={() => setHover(true)}
                      onMouseOut={() => setHover(false)}
                      style={{
                        backgroundColor: hover
                          ? settings?.settings?.theme_color1 + 'cc'
                          : settings?.settings?.theme_color1
                      }}
                    >
                      アップデート
                    </Button>
                  </Box>
                </form>
              )}
            </Formik>
          </Box>
        </Box>
        {openDialog && (
          <NotiDialog
            open={openDialog}
            path={ROUTES_AUTHEN.LOGIN}
            message={'パスワードの変更が完了しました'}
            onClose={setOpenDialog}
            buttonName={'ログイン画面へ'}
          />
        )}
      </Container>
      <LoaderLayer loading={auth?.isFetching} />
    </div>
  );
}

export default ResetPasswordView;
