import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'src/utils/API';

export const getTagsWithType = createAsyncThunk(
  'tags',
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.get(`/tags?type=${data.type}`);

      if (response.status === 200) {
        return {
          data: response.data.data.data,
          type: data.type
        };
      }

      return rejectWithValue(response.data);
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
