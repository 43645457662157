import _ from 'lodash';
import clsx from 'clsx';
import moment from 'moment';
import { useNavigate } from 'react-router';
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Box,
  Container,
  makeStyles,
  Typography,
  Button,
  Switch,
  styled
} from '@material-ui/core';

import BannerDialog from './BannerDialog';
import { ROUTES_AUTHEN } from 'src/constants';
import { authSelector } from 'src/slices/auth';
import LoaderLayer from 'src/components/LoaderLayer';
import { getUserProfile } from 'src/services/authService';
import { autoPayment } from 'src/services/productService';
import noSetAvatar from 'src/assets/images/noSetAvatar.svg';
import BannerMember from 'src/assets/images/img_member.jpg';
import CustomIconMember from 'src/components/CustomIconMember';
import BannerBirthday from 'src/assets/images/img_birthday.jpg';
import { getDomainTalent, getLoginToken } from 'src/utils/auth';
import appStore from 'src/assets/images/app-store/app-store.png';
import AutoPaymentDialog from 'src/components/AutoPaymentDialog';
import CustomIconBirthday from 'src/components/CustomIconBirthday';
import googlePlay from 'src/assets/images/google-play-badge/google-play-badge.png';

const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: '#1890ff',
        opacity: 1,
        border: 0
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5
      }
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff'
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color:
        theme.palette.mode === 'light'
          ? theme.palette.grey[100]
          : theme.palette.grey[600]
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3
    }
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 22,
    height: 22
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500
    })
  }
}));

const useStyles = makeStyles((theme) => ({
  root: {
    justifyContent: 'center',
    display: 'flex',
    flexDirection: 'column',
    paddingTop: '5rem',
    [theme.breakpoints.down(700)]: {
      padding: '1rem 0 2rem'
    }
  },
  itemCenter: {
    display: 'flex',
    justifyContent: 'center'
  },
  displayNone: {
    [theme.breakpoints.down(700)]: {
      display: 'none'
    }
  },
  wrapTitleSP: {
    margin: '2rem 0 6rem',
    [theme.breakpoints.down(700)]: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: '2rem'
    }
  },
  pageTitle: {
    color: '#000000',
    fontSize: '1.4rem',
    fontWeight: '600',
    [theme.breakpoints.down(700)]: {
      fontSize: '1rem',
      padding: '0 1.5rem 0 0'
    }
  },
  buttonEdit: {
    backgroundColor: 'black',
    color: '#fff !important',
    borderRadius: 0,
    padding: '12px 0',
    width: '100%',
    fontSize: 18,
    '&:hover': {
      backgroundColor: '#1175ae'
    },
    [theme.breakpoints.down(700)]: {
      width: '100%'
    }
  },
  wrapLoginInfo: {
    padding: '0.8rem 0'
  },
  textEndDateTime: {
    color: '#ffffff'
  },
  loginInfoText: {
    color: '#ffffff',
    textDecoration: 'underline',
    [theme.breakpoints.down(700)]: {
      textAlign: 'center',
      padding: '0 4rem'
    }
  },
  wrapTextConfirm: {
    margin: '4rem 0 1rem'
  },
  textConfirm: {
    fontWeight: '600'
  },
  containerProfile: {
    backgroundColor: '#ffffff',
    padding: '2rem',
    [theme.breakpoints.down(700)]: {
      padding: '0.5rem'
    }
  },
  avatar: {
    width: '14rem',
    height: '14rem',

    borderRadius: '50%',
    border: '1px solid #fff',
    margin: '2rem 0',
    [theme.breakpoints.down(700)]: {
      width: '10rem',
      height: '10rem'
    }
  },
  avatarCover: {
    objectFit: 'cover'
  },
  avatarOutProfile: {
    display: 'none',
    [theme.breakpoints.down(700)]: {
      display: 'flex',
      justifyContent: 'center'
    }
  },
  containerProfileDetail: {
    display: 'flex'
  },
  profileLeft: {
    width: '20%',
    backgroundColor: '#C5C5C5',
    textAlign: 'center',
    borderRight: '1px solid #707070',
    borderBottom: '1px dashed #AFAFAF',
    '& p': {
      padding: '0.5rem 0',
      height: '100%',
      [theme.breakpoints.down(700)]: {
        fontSize: '0.9rem'
      }
    },
    [theme.breakpoints.down(700)]: {
      width: '40%'
    }
  },
  profileRight: {
    flexGrow: 1,
    width: '80%',
    borderBottom: '1px dashed #AFAFAF',
    '& p': {
      padding: '0.5rem 0 0.5rem 1rem',
      height: '100%',
      [theme.breakpoints.down(700)]: {
        padding: '0.5rem 0 0.5rem 0.8rem',
        fontSize: '0.9rem'
      }
    },
    [theme.breakpoints.down(700)]: {
      width: '60%'
    }
  },
  description: {
    margin: '6rem 0 3rem',
    [theme.breakpoints.down(700)]: {
      padding: '0.5rem 0 0.5rem 0.8rem',
      margin: '3rem 0 1rem'
    }
  },
  containerShare: {
    display: 'flex',
    backgroundColor: '#ffffff',
    margin: '5rem 0 3rem',
    padding: '2rem 1rem 2rem 2rem',
    alignItems: 'center',
    width: '100%',
    [theme.breakpoints.down(700)]: {
      flexDirection: 'column'
    }
  },
  textShare: {
    flexGrow: 1,
    '& p': {
      fontSize: '5rem',
      paddingLeft: '2rem',
      [theme.breakpoints.down(700)]: {
        fontSize: '10vw',
        paddingLeft: 0
      }
    }
  },
  containerStoreLogo: {
    width: '30%',
    [theme.breakpoints.down(700)]: {
      width: '100%',
      textAlign: 'center',
      marginTop: '2rem',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center'
    }
  },
  googlePlayLogo: {
    cursor: 'pointer',
    [theme.breakpoints.down(700)]: {
      paddingLeft: 0,
      width: '10rem'
    }
  },
  appStoreLogo: {
    paddingLeft: '1rem',
    cursor: 'pointer',
    [theme.breakpoints.down(700)]: {
      paddingLeft: 0,
      width: '9rem'
    }
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down(700)]: {
      flexDirection: 'column'
    }
  },
  buttonContainerCenter: {
    display: 'flex',
    justifyContent: 'center'
  },
  buttonContent: {
    width: '49%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    margin: 10,
    marginTop: 0,
    [theme.breakpoints.down(700)]: {
      width: '100%',
      margin: '0 auto 10px'
    }
  },
  buttonContentLeft: {
    marginRight: '2rem',
    [theme.breakpoints.down(700)]: {
      margin: '0 auto'
    }
  },
  descriptionRight: {
    marginBottom: '6rem',
    [theme.breakpoints.down(700)]: {
      marginBottom: '1rem'
    }
  },
  wrapBirthday: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '1rem',
    alignItems: 'center',
    padding: '1rem 0',
    width: 'fit-content',
    margin: 'auto',
    cursor: 'pointer'
  },
  imageBirthday: {
    width: '100%',
    height: '14rem',
    objectFit: 'scale-down',
    cursor: 'pointer',
    [theme.breakpoints.down(700)]: {
      width: '90%'
    }
  },
  iconBirthday: {
    marginRight: '1.5rem'
  },
  bannerHover: {
    backgroundColor: '#DF0000',
    cursor: 'pointer',
    transition: '0.2s ease',
    '&:hover': {
      backgroundColor: 'gray'
    }
  },
  wrapButton: {
    display: 'flex',
    justifyContent: 'center',
    margin: '5rem 0',
    fontSize: 18,
    [theme.breakpoints.down(700)]: {
      fontSize: 16,
      flexDirection: 'column',
      margin: '2rem 0'
    }
  },
  buttonDelete: {
    backgroundColor: '#000',
    borderRadius: 0,
    padding: '11px 150px',
    marginRight: '2rem',
    color: '#fff',
    fontSize: 18,
    '&:hover': {
      backgroundColor: '#000'
    },
    [theme.breakpoints.down(700)]: {
      fontSize: 16,
      padding: '12px 0',
      width: '100%',
      marginBottom: '1rem'
    }
  },
  continueSubscribe: {
    display: 'flex',
    alignItems: 'center',
    padding: '0 0 0 1rem',
    [theme.breakpoints.down(700)]: {
      padding: '0 0 0 0.8rem',
      fontSize: '0.9rem'
    }
  }
}));

function MyPageView({ setting }) {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const auth = useSelector(authSelector);
  const { profile } = useSelector(authSelector);

  const [currentProfile, setCurrentProfile] = useState({});
  const [openDialog, setOpenDialog] = useState(false);
  const [imageDialog, setImageDialog] = useState('');
  const [openDialogAutoSubscribe, setOpenDialogAutoSubscribe] = useState(false);
  const [titleDialogAutoSubscribe, setTitleDialogAutoSubscribe] = useState('');
  const [typeDialog, setTypeDialog] = useState({});
  const [isPayment, setIsPayment] = useState(false);
  const [continueSubscribe, setContinueSubscribe] = useState(null);
  const [hover, setHover] = useState(false);
  const [hoverButtonEdit, setHoverButtonEdit] = useState(false);

  useEffect(() => {
    const { members } = profile;
    const domainTalent = getDomainTalent();

    const myProfile = _.find(
      members,
      (member) => member.talent.domain.split('//')[1] === domainTalent
    );

    if (myProfile?.auto_continuation === 1) {
      setContinueSubscribe(true);
    } else {
      setContinueSubscribe(false);
    }

    setCurrentProfile(myProfile);

    if (myProfile?.member_type === 'lifetime') {
      setIsPayment(true);
      return;
    } else if (
      myProfile?.role === 'talent' ||
      myProfile?.role === 'staff_view' ||
      myProfile?.role === 'staff_edit'
    ) {
      setIsPayment(true);
      return;
    } else if (
      myProfile?.end_datetime >= moment(new Date()).format('YYYY-MM-DD') &&
      myProfile?.role === 'fan'
    ) {
      setIsPayment(true);
      return;
    }
  }, [profile]);

  const checkShowBannerContinueSubscribe = () => {
    if (currentProfile?.end_datetime === null) {
      return false;
    }

    const today = new Date();
    const endTime = new Date(currentProfile?.end_datetime);
    const currentMonth = endTime.getMonth() + 1; // Note: Months are zero-indexed
    const currentYear = endTime.getFullYear();

    const startDate = new Date(currentYear, currentMonth - 2, 1);
    const endDate = new Date(currentYear, currentMonth - 1, 21); // 20th of current month

    return today >= startDate && today < endDate;
  };

  const checkShowButtonContinueSubscribe = () => {
    if (currentProfile?.end_datetime === null) {
      return true;
    }
    // End date time add 2 month and reset the date to the first day of the month
    const startDate = moment().diff(
      moment(currentProfile?.end_datetime)
        .add(2, 'months')
        .set('date', 1)
        .format('YYYY/MM/DD'),
      'days'
    );

    if (startDate >= 0) {
      return false;
    } else {
      return true;
    }
  };

  const checkShowBannerSubscribe = () => {
    if (currentProfile?.end_datetime === null) {
      return false;
    }

    if (
      moment(currentProfile?.end_datetime).format('YYYY/MM/DD') <
      moment(new Date()).format('YYYY/MM/DD')
    ) {
      return true;
    } else {
      return false;
    }
  };

  const handleContinueSubscribe = async (event) => {
    const { payload } = await dispatch(
      autoPayment({
        autoContinuation: 0,
        talentId: currentProfile?.talent_id,
        domain: currentProfile?.talent?.domain
      })
    );

    if (payload.msg === 'Success') {
      dispatch(getUserProfile());
      setContinueSubscribe(false);
      setOpenDialogAutoSubscribe(true);
      setTitleDialogAutoSubscribe('自動更新がキャンセルされました');
    } else {
      setOpenDialogAutoSubscribe(true);
      setTitleDialogAutoSubscribe('エラーが発生しました');
    }
  };

  const checkShowBannerBirthday = () => {
    const newDate = moment(
      `${moment().year()}/` + `${moment(profile?.birthday).month() + 1}/1`
    );
    const currentDate = moment();

    newDate.add(12, 'hours');

    if (
      currentDate.isAfter(newDate) &&
      moment(profile?.birthday).month() === moment().month() &&
      isPayment
    ) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <div className={clsx(classes.root)}>
      {getLoginToken() && (
        <Container maxWidth="md">
          <Box className={clsx(classes.wrapTitleSP)}>
            <Typography className={clsx(classes.pageTitle)}>
              MYPAGE -マイページ-
            </Typography>
          </Box>

          {currentProfile?.end_datetime && (
            <Box
              className={clsx(classes.wrapLoginInfo, classes.itemCenter)}
              style={{ backgroundColor: setting?.theme_color1 }}
            >
              <Typography className={clsx(classes.textEndDateTime)}>
                {`会員期限 ${moment(currentProfile?.end_datetime).format(
                  'YYYY年MM月DD日'
                )}`}
              </Typography>
            </Box>
          )}

          {currentProfile?.delete_request === 1 && (
            <Box
              className={clsx(classes.wrapLoginInfo, classes.itemCenter)}
              mt={2}
              textAlign="center"
            >
              <Typography
                className={clsx(classes.textEndDateTime)}
                style={{
                  color: 'black'
                }}
              >
                退会処理を受付ました。
                退会手続きをされた月末までご利用可能です。
                <br />
                再度会員登録をされる場合は会員期限満了後、新規登録をお願いいたします。
              </Typography>
            </Box>
          )}

          {/* Banner account free */}
          {checkShowBannerSubscribe() && (
            <Box
              onClick={() => navigate(ROUTES_AUTHEN.PAYMENT_TYPE)}
              className={clsx(
                classes.wrapLoginInfo,
                classes.itemCenter,
                classes.bannerHover
              )}
              mt={2}
            >
              <Typography className={clsx(classes.loginInfoText)}>
                会員更新期限がきれました
              </Typography>
            </Box>
          )}

          {/* Banner continue subscribe */}
          {checkShowBannerContinueSubscribe() &&
            currentProfile?.delete_request !== 1 && (
              <Box
                onClick={() => navigate(ROUTES_AUTHEN.PAYMENT_TYPE)}
                className={clsx(
                  classes.wrapLoginInfo,
                  classes.itemCenter,
                  classes.bannerHover
                )}
                mt={2}
              >
                <Typography className={clsx(classes.loginInfoText)}>
                  会員更新期限が近づいています【更新手続きはこちら】
                </Typography>
              </Box>
            )}

          {/* Membership banner */}
          {moment().diff(
            moment(currentProfile?.first_successful_order_date)
              .add(1, 'months')
              .format('YYYY/MM/DD'),
            'days'
          ) <= 0 &&
            isPayment && (
              <Box
                className={clsx(classes.wrapBirthday, classes.itemCenter)}
                mt={2}
                onClick={() => {
                  setImageDialog(setting?.talent_settings?.img_membership);
                  setTypeDialog({
                    icon: 'membership',
                    title: '会員登録ありがとうございます！！',
                    colorIcon: setting?.theme_color1,
                    colorText: setting?.theme_color1
                  });
                  setOpenDialog(true);
                }}
              >
                <Box
                  component="img"
                  className={clsx(classes.imageBirthday)}
                  src={BannerMember}
                  alt="FRAU INTERNATIONAL"
                />
              </Box>
            )}

          {/* Birthday banner  */}
          {checkShowBannerBirthday() && (
            <Box
              className={clsx(classes.wrapBirthday, classes.itemCenter)}
              mt={2}
              onClick={() => {
                setImageDialog(setting?.talent_settings?.img_birthday);
                setTypeDialog({
                  icon: 'birthday',
                  title: 'お誕生日おめでとうございます！！',
                  colorIcon: setting?.theme_color1,
                  colorText: setting?.theme_color1
                });
                setOpenDialog(true);
              }}
            >
              <Box
                component="img"
                className={clsx(classes.imageBirthday)}
                src={BannerBirthday}
                alt="FRAU INTERNATIONAL"
              />
            </Box>
          )}

          <Box className={clsx(classes.itemCenter, classes.wrapTextConfirm)}>
            <Typography variant="h6" className={clsx(classes.textConfirm)}>
              会員情報確認
            </Typography>
          </Box>

          {/* Avatar on SP view */}
          {isPayment && (
            <Box className={clsx(classes.avatarOutProfile)}>
              {currentProfile?.role === 'fan' ? (
                <Box
                  className={clsx(
                    classes.avatar,
                    profile?.avatar && classes.avatarCover
                  )}
                  component="img"
                  src={
                    currentProfile?.avatar
                      ? currentProfile?.avatar
                      : noSetAvatar
                  }
                />
              ) : (
                <Box
                  className={clsx(
                    classes.avatar,
                    profile?.avatar && classes.avatarCover
                  )}
                  component="img"
                  src={
                    currentProfile?.avatar_url
                      ? currentProfile?.avatar_url
                      : noSetAvatar
                  }
                />
              )}
            </Box>
          )}

          <Box className={clsx(classes.containerProfile)}>
            {/* Avatar on PC view */}
            {isPayment && (
              <Box className={clsx(classes.itemCenter, classes.displayNone)}>
                {currentProfile?.role === 'fan' ? (
                  <Box
                    className={clsx(
                      classes.avatar,
                      profile?.avatar && classes.avatarCover
                    )}
                    component="img"
                    src={
                      currentProfile?.avatar
                        ? currentProfile?.avatar
                        : noSetAvatar
                    }
                  />
                ) : (
                  <Box
                    className={clsx(
                      classes.avatar,
                      profile?.avatar && classes.avatarCover
                    )}
                    component="img"
                    src={
                      currentProfile?.avatar_url
                        ? currentProfile?.avatar_url
                        : noSetAvatar
                    }
                  />
                )}
              </Box>
            )}

            <Box>
              {isPayment && (
                <>
                  {/* ID  */}
                  <Box className={clsx(classes.containerProfileDetail)}>
                    <Box className={clsx(classes.profileLeft)}>
                      <Typography>会員番号</Typography>
                    </Box>

                    <Box className={clsx(classes.profileRight)}>
                      <Typography>{currentProfile?.member_id}</Typography>
                    </Box>
                  </Box>
                </>
              )}

              {/* Name */}
              <Box className={clsx(classes.containerProfileDetail)}>
                <Box className={clsx(classes.profileLeft)}>
                  <Typography>お名前</Typography>
                </Box>

                <Box className={clsx(classes.profileRight)}>
                  <Typography>{`${profile?.family_name} ${profile?.first_name}`}</Typography>
                </Box>
              </Box>

              {/* Furigana */}
              <Box className={clsx(classes.containerProfileDetail)}>
                <Box className={clsx(classes.profileLeft)}>
                  <Typography>フリガナ</Typography>
                </Box>

                <Box className={clsx(classes.profileRight)}>
                  <Typography>{`${profile?.family_furigana} ${profile?.first_furigana}`}</Typography>
                </Box>
              </Box>

              {/* Nick name */}
              <Box className={clsx(classes.containerProfileDetail)}>
                <Box className={clsx(classes.profileLeft)}>
                  <Typography>ニックネーム</Typography>
                </Box>

                <Box className={clsx(classes.profileRight)}>
                  <Typography>{currentProfile?.nickname || ''}</Typography>
                </Box>
              </Box>

              {/* Birthday */}
              <Box className={clsx(classes.containerProfileDetail)}>
                <Box className={clsx(classes.profileLeft)}>
                  <Typography>生年月日</Typography>
                </Box>

                <Box className={clsx(classes.profileRight)}>
                  <Typography>
                    {moment(profile?.birthday).format('YYYY年MM月DD日')}
                  </Typography>
                </Box>
              </Box>

              {/* Post code */}
              <Box className={clsx(classes.containerProfileDetail)}>
                <Box className={clsx(classes.profileLeft)}>
                  <Typography>郵便番号/住所</Typography>
                </Box>

                <Box className={clsx(classes.profileRight)}>
                  <Typography>{`〒${String(profile?.post_code).slice(
                    0,
                    3
                  )}-${String(profile?.post_code).slice(3)} ${
                    profile?.address1 + profile?.address2
                  } ${
                    profile?.address3 !== null ? profile?.address3 : ''
                  }`}</Typography>
                </Box>
              </Box>

              {/* Phone */}
              <Box className={clsx(classes.containerProfileDetail)}>
                <Box className={clsx(classes.profileLeft)}>
                  <Typography>電話番号</Typography>
                </Box>

                <Box className={clsx(classes.profileRight)}>
                  <Typography>{profile?.tel}</Typography>
                </Box>
              </Box>

              {/* Email */}
              <Box className={clsx(classes.containerProfileDetail)}>
                <Box className={clsx(classes.profileLeft)}>
                  <Typography>メールアドレス</Typography>
                </Box>

                <Box className={clsx(classes.profileRight)}>
                  <Typography>{profile?.email}</Typography>
                </Box>
              </Box>

              {/* Gender */}
              <Box className={clsx(classes.containerProfileDetail)}>
                <Box className={clsx(classes.profileLeft)}>
                  <Typography>性別</Typography>
                </Box>

                <Box className={clsx(classes.profileRight)}>
                  <Typography>
                    {profile?.gender === 1
                      ? '男性'
                      : profile?.gender === 2
                      ? '女性'
                      : 'その他'}
                  </Typography>
                </Box>
              </Box>

              {/* Payment */}

              <>
                <Box className={clsx(classes.containerProfileDetail)}>
                  <Box className={clsx(classes.profileLeft)}>
                    <Typography>決済方法</Typography>
                  </Box>

                  <Box className={clsx(classes.profileRight)}>
                    <Typography>
                      {currentProfile?.payment_type == 4
                        ? '銀行ネット決済'
                        : currentProfile?.payment_type == 2
                        ? 'ATM決済'
                        : currentProfile?.payment_type == 3
                        ? 'コンビニ決済'
                        : currentProfile?.payment_type == 1
                        ? 'クレジットカード'
                        : ''}
                    </Typography>
                  </Box>
                </Box>

                <Box className={clsx(classes.containerProfileDetail)}>
                  <Box className={clsx(classes.profileLeft)}>
                    <Typography>会員期限</Typography>
                  </Box>

                  <Box className={clsx(classes.profileRight)}>
                    <Typography>
                      {currentProfile?.end_datetime
                        ? moment(currentProfile?.end_datetime).format(
                            'YYYY年MM月DD日'
                          )
                        : ''}
                    </Typography>
                  </Box>
                </Box>

                <Box className={clsx(classes.containerProfileDetail)}>
                  <Box className={clsx(classes.profileLeft)}>
                    <Typography>更新回数</Typography>
                  </Box>

                  <Box className={clsx(classes.profileRight)}>
                    <Typography>
                      {currentProfile?.number_of_payment_updates
                        ? currentProfile?.number_of_payment_updates
                        : '0'}
                    </Typography>
                  </Box>
                </Box>
              </>

              <Box className={clsx(classes.containerProfileDetail)}>
                <Box className={clsx(classes.profileLeft)}>
                  <Typography>会員ステータス</Typography>
                </Box>

                <Box className={clsx(classes.profileRight)}>
                  <Typography>{isPayment ? '正会員' : '仮会員'}</Typography>
                </Box>
              </Box>
              {/* {isPayment && currentProfile?.role === "fan" && (
                <Box className={clsx(classes.containerProfileDetail)}>
                  <Box className={clsx(classes.profileLeft)}>
                    <Typography>自動更新</Typography>
                  </Box>

                  <Box className={clsx(classes.profileRight)}>
                    <Box className={clsx(classes.continueSubscribe)}>
                      {continueSubscribe && (
                        <IOSSwitch
                          checked={continueSubscribe}
                          onChange={handleContinueSubscribe}
                        />
                      )}
                      <Typography
                        style={{
                          paddingLeft: continueSubscribe ? '0.5rem' : 0
                        }}
                      >
                        {continueSubscribe ? 'する' : 'しない'}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              )} */}
            </Box>
          </Box>

          {isPayment ? (
            <Box
              className={clsx(
                checkShowBannerContinueSubscribe()
                  ? classes.buttonContainer
                  : classes.buttonContainerCenter
              )}
            >
              <Box
                className={clsx(
                  classes.buttonContent,
                  checkShowBannerContinueSubscribe() &&
                    classes.buttonContentLeft
                )}
              >
                <Box className={clsx(classes.description)}>
                  <Typography>
                    登録内容に間違いや変更がある場合は、下記の変更ボタンよりお手続きください。
                  </Typography>
                  <Typography>
                    ※基本的に会員名義の変更（会員権利の譲渡）、生年月日の変更はできません。
                  </Typography>
                </Box>

                <Box>
                  <Button
                    onClick={() => navigate(ROUTES_AUTHEN.EDIT_PROFILE)}
                    onMouseOver={() => setHoverButtonEdit(true)}
                    onMouseOut={() => setHoverButtonEdit(false)}
                    style={{
                      backgroundColor: hoverButtonEdit
                        ? setting?.theme_color1 + 'cc'
                        : setting?.theme_color1
                    }}
                    className={clsx(classes.buttonEdit)}
                  >
                    会員情報変更する
                  </Button>
                </Box>
              </Box>

              {checkShowBannerContinueSubscribe() && (
                <Box className={clsx(classes.buttonContent)}>
                  <Box
                    className={clsx(
                      classes.description,
                      classes.descriptionRight
                    )}
                  >
                    <Typography>
                      決済情報を登録されていない場合、下記のボタンからお手続きください。
                    </Typography>
                  </Box>

                  <Box>
                    <Button
                      onClick={() => navigate(ROUTES_AUTHEN.PAYMENT_TYPE)}
                      onMouseOver={() => setHover(true)}
                      onMouseOut={() => setHover(false)}
                      style={{
                        backgroundColor: hover
                          ? setting?.theme_color1 + 'cc'
                          : setting?.theme_color1
                      }}
                      className={clsx(classes.buttonEdit)}
                    >
                      決済情報登録
                    </Button>
                  </Box>
                </Box>
              )}
            </Box>
          ) : (
            <Box className={clsx(classes.buttonContainer)}>
              <Box
                className={clsx(
                  classes.buttonContent,
                  checkShowBannerContinueSubscribe() &&
                    classes.buttonContentLeft
                )}
              >
                <Box className={clsx(classes.description)}>
                  <Typography>
                    登録内容に間違いや変更がある場合は、下記の変更ボタンよりお手続きください。
                  </Typography>
                  <Typography>
                    ※基本的に会員名義の変更（会員権利の譲渡）、生年月日の変更はできません。
                  </Typography>
                </Box>

                <Box>
                  <Button
                    onClick={() => navigate(ROUTES_AUTHEN.EDIT_PROFILE)}
                    onMouseOver={() => setHoverButtonEdit(true)}
                    onMouseOut={() => setHoverButtonEdit(false)}
                    style={{
                      backgroundColor: hoverButtonEdit
                        ? setting?.theme_color1 + 'cc'
                        : setting?.theme_color1
                    }}
                    className={clsx(classes.buttonEdit)}
                  >
                    会員情報変更する
                  </Button>
                </Box>
              </Box>

              <Box className={clsx(classes.buttonContent)}>
                <Box
                  className={clsx(
                    classes.description,
                    classes.descriptionRight
                  )}
                >
                  <Typography>
                    決済情報を登録されていない場合、下記のボタンからお手続きください。
                  </Typography>
                </Box>

                <Box>
                  <Button
                    onClick={() => navigate(ROUTES_AUTHEN.PAYMENT_TYPE)}
                    onMouseOver={() => setHover(true)}
                    onMouseOut={() => setHover(false)}
                    style={{
                      backgroundColor: hover
                        ? setting?.theme_color1 + 'cc'
                        : setting?.theme_color1
                    }}
                    className={clsx(classes.buttonEdit)}
                  >
                    決済情報登録
                  </Button>
                </Box>
              </Box>
            </Box>
          )}
          <br />
          <br />

          {/* <Box className={clsx(classes.containerShare)}>
            <Box className={clsx(classes.textShare)}>
              <Typography>アプリの紹介</Typography>
            </Box>
            <Box className={clsx(classes.containerStoreLogo)}>
              <Link
                href="https://play.google.com/store/apps/details?id=com.instagram.android&hl=vi&gl=US"
                target="blank"
              >
                <Box
                  className={clsx(classes.googlePlayLogo)}
                  component="img"
                  src={googlePlay}
                />
              </Link>
              <Link
                href="https://apps.apple.com/vn/app/messenger/id1480068668?mt=12"
                target="blank"
              >
                <Box
                  className={clsx(classes.appStoreLogo)}
                  component="img"
                  src={appStore}
                />
              </Link>
            </Box>
          </Box> */}
        </Container>
      )}
      <LoaderLayer loading={auth?.isFetching} />
      <BannerDialog
        open={openDialog}
        onClose={setOpenDialog}
        image={imageDialog}
        type={typeDialog}
      />
      <AutoPaymentDialog
        open={openDialogAutoSubscribe}
        onClose={setOpenDialogAutoSubscribe}
        title={titleDialogAutoSubscribe}
      />
    </div>
  );
}

export default MyPageView;
