import './styles.css';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import SwiperCore, { Autoplay, Pagination, Navigation } from 'swiper/core';
SwiperCore.use([Autoplay, Pagination, Navigation]);

const breakpoints = {
  0: {
    slidesPerView: 1.2
  },
  600: {
    slidesPerView: 2.2
  }
};

const BottomBanner = ({ imageList }) => {
  return (
    <>
      <Swiper
        spaceBetween={10}
        centeredSlides={true}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
          pauseOnMouseEnter: true
        }}
        loop
        className="bottomBanner"
        breakpoints={breakpoints}
      >
        {imageList.map((image, index) => {
          return (
            <SwiperSlide
              key={index}
              className={image.external_url ? 'open__newtab' : ''}
              onClick={() => {
                if (image.external_url) {
                  window.open(image.external_url, '_blank');
                }
              }}
            >
              <img
                draggable={false}
                style={{ width: '100%', height: '100%', aspectRatio: '16/9' }}
                src={image.image_url}
                className={image.external_url ? 'open__newtab' : ''}
              />
            </SwiperSlide>
          );
        })}
      </Swiper>
      <div className="swiper-custom-pagination" />
    </>
  );
};

export default BottomBanner;
