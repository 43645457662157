import clsx from 'clsx';
import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Container,
  makeStyles,
  Typography,
  Button
} from '@material-ui/core';

import LoaderLayer from 'src/components/LoaderLayer';
import { settingSelector } from 'src/slices/settings';
import './styles.css';

const useStyles = makeStyles((theme) => ({
  root: {
    justifyContent: 'center',
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down(700)]: {
      padding: '0 2px 0'
    }
  },
  container: {
    maxWidth: 1040,
    padding: theme.spacing(0, 8),
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(0, 2)
    }
  },
  contentTitle: {
    color: '#000000',
    fontSize: '1.5rem',
    fontWeight: '600',
    [theme.breakpoints.down(600)]: {
      fontSize: '1rem',
      padding: '0 1.5rem'
    }
  },
  wrapTextSuccess: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    marginTop: '1rem'
  },
  newContent: {
    margin: '3rem 0',
    padding: '3rem 0',
    display: 'flex',
    flexWrap: 'wrap',
    gap: 8,
    justifyContent: 'space-between',
    [theme.breakpoints.down(700)]: {
      margin: '1rem 0 2rem'
    }
  },
  boxTitle: {
    fontSize: '1.4rem',
    fontWeight: '700',
    display: 'flex',
    justifyContent: 'center',
    margin: '0 0 2rem',
    [theme.breakpoints.down(700)]: {
      fontSize: '1rem',
      margin: '1rem 0'
    }
  },
  wrapButton: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    margin: '2rem 0 5rem',
    [theme.breakpoints.down(700)]: {
      flexDirection: 'column-reverse'
    }
  },
  button: {
    padding: '12px 130px',
    borderRadius: 0,
    color: 'white',
    [theme.breakpoints.down(700)]: {
      padding: '10px 0',
      width: '100%',
      margin: '0 0 1rem'
    }
  },
  textLoading: {
    fontWeight: '700',
    fontSize: '6rem',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.down(700)]: {
      fontSize: '2.5rem'
    }
  }
}));

function SendLetterSuccess() {
  const classes = useStyles();
  const navigate = useNavigate();
  const settings = useSelector(settingSelector);

  const [active, setActive] = useState(true);

  useMemo(() => {
    setTimeout(() => {
      setActive(false);
    }, 2000);
  }, []);

  return (
    <div className={clsx(classes.root)}>
      <Container className={classes.container}>
        <Box>
          <Typography className={clsx(classes.contentTitle)}>
            LOVE LETTER
          </Typography>
        </Box>
        <Box className={clsx(classes.newContent)}>
          <div className="animation">
            <div className="i-mail">
              <div className="mail-anim"></div>
            </div>
            <div className="line"></div>
            <div className="i-success">
              {/* <div class="success-anim"></div> */}
            </div>
          </div>
          {!active && (
            <>
              <Box className={clsx(classes.wrapTextSuccess)}>
                <Box display="flex" flexDirection="column" alignItems="center">
                  <Typography>ありがとうございます。</Typography>
                  <Typography>{`${settings?.settings.name}へ手紙を送信しました`}</Typography>
                </Box>
              </Box>
              <Box className={clsx(classes.wrapButton)}>
                <Button
                  onClick={() => navigate('/loveletter')}
                  className={clsx(classes.button)}
                  style={{ backgroundColor: settings?.settings.theme_color2 }}
                >
                  一覧画面に戻る
                </Button>
              </Box>
            </>
          )}
        </Box>
      </Container>
      <LoaderLayer loading={settings?.loading} />
    </div>
  );
}

export default SendLetterSuccess;
