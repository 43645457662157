import * as React from 'react';
import PropTypes from 'prop-types';
import Stack from '@mui/material/Stack';
import { makeStyles } from '@material-ui/core';
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import { useSearchParams } from 'react-router-dom';
const useStyles = makeStyles(() => ({
  ul: {
    justifyContent: 'center',
    '& .MuiPaginationItem-root.Mui-selected': {
      backgroundColor: 'transparent',
      border: '1px solid #006CA5'
    },
    '& .MuiPaginationItem-root': {
      color: '#000',
      fontWeight: '700'
    }
  }
}));

export default function PaginationButton({
  totalItem,
  itemPerPage,
  paginate,
  page,
  pageDetail = false
}) {
  const classes = useStyles();
  const [searchParams, setSearchParams] = useSearchParams();

  const handleChange = (event, value) => {
    paginate(value);
    setSearchParams('?page=' + value);
    if (!pageDetail) {
      setTimeout(() => {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
      }, 0);
    }
  };

  return (
    <Stack spacing={2}>
      <Pagination
        count={Math.ceil(totalItem / itemPerPage)}
        size={window.innerWidth <= 700 ? 'medium' : 'large'}
        classes={{ ul: classes.ul }}
        showFirstButton
        showLastButton
        page={page}
        onChange={handleChange}
        renderItem={(item) => (
          <PaginationItem
            components={{
              first: KeyboardDoubleArrowLeftIcon,
              last: KeyboardDoubleArrowRightIcon
            }}
            {...item}
          />
        )}
      />
    </Stack>
  );
}

PaginationButton.propTypes = {
  totalItem: PropTypes.number,
  itemPerPage: PropTypes.number,
  paginate: PropTypes.func
};
