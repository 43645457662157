import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Box, Container, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingBottom: 20,
    [theme.breakpoints.down(700)]: {
      paddingBottom: 30
    }
  },
  container: {
    maxWidth: 1040,
    padding: 0
  },
  media: {
    width: '95%',
    [theme.breakpoints.down(700)]: {
      width: '100%',
      margin: 'auto'
    }
  },
  htmlCode: {
    '& *': {
      padding: 'revert'
    }
  },
  spMt10: {
    [theme.breakpoints.down('700')]: {
      marginTop: 10
    }
  }
}));

function Profile({ className, profiles }) {
  const classes = useStyles();

  return (
    <div
      className={clsx(classes.root, className)}
      style={{ paddingBottom: profiles?.totalPage > 1 ? 20 : 50 }}
    >
      <Container className={classes.container}>
        {profiles?.profiles?.map((profile) => (
          <Grid container key={profile.id}>
            <Grid item xs={12} md={6}>
              <Box>
                <img className={classes.media} src={profile.img} />
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box width="100%">
                <Grid container spacing={3}>
                  <Grid item xs={12} className={classes.spMt10}>
                    <span
                      className={clsx(classes.htmlCode, 'ck-content')}
                      dangerouslySetInnerHTML={{ __html: profile.body }}
                    />
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        ))}
      </Container>
    </div>
  );
}

Profile.propTypes = {
  className: PropTypes.string,
  noticeList: PropTypes.array
};

export default Profile;
