import clsx from 'clsx';
import Zoom from '@mui/material/Zoom';
import React, { useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { Box, makeStyles, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  accordionContainer: {
    margin: '1rem 0'
  },
  accordionTop: {
    backgroundColor: 'white',
    padding: '0.8rem 1rem',
    borderRadius: 17
  },
  accordionTopRight: {
    display: 'flex',
    [theme.breakpoints.up(700)]: {
      alignItems: 'center'
    }
  },
  accordionText: {
    fontSize: '1.2rem',
    padding: '0.5rem 1rem',
    color: 'white',
    borderRadius: '50%',
    marginRight: '1rem',
    height: 'fit-content',
    [theme.breakpoints.down(700)]: {
      fontSize: '1rem',
      padding: '0.4rem 0.8rem'
    }
  },
  accordionTextQ: {
    backgroundColor: 'black'
  },
  accordionTopTitle: {
    fontSize: '1.2rem',
    [theme.breakpoints.down(700)]: {
      fontSize: '1.1rem'
    }
  },
  addIcon: {
    fontSize: '1.8rem'
  },
  accordionContent: {
    display: 'flex',
    padding: '1rem'
  }
}));

function Accordion({ title, content, open, setting }) {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(open ? true : false);
  return (
    <>
      <Box className={clsx(classes.accordionContainer)}>
        <Box className={clsx(classes.accordionTop)}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Box className={clsx(classes.accordionTopRight)}>
              <Typography
                className={clsx(classes.accordionText, classes.accordionTextQ)}
              >
                Q
              </Typography>
              <Typography
                className={clsx(classes.accordionTopTitle)}
                dangerouslySetInnerHTML={{ __html: title }}
              />
            </Box>
            <Box onClick={() => setExpanded(!expanded)}>
              {expanded ? (
                <RemoveIcon style={{ fontSize: '2.2rem', cursor: 'pointer' }} />
              ) : (
                <AddIcon style={{ fontSize: '2.2rem', cursor: 'pointer' }} />
              )}
            </Box>
          </Box>
        </Box>
        {expanded && (
          <Zoom
            in={expanded}
            timeout={400}
            easing={{
              exit: 'linear'
            }}
          >
            <Box className={clsx(classes.accordionContent)}>
              <Typography
                className={clsx(classes.accordionText)}
                style={{ backgroundColor: setting?.theme_color1 }}
              >
                A
              </Typography>
              <Typography dangerouslySetInnerHTML={{ __html: content }} />
            </Box>
          </Zoom>
        )}
      </Box>
    </>
  );
}
export default Accordion;
