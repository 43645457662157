import React, { useState, useRef, useEffect, useMemo } from 'react';
import clsx from 'clsx';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import FullCalendar from '@fullcalendar/react';
import jaLocale from '@fullcalendar/core/locales/ja';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import listPlugin from '@fullcalendar/list';
import timelinePlugin from '@fullcalendar/timeline';
import JapaneseHolidays from 'japanese-holidays';
import {
  Box,
  Container,
  Typography,
  Paper,
  Modal,
  makeStyles
} from '@material-ui/core';
import '@fullcalendar/daygrid/main.css';
import '@fullcalendar/timegrid/main.css';
import '@fullcalendar/list/main.css';
import AddEditEvent from './AddEditEvent';
import Toolbar from './Toolbar';
import { getSchedule } from 'src/services/scheduleService';
import { scheduleSelector } from 'src/slices/schedules';
import LoaderLayer from 'src/components/LoaderLayer';
import iconJapan from 'src/assets/images/schedule/japan.svg';
import iconKorean from 'src/assets/images/schedule/korean.svg';
import iconAnniversaryWhite from 'src/assets/images/schedule/anniversary-icon.png';
import iconEventWhite from 'src/assets/images/schedule/event-icon.png';
import iconMediaWhite from 'src/assets/images/schedule/media-icon.png';
import iconOtherWhite from 'src/assets/images/schedule/other-icon.png';
import iconReleaseWhite from 'src/assets/images/schedule/release-icon.png';
import iconScheduleWhite from 'src/assets/images/schedule/schedule-icon.png';
import iconAnniversary from 'src/assets/images/schedule/anniversary-icon-black.png';
import iconEvent from 'src/assets/images/schedule/event-icon-black.png';
import iconMedia from 'src/assets/images/schedule/media-icon-black.png';
import iconOther from 'src/assets/images/schedule/other-icon-black.png';
import iconRelease from 'src/assets/images/schedule/release-icon-black.png';
import iconSchedule from 'src/assets/images/schedule/schedule-icon-black.png';
import { getDomainTalent, getLoginToken } from 'src/utils/auth';
import { authSelector } from 'src/slices/auth';
import CheckPaymentDialog from 'src/components/CheckPaymentDialog';
import { settingSelector } from 'src/slices/settings';

import { ROUTES } from 'src/constants';
import './styles.css';
const useStyles = makeStyles((theme) => ({
  root: {
    justifyContent: 'center',
    display: 'flex',
    flexDirection: 'column',
    padding: '0px 0px 50px'
  },
  container: {
    maxWidth: 1040,
    padding: theme.spacing(0, 8),
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(0, 2)
    }
  },
  textUppercase: {
    fontWeight: 'bold',
    fontSize: 25,
    textTransform: 'uppercase'
  },
  calendar: {
    color: '#000',
    boxShadow: 'none',
    backgroundColor: 'inherit',
    padding: 0,
    '& > div > div': {
      '&:first-child': {
        display: 'none'
      }
    },
    '& .fc-unthemed th': {
      borderColor: theme.palette.divider
    },
    '& .fc-unthemed td': {
      borderColor: theme.palette.divider
    },
    '& .fc-unthemed td.fc-today': {
      backgroundColor: theme.palette.background.dark
    },
    '& .fc-head': {
      backgroundColor: theme.palette.background.dark
    },
    '& .fc-body': {
      backgroundColor: theme.palette.background.default
    },
    '& .fc-axis': {
      ...theme.typography.body2
    },
    '& .fc-list-item-time': {
      ...theme.typography.body2
    },
    '& .fc-list-item-title': {
      ...theme.typography.body1
    },
    '& .fc-list-heading-main': {
      ...theme.typography.h6
    },
    '& .fc-list-heading-alt': {
      ...theme.typography.h6
    },
    '& .fc th': {
      borderColor: theme.palette.divider
    },
    '& .fc-day-header': {
      ...theme.typography.subtitle2,
      fontWeight: theme.typography.fontWeightMedium,
      color: theme.palette.text.secondary,
      padding: theme.spacing(1),
      backgroundColor: theme.palette.background.dark
    },
    '& .fc-day-top': {
      ...theme.typography.body2
    },
    '& .fc-day': {
      border: 'none'
    },
    '& .fc-highlight': {
      backgroundColor: '#bab7b9'
    },
    '& .fc-event': {
      backgroundColor: 'transparent',
      color: theme.palette.secondary.contrastText,
      borderWidth: 2,
      opacity: 0.7,
      '& .fc-time': {
        ...theme.typography.h6,
        color: 'inherit'
      },
      '& .fc-title': {
        ...theme.typography.body1,
        color: 'inherit'
      }
    },
    '& .fc-list-empty': {
      ...theme.typography.subtitle1
    },
    '& .fc-license-message': {
      display: 'none'
    },
    '& .fc-daygrid-day': {
      border: '1px solid #000'
    },
    '& .fc-scrollgrid-section': {
      '& > td': {
        border: '1px solid #000'
      }
    },
    '& .fc-scrollgrid-section': {
      '& > th': {
        border: 'none'
      }
    },
    '& .fc-h-event': {
      border: '0px solid #3788d8'
    },
    '& .fc-media-screen': {
      backgroundColor: 'inherit'
    },
    '& .fc-scrollgrid-liquid': {
      border: 'none'
    },
    '& .fc-daygrid-event': {
      backgroundColor: 'rgb(176, 196, 222)'
    }
  },
  imageScheduleContainer: {
    paddingTop: 4,
    paddingLeft: 4
  },
  imageSchedule1: {
    height: 34,
    [theme.breakpoints.down(768)]: {
      height: 30
    },
    [theme.breakpoints.down(486)]: {
      height: 15
    }
  },
  imageSchedule2: {
    height: 34,
    marginLeft: 14,
    [theme.breakpoints.down(768)]: {
      height: 30,
      marginLeft: 8
    },
    [theme.breakpoints.down(486)]: {
      height: 20,
      marginLeft: 4
    }
  },
  modal: {
    margin: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      margin: theme.spacing(10, 2)
    }
  }
}));

function ScheduleView() {
  const classes = useStyles();
  const calendarRef = useRef(null);
  const { settings } = useSelector(settingSelector);

  // const mobileDevice = useMediaQuery(theme.breakpoints.down('sm'));
  const [view, setView] = useState('dayGridMonth');
  const [date, setDate] = useState(moment().toDate());
  const [events, setEvents] = useState([]);
  const [page, setPage] = useState(1);
  const [openDialog, setOpenDialog] = useState(false);
  const dispatch = useDispatch();
  const [eventModal, setEventModal] = useState({
    open: false,
    event: null
  });
  const [firstDay, setFirstDay] = useState();
  const [lastDay, setLastDay] = useState();

  const schedules = useSelector(scheduleSelector);
  const { profile } = useSelector(authSelector);

  const handleEventClick = (info) => {
    setEventModal({
      open: true,
      event: info.dateStr
        ? info.dateStr
        : moment(info.event.startStr).format('YYYY-MM-DD')
    });
  };
  const handleDateClick = (info) => {
    let dateClick = moment(info.date).add(1439, 'minutes');
    let hasEvent = false;
    events.forEach((item) => {
      if (dateClick.isBetween(moment(item.start), moment(item.end))) {
        hasEvent = true;
      }
    });
    if (!hasEvent) {
      return;
    }
    setEventModal({
      open: true,
      event: info.dateStr
        ? info.dateStr
        : moment(info.event.startStr).format('YYYY-MM-DD')
    });
  };

  const handleModalClose = () => {
    setEventModal({
      open: false,
      event: null
    });
  };

  useEffect(() => {
    setEvents(
      schedules?.schedules?.map((schedule) => {
        const isSameDay = moment(schedule.date_from).isSame(
          schedule.date_to,
          'day'
        );
        const backgroundColor =
          !isSameDay && settings?.theme_color1
            ? settings?.theme_color1
            : 'transparent';
        return {
          title: schedule.description,
          start: schedule.date_from,
          end: moment(schedule.date_to).format('YYYY-MM-DD 23:59:00'),
          backgroundColor,
          national_flag: schedule.national_flag,
          type: schedule.type,
          id: schedule.id,
          isSameDay
        };
      })
    );
  }, [schedules]);

  useEffect(() => {
    const { members } = profile;
    const domainTalent = getDomainTalent();

    const myProfile = _.find(
      members,
      (member) => member.talent.domain.split('//')[1] === domainTalent
    );

    if (
      myProfile?.member_type === 'lifetime' ||
      myProfile?.member_type === 'existing_fan'
    ) {
      return;
    } else if (
      myProfile?.role === 'talent' ||
      myProfile?.role === 'staff_view' ||
      myProfile?.role === 'staff_edit'
    ) {
      return;
    } else if (
      myProfile?.end_datetime >= moment(new Date()).format('YYYY-MM-DD') &&
      myProfile?.role === 'fan'
    ) {
      return;
    } else {
      setOpenDialog(true);
    }
  }, [profile]);

  useEffect(() => {
    if (firstDay) {
      dispatch(
        getSchedule({
          page: page,
          perPage: 60,
          date_from: firstDay,
          date_to: lastDay
        })
      );
    }
  }, [firstDay, lastDay]);

  useMemo(() => {
    setPage(1);
  }, []);

  const handleDateToday = () => {
    const calendarEl = calendarRef.current;

    if (calendarEl) {
      const calendarApi = calendarEl.getApi();

      calendarApi.today();
      setDate(calendarApi.getDate());
    }
  };

  const handleDatePrev = () => {
    const calendarEl = calendarRef.current;
    if (calendarEl) {
      const calendarApi = calendarEl.getApi();

      calendarApi.prev();
      setDate(calendarApi.getDate());
    }
  };

  const handleDateNext = () => {
    const calendarEl = calendarRef.current;

    if (calendarEl) {
      const calendarApi = calendarEl.getApi();

      calendarApi.next();
      setDate(calendarApi.getDate());
    }
  };

  const renderImage = (data) => {
    return (
      <div>
        {(events || [])
          .filter(
            (item) =>
              item.type === data.event.extendedProps.type &&
              moment(item.start).format('YYYY-MM-DD') ===
                moment(data.event.startStr).format('YYYY-MM-DD')
          )
          .map((schedule, index) => (
            <div key={index} className={classes.imageScheduleContainer}>
              <img
                className={classes.imageSchedule1}
                alt="FRAU INTERNATIONAL"
                src={schedule.national_flag === 'jp' ? iconJapan : iconKorean}
              />
              <img
                alt="FRAU INTERNATIONAL"
                className={classes.imageSchedule2}
                src={
                  schedule.type === 'media'
                    ? schedule.isSameDay
                      ? iconMedia
                      : iconMediaWhite
                    : schedule.type === 'schedule'
                    ? schedule.isSameDay
                      ? iconSchedule
                      : iconScheduleWhite
                    : schedule.type === 'other'
                    ? schedule.isSameDay
                      ? iconOther
                      : iconOtherWhite
                    : schedule.type === 'anniversary'
                    ? schedule.isSameDay
                      ? iconAnniversary
                      : iconAnniversaryWhite
                    : schedule.type === 'release'
                    ? schedule.isSameDay
                      ? iconRelease
                      : iconReleaseWhite
                    : schedule.isSameDay
                    ? iconEvent
                    : iconEventWhite
                }
              />
            </div>
          ))}
      </div>
    );
  };

  return (
    <div className={clsx(classes.root)}>
      {getLoginToken() && !openDialog && (
        <Container className={classes.container}>
          <Box mb={2} display="flex">
            <Box>
              <Typography className={classes.textUppercase}>
                Schedule
              </Typography>
            </Box>
          </Box>
          <Toolbar
            date={date}
            onDateNext={handleDateNext}
            onDatePrev={handleDatePrev}
            onDateToday={handleDateToday}
            view={view}
          />
          <Paper className={classes.calendar} component={Box} mt={3} p={2}>
            <FullCalendar
              datesSet={(dateInfo) => {
                setFirstDay(moment(dateInfo.startStr).format('YYYY-MM-DD'));
                setLastDay(moment(dateInfo.endStr).format('YYYY-MM-DD'));
              }}
              dayCellClassNames={(e) => {
                // If date is holiday add class fc-hol
                if (JapaneseHolidays.isHoliday(e.date)) {
                  const el = document.querySelector(
                    'td[data-date="' +
                      moment(e.date).format('YYYY-MM-DD') +
                      '"]'
                  );
                  el?.classList.add('fc-hol');
                }
              }}
              allDayMaintainDuration
              initialDate={date}
              initialView="dayGridMonth"
              droppable
              events={events}
              eventContent={renderImage}
              dateClick={handleDateClick}
              eventClick={handleEventClick}
              height={800}
              ref={calendarRef}
              rerenderDelay={10}
              weekends
              locales={[jaLocale]}
              locale="ja"
              firstDay={1}
              plugins={[
                dayGridPlugin,
                timeGridPlugin,
                interactionPlugin,
                listPlugin,
                timelinePlugin
              ]}
              dayCellContent={(e) => {
                e.dayNumberText = e.dayNumberText.replace('日', '');
              }}
            />
          </Paper>
          <Modal
            onClose={handleModalClose}
            open={eventModal.open}
            className={classes.modal}
          >
            <AddEditEvent event={eventModal.event} open={eventModal.open} />
          </Modal>
        </Container>
      )}
      <LoaderLayer loading={schedules?.loading} />
      {getLoginToken() && openDialog && (
        <CheckPaymentDialog
          open={openDialog}
          onClose={setOpenDialog}
          pathName={ROUTES.HOMEVIEW}
        />
      )}
    </div>
  );
}

export default ScheduleView;
