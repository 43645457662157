import React, { useState } from 'react';
import OtpInput from 'react-otp-input';
import {
  Box,
  Container,
  Typography,
  makeStyles,
  Button
} from '@material-ui/core';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';

import { authSelector } from 'src/slices/auth';
import {
  sendVerifyUpdateEmail,
  verifyEmailUpdate
} from 'src/services/authService';
import { useLocation, useNavigate } from 'react-router-dom';
import LoaderLayer from 'src/components/LoaderLayer';
import { getLoginToken } from 'src/utils/auth';
import { settingSelector } from 'src/slices/settings';
import AutoPaymentDialog from 'src/components/AutoPaymentDialog';

const useStyles = makeStyles((theme) => ({
  root: {
    justifyContent: 'center',
    backgroundColor: '#EBEBEB',
    display: 'flex',
    flexDirection: 'column',
    paddingTop: 100
  },
  registerBorderBottom: {
    borderBottom: '1px solid #919090'
  },
  wrapTitle: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'start'
  },
  registerTitleText: {
    fontWeight: 'bold',
    marginBottom: 5,
    fontSize: 20,
    [theme.breakpoints.down(700)]: {
      fontSize: '1rem'
    }
  },
  registerSubTitle: {
    fontSize: 20,
    fontWeight: 'bold',
    textAlign: 'center',
    margin: '70px 0'
  },
  registerDescription: {
    marginBottom: 5,
    fontSize: 14
  },
  buttonHover: {
    '&:hover': {
      backgroundColor: 'rgb(34 152 221);'
    }
  },
  registerLabel: {
    fontWeight: 'bold',
    marginRight: 100,
    [theme.breakpoints.down('700')]: {
      fontSize: '0.9rem'
    }
  },
  email: {
    fontWeight: '600'
  },
  otpInput: {
    margin: '0 5px',
    width: '4em',
    height: '4em'
  },
  spViewEmail: {
    paddingLeft: '1rem',
    [theme.breakpoints.down(700)]: {
      flexDirection: 'column',
      paddingLeft: 0
    }
  },
  registerButton: {
    backgroundColor: 'black',
    color: 'white !important',
    borderRadius: 0,
    padding: '12px 140px',
    '&:hover': {
      backgroundColor: '#1175ae'
    },
    [theme.breakpoints.down(700)]: {
      padding: '10px 120px'
    }
  },
  otpInputContainer: {
    justifyContent: 'center',
    [theme.breakpoints.down(700)]: {
      justifyContent: 'space-around'
    }
  },
  otpInputStyle: {
    width: '3em !important',
    height: '3em',
    margin: '0 6px !important',
    fontSize: '1.2rem',
    [theme.breakpoints.down(700)]: {
      margin: '0 !important'
    }
  },
  textResend: {
    cursor: 'pointer',
    '& p': {
      fontWeight: '600'
    }
  },
  textResendDisable: {
    cursor: 'not-allowed',
    opacity: 0.5
  }
}));

function CodeConfirmChangeEmail() {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const auth = useSelector(authSelector);
  const setting = useSelector(settingSelector);
  const { state } = useLocation();

  const [otp, setOtp] = useState('');
  const [open, setOpen] = useState(false);
  const [disableResend, setDisableResend] = useState(false);

  const handleChange = (newValue) => {
    setOtp(newValue.toUpperCase());
  };

  const handleResendEmail = () => {
    if (!disableResend) {
      dispatch(sendVerifyUpdateEmail({ email: auth?.newEmailUpdate }));
      setDisableResend(true);
    }

    setTimeout(() => {
      setDisableResend(false);
    }, 15 * 60 * 1000);
  };

  const handleSubmitCodeVerify = async () => {
    const data = {
      email: auth?.newEmailUpdate,
      token: otp
    };
    const { payload } = await dispatch(verifyEmailUpdate(data));

    if (payload.msg === 'Success') {
      setOpen(true);
    } else {
      alert(payload.msg);
    }
  };

  return (
    <div className={classes.root}>
      {getLoginToken() && (
        <Container maxWidth="md">
          <Box
            mb={6}
            paddingBottom={8}
            className={classes.registerBorderBottom}
          >
            <Box className={clsx(classes.wrapTitle)}>
              <Typography className={classes.registerTitleText}>
                メールアドレス変更
              </Typography>
            </Box>
            <Typography className={classes.registerSubTitle}>
              ご登録されたメールアドレスに認証コードを送信いたしました。
              <br />
              メールをご確認いただき、下記認証コード入力フォームにご入力ください。
            </Typography>

            <Typography className={classes.registerDescription}>
              ※メールが届かない場合は、ご入力ミスや、携帯キャリアの設定によりブロックされている可能性がございます。
            </Typography>
            <Typography className={classes.registerDescription}>
              {setting?.settings?.email}
              からのメールが受信できるよう、受信設定を行っていただきますようお願いいたします。
            </Typography>
            <Typography className={classes.registerDescription}>
              ※一部のメールクライアントの場合、迷惑メールフォルダに振り分けられている場合がございます。
            </Typography>
            <Typography className={classes.registerDescription}>
              迷惑メールフォルダにメールが届いていないかご確認ください。
            </Typography>
          </Box>

          <Box mb={10} paddingBottom={8}>
            <Box
              display="flex"
              className={clsx(classes.spViewEmail)}
              sx={{ justifyContent: 'flex-start' }}
            >
              <Typography className={classes.registerLabel}>
                ご登録メールアドレス
              </Typography>
              <Typography className={classes.email}>
                {auth?.newEmailUpdate}
              </Typography>
            </Box>

            <Box>
              <OtpInput
                containerStyle={clsx(classes.otpInputContainer)}
                value={otp}
                onChange={handleChange}
                numInputs={5}
                inputStyle={clsx(classes.otpInputStyle)}
                isInputNum
              />
            </Box>

            <Box mt={1} display="flex" justifyContent="center">
              <Typography style={{ fontSize: 14, color: 'red' }}>
                ※半角英数字でご入力ください
              </Typography>
            </Box>

            <Box
              mt={3}
              pb={2}
              onClick={handleResendEmail}
              display="flex"
              justifyContent="center"
              className={clsx(
                disableResend ? classes.textResendDisable : classes.textResend,
                classes.registerBorderBottom
              )}
            >
              <Typography>認証コードを再送信する</Typography>
            </Box>

            <Box mt={8} display="flex" sx={{ justifyContent: 'center' }}>
              <Button
                onClick={handleSubmitCodeVerify}
                disabled={otp.length === 5 ? false : true}
                className={clsx(classes.registerButton)}
              >
                確認
              </Button>
            </Box>
          </Box>
        </Container>
      )}
      <LoaderLayer loading={auth?.isFetching} />

      {open && (
        <AutoPaymentDialog
          open={open}
          onClose={setOpen}
          setFocus={() => {}}
          title="メールアドレス変更しました"
          path={state.pathBack}
        />
      )}
    </div>
  );
}

export default CodeConfirmChangeEmail;
