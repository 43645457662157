import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Box, Container, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingBottom: 50
  },
  container: {
    maxWidth: 1040,
    padding: 0,
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(0, 2)
    }
  },
  gridContainer: {
    borderBottom: '1px solid #707070',
    margin: '0 -12px',
    '&:last-child': {
      borderBottom: 'none'
    }
  },
  title: {
    textAlign: 'start'
  },
  htmlCode: {
    display: '-webkit-box',
    '-webkit-box-orient': 'vertical',
    '-webkit-line-clamp': 3,
    overflow: 'hidden',
    whiteSpace: 'pre-line',
    textAlign: 'start',
    '& *': {
      padding: 'revert'
    },
    '& p': {
      margin: 0
    }
  },
  noRecord: {
    fontSize: '24px',
    fontWeight: 500,
    textAlign: 'center'
  },
  media: {
    width: '95%',
    [theme.breakpoints.down(700)]: {
      width: '100%',
      margin: 'auto'
    }
  },
  textContent: {
    padding: '10px 12px !important',
    display: 'flex',
    alignItems: 'center'
  }
}));

function Award({ className, profiles }) {
  const classes = useStyles();

  if (profiles.length === 0) {
    return (
      <Typography variant="body1" color="inherit" className={classes.noRecord}>
        該当する結果が見つかりませんでした
      </Typography>
    );
  }

  return (
    <div
      className={clsx(classes.root, className)}
      style={{ paddingBottom: profiles?.totalPage > 1 ? 20 : 50 }}
    >
      <Container className={classes.container}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={12} lg={12}>
            <Box width="100%">
              {profiles?.profiles?.map((other) => (
                <Grid
                  container
                  spacing={3}
                  key={other.id}
                  className={classes.gridContainer}
                >
                  <Grid item xs={3} md={1} className={classes.textContent}>
                    <Box>
                      <Typography
                        variant="body1"
                        color="inherit"
                        className={classes.title}
                      >
                        {moment(other?.date).format('YYYY')}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={9} md={11} className={classes.textContent}>
                    <Box>
                      <span
                        className={clsx(classes.htmlCode, 'ck-content')}
                        dangerouslySetInnerHTML={{ __html: other?.body }}
                      />
                    </Box>
                  </Grid>
                </Grid>
              ))}
            </Box>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}

Award.propTypes = {
  className: PropTypes.string,
  noticeList: PropTypes.array
};

export default Award;
