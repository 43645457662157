import React from 'react';
import clsx from 'clsx';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Container,
  Typography,
  makeStyles,
  Button
} from '@material-ui/core';

import { useSelector } from 'react-redux';
import LoaderLayer from 'src/components/LoaderLayer';
import { settingSelector } from 'src/slices/settings';
import { ROUTES_AUTHEN } from 'src/constants';
import { getLoginToken } from 'src/utils/auth';

const useStyles = makeStyles((theme) => ({
  root: {
    justifyContent: 'center',
    display: 'flex',
    flexDirection: 'column',
    paddingTop: 100
  },
  registerBorderBottom: {
    borderBottom: '1px solid #919090'
  },
  registerTitleText: {
    fontWeight: 'bold',
    marginBottom: 35,
    fontSize: 22,
    [theme.breakpoints.down('700')]: {
      fontSize: 18
    }
  },
  registerTitle: {
    fontWeight: 'bold',
    fontSize: 22,
    marginBottom: 5,
    [theme.breakpoints.down('700')]: {
      fontSize: 18
    }
  },
  registerDescription: {
    marginBottom: 40
  },
  textBorderBottom: {
    borderBottom: '1px solid #707070',
    width: 'fit-content'
  },
  registerButton: {
    backgroundColor: 'black',
    borderRadius: 0,
    color: 'white !important',
    padding: '12px 110px',
    '&:hover': {
      backgroundColor: '#1175ae'
    },
    [theme.breakpoints.down(700)]: {
      padding: '10px 80px'
    }
  }
}));

function Community() {
  const classes = useStyles();
  const navigate = useNavigate();
  const settings = useSelector(settingSelector);

  return (
    <div className={classes.root}>
      {getLoginToken() && (
        <Container maxWidth="md">
          <Box
            sx={{ justifyContent: 'center' }}
            mb={6}
            display="flex"
            alignItems="center"
            className={clsx(classes.registerBorderBottom)}
          >
            <Typography className={clsx(classes.registerTitle)}>
              掲示板使用についてお願い
            </Typography>
          </Box>

          <Box>
            <Typography
              className={classes.registerDescription}
              dangerouslySetInnerHTML={{
                __html: settings?.settings?.community_rule_1
              }}
            ></Typography>
          </Box>

          <Box
            sx={{ justifyContent: 'center' }}
            mb={6}
            display="flex"
            alignItems="center"
            className={clsx(classes.registerBorderBottom)}
          >
            <Typography className={clsx(classes.registerTitle)}>
              削除ガイドライン
            </Typography>
          </Box>

          <Box>
            <Typography
              className={classes.registerDescription}
              dangerouslySetInnerHTML={{
                __html: settings?.settings?.community_rule_2
              }}
            ></Typography>
          </Box>

          <Box
            sx={{ justifyContent: 'center' }}
            mb={6}
            display="flex"
            alignItems="center"
            className={clsx(classes.registerBorderBottom)}
          >
            <Typography className={clsx(classes.registerTitle)}>
              ※著作権侵害とは
            </Typography>
          </Box>

          <Box>
            <Typography
              className={classes.registerDescription}
              dangerouslySetInnerHTML={{
                __html: settings?.settings?.community_rule_3
              }}
            ></Typography>
          </Box>

          <Box
            sx={{ justifyContent: 'center' }}
            mb={6}
            display="flex"
            alignItems="center"
            className={clsx(classes.registerBorderBottom)}
          >
            <Typography className={clsx(classes.registerTitle)}>
              その他注意事項
            </Typography>
          </Box>

          <Box>
            <Typography
              className={classes.registerDescription}
              dangerouslySetInnerHTML={{
                __html: settings?.settings?.community_rule_4
              }}
            ></Typography>
          </Box>

          <Box mt={8} mb={10} display="flex" sx={{ justifyContent: 'center' }}>
            <Button
              onClick={() => navigate(ROUTES_AUTHEN.COMMUNITY_BOARD)}
              className={clsx(classes.registerButton)}
            >
              コミュニティ掲示板へ
            </Button>
          </Box>
        </Container>
      )}
      <LoaderLayer loading={settings?.loading} />
    </div>
  );
}

export default Community;
