import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { Box, Container, Grid, Typography, CardMedia } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ButtonRadius from 'src/components/ButtonRadius';
import { ROUTES } from 'src/constants';
import AuthDialog from 'src/components/AuthDialog';
import { getLoginToken } from 'src/utils/auth';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingBottom: 50
  },
  media: {
    height: 220,
    cursor: 'pointer',
    [theme.breakpoints.down(700)]: {
      height: 168
    }
  },
  name: {
    fontFamily: 'futura-pt',
    fontSize: 18,
    margin: theme.spacing(1, 0),
    flex: '1 1 auto',
    textAlign: 'start',
    [theme.breakpoints.down(700)]: {
      fontSize: 14
    }
  },
  wrapButton: {
    display: 'flex',
    margin: theme.spacing(2, 0),
    [theme.breakpoints.down(486)]: {
      alignItems: 'center',
      margin: '4px 0 0',
      '& p': {
        fontSize: 11
      }
    },
    '& > div > button': {
      width: 120,
      [theme.breakpoints.down(700)]: {
        padding: "5px",
        width: 100,
        fontSize: 13
      },
      [theme.breakpoints.down(486)]: {
        padding: "2px",
        width: 80,
        fontSize: 11
      }
    }
  },
  content: {
    fontSize: 18,
    margin: theme.spacing(1, 0),
    fontWeight: 500,
    textAlign: 'start',
    [theme.breakpoints.down(700)]: {
      fontSize: 16,
      margin: 0
    }
  }
}));

function PhotoList({ className, photos, setting, onOpen, isPayment }) {
  const classes = useStyles();
  const navigate = useNavigate();

  const [openDialog, setOpenDialog] = useState(false);

  return (
    <div className={clsx(classes.root, className)}>
      <Container style={{ padding: 0 }}>
        <Grid container spacing={3}>
          {photos?.photos?.map((photo) => (
            <Grid item md={4} sm={6} xs={6} key={photo.id}>
              <Box mt={3} textAlign="center">
                <div>
                  <CardMedia
                    className={classes.media}
                    image={`${photo?.thumbnail}?v=${moment().toDate().getTime()}`}
                    onClick={() => {
                      if (!getLoginToken()) {
                        setOpenDialog(true);
                      } else {
                        if (isPayment === true) {
                          navigate(ROUTES.PHOTO + '/' + photo?.id);
                        } else {
                          onOpen(true);
                        }
                      }
                    }}
                  />
                </div>
                <div className={classes.wrapButton}>
                  <Typography
                    variant="body1"
                    color="inherit"
                    className={classes.name}
                  >
                    {moment(photo?.release_date).format('YYYY/MM/DD')}
                  </Typography>
                  <ButtonRadius
                    buttonName={`${photo?.photo_imgs?.length}picture`}
                    color={setting.theme_color2}
                    className={classes.buttonRadius}
                    onClick={() => {
                      if (!getLoginToken()) {
                        setOpenDialog(true);
                      } else {
                        if (isPayment === true) {
                          navigate(ROUTES.PHOTO + '/' + photo?.id);
                        } else {
                          onOpen(true);
                        }
                      }
                    }}
                  />
                </div>
                <div>
                  <Typography
                    variant="body1"
                    color="inherit"
                    className={classes.content}
                  >
                    {photo?.title}
                  </Typography>
                </div>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Container>
      <AuthDialog
        open={openDialog}
        onClose={setOpenDialog}
        path={ROUTES.PHOTO}
      />
    </div>
  );
}

PhotoList.propTypes = {
  className: PropTypes.string,
  noticeList: PropTypes.array,
  onOpen: PropTypes.func
};

export default PhotoList;
