import axios from 'axios';
import { checkNameTalent } from './auth';

export const submitPayment = (values) => {
  const { merchant_id, token } = checkNameTalent();
  const card_number =
    values.card_number_1 +
    values.card_number_2 +
    values.card_number_3 +
    values.card_number_4;

  const data = `merchant_id=${merchant_id}&token_generate_key=${token}&card_number=${card_number}&card_expire_year=${values.card_expire_year}&card_expire_month=${values.card_expire_month}&card_cvc&card_name`;

  return axios({
    method: 'post',
    url: process.env.REACT_APP_PAYMENT_SANDBOX,
    data: data,
    headers: {
      'Content-Type': 'text/plain'
    }
  });
};
