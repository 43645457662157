import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'src/utils/API';

export const getLoveLetters = createAsyncThunk(
  'loveLetter',
  async ({ page, perPage }, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `/love-letters?page=${page}&perPage=${perPage}`
      );

      if (response.status === 200) {
        return response?.data.data;
      }
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const sendLoveLetter = createAsyncThunk(
  'sendLoveLetter',
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post('/love-letters', data);

      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
