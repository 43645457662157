import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    minHeight: 600
  },
  wrapper: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden'
  },
  contentContainer: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden'
  },
  content: {
    flex: '1 1 auto',
    height: '100%',
    overflow: 'auto',
    marginTop: theme.spacing(6)
  }
}));

function SubLayout({ children, setting }) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.wrapper}>
        <div
          className={classes.contentContainer}
          style={{ backgroundColor: setting?.settings?.base_color }}
        >
          <div className={classes.content}>{children}</div>
        </div>
      </div>
    </div>
  );
}

SubLayout.propTypes = {
  children: PropTypes.any,
  setting: PropTypes.object
};

export default SubLayout;
