import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Box,
  Container,
  Typography,
  makeStyles,
  Button,
  TextField,
  TextareaAutosize
} from '@material-ui/core';
import moment from 'moment';
import { useSelector, useDispatch } from 'react-redux';

import LoaderLayer from 'src/components/LoaderLayer';
import { settingSelector } from 'src/slices/settings';
import { ROUTES, ROUTES_AUTHEN } from 'src/constants';
import { authSelector } from 'src/slices/auth';
import { getDomainTalent, getLoginToken } from 'src/utils/auth';
import AuthDialog from 'src/components/AuthDialog';
import CheckPaymentDialog from 'src/components/CheckPaymentDialog';
import { communitySelector, setPostCommunityInfo } from 'src/slices/community';

const useStyles = makeStyles((theme) => ({
  root: {
    justifyContent: 'center',
    display: 'flex',
    flexDirection: 'column',
    paddingTop: 100,
    [theme.breakpoints.down('700')]: {
      paddingTop: 60
    }
  },
  borderBottom: {
    borderBottom: '1px solid #919090'
  },
  wrapButton: {
    display: 'flex',
    margin: '3rem 0 4rem',
    [theme.breakpoints.down('700')]: {
      margin: '2rem 0'
    }
  },
  registerButton: {
    backgroundColor: 'black',
    color: '#fff !important',
    borderRadius: 0,
    padding: '12px 140px',
    '&:hover': {
      backgroundColor: '#1175ae'
    },
    [theme.breakpoints.down(700)]: {
      padding: '10px 80px',
      width: '100%'
    }
  },
  inputContainer: {
    flexGrow: 1,
    [theme.breakpoints.down(700)]: {
      width: '100%',
      margin: '0.5rem 0 1rem'
    }
  },
  wrapInput: {
    color: 'black',
    height: 38,
    backgroundColor: '#FFFFFF',
    '& input': {
      padding: '10px 0 10px 10px',
      height: 28
    }
  },
  searchContainer: {
    display: 'flex',
    alignItems: 'center',
    paddingBottom: '2rem',
    [theme.breakpoints.down(700)]: {
      flexDirection: 'column',
      paddingBottom: '1rem'
    }
  },
  textRed: {
    color: 'red',
    fontSize: '0.8rem'
  },
  buttonInputFile: {
    backgroundColor: 'transparent',
    border: '1px solid #000',
    padding: '0.5rem 2rem',
    color: '#000',
    borderRadius: 0
  },
  imagePreview: {
    width: '18rem',
    height: '12rem',
    objectFit: 'cover',
    [theme.breakpoints.down(700)]: {
      width: '18rem',
      height: '14rem'
    }
  }
}));

function PostCommunity() {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();

  const settings = useSelector(settingSelector);
  const { profile } = useSelector(authSelector);
  const { postCommunity } = useSelector(communitySelector);
  const [currentProfile, setCurrentProfile] = useState({});

  const [title, setTitle] = useState(
    postCommunity?.title ? postCommunity?.title : ''
  );
  const [content, setContent] = useState(
    postCommunity?.body ? postCommunity?.body : ''
  );
  const [image, setImage] = useState(postCommunity?.image);
  const [imagePreview, setImagePreview] = useState(postCommunity?.imagePreview);
  const [openDialog, setOpenDialog] = useState(false);

  // useEffect(() => {
  //   return () => {
  //     image && URL.revokeObjectURL(imagePreview);
  //   };
  // }, [image]);

  useEffect(() => {
    const { members } = profile;
    const domainTalent = getDomainTalent();

    const myProfile = _.find(
      members,
      (member) => member.talent.domain.split('//')[1] === domainTalent
    );

    setCurrentProfile(myProfile);

    if (
      myProfile?.member_type === 'lifetime' ||
      myProfile?.member_type === 'existing_fan'
    ) {
      return;
    } else if (
      myProfile?.role === 'talent' ||
      myProfile?.role === 'staff_view' ||
      myProfile?.role === 'staff_edit'
    ) {
      return;
    } else if (
      myProfile?.end_datetime >= moment(new Date()).format('YYYY-MM-DD') &&
      myProfile?.role === 'fan'
    ) {
      return;
    } else {
      setOpenDialog(true);
    }
  }, []);

  const handlePreviewImage = (event) => {
    const file = event.target.files[0];

    setImagePreview(URL.createObjectURL(file));
    setImage(file);
  };

  return (
    <div className={classes.root}>
      {getLoginToken() && !openDialog && (
        <Container maxWidth="md">
          <Box mb={1}>
            <Typography>タイトル</Typography>
          </Box>

          <Box className={clsx(classes.searchContainer)}>
            <Box className={clsx(classes.inputContainer)}>
              <TextField
                fullWidth
                value={title}
                onChange={(event) => setTitle(event.target.value)}
                InputProps={{
                  disableUnderline: true,
                  className: classes.wrapInput
                }}
              />
            </Box>
          </Box>

          <Box mb={1}>
            <Typography>内容</Typography>
          </Box>

          <Box>
            <TextareaAutosize
              value={content}
              onChange={(event) => setContent(event.target.value)}
              type="text"
              required
              style={{
                maxWidth: '100%',
                width: '100%',
                padding: '0.5rem',
                fontSize: '1rem',
                border: 'none'
              }}
              minRows={15}
            />
            <Box mt={1}>
              <Typography className={clsx(classes.textRed)}>
                ※ご使用いただけない一部絵文字を入力されると送信エラーや文字化けが発生する場合がございます。
              </Typography>
            </Box>
          </Box>

          <Box mb={1} mt={3}>
            <Typography>写真</Typography>
          </Box>

          <Box>
            <Button component="label" className={clsx(classes.buttonInputFile)}>
              ファイルを選択
              <input type="file" onChange={handlePreviewImage} hidden />
            </Button>
          </Box>

          <Box mb={1} mt={2}>
            <Typography>投稿できる画像の最大容量は1024KBです</Typography>
          </Box>

          {image && (
            <Box mt={2}>
              <Box
                className={clsx(classes.imagePreview)}
                component="img"
                src={imagePreview}
                alt="FRAU INTERNATIONAL"
              />
            </Box>
          )}

          <Box
            className={clsx(classes.wrapButton)}
            sx={{ justifyContent: 'end' }}
          >
            <Button
              disabled={
                currentProfile?.status === 'frozen'
                  ? true
                  : title.length !== 0 && content.length !== 0
                  ? false
                  : true
              }
              onClick={() => {
                dispatch(
                  setPostCommunityInfo({
                    title: title,
                    body: content,
                    image,
                    imagePreview
                  })
                );
                navigate(ROUTES_AUTHEN.COMMUNITY_POST_CONFIRM + `/${id}`);
              }}
              className={clsx(classes.registerButton)}
            >
              入力内容確認
            </Button>
          </Box>

          <Box
            className={clsx(classes.wrapButton)}
            sx={{ justifyContent: 'center' }}
          >
            <Button
              onClick={() => navigate(ROUTES.COMMUNITY)}
              className={clsx(classes.registerButton)}
            >
              その他注意事項
            </Button>
          </Box>
        </Container>
      )}
      <LoaderLayer loading={settings?.loading} />
      {!getLoginToken() ? (
        <AuthDialog open={true} onClose={() => {}} path={ROUTES.HOMEVIEW} />
      ) : (
        openDialog && (
          <CheckPaymentDialog
            open={openDialog}
            onClose={setOpenDialog}
            pathName={ROUTES_AUTHEN.COMMUNITY_ON_BOARD + `/${id}`}
          />
        )
      )}
    </div>
  );
}

export default PostCommunity;
