import clsx from 'clsx';
import { forwardRef } from 'react';
import PropTypes from 'prop-types';
import Slide from '@mui/material/Slide';
import Dialog from '@mui/material/Dialog';
import { useLocation, useNavigate } from 'react-router';
import { Box, makeStyles, Typography, Button } from '@material-ui/core';

import { ROUTES, ROUTES_AUTHEN } from 'src/constants';

const useStyles = makeStyles((theme) => ({
  dialogContainer: {
    padding: '1.5rem 3rem',
    [theme.breakpoints.down(700)]: {
      padding: '1.5rem 2rem'
    }
  },
  title: {
    margin: '1rem 0 3rem',
    '& h5': {
      fontWeight: '600'
    },
    [theme.breakpoints.down(700)]: {
      padding: '0 2rem'
    }
  },
  wrapButton: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '5rem',
    [theme.breakpoints.down(700)]: {
      flexDirection: 'column',
      marginTop: '2rem'
    }
  },
  buttonDelete: {
    backgroundColor: 'black !important',
    color: '#fff !important'
  },
  button: {
    width: '100%',
    padding: '12px 130px !important',
    color: '#fff !important',
    borderRadius: 0,
    backgroundColor: 'black !important',
    '&:hover': {
      backgroundColor: '#1175ae'
    },
    [theme.breakpoints.down(700)]: {
      padding: '12px 0 !important'
    }
  },
  buttonBack: {
    backgroundColor: '#A7A7A7 !important',
    color: '#fff !important'
  }
}));

export default function AuthDialog({ open, onClose, path }) {
  const location = useLocation();
  const classes = useStyles();
  const navigate = useNavigate();
  const handleClose = () => {
    onClose(false);
  };

  return (
    <Dialog
      TransitionComponent={Transition}
      open={open}
      aria-describedby="alert-dialog-slide-description"
    >
      <Box className={clsx(classes.dialogContainer)}>
        <Box className={clsx(classes.title)}>
          <Typography style={{ fontSize: 16 }}>
            <strong>ログインが必要です。</strong>
          </Typography>
        </Box>
        <Box mt={2}>
          <Button
            onClick={() => {
              handleClose();
              navigate(
                ROUTES_AUTHEN.LOGIN +
                  '?current=' +
                  location.pathname.replace('/', '') +
                  location.search
              );
            }}
            className={clsx(classes.button, classes.buttonDelete)}
          >
            ログイン
          </Button>
        </Box>
        <Box mt={2}>
          <Button
            onClick={() => {
              handleClose();
              navigate(path);
            }}
            className={`${classes.button} ${classes.buttonBack}`}
          >
            {path === ROUTES.HOMEVIEW ? 'ホーム画面へ' : '閉じる'}
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
}

AuthDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired
};

const Transition = forwardRef(function Transition(props, ref) {
  return (
    <Slide
      direction="up"
      ref={ref}
      {...props}
      timeout={400}
      easing={{
        exit: 'linear'
      }}
    />
  );
});
