import clsx from 'clsx';
import moment from 'moment';
import _ from 'lodash';
import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  Box,
  Container,
  makeStyles,
  Typography,
  Grid,
  Button,
  TextareaAutosize
} from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMessage } from '@fortawesome/free-regular-svg-icons';
import PaginationButton from 'src/components/PaginationButton';
import noSetAvatar from 'src/assets/images/noSetAvatar.svg';
import { ROUTES, ROUTES_AUTHEN } from 'src/constants';
import LoaderLayer from 'src/components/LoaderLayer';
import { authSelector } from 'src/slices/auth';
import { getThreadById, postComment } from 'src/services/communityService';
import { communitySelector } from 'src/slices/community';
import PaymentDialog from 'src/components/PaymentDialog';
import CheckPaymentDialog from 'src/components/CheckPaymentDialog';
import { getDomainTalent, getLoginToken } from 'src/utils/auth';
import AuthDialog from 'src/components/AuthDialog';

const useStyles = makeStyles((theme) => ({
  root: {
    justifyContent: 'center',
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down(600)]: {
      padding: '0'
    }
  },
  container: {
    maxWidth: 1040,
    padding: theme.spacing(0, 8),
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(0, 4)
    }
  },
  wrapTopTitle: {
    margin: '3rem 0',
    [theme.breakpoints.down('700')]: {
      margin: '3rem 0 1rem'
    }
  },
  topTitleText: {
    fontWeight: 'bold',
    fontSize: 22,
    [theme.breakpoints.down('700')]: {
      fontSize: 18
    }
  },
  wrapTitle: {
    display: 'flex',
    justifyContent: 'center',
    paddingBottom: '0.5rem',
    borderBottom: '1px solid #707070'
  },

  contentTitle: {
    color: '#000000',
    fontSize: '1.5rem',
    fontWeight: '600',
    [theme.breakpoints.down(600)]: {
      fontSize: '1rem',
      padding: '0 1.5rem'
    }
  },
  newContent: {
    margin: '4rem 0 3rem',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    [theme.breakpoints.between(600, 1023)]: {
      justifyContent: 'space-evenly'
    },
    [theme.breakpoints.down(600)]: {
      margin: '1rem 0 2rem',
      justifyContent: 'center'
    }
  },
  wrapBoxComment: {
    padding: 24,
    backgroundColor: '#fff',
    width: '100%',
    margin: '40px 0px'
  },
  avatar: {
    width: 100,
    height: 100,
    borderRadius: '50%',
    border: '1px solid #fff',
    [theme.breakpoints.down(600)]: {
      width: 70,
      height: 70
    }
  },
  avatarCover: {
    objectFit: 'cover'
  },
  wrapPagination: {
    margin: '40px 0',
    width: '100%'
  },
  formControl: {
    '& > div': {
      color: '#000000',
      '&:before': {
        borderBottomColor: '#000000'
      },
      '& > svg': {
        color: '#000000'
      },
      '&:after': {
        borderBottomColor: '#000000 !important'
      }
    }
  },
  blogDate: {
    fontWeight: '400',
    textAlign: 'left'
  },
  blogBody: {
    whiteSpace: 'pre-line',
    textAlign: 'start',
    [theme.breakpoints.down(700)]: {
      fontSize: '0.8rem'
    }
  },
  displayComment: {
    padding: '1rem 0px 2rem',
    '&:last-child': {
      borderBottom: 'none'
    },
    [theme.breakpoints.down(700)]: {
      padding: '0.5rem 0 1rem'
    }
  },
  button: {
    backgroundColor: 'black',
    color: '#fff !important',
    borderRadius: 0,
    padding: '12px 130px',
    '&:hover': {
      backgroundColor: '#24292d'
    },
    [theme.breakpoints.down(700)]: {
      padding: '10px 0',
      width: '100%'
    }
  },
  iconLike: {
    cursor: 'pointer'
  },
  textBold: {
    fontWeight: '600'
  },
  communityContent: {
    borderBottom: '1px solid #707070',
    borderTop: '1px solid #707070',
    margin: '1rem 0 3rem',
    [theme.breakpoints.down(700)]: {
      margin: '1rem 0'
    }
  },
  wrapUserInfo: {
    textAlign: 'center',
    borderRight: '1px solid #707070',
    marginRight: '0.8rem'
  },
  wrapComment: {
    maxWidth: '100%',
    flexGrow: 1
  },
  commentTitleContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '0.5rem 0.8rem',
    color: 'white',
    marginBottom: '1.5rem',
    [theme.breakpoints.down(700)]: {
      marginBottom: '0.5rem'
    }
  },
  textName: {
    [theme.breakpoints.down(700)]: {
      fontSize: '0.8rem'
    }
  },
  wrapButton: {
    [theme.breakpoints.down(700)]: {
      marginTop: '1rem'
    }
  }
}));

function CommunityPost({ setting }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const inputElement = useRef();

  // Get params
  const { id } = useParams();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const categoryId = query.get('category_id');

  const { profile } = useSelector(authSelector);
  const community = useSelector(communitySelector);
  const { thread } = community;

  const [content, setContent] = useState('');
  const [page, setPage] = useState(1);
  const [currentProfile, setCurrentProfile] = useState({});
  const [openDialog, setOpenDialog] = useState(false);
  const [pageTitle, setPageTitle] = useState('');

  const focusComment = () => {
    inputElement?.current?.scrollIntoView({
      behavior: 'smooth'
    });
  };

  const handleChange = (value) => {
    setContent(value);
  };

  useEffect(() => {
    dispatch(
      getThreadById({ threadId: id, page: page, categoryId: categoryId })
    );
  }, [page]);

  useEffect(() => {
    const { members } = profile;
    const domainTalent = getDomainTalent();

    const myProfile = _.find(
      members,
      (member) => member.talent.domain.split('//')[1] === domainTalent
    );

    setCurrentProfile(myProfile);

    if (
      myProfile?.member_type === 'lifetime' ||
      myProfile?.member_type === 'existing_fan'
    ) {
      return;
    } else if (
      myProfile?.role === 'talent' ||
      myProfile?.role === 'staff_view' ||
      myProfile?.role === 'staff_edit'
    ) {
      return;
    } else if (
      myProfile?.end_datetime >= moment(new Date()).format('YYYY-MM-DD') &&
      myProfile?.role === 'fan'
    ) {
      return;
    } else {
      setOpenDialog(true);
    }
  }, []);

  useEffect(() => {
    const { categories } = community;

    categories?.map((category) => {
      if (category?.id == categoryId) {
        setPageTitle(category?.title);
      }
    });
  }, [categoryId]);

  const handlePostComment = async () => {
    const data = {
      thread_id: id,
      body: {
        comment: content
      }
    };

    const { payload } = await dispatch(postComment(data));
    setContent('');

    if (payload?.msg === 'Success') {
      dispatch(
        getThreadById({ threadId: id, page: page, categoryId: categoryId })
      );
    }
  };

  return (
    <div className={clsx(classes.root)}>
      {getLoginToken() && !openDialog && (
        <Container className={classes.container}>
          <Box className={clsx(classes.newContent)}>
            <Grid container spacing={3} style={{ gridGap: 24 }}>
              <Grid container>
                <Grid item md={12}>
                  <Grid container>
                    <Box className={clsx(classes.wrapTopTitle)}>
                      <Typography className={classes.topTitleText}>
                        {pageTitle}
                      </Typography>
                    </Box>
                    <Grid item md={10} xs={8}>
                      <Box textAlign="center">
                        <Typography className={clsx(classes.blogDate)}>
                          {moment(thread?.created_at).format('YYYY/MM/DD')}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item md={2} xs={4}>
                      <Box textAlign="end">
                        <Typography className={clsx(classes.textBold)}>
                          {thread?.member?.nickname}
                        </Typography>
                      </Box>
                    </Grid>

                    <Grid item md={10} xs={12}>
                      <Box textAlign="center" mt={2}>
                        <Typography className={clsx(classes.blogDate)}>
                          {thread?.title}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item md={2} xs={12}>
                      <Box
                        display="flex"
                        justifyContent="end"
                        gridGap={25}
                        mt={2}
                      >
                        <Box display="flex" alignItems="center" gridGap={5}>
                          <FontAwesomeIcon
                            className={clsx(classes.iconLike)}
                            icon={faMessage}
                            size="lg"
                            onClick={focusComment}
                            style={{ color: setting?.theme_color2 }}
                          />
                          <Typography
                            className={clsx(classes.textBold)}
                            style={{ color: setting?.theme_color2 }}
                          >
                            {thread?.comments?.data.length}
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      className={clsx(classes.communityContent)}
                    >
                      <Box
                        mt={3}
                        mb={currentProfile?.status !== 'frozen' ? 0 : 3}
                      >
                        {thread?.img && (
                          <Box mb={3} display="flex" justifyContent="center">
                            <Box
                              width={
                                window.innerWidth <= 700 ? '21rem' : '30rem'
                              }
                              component="img"
                              src={`${thread?.img}?v=${moment()
                                .toDate()
                                .getTime()}`}
                              alt="FRAU INTERNATIONAL"
                            />
                          </Box>
                        )}
                        <Typography style={{ whiteSpace: 'pre-line' }}>
                          {thread?.body}
                        </Typography>
                      </Box>

                      {currentProfile?.status !== 'frozen' && (
                        <Box mt={3} mb={4}>
                          <Button
                            onClick={focusComment}
                            className={clsx(classes.button)}
                          >
                            コメントを書く
                          </Button>
                        </Box>
                      )}
                    </Grid>
                  </Grid>
                </Grid>

                {thread?.comments?.data?.map((blog) => (
                  <Grid
                    container
                    className={clsx(classes.displayComment)}
                    key={blog.id}
                  >
                    <Grid item md={12} xs={12}>
                      <Box
                        className={clsx(classes.commentTitleContainer)}
                        style={{
                          backgroundColor:
                            blog?.member?.role !== 'talent'
                              ? setting?.theme_color2 + '80'
                              : setting?.base_color
                        }}
                      >
                        <Typography>0000</Typography>
                        <Typography>
                          {moment(blog?.created_at).format('YYYY/MM/DD hh:mm')}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid
                      item
                      md={4}
                      xs={4}
                      className={clsx(classes.wrapUserInfo)}
                    >
                      <Box
                        className={clsx(
                          classes.avatar,
                          currentProfile?.avatar && classes.avatarCover
                        )}
                        component="img"
                        src={
                          blog?.member?.avatar
                            ? blog?.member?.avatar
                            : noSetAvatar
                        }
                      />
                      <Box mt={1}>
                        <Typography
                          className={clsx(classes.textBold, classes.textName)}
                        >
                          {blog?.member?.nickname}
                        </Typography>
                      </Box>
                    </Grid>

                    <Grid
                      item
                      md={7}
                      xs={7}
                      className={clsx(classes.wrapComment)}
                    >
                      <Typography className={clsx(classes.blogBody)}>
                        {blog?.comment}
                      </Typography>
                    </Grid>
                  </Grid>
                ))}

                {thread?.comments?.last_page > 1 && (
                  <Box className={clsx(classes.wrapPagination)}>
                    <PaginationButton
                      page={page}
                      totalItem={thread?.comments?.last_page * 10}
                      itemPerPage={10}
                      paginate={setPage}
                    />
                  </Box>
                )}

                {currentProfile?.status !== 'frozen' && (
                  <Box className={classes.wrapBoxComment}>
                    <Box display="flex" py={3} gridGap="16px">
                      <Box
                        className={clsx(
                          classes.avatar,
                          currentProfile?.avatar && classes.avatarCover
                        )}
                        component="img"
                        src={
                          currentProfile?.avatar
                            ? currentProfile?.avatar
                            : noSetAvatar
                        }
                      />
                      <Box flexDirection="column" alignSelf="center">
                        <Typography className={clsx(classes.textBold)}>
                          {currentProfile?.nickname}
                        </Typography>
                        <Typography className={clsx(classes.textBold)}>
                          {moment(currentProfile?.end_datetime).format(
                            'YYYY/MM/DD'
                          )}
                        </Typography>
                      </Box>
                    </Box>
                    <TextareaAutosize
                      value={content}
                      maxLength={250}
                      onChange={(event) => handleChange(event.target.value)}
                      type="text"
                      required
                      style={{
                        maxWidth: '100%',
                        width: '100%',
                        padding: '0.5rem',
                        fontSize: '1rem'
                      }}
                      minRows={10}
                      ref={inputElement}
                    />

                    <Grid container style={{ marginTop: 16 }}>
                      <Grid item xs={12} sm={4}>
                        <Box mt={1}>
                          <Typography className={clsx(classes.blogDate)}>
                            {`のこり ${250 - content.length} 文字`}
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item xs={12} sm={8} style={{ textAlign: 'end' }}>
                        <Box className={clsx(classes.wrapButton)}>
                          <Button
                            onClick={handlePostComment}
                            disabled={content.length === 0 ? true : false}
                            className={clsx(classes.button)}
                            ref={inputElement}
                          >
                            投稿を確認する
                          </Button>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                )}

                <Grid item xs={12} sm={12} style={{ textAlign: 'center' }}>
                  <Box
                    mt={currentProfile?.status === 'frozen' ? 8 : 3}
                    mb={4}
                    className={clsx(classes.wrapButton)}
                  >
                    <Button
                      onClick={() => {
                        navigate(ROUTES.COMMUNITY);
                      }}
                      className={clsx(classes.button)}
                    >
                      その他注意事項
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Container>
      )}
      <LoaderLayer loading={community?.loading} />
      {!getLoginToken() ? (
        <AuthDialog open={true} onClose={() => {}} path={ROUTES.HOMEVIEW} />
      ) : (
        openDialog && (
          <CheckPaymentDialog
            open={openDialog}
            onClose={setOpenDialog}
            pathName={ROUTES_AUTHEN.COMMUNITY_ON_BOARD + `/${categoryId}`}
          />
        )
      )}
    </div>
  );
}

export default CommunityPost;
