import React, { useEffect } from 'react';
import clsx from 'clsx';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Container,
  Typography,
  makeStyles,
  Button
} from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';

import LoaderLayer from 'src/components/LoaderLayer';
import { ROUTES, ROUTES_AUTHEN } from 'src/constants';
import { communitySelector } from 'src/slices/community';
import { getCategories } from 'src/services/communityService';
import { getLoginToken } from 'src/utils/auth';

const useStyles = makeStyles((theme) => ({
  root: {
    justifyContent: 'center',
    display: 'flex',
    flexDirection: 'column',
    paddingTop: 100
  },
  border: {
    border: '2.5px solid #707070'
  },
  boxWrap: {
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    [theme.breakpoints.down('700')]: {
      flexDirection: 'column'
    }
  },
  boxContainer: {
    padding: '1.5rem 2rem 3rem',
    [theme.breakpoints.down('700')]: {
      width: '100%',
      padding: '0 1rem 3rem'
    }
  },
  boxBottomContainer: {
    width: '49%',
    padding: '0 2rem 3rem',
    [theme.breakpoints.down('700')]: {
      width: '100%',
      padding: '0 1rem 3rem'
    }
  },
  registerTitleText: {
    fontWeight: 'bold',
    marginBottom: 35,
    fontSize: 22,
    [theme.breakpoints.down('700')]: {
      fontSize: 18
    }
  },
  boxTitle: {
    fontWeight: 'bold',
    fontSize: 22,
    margin: '2.5rem 0',
    [theme.breakpoints.down('700')]: {
      fontSize: 18
    }
  },
  registerButton: {
    backgroundColor: 'black',
    color: 'white !important',
    borderRadius: 0,
    padding: '12px 140px',
    '&:hover': {
      backgroundColor: '#1175ae'
    },
    [theme.breakpoints.down(700)]: {
      padding: '10px 80px',
      width: '100%'
    }
  },
  button: {
    backgroundColor: 'black',
    color: 'white !important',
    borderRadius: 0,
    padding: '12px 0',
    width: '100%',
    '&:hover': {
      backgroundColor: '#1175ae'
    },
    [theme.breakpoints.down(700)]: {
      padding: '10px 80px'
    }
  }
}));

function CommunityBulletinBoard() {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const community = useSelector(communitySelector);
  const { categories } = community;

  useEffect(() => {
    dispatch(getCategories());
  }, []);

  return (
    <div className={classes.root}>
      {getLoginToken() && (
        <Container maxWidth="md">
          <Box mb={8}>
            <Typography className={classes.registerTitleText}>
              COMMUNITY
            </Typography>
          </Box>

          {categories?.length > 0 && (
            <Box
              sx={{ justifyContent: 'center' }}
              mb={2}
              display="flex"
              flexDirection="column"
              alignItems="center"
              className={clsx(classes.border, classes.boxContainer)}
            >
              <Typography className={clsx(classes.boxTitle)}>
                {categories[0]?.title}
              </Typography>
              <Button
                onClick={() =>
                  navigate(
                    ROUTES_AUTHEN.COMMUNITY_ON_BOARD + `/${categories[0]?.id}`
                  )
                }
                className={clsx(classes.registerButton)}
              >
                {categories[0]?.button_name}
              </Button>
            </Box>
          )}

          {categories?.length > 1 && (
            <Box className={clsx(classes.boxWrap)}>
              {categories?.slice(1).map((category) => (
                <Box
                  key={category?.id}
                  sx={{ justifyContent: 'center' }}
                  mb={2}
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  className={clsx(classes.border, classes.boxBottomContainer)}
                >
                  <Typography className={clsx(classes.boxTitle)}>
                    {category?.title}
                  </Typography>
                  <Button
                    onClick={() =>
                      navigate(
                        ROUTES_AUTHEN.COMMUNITY_ON_BOARD + `/${category?.id}`
                      )
                    }
                    className={clsx(classes.button)}
                  >
                    {category?.button_name}
                  </Button>
                </Box>
              ))}
            </Box>
          )}

          <Box mt={6} mb={10} display="flex" sx={{ justifyContent: 'center' }}>
            <Button
              onClick={() => navigate(ROUTES.COMMUNITY)}
              className={clsx(classes.registerButton)}
            >
              その他注意事項
            </Button>
          </Box>
        </Container>
      )}
      <LoaderLayer loading={community?.loading} />
    </div>
  );
}

export default CommunityBulletinBoard;
