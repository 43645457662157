/* eslint-disable */
import React, { useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Hidden,
  IconButton,
  Toolbar,
  makeStyles,
  Button,
  Typography
} from '@material-ui/core';
import { Menu as MenuIcon } from 'react-feather';
import Logo from 'src/components/Logo';
import { THEMES, ROUTES, ROUTES_AUTHEN } from 'src/constants';
import PopUpFCMenu from './PopUpFCMenu';
import SNSButton from 'src/components/SNSButton';
import { clearState, logout } from 'src/slices/auth';
import { useDispatch } from 'react-redux';
import { sendMessageToReactNative } from 'src/components/WebReactNativeCommunicate';
import {
  faInstagram,
  faLine,
  faXTwitter,
  faYoutube
} from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSearchParams } from 'react-router-dom';
const useStyles = makeStyles((theme) => ({
  root: {
    position: 'sticky',
    width: '100%',
    bottom: 0,
    zIndex: theme.zIndex.drawer + 100,
    ...(theme.name === THEMES.LIGHT
      ? {
          boxShadow: 'none',
          backgroundColor: theme.palette.primary.main
        }
      : {}),
    ...(theme.name === THEMES.ONE_DARK
      ? {
          backgroundColor: theme.palette.background.default
        }
      : {})
  },
  toolbar: {
    height: 70,
    position: 'fixed',
    width: '100%',
    paddingRight: 0,
    top: 0
  },
  boxMenuTop: {
    width: '75%',
    display: 'flex',
    justifyContent: 'flex-end',
    flexWrap: 'wrap',
    marginBottom: 8,
    '& > div > button': {
      fontSize: '0.8rem'
    },
    '@media(max-width: 960px)': {
      display: 'none'
    }
  },
  boxMenu: {
    width: '75%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    flexWrap: 'wrap',
    marginBottom: 8,
    '& > div > button': {
      fontSize: '0.8rem'
    },
    '@media(max-width: 960px)': {
      display: 'none'
    }
  },
  toolbarPosition: {
    height: 90,
    width: '100%',
    paddingRight: 0
  },
  rightToolbar: {
    maxWidth: 410,
    gap: 12,
    height: '100%',
    width: '100%',
    color: '#fff',
    '@media(max-width: 960px)': {
      display: 'none'
    }
  },
  rightToolbarPosition: {
    maxWidth: 410,
    gap: 12,
    height: 83,
    width: '100%',
    color: '#fff',
    top: 24,
    '@media(max-width: 1155px)': {
      height: 73
    },
    '@media(max-width: 960px)': {
      display: 'none'
    }
  },
  rightToolbar_button: {
    width: 109,
    height: 29,
    fontSize: 14,
    borderRadius: 15,
    overflow: 'hidden',
    transition: 'ease .2s',
    isolation: 'isolate',
    '&:hover': {
      color: '#000',
      borderColor: '#fff'
    },
    '&:hover:before': {
      transformOrigin: 'left top',
      transform: 'scale(1, 1)'
    },
    '&:before': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      zIndex: 0,
      background: '#fff',
      width: '100%',
      height: '100%',
      transition: 'transform .6s cubic-bezier(0.8, 0, 0.2, 1) 0s',
      transform: 'scale(0, 1)',
      transformOrigin: 'right top'
    },
    '& span': {
      zIndex: 3
    }
  },
  rightToolbarLayout: {
    maxWidth: 410,
    width: '100%',
    gap: 15,
    height: 85,
    color: '#fff',
    '@media(max-width: 960px)': {
      display: 'none'
    }
  },
  rightToolbarPositionLayout: {
    maxWidth: 410,
    width: '100%',
    gap: 15,
    height: 84,
    color: '#fff',
    '@media(max-width: 960px)': {
      display: 'none'
    }
  },
  wrapIconSns: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '50px',
    position: 'absolute',
    top: '60%',
    left: 20,
    '@media(max-width: 960px)': {
      display: 'none'
    }
  },
  wrapLineSNS: {
    display: 'flex',
    flexDirection: 'column',
    width: '75%',
    '& > div': {
      placeSelf: 'end'
    },
    '@media(max-width: 960px)': {
      display: 'none'
    }
  },
  iconButton: {
    padding: '0 8px',
    fontSize: '1.5rem !important'
  },
  wrapIconSnsLayout: {
    display: 'flex',
    justifyContent: 'end',
    '@media(max-width: 960px)': {
      display: 'none'
    }
  },
  wrapButtonClick: {
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    opacity: 0.5,
    position: 'absolute',
    right: 50,
    bottom: 20,
    width: 285,
    height: 78,
    '@media(max-width: 960px)': {
      display: 'none'
    }
  },
  buttonClickEvent: {
    fontSize: 26,
    fontWeight: 600
  },
  underline1: {
    '&:before': {
      borderLeft: '2px solid #006EB1',
      position: 'absolute',
      right: 14,
      width: 20,
      height: 20,
      content: '""',
      display: 'block',
      transform: 'rotate(140deg)'
    }
  },
  underline2: {
    '&:before': {
      borderBottom: '2px solid #006EB1',
      position: 'absolute',
      right: 10,
      width: 256,
      height: 10,
      content: '""',
      display: 'block'
    }
  },
  font: {
    fontFamily: 'futura-pt !important',
    fontStyle: 'normal',
    fontWeight: '400',
    position: 'relative',
    fontSize: 14,
    '&:hover': {
      color: '#000'
    },
    '&:after': {
      content: '""',
      position: 'absolute',
      bottom: 0,
      left: 0,
      width: '100%',
      height: '2px',
      background: '#fff',
      transition: 'all .4s',
      transform: 'scale(0, 1)',
      transformOrigin: 'left top'
    },
    '&:hover': {
      '&:after': {
        transform: 'scale(1, 1)'
      }
    }
  },
  toolbarSp: {
    '@media(max-width: 960px)': {
      backgroundColor: '#000000 !important'
    }
  },
  toolbarSpKJW: {
    '@media(max-width: 960px)': {
      backgroundColor: '#84909B !important'
    }
  }
}));

function Header({ className, onOpenNavBarMobile, setting, profile, ...rest }) {
  const classes = useStyles();
  const navigate = useNavigate();
  const [openPopUpFCMenu, setOpenPopUpFCMenu] = useState(false);
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();

  const handleOpenFCMenu = () => {
    setOpenPopUpFCMenu(true);
  };

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <div>
        <Toolbar
          className={clsx(
            setting?.settings?.talent_settings?.menu_type === 1
              ? classes.toolbar
              : classes.toolbarPosition
          )}
          style={{ backgroundColor: setting?.settings?.theme_color1 }}
        >
          <Hidden mdUp>
            <RouterLink to="/" style={{ width: '90%' }}>
              <Logo />
            </RouterLink>
            <IconButton
              className={classes.menuButton}
              color="inherit"
              onClick={onOpenNavBarMobile}
            >
              <img alt="Logo" src="/static/home/menu-icon.svg" />
            </IconButton>
          </Hidden>
          <Hidden smDown>
            <RouterLink to="/">
              <Logo />
            </RouterLink>
          </Hidden>
          <Box ml={2} flexGrow={0.5} />
          {setting?.settings?.talent_settings?.menu_type === 1 ? (
            <Box
              sx={{ display: { xs: 'none', sm: 'block' } }}
              className={classes.boxMenuTop}
            >
              {setting?.settings?.talent_settings?.menu_notice_position ===
                1 && (
                <Button
                  style={{
                    textTransform: 'none',
                    order:
                      setting?.settings?.talent_settings?.menu_notice_number,
                    color: '#fff'
                  }}
                  className={classes.font}
                  onClick={() => {
                    navigate(ROUTES.NOTICE);
                  }}
                >
                  NOTICE
                </Button>
              )}
              {setting?.settings?.talent_settings?.menu_profile_position ===
                1 && (
                <Button
                  style={{
                    textTransform: 'none',
                    order:
                      setting?.settings?.talent_settings?.menu_profile_number,
                    color: '#fff'
                  }}
                  className={classes.font}
                  onClick={() => {
                    navigate(ROUTES.PROFILE);
                  }}
                >
                  PROFILE
                </Button>
              )}
              {setting?.settings?.talent_settings?.menu_discography_position ===
                1 && (
                <Button
                  style={{
                    textTransform: 'none',
                    order:
                      setting?.settings?.talent_settings
                        ?.menu_discography_number,
                    color: '#fff'
                  }}
                  className={classes.font}
                  onClick={() => {
                    navigate(ROUTES.DISCOGRAPHY);
                  }}
                >
                  DISCOGRAPHY
                </Button>
              )}
              {setting?.settings?.talent_settings?.menu_youtube_position ===
                1 && (
                <Button
                  style={{
                    textTransform: 'none',
                    order:
                      setting?.settings?.talent_settings?.menu_youtube_number,
                    color: '#fff'
                  }}
                  className={classes.font}
                  onClick={() => {
                    navigate(ROUTES.YOUTUBE);
                  }}
                >
                  YOUTUBE
                </Button>
              )}
              {setting?.settings?.talent_settings?.menu_photo_position ===
                1 && (
                <Button
                  style={{
                    textTransform: 'none',
                    order:
                      setting?.settings?.talent_settings?.menu_photo_number,
                    color: '#fff'
                  }}
                  className={classes.font}
                  onClick={() => {
                    navigate(ROUTES.PHOTO);
                  }}
                >
                  PHOTO
                </Button>
              )}
              {setting?.settings?.talent_settings?.menu_video_position ===
                1 && (
                <Button
                  style={{
                    textTransform: 'none',
                    order:
                      setting?.settings?.talent_settings?.menu_video_number,
                    color: '#fff'
                  }}
                  className={classes.font}
                  onClick={() => {
                    navigate(ROUTES.VIDEO);
                  }}
                >
                  VIDEO
                </Button>
              )}
              {setting?.settings?.talent_settings?.menu_shop_position === 1 && (
                <Button
                  style={{
                    textTransform: 'none',
                    order: setting?.settings?.talent_settings?.menu_shop_number,
                    color: '#fff'
                  }}
                  className={classes.font}
                  onClick={() => {
                    window.open(
                      setting?.settings?.shop_url,
                      '_blank',
                      'noopener,noreferrer'
                    );
                  }}
                >
                  SHOP
                </Button>
              )}
              {setting?.settings?.talent_settings?.menu_fromtalent_position ===
                1 && (
                <Button
                  style={{
                    textTransform: 'none',
                    order:
                      setting?.settings?.talent_settings
                        ?.menu_fromtalent_number,
                    color: '#fff'
                  }}
                  className={classes.font}
                  onClick={() => {
                    navigate(ROUTES.TALENT_BLOG);
                  }}
                >
                  MESSAGE
                </Button>
              )}
              {setting?.settings?.talent_settings?.menu_staffblog_position ===
                1 && (
                <Button
                  style={{
                    textTransform: 'none',
                    order:
                      setting?.settings?.talent_settings?.menu_staffblog_number,
                    color: '#fff'
                  }}
                  className={classes.font}
                  onClick={() => {
                    navigate(ROUTES.TALENT_BLOG);
                  }}
                >
                  STAFF BLOG
                </Button>
              )}
              {setting?.settings?.talent_settings?.menu_magazine_position ===
                1 && (
                <Button
                  style={{
                    textTransform: 'none',
                    order:
                      setting?.settings?.talent_settings?.menu_magazine_number,
                    color: '#fff'
                  }}
                  className={classes.font}
                  onClick={() => {
                    navigate(ROUTES.MAGAZINE);
                  }}
                >
                  MAGAZINE
                </Button>
              )}
              {setting?.settings?.talent_settings?.menu_community_position ===
                1 && (
                <Button
                  style={{
                    textTransform: 'none',
                    order:
                      setting?.settings?.talent_settings?.menu_community_number,
                    color: '#fff'
                  }}
                  className={classes.font}
                  onClick={() => {
                    navigate(ROUTES.COMMUNITY);
                  }}
                >
                  COMMUNITY
                </Button>
              )}
              {setting?.settings?.talent_settings?.menu_schedule_position ===
                1 && (
                <Button
                  style={{
                    textTransform: 'none',
                    order:
                      setting?.settings?.talent_settings?.menu_schedule_number,
                    color: '#fff'
                  }}
                  className={classes.font}
                  onClick={() => {
                    navigate(ROUTES.SCHEDULE);
                  }}
                >
                  SCHEDULE
                </Button>
              )}
              {setting?.settings?.talent_settings?.menu_download_position ===
                1 && (
                <Button
                  style={{
                    textTransform: 'none',
                    order:
                      setting?.settings?.talent_settings?.menu_download_number,
                    color: '#fff'
                  }}
                  className={classes.font}
                  onClick={() => {
                    navigate(ROUTES.DOWNLOAD);
                  }}
                >
                  DOWNLOAD
                </Button>
              )}
              {setting?.settings?.talent_settings?.menu_loveletter_position ===
                1 && (
                <Button
                  style={{
                    textTransform: 'none',
                    order:
                      setting?.settings?.talent_settings
                        ?.menu_loveletter_number,
                    color: '#fff'
                  }}
                  className={classes.font}
                  onClick={() => {
                    navigate(ROUTES.LOVE_LETTER);
                  }}
                >
                  LOVE LETTER
                </Button>
              )}
            </Box>
          ) : (
            <div className={classes.boxMenu}>
              <Box
                sx={{ display: { xs: 'none', sm: 'block' } }}
                style={{ display: 'block', textAlign: 'end' }}
              >
                {setting?.settings?.talent_settings?.menu_notice_position ===
                  1 && (
                  <Button
                    style={{
                      textTransform: 'none',
                      order:
                        setting?.settings?.talent_settings?.menu_notice_number,
                      color: '#fff'
                    }}
                    className={classes.font}
                    onClick={() => {
                      navigate(ROUTES.NOTICE);
                    }}
                  >
                    NOTICE
                  </Button>
                )}
                {setting?.settings?.talent_settings?.menu_profile_position ===
                  1 && (
                  <Button
                    style={{
                      textTransform: 'none',
                      order:
                        setting?.settings?.talent_settings?.menu_profile_number,
                      color: '#fff'
                    }}
                    className={classes.font}
                    onClick={() => {
                      navigate(ROUTES.PROFILE);
                    }}
                  >
                    PROFILE
                  </Button>
                )}
                {setting?.settings?.talent_settings
                  ?.menu_discography_position === 1 && (
                  <Button
                    style={{
                      textTransform: 'none',
                      order:
                        setting?.settings?.talent_settings
                          ?.menu_discography_number,
                      color: '#fff'
                    }}
                    className={classes.font}
                    onClick={() => {
                      navigate(ROUTES.DISCOGRAPHY);
                    }}
                  >
                    DISCOGRAPHY
                  </Button>
                )}
                {setting?.settings?.talent_settings?.menu_youtube_position ===
                  1 && (
                  <Button
                    style={{
                      textTransform: 'none',
                      order:
                        setting?.settings?.talent_settings?.menu_youtube_number,
                      color: '#fff'
                    }}
                    className={classes.font}
                    onClick={() => {
                      navigate(ROUTES.YOUTUBE);
                    }}
                  >
                    YOUTUBE
                  </Button>
                )}
                {setting?.settings?.talent_settings?.menu_photo_position ===
                  1 && (
                  <Button
                    style={{
                      textTransform: 'none',
                      order:
                        setting?.settings?.talent_settings?.menu_photo_number,
                      color: '#fff'
                    }}
                    className={classes.font}
                    onClick={() => {
                      navigate(ROUTES.PHOTO);
                    }}
                  >
                    PHOTO
                  </Button>
                )}
                {setting?.settings?.talent_settings?.menu_video_position ===
                  1 && (
                  <Button
                    style={{
                      textTransform: 'none',
                      order:
                        setting?.settings?.talent_settings?.menu_video_number,
                      color: '#fff'
                    }}
                    className={classes.font}
                    onClick={() => {
                      navigate(ROUTES.VIDEO);
                    }}
                  >
                    VIDEO
                  </Button>
                )}
                {setting?.settings?.talent_settings?.menu_shop_position ===
                  1 && (
                  <Button
                    style={{
                      textTransform: 'none',
                      order:
                        setting?.settings?.talent_settings?.menu_shop_number,
                      color: '#fff'
                    }}
                    className={classes.font}
                    onClick={() => {
                      window.open(
                        setting?.settings?.shop_url,
                        '_blank',
                        'noopener,noreferrer'
                      );
                    }}
                  >
                    SHOP
                  </Button>
                )}
                {setting?.settings?.talent_settings
                  ?.menu_fromtalent_position === 1 && (
                  <Button
                    style={{
                      textTransform: 'none',
                      order:
                        setting?.settings?.talent_settings
                          ?.menu_fromtalent_number,
                      color: '#fff'
                    }}
                    className={classes.font}
                    onClick={() => {
                      navigate(ROUTES.TALENT_BLOG);
                    }}
                  >
                    MESSAGE
                  </Button>
                )}
                {setting?.settings?.talent_settings?.menu_staffblog_position ===
                  1 && (
                  <Button
                    style={{
                      textTransform: 'none',
                      order:
                        setting?.settings?.talent_settings
                          ?.menu_staffblog_number,
                      color: '#fff'
                    }}
                    className={classes.font}
                    onClick={() => {
                      navigate(ROUTES.TALENT_BLOG);
                    }}
                  >
                    STAFF BLOG
                  </Button>
                )}
                {setting?.settings?.talent_settings?.menu_magazine_position ===
                  1 && (
                  <Button
                    style={{
                      textTransform: 'none',
                      order:
                        setting?.settings?.talent_settings
                          ?.menu_magazine_number,
                      color: '#fff'
                    }}
                    className={classes.font}
                    onClick={() => {
                      navigate(ROUTES.MAGAZINE);
                    }}
                  >
                    MAGAZINE
                  </Button>
                )}
                {setting?.settings?.talent_settings?.menu_community_position ===
                  1 && (
                  <Button
                    style={{
                      textTransform: 'none',
                      order:
                        setting?.settings?.talent_settings
                          ?.menu_community_number,
                      color: '#fff'
                    }}
                    className={classes.font}
                    onClick={() => {
                      navigate(ROUTES.COMMUNITY);
                    }}
                  >
                    COMMUNITY
                  </Button>
                )}
                {setting?.settings?.talent_settings?.menu_schedule_position ===
                  1 && (
                  <Button
                    style={{
                      textTransform: 'none',
                      order:
                        setting?.settings?.talent_settings
                          ?.menu_schedule_number,
                      color: '#fff'
                    }}
                    className={classes.font}
                    onClick={() => {
                      navigate(ROUTES.SCHEDULE);
                    }}
                  >
                    SCHEDULE
                  </Button>
                )}
                {setting?.settings?.talent_settings?.menu_download_position ===
                  1 && (
                  <Button
                    style={{
                      textTransform: 'none',
                      order:
                        setting?.settings?.talent_settings
                          ?.menu_download_number,
                      color: '#fff'
                    }}
                    className={classes.font}
                    onClick={() => {
                      navigate(ROUTES.DOWNLOAD);
                    }}
                  >
                    DOWNLOAD
                  </Button>
                )}
                {setting?.settings?.talent_settings
                  ?.menu_loveletter_position === 1 && (
                  <Button
                    style={{
                      textTransform: 'none',
                      order:
                        setting?.settings?.talent_settings
                          ?.menu_loveletter_number,
                      color: '#fff'
                    }}
                    className={classes.font}
                    onClick={() => {
                      navigate(ROUTES.LOVE_LETTER);
                    }}
                  >
                    LOVE LETTER
                  </Button>
                )}
              </Box>
              <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                {setting?.settings?.social_infos?.twitter_active_web === 1 && (
                  <IconButton
                    color="inherit"
                    className={classes.iconButton}
                    style={{
                      order: setting?.settings?.social_infos?.twitter_number
                    }}
                    onClick={() =>
                      window.open(
                        setting?.settings?.social_infos?.twitter_url,
                        '_blank',
                        'noopener,noreferrer'
                      )
                    }
                  >
                    <FontAwesomeIcon
                      icon={faXTwitter}
                      className={classes.iconSNS}
                      // color={setting?.settings?.theme_color2}
                      color={'#fff'}
                    />
                  </IconButton>
                )}
                {setting?.settings?.social_infos?.instagram_active_web ===
                  1 && (
                  <IconButton
                    color="inherit"
                    className={classes.iconButton}
                    style={{
                      order: setting?.settings?.social_infos?.instagram_number
                    }}
                    onClick={() =>
                      window.open(
                        setting?.settings?.social_infos?.instagram_url,
                        '_blank',
                        'noopener,noreferrer'
                      )
                    }
                  >
                    <FontAwesomeIcon
                      icon={faInstagram}
                      className={classes.iconSNS}
                      // color={setting?.settings?.theme_color2}
                      color={'#fff'}
                    />
                  </IconButton>
                )}
                {setting?.settings?.social_infos?.line_active_web === 1 && (
                  <IconButton
                    color="inherit"
                    className={classes.iconButton}
                    style={{
                      order: setting?.settings?.social_infos?.line_number
                    }}
                    onClick={() =>
                      window.open(
                        setting?.settings?.social_infos?.line_url,
                        '_blank',
                        'noopener,noreferrer'
                      )
                    }
                  >
                    <FontAwesomeIcon
                      icon={faLine}
                      className={classes.iconSNS}
                      // color={setting?.settings?.theme_color2}
                      color={'#fff'}
                    />
                  </IconButton>
                )}
                {setting?.settings?.social_infos?.youtube_active_web === 1 && (
                  <IconButton
                    color="inherit"
                    className={classes.iconButton}
                    style={{
                      order: setting?.settings?.social_infos?.youtube_number
                    }}
                    onClick={() =>
                      window.open(
                        setting?.settings?.social_infos?.youtube_url,
                        '_blank',
                        'noopener,noreferrer'
                      )
                    }
                  >
                    <FontAwesomeIcon
                      icon={faYoutube}
                      className={classes.iconSNS}
                      // color={setting?.settings?.theme_color2}
                      color={'#fff'}
                    />
                  </IconButton>
                )}
              </div>
            </div>
          )}
          <Toolbar
            variant="dense"
            className={
              setting?.settings?.talent_settings?.menu_type === 2
                ? classes.rightToolbar
                : classes.rightToolbarPosition
            }
            style={{
              backgroundColor: setting?.settings?.theme_color2,
              color:
                setting?.settings?.theme_color2?.toLowerCase() === '#ffffff'
                  ? '#000000'
                  : ''
            }}
          >
            <Typography
              variant="h6"
              color="inherit"
              component="div"
              style={{
                fontFamily: 'futura-pt',
                fontStyle: 'normal',
                fontWeight: '400',
                fontSize: 14
              }}
            >
              FC MENU
            </Typography>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              sx={{ mr: 2 }}
              onClick={handleOpenFCMenu}
            >
              <MenuIcon style={{ width: 25, height: 18 }} />
            </IconButton>
            <Button
              color="inherit"
              variant="outlined"
              className={classes.rightToolbar_button}
              onClick={() => {
                if (profile?.isAuthenticated) {
                  navigate(ROUTES_AUTHEN.MY_PAGE);
                } else {
                  navigate(ROUTES_AUTHEN.LOGIN);
                }
              }}
            >
              {profile?.isAuthenticated ? 'マイページ' : 'ログイン'}
            </Button>
            <Button
              color="inherit"
              variant="outlined"
              className={classes.rightToolbar_button}
              onClick={() => {
                if (profile?.isAuthenticated) {
                  dispatch(logout());
                  dispatch(clearState());
                  sendMessageToReactNative('log_out');
                  navigate(ROUTES_AUTHEN.LOGIN);
                } else {
                  navigate(ROUTES_AUTHEN.MEMBER_BENEFITS);
                }
              }}
            >
              {!profile?.isAuthenticated
                ? '入会'
                : !searchParams.get('appacc') && 'ログアウト'}
            </Button>
          </Toolbar>
          <PopUpFCMenu
            open={{
              openPopUpFCMenu,
              handleClosePopUp: () => setOpenPopUpFCMenu(false)
            }}
            settings={setting?.settings?.talent_settings}
          />
        </Toolbar>
      </div>
      {setting?.settings?.talent_settings?.menu_type === 1 && (
        <SNSButton settings={setting?.settings} />
      )}
    </div>
  );
}

Header.propTypes = {
  className: PropTypes.string,
  onOpenNavBarMobile: PropTypes.func,
  setting: PropTypes.object,
  profile: PropTypes.object
};

export default Header;
