import React from 'react';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'absolute'
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 9999,
    color: '#fff'
  }
}));

export default function LoaderLayer({ loading }) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
}

LoaderLayer.propTypes = {
  loading: PropTypes.bool.isRequired
};
