import clsx from 'clsx';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { saveAs } from 'file-saver';
import moment from 'moment';
import _ from 'lodash';
import { Box, Container, makeStyles, Typography } from '@material-ui/core';

import LoaderLayer from 'src/components/LoaderLayer';
import { downloadSelector } from 'src/slices/download';
import { getDownloads } from 'src/services/download';
import { getDomainTalent, getLoginToken } from 'src/utils/auth';
import { authSelector } from 'src/slices/auth';
import CheckPaymentDialog from 'src/components/CheckPaymentDialog';
import { ROUTES } from 'src/constants';

const useStyles = makeStyles((theme) => ({
  root: {
    justifyContent: 'center',
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down(700)]: {
      padding: '0 1rem 0'
    }
  },
  container: {
    maxWidth: 1040,
    padding: theme.spacing(0, 8),
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(0, 2)
    }
  },
  thumbnailContainer: {
    display: 'flex',
    justifyContent: 'center'
  },
  thumbnail: {
    width: '100%',
    height: 'auto'
    // [theme.breakpoints.down(700)]: {
    //   width: '100%',
    //   height: '100%'
    // }
  },
  descriptionContainer: {
    margin: '3rem 0',
    padding: '0 4rem',
    [theme.breakpoints.down(700)]: {
      padding: '0',
      margin: '1rem 0 2rem'
    }
  },
  buttonDownloadContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    margin: '1rem 0 5rem',
    justifyContent: 'center',
    [theme.breakpoints.down(700)]: {
      justifyContent: 'start',
      margin: '1rem 0 3rem'
    }
  },
  buttonDownload: {
    cursor: 'pointer',
    padding: '0.5rem 4rem',
    backgroundColor: '#000000',
    color: '#ffffff',
    margin: '0 0.2rem 0.5rem',
    [theme.breakpoints.down(700)]: {
      padding: '0.5rem 2rem'
    }
  },
  bodyContent: {
    whiteSpace: 'pre-line'
  }
}));

function DownloadView() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { downloads, loading } = useSelector(downloadSelector);
  const { profile } = useSelector(authSelector);

  const [downloadsArray, setDownloads] = useState([]);
  const [isPayment, setIsPayment] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);

  useEffect(() => {
    dispatch(getDownloads());
  }, []);

  useEffect(() => {
    const { members } = profile;
    const domainTalent = getDomainTalent();

    const myProfile = _.find(
      members,
      (member) => member.talent.domain.split('//')[1] === domainTalent
    );

    if (
      myProfile?.member_type === 'lifetime' ||
      myProfile?.member_type === 'existing_fan'
    ) {
      return;
    } else if (
      myProfile?.role === 'talent' ||
      myProfile?.role === 'staff_view' ||
      myProfile?.role === 'staff_edit'
    ) {
      return;
    } else if (
      myProfile?.end_datetime >= moment(new Date()).format('YYYY-MM-DD') &&
      myProfile?.role === 'fan'
    ) {
      return;
    } else {
      setIsPayment(true);
    }
  }, [profile]);

  useEffect(() => {
    if (!_.isEmpty(downloads)) {
      setDownloads([
        {
          contents_name: downloads?.contents_name1,
          contents_s3_url: downloads?.contents_s3_url1
        },
        {
          contents_name: downloads?.contents_name2,
          contents_s3_url: downloads?.contents_s3_url2
        },
        {
          contents_name: downloads?.contents_name3,
          contents_s3_url: downloads?.contents_s3_url3
        },
        {
          contents_name: downloads?.contents_name4,
          contents_s3_url: downloads?.contents_s3_url4
        },
        {
          contents_name: downloads?.contents_name5,
          contents_s3_url: downloads?.contents_s3_url5
        },
        {
          contents_name: downloads?.contents_name6,
          contents_s3_url: downloads?.contents_s3_url6
        },
        {
          contents_name: downloads?.contents_name7,
          contents_s3_url: downloads?.contents_s3_url7
        },
        {
          contents_name: downloads?.contents_name8,
          contents_s3_url: downloads?.contents_s3_url8
        },
        {
          contents_name: downloads?.contents_name9,
          contents_s3_url: downloads?.contents_s3_url9
        },
        {
          contents_name: downloads?.contents_name10,
          contents_s3_url: downloads?.contents_s3_url10
        }
      ]);
    }
  }, [downloads]);

  return (
    <div className={clsx(classes.root)}>
      {getLoginToken() && (
        <Container className={classes.container}>
          <Box className={clsx(classes.thumbnailContainer)}>
            <Box
              className={clsx(classes.thumbnail)}
              component="img"
              alt="FRAU INTERNATIONAL"
              src={downloads?.thumbnail}
            />
          </Box>

          <Box className={clsx(classes.descriptionContainer)}>
            <Typography className={classes.bodyContent}>
              {downloads?.body}
            </Typography>
          </Box>

          {downloadsArray.length > 0 && (
            <Box className={clsx(classes.buttonDownloadContainer)}>
              {downloadsArray.map(
                (download, index) =>
                  download?.contents_name && (
                    <Typography
                      key={index}
                      className={clsx(classes.buttonDownload)}
                      onClick={() => {
                        if (isPayment) {
                          setOpenDialog(true);
                        } else {
                          const fileName = download?.contents_s3_url.substring(
                            download?.contents_s3_url.lastIndexOf('/') + 1
                          );
                          saveAs(download?.contents_s3_url, fileName);
                        }
                      }}
                    >
                      {download?.contents_name}
                    </Typography>
                  )
              )}
            </Box>
          )}
        </Container>
      )}
      <LoaderLayer loading={loading} />
      {getLoginToken() && openDialog && (
        <CheckPaymentDialog
          open={openDialog}
          onClose={setOpenDialog}
          pathName={ROUTES.DOWNLOAD}
        />
      )}
    </div>
  );
}

export default DownloadView;
