import React from 'react';
import clsx from 'clsx';
import { Box, Container, Typography, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    justifyContent: 'center',
    display: 'flex',
    flexDirection: 'column',
    padding: '1rem 5px 0'
  },
  registerBorderBottom: {
    borderBottom: '1px solid #919090'
  },
  wrapTitle: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center'
  },
  inquiryTitle: {
    fontWeight: 'bold',
    fontSize: 22,
    marginBottom: 5,
    [theme.breakpoints.down('700')]: {
      fontSize: 18
    }
  },
  wrapCheckbox: {
    justifyContent: 'center',
    alignItems: 'center'
  },
  checkboxLabel: {
    [theme.breakpoints.down(700)]: {
      fontSize: '0.9rem'
    }
  }
}));

function InquiryFinishView() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Container maxWidth="md">
        <Box style={{ marginTop: '10%' }}>
          <Box
            sx={{ justifyContent: 'center' }}
            mb={5}
            display="flex"
            alignItems="center"
            className={clsx(classes.registerBorderBottom)}
          >
            <Typography className={clsx(classes.inquiryTitle)}>
              お問い合わせ送信完了
            </Typography>
          </Box>

          <Box>
            <Typography>
              お問い合わせを受付しました。 <br />
              数日たってもお返事がない場合は入力されたアドレスに間違いがあるか、メールの受信制限などにより、メールが届いていない可能性がございます。
              お問い合わせの後、自動返信をしておりますのでメールの受信設定をご確認のうえ自動返信メールが届かない場合は、再度お問い合わせくださいませ。
            </Typography>
          </Box>
        </Box>
      </Container>
    </div>
  );
}
export default InquiryFinishView;
