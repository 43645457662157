import React from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import { checkNameTalent } from 'src/utils/auth';

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 1040,
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto',
    '& p': {
      fontSize: 16
    }
  },
  topBanner: {
    width: '100%',
    background: '#B1B1B1',
    display: 'flex',
    height: 80
  },
  borderBottom: {
    padding: '0 5%',
    paddingBottom: '20px',
    marginBottom: '20px',
    borderBottom: '1px solid #707070'
  },
  heading: {
    fontSize: '2.8rem',
    lineHeight: 1,
    letterSpacing: 0,
    fontWeight: 400,
    marginBottom: '2.5rem',
    [theme.breakpoints.down(700)]: {
      fontSize: '2rem'
    },
    '&::before': {
      marginRight: '10px',
      content: '""',
      width: '30px',
      height: '30px',
      display: 'inline-block',
      backgroundColor: '#006eb1',
      [theme.breakpoints.down(700)]: {
        width: 22,
        height: 22
      }
    }
  },
  textWhite: {
    color: '#ffffff',
    marginTop: 'auto',
    marginLeft: 30,
    marginBottom: 'auto',
    fontSize: 25,
    [theme.breakpoints.up(700)]: {
      fontSize: 40
    }
  }
}));

function Site() {
  const classes = useStyles();
  const { name } = checkNameTalent();

  return (
    <div className={classes.root} title="このサイトについて">
      <div className={classes.topBanner}>
        <Typography className={classes.textWhite}>
          <strong style={{ fontSize: 30 }}>このサイトについて</strong>
        </Typography>
      </div>
      <br />
      <br />
      <Typography className={classes.borderBottom}>
        {name} ジャパン
        オフィシャルファンクラブ（以下「本サイト」といいます）をご利用いただき有り難うございます。本サイトをご利用いただくにあたり、以下の内容をご了承ください。
        <br />
        <br />
        <strong>著作権等について</strong>
        <br />
        <br />
        本サイトで提供する情報、並びに本サイトに掲載されている全ての画像（写真、デザイン）、映像、音楽、文章などに関する著作権その他一切の権利は、
        {name}{' '}
        ジャパンオフィシャルファンクラブ事務局（以下、「当事務局」といいます）、若しくはその他の正当な権利者に帰属しております。よって、本サイトで掲載されている情報の全てについて、無断使用・無断複製を禁止いたします。
        <br />
        <br />
        <strong>免責事項</strong>
        <br />
        <br />
        本サイトに掲載されている全ての情報に関し、当事務局はいかなる保証も行わないものといたします。本サイトのご利用に際し、当事務局以外の第三者からなされた行為、サービスについても、当社は責任を負わないものといたします。
        <br />
        掲載されている情報は予告なしに変更されることがありますので、予めご了承ください。また、本サイトのご利用にあたり生じた、直接的又は間接的な損害につきましては、一切の責任を負いかねます。
        <br />
        <br />
        <strong>推奨環境</strong>
        <br />
        <br />
        本サイトは下記の環境でご利用いただくことを推奨いたします。
        <br />
        推奨される環境以外でのご利用の場合、視認性の不具合や、正常に動作しない可能性があります。予めご了承ください。
        <br />
        <br />
        ・ブラウザ： Safari、Google Chrome、Microsoft Edge 　各最新版
        <br />
        ・OS： iOS16以上、Android11以上
        <br />
      </Typography>
      <Typography style={{ padding: '0 5%' }}>
        {name} ジャパンオフィシャル　ファンクラブ事務局
        <br />
        所在地：〒540-0031　大阪市中央区北浜東1-22北浜東野村ビル8F
        <br />
        （平日10:00～18：00 土日祝/年末年始は休業）
      </Typography>
      <br />
      <br />
    </div>
  );
}

export default Site;
