import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'src/utils/API';

export const getVideoById = createAsyncThunk(
  'videos/id',
  async ({ id }, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `/videos/${id}`
      );
      if (response.status === 200) {
        return response.data.data;
      }
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getVideo = createAsyncThunk(
  'videos',
  async ({ page, perPage, data }, { rejectWithValue }) => {
    try {
      const response = await axios.get(`/videos?page=${page}&perPage=${perPage}`);
      if (response.status === 200) {
        return response.data.data;
      }
      return rejectWithValue(response.data);
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const postFavoriteVideo = createAsyncThunk(
  'videos/favotites',
  async ({ id }, { rejectWithValue }) => {
    try {
      const response = await axios.post(`/videos/${id}/toggle-favorite`,
      {
        headers: {
          'Authorization': axios.defaults.headers.common.Authorization
        }
      });
      if (response.status === 200) {
        return response.data.data;
      }
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);