import { createSlice } from '@reduxjs/toolkit';
import { getProducts, payment, autoPayment } from 'src/services/productService';

export const productSlice = createSlice({
  name: 'product',
  initialState: {
    loading: false,
    products: []
  },
  reducers: {
    clearState: (state) => {
      state.products = [];
      state.loading = false;
      return state;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getProducts.pending, (state) => {
      state.loading = true;
    }),
      builder.addCase(getProducts.fulfilled, (state, { payload }) => {
        state.products = payload;
        state.loading = false;
        return state;
      }),
      builder.addCase(getProducts.rejected, (state, { payload }) => {
        state.errorMessage = payload?.error;
        state.loading = false;
      });
    builder.addCase(payment.pending, (state) => {
      state.loading = true;
    }),
      builder.addCase(payment.fulfilled, (state, { payload }) => {
        state.loading = false;
        return state;
      }),
      builder.addCase(payment.rejected, (state, { payload }) => {
        state.errorMessage = payload?.error;
        state.loading = false;
      });
    builder.addCase(autoPayment.pending, (state) => {
      state.loading = true;
    }),
      builder.addCase(autoPayment.fulfilled, (state, { payload }) => {
        state.loading = false;
        return state;
      }),
      builder.addCase(autoPayment.rejected, (state, { payload }) => {
        state.errorMessage = payload?.error;
        state.loading = false;
      });
  }
});

export const productSelector = (state) => state.product;
export const reducer = productSlice.reducer;
export const { clearState } = productSlice.actions;
