import clsx from 'clsx';
import React from 'react';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Box,
  Container,
  Typography,
  makeStyles,
  Grid,
  TextField,
  Button
} from '@material-ui/core';
import { ROUTES, ROUTES_AUTHEN } from 'src/constants';
import { useSelector, useDispatch } from 'react-redux';

import { authSelector } from 'src/slices/auth';
import { getLoginToken } from 'src/utils/auth';
import LoaderLayer from 'src/components/LoaderLayer';
import { sendVerifyUpdateEmail } from 'src/services/authService';

const useStyles = makeStyles((theme) => ({
  root: {
    justifyContent: 'center',
    display: 'flex',
    flexDirection: 'column',
    paddingTop: 100
  },
  registerBorderBottom: {
    borderBottom: '1px solid #919090'
  },
  registerTitleText: {
    fontWeight: 'bold',
    marginBottom: 35,
    fontSize: 22,
    [theme.breakpoints.down('700')]: {
      fontSize: 18
    }
  },
  registerTitle: {
    fontWeight: 'bold',
    fontSize: 22,
    marginBottom: 5,
    [theme.breakpoints.down('700')]: {
      fontSize: 18
    }
  },
  registerSubTitle: {
    fontSize: 16,
    fontWeight: 'bold',
    marginBottom: 16
  },
  registerDescription: {
    marginBottom: 40
  },
  textBorderBottom: {
    borderBottom: '1px solid #707070',
    width: 'fit-content'
  },
  buttonHover: {
    '&:hover': {
      backgroundColor: 'rgb(34 152 221);'
    }
  },
  registerLabel: {
    width: '55%',
    fontWeight: 'bold',
    textAlign: 'right',
    marginRight: '2rem',
    [theme.breakpoints.down('700')]: {
      fontSize: '0.8rem',
      width: '65%',
      textAlign: 'left',
      marginRight: '0.7rem'
    }
  },
  registerSubLabel: {
    width: '8%',
    fontWeight: 'bold',
    lineHeight: 2,
    marginLeft: 4
  },
  wrapTextField: {
    display: 'flex',
    justifyContent: 'start',
    paddingLeft: '2rem',
    borderBottom: '1px solid #919090',
    [theme.breakpoints.down('700')]: {
      paddingLeft: 0
    }
  },
  wrapInput: {
    color: 'black',
    height: window.innerWidth <= 700 ? 28 : 38,
    backgroundColor: '#FFFFFF',
    '& input': {
      padding: '10px 0 10px 10px'
    }
  },
  registerButton: {
    backgroundColor: 'black',
    color: 'white !important',
    borderRadius: 0,
    padding: '12px 140px',
    fontSize: 18,
    '&:hover': {
      backgroundColor: '#1175ae'
    },
    [theme.breakpoints.down(700)]: {
      fontSize: 16,
      padding: '10px 80px'
    }
  },
  email: {
    width: '100%',
    fontWeight: '600',
    [theme.breakpoints.down(700)]: {
      fontSize: '0.9rem'
    }
  }
}));

function ChangeEmail() {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { state } = useLocation();
  const auth = useSelector(authSelector);
  const { profile } = auth;

  return (
    <div className={classes.root}>
      {getLoginToken() && (
        <Container maxWidth="md">
          <Box mb={10}>
            <Typography className={classes.registerTitleText}>
              メールアドレス変更
            </Typography>
            {/* <Typography className={classes.registerSubTitle}>
              メールアドレスを登録してください。
            </Typography> */}
            <Typography className={classes.registerDescription}>
              「送信する」ボタンを押されますと、ご登録いただいたメールアドレスに自動送信メールが届きます。メール本文に記載されている認証コードをご入力の上、メールアドレスの変更手続きをお済ませください。
            </Typography>
            {/* <Typography className={classes.registerDescription}>
              メールアドレスの登録については、以下の利用目的をご確認いただき同意のうえ、「同意して送信する」ボタンを押してください。
              「同意して送信する」ボタンを押した後は同意したものとします。
            </Typography>

            <Typography className={classes.registerSubTitle}>
              メールアドレス登録の利用目的について
            </Typography>
            <Typography className={classes.registerDescription}>
              1.当社では、本フォームにご登録いただき取得した情報は以下の目的のみに使用いたします。
              <br />
              （1）新規会員登録にかかる目的達成のため
            </Typography>
            <Typography className={classes.registerDescription}>
              2.当社では、1項に記された目的を行うにあたり、ご提供いただいた個人情報の取り扱いをインターネットサーバー業者に委託いたします。
              この場合、個人情報を適切に取り扱っている委託先を選定し、個人情報の適正管理や機密の保持に関して契約などを締結し、電子的方法
              で送付することにより適切な管理を実施します。
              <br />
              当ファンクラブサイトの個人情報の取り扱いについては「個人情報の取り扱いについて」をご覧ください
            </Typography>
            <div
              className={clsx(
                classes.registerDescription,
                classes.textBorderBottom
              )}
              style={{ cursor: 'pointer' }}
              onClick={() => navigate(ROUTES.TERMS)}
            >
              会員規約はこちら
            </div> */}
          </Box>

          <Box
            sx={{ justifyContent: 'center' }}
            mb={6}
            display="flex"
            alignItems="center"
            className={clsx(classes.registerBorderBottom)}
          >
            <Typography className={clsx(classes.registerTitle)}>
              メールアドレス変更
            </Typography>
          </Box>

          <Box mb={10} display="flex" sx={{ justifyContent: 'center' }}>
            <Formik
              initialValues={{
                email: ''
              }}
              validationSchema={Yup.object().shape({
                email: Yup.string()
                  .email('有効な電子メールアドレスでなければなりません')
                  .max(255)
                  .required('メールアドレス必須')
              })}
              onSubmit={async (values) => {
                const { payload } = await dispatch(
                  sendVerifyUpdateEmail(values)
                );

                if (payload?.data?.msg === 'Success') {
                  navigate(ROUTES_AUTHEN.CHANGE_EMAIL_OTP, {
                    state: {
                      ...state
                    }
                  });
                } else {
                  alert(payload?.msg);
                }
              }}
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                touched,
                values
              }) => (
                <form
                  noValidate
                  onSubmit={handleSubmit}
                  style={{ width: '-webkit-fill-available' }}
                >
                  <Box className={classes.wrapTextField}>
                    <Grid item xs={12} sm={8}>
                      <Box display="flex" mb={6}>
                        <Typography className={clsx(classes.registerLabel)}>
                          現在のメールアドレス
                        </Typography>
                        <Typography className={clsx(classes.email)}>
                          {profile?.email}
                        </Typography>
                      </Box>
                      <Box display="flex" mb={6}>
                        <Typography className={clsx(classes.registerLabel)}>
                          新規メールアドレス
                        </Typography>
                        <TextField
                          name="email"
                          error={Boolean(touched.email && errors.email)}
                          helperText={touched.email && errors.email}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.email}
                          type="email"
                          variant="standard"
                          fullWidth
                          InputProps={{
                            disableUnderline: true,
                            className: classes.wrapInput
                          }}
                        />
                      </Box>
                    </Grid>
                  </Box>

                  <Box mt={8} display="flex" sx={{ justifyContent: 'center' }}>
                    <Button
                      type="submit"
                      className={clsx(classes.registerButton)}
                    >
                      送信する
                    </Button>
                  </Box>
                </form>
              )}
            </Formik>
          </Box>
        </Container>
      )}
      <LoaderLayer loading={auth?.isFetching} />
    </div>
  );
}

export default ChangeEmail;
