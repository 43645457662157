import { createSlice } from '@reduxjs/toolkit';
import {
  getNoticesAll,
  getNoticeDetail,
  getTagsNotice,
  getNoticesByTagId
} from 'src/services/noticeService';

export const noticeSlice = createSlice({
  name: 'notices',
  initialState: {
    loading: false,
    notices: [],
    tagsNotice: [],
    noticeDetail: {},
    errorMessage: '',
    currentPage: 1,
    totalPage: 1
  },
  reducers: {
    clearState: (state) => {
      state.notices = [];
      state.tagsNotice = [];
      state.noticeDetail = '';
      state.errorMessage = '';
      state.currentPage = 1;
      state.totalPage = 1;
      state.loading = false;
      return state;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getNoticesAll.rejected, (state, { payload }) => {
      state.errorMessage = payload?.error;
      state.loading = false;
    }),
      builder.addCase(getNoticesAll.pending, (state) => {
        state.loading = true;
      }),
      builder.addCase(getNoticesAll.fulfilled, (state, { payload }) => {
        state.notices = payload.data;
        state.currentPage = payload.current_page;
        state.totalPage = payload.last_page;
        state.loading = false;
        return state;
      });
    builder.addCase(getTagsNotice.pending, (state) => {
      state.loading = true;
    }),
      builder.addCase(getTagsNotice.fulfilled, (state, { payload }) => {
        state.tagsNotice = payload;
        state.loading = false;
        return state;
      }),
      builder.addCase(getTagsNotice.rejected, (state, { payload }) => {
        state.errorMessage = payload?.error;
        state.loading = false;
      });
    builder.addCase(getNoticesByTagId.pending, (state) => {
      state.loading = true;
    }),
      builder.addCase(getNoticesByTagId.fulfilled, (state, { payload }) => {
        state.notices = payload.data;
        state.currentPage = payload.current_page;
        state.totalPage = payload.last_page;
        state.loading = false;
        return state;
      }),
      builder.addCase(getNoticesByTagId.rejected, (state, { payload }) => {
        state.errorMessage = payload.error;
        state.loading = false;
      });
    builder.addCase(getNoticeDetail.rejected, (state, { payload }) => {
      state.errorMessage = payload?.error;
      state.loading = false;
    }),
      builder.addCase(getNoticeDetail.pending, (state) => {
        state.loading = true;
      }),
      builder.addCase(getNoticeDetail.fulfilled, (state, { payload }) => {
        state.noticeDetail = payload.data;
        state.loading = false;
        return state;
      });
  }
});

export const noticeSelector = (state) => state.notice;
export const reducer = noticeSlice.reducer;
export const { clearState } = noticeSlice.actions;
