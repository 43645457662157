import clsx from 'clsx';
import * as Yup from 'yup';
import { Formik } from 'formik';
import React, { useState } from 'react';
import {
  Box,
  Typography,
  makeStyles,
  Button,
  TextField,
  Radio,
  FormControlLabel,
  RadioGroup,
  TextareaAutosize,
  FormHelperText
} from '@material-ui/core';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import Checkbox from '@mui/material/Checkbox';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { ROUTES } from 'src/constants';
import { settingSelector } from 'src/slices/settings';

const useStyles = makeStyles((theme) => ({
  root: {
    justifyContent: 'center',
    display: 'flex',
    flexDirection: 'column'
  },
  registerBorderBottom: {
    borderBottom: '1px solid #919090'
  },
  wrapTitle: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center'
  },
  registerTitle: {
    fontWeight: 'bold',
    fontSize: 22,
    marginBottom: 5,
    [theme.breakpoints.down('700')]: {
      fontSize: 18
    }
  },
  inquiryTitleText: {
    fontWeight: 'bold',
    fontSize: 18,
    borderBottom: '1px solid'
  },
  inquirySubTitle: {
    margin: '3rem 0 2rem',
    fontSize: 14,
    [theme.breakpoints.down(700)]: {
      margin: '2rem 0'
    }
  },
  inquiryList: {
    marginLeft: '1.5rem',
    fontSize: 14,
    fontFamily: 'sans-serif',
    marginBottom: '4rem',
    '& li': {
      padding: '0.1rem 0'
    }
  },
  inquiryTextRight: {
    flexGrow: 1,
    backgroundColor: '#737373',
    color: 'white',
    padding: '0.5rem',
    cursor: 'pointer'
  },
  registerDescription: {
    marginBottom: 5,
    fontSize: '0.9rem'
  },
  inputContainer: {
    marginRight: '0.3rem'
  },
  wrapInput: {
    color: 'black',
    height: 38,
    backgroundColor: '#FFFFFF',
    '& input': {
      padding: '10px 0 10px 10px'
    },
    [theme.breakpoints.down(700)]: {
      height: 35
    }
  },
  input50: {
    width: '50%'
  },
  button: {
    color: '#fff !important',
    borderRadius: 0,
    padding: '12px 140px',
    fontSize: 18,
    [theme.breakpoints.down(700)]: {
      fontSize: 16,
      padding: '10px 0',
      width: '100%'
    }
  },
  displayFlex: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down(700)]: {
      flexDirection: 'column',
      alignItems: 'start',
      display: 'block'
    }
  },
  labelContainer: {
    width: '30%',
    justifyContent: 'flex-end',
    whiteSpace: 'nowrap',
    [theme.breakpoints.down(700)]: {
      width: '100%',
      justifyContent: 'flex-start',
      marginBottom: 14
    }
  },
  label: {
    fontSize: 16,
    fontWeight: 'bold',
    marginRight: 6
  },
  labelRequire: {
    fontSize: 16,
    backgroundColor: '#E02323',
    marginRight: 10,
    padding: '0 14px',
    color: '#ffffff',
    borderRadius: 12
  },
  textError: {
    color: 'red',
    [theme.breakpoints.down(700)]: {
      fontSize: 12,
      marginTop: 8
    }
  },
  input: {
    '& input[type=number]': {
      '-moz-appearance': 'textfield'
    },
    '& input[type=number]::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0
    },
    '& input[type=number]::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0
    }
  },
  radioButton: {
    '& span': {
      fontSize: 15
    }
  },
  errorMessage: {
    marginTop: -22,
    marginBottom: 10,
    color: 'red'
  },
  boxWrap: {
    backgroundColor: '#fff',
    height: 250,
    overflowX: 'scroll',
    padding: '1rem 1.5rem 1rem 1rem',
    margin: '4rem 0 2rem',
    '& p': {
      fontSize: 16
    }
  },
  wrapTextArea: {
    display: 'flex',
    flexGrow: 1,
    width: '75%',
    [theme.breakpoints.down(700)]: {
      width: '100%'
    }
  },
  radio: {
    color: '#333333',
    '&$checked': {
      color: '#333333'
    },
    '& .MuiTypography-body1': {
      fontSize: 14
    }
  },
  checked: {
    fontSize: '14px !important'
  },
  topBanner: {
    width: '100%',
    maxWidth: 1040,
    margin: 'auto',
    background: '#B1B1B1',
    display: 'flex',
    height: 80
  },
  textWhite: {
    color: '#ffffff',
    marginTop: 'auto',
    marginLeft: 30,
    marginBottom: 'auto',
    fontSize: 25,
    [theme.breakpoints.up(700)]: {
      fontSize: 40
    }
  },
  dFlex: {
    display: 'flex'
  },
  addSpace: {
    marginRight: 10,
    whiteSpace: 'nowrap'
  },
  wrapCheckbox: {
    justifyContent: 'center',
    alignItems: 'center'
  },
  checkboxLabel: {
    [theme.breakpoints.down(700)]: {
      fontSize: '0.9rem'
    }
  }
}));

function InquiryView() {
  const classes = useStyles();
  const navigate = useNavigate();
  const setting = useSelector(settingSelector);

  const [checked, setChecked] = useState(false);
  const [checkShowError, setCheckShowError] = useState(false);

  const handleChecked = (event) => {
    setChecked(event.target.checked);
  };

  return (
    <div className={classes.root}>
      <div>
        <div className={classes.topBanner}>
          <Typography className={classes.textWhite}>
            <h5>お問い合わせ</h5>
          </Typography>
        </div>
        <br />
        <br />
        <Box
          mb={8}
          paddingBottom={window.innerWidth <= 700 ? 4 : 8}
          style={{ padding: '5%', maxWidth: 1040, margin: 'auto' }}
        >
          <Box className={classes.wrapTitle}>
            <Typography className={classes.inquiryTitleText}>
              お問い合わせ前にご確認ください
            </Typography>
          </Box>
          <Typography className={classes.inquirySubTitle}>
            匿名でのお問い合わせについては返答できかねます。
            <br />{' '}
            <a
              href="https://www.frau-inter.co.jp/contact_privacypolicy/"
              target="blank"
            >
              「個人情報の取り扱いについて」
            </a>
            をよくお読みいただき、同意した上で氏名、メールアドレス、お問合せ内容を記入し、確認画面へお進みください。
          </Typography>

          <ul className={clsx(classes.inquiryList)}>
            <li>
              来日予定や出演情報等、NOTICEに記載されていること以外はお問い合わせいただいてもお答えできません。
            </li>
            <li>
              お問い合わせには順次お答えしていきますが、返答までにお時間をいただく場合があります。
            </li>
            <li>
              省略語や機種依存文字（絵文字・半角カタカナ等）は使用しないでください。
            </li>
            <li>
              ファンクラブへのご意見には基本的にご返信しませんので、あらかじめご了承ください。
            </li>
            <li>ファンメールはこちらの窓口では受け付けておりません。</li>
          </ul>

          <Box className={classes.wrapTitle}>
            <Typography className={classes.inquiryTitleText}>
              ＜当事務局からの返信メールが届かない場合＞
            </Typography>
          </Box>

          <Typography className={classes.inquirySubTitle}>
            お客様のメーラーやWebメールサービスの設定により、「迷惑メール」フォルダに振り分けられる場合がございます。
            <br />【{setting?.settings?.email}
            】のドメインからのメールを受信できるように設定をお願いいたします。
            <br />
            お問い合わせ後、7営業日以上経っても返信メールが届かない場合には、お客様がご利用されている端末の受信設定をご確認いただき、再度お問い合わせくださいますようお願い申し上げます。
          </Typography>
          <Box
            sx={{ justifyContent: 'center' }}
            mb={5}
            mt={6}
            display="flex"
            alignItems="center"
            className={clsx(classes.registerBorderBottom)}
          >
            <Typography className={clsx(classes.registerTitle)}>
              お問い合わせ入力
            </Typography>
          </Box>

          <Formik
            initialValues={{
              first_name: '',
              last_name: '',
              type: '入会について',
              email: '',
              emailcheck: '',
              body: ''
            }}
            validationSchema={Yup.object().shape({
              first_name: Yup.string().max(45, '長すぎる').required('必須'),
              last_name: Yup.string().max(45, '長すぎる').required('必須'),
              email: Yup.string().email('無効な電子メール').required('必須'),
              emailcheck: Yup.string()
                .email('無効な電子メール')
                .required('必須')
                .oneOf([Yup.ref('email')], 'メールアドレスが一致しません。'),
              body: Yup.string().required('必須')
            })}
            onSubmit={(values) => {
              navigate(ROUTES.INQUIRY_CONFIRM, {
                state: {
                  ...values
                }
              });
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values
            }) => (
              <form
                onSubmit={(e) => {
                  if (values.body === '') {
                    setCheckShowError(true);
                  } else {
                    setCheckShowError(false);
                  }

                  handleSubmit(e);
                }}
              >
                <Box pb={5} className={classes.registerBorderBottom}>
                  {/* First name */}
                  <Box mb={3} className={clsx(classes.displayFlex)}>
                    <Box
                      display={'flex'}
                      className={clsx(classes.labelContainer)}
                    >
                      <Typography className={clsx(classes.label)}>
                        お名前
                      </Typography>
                      <Typography className={clsx(classes.labelRequire)}>
                        必須
                      </Typography>
                    </Box>
                    <Box display={'flex'} flexGrow={1}>
                      <TextField
                        name="first_name"
                        className={clsx(classes.inputContainer)}
                        fullWidth
                        error={Boolean(touched.first_name && errors.first_name)}
                        helperText={touched.first_name && errors.first_name}
                        onBlur={handleBlur}
                        value={values.first_name}
                        onChange={handleChange}
                        InputProps={{
                          disableUnderline: true,
                          className: classes.wrapInput
                        }}
                      />
                      <TextField
                        name="last_name"
                        error={Boolean(touched.last_name && errors.last_name)}
                        helperText={touched.last_name && errors.last_name}
                        onBlur={handleBlur}
                        value={values.last_name}
                        onChange={handleChange}
                        fullWidth
                        InputProps={{
                          disableUnderline: true,
                          className: classes.wrapInput
                        }}
                      />
                    </Box>
                  </Box>
                  {/* email */}
                  <Box mb={1} className={clsx(classes.displayFlex)}>
                    <Box
                      display={'flex'}
                      className={clsx(classes.labelContainer)}
                      alignItems="center"
                    >
                      <Typography
                        className={clsx(classes.label)}
                        style={{ textAlign: 'end' }}
                      >
                        返信用 {window.innerWidth > 700 && <br />}{' '}
                        メールアドレス
                      </Typography>
                      <Typography
                        className={clsx(classes.labelRequire)}
                        style={{ height: 'fit-content' }}
                      >
                        必須
                      </Typography>
                    </Box>
                    <Box display={'flex'} flexGrow={1}>
                      <TextField
                        name="email"
                        error={Boolean(touched.email && errors.email)}
                        helperText={touched.email && errors.email}
                        onBlur={handleBlur}
                        value={values.email}
                        onChange={handleChange}
                        fullWidth
                        InputProps={{
                          disableUnderline: true,
                          className: [
                            classes.wrapInput,
                            window.innerWidth <= 700 ? '' : classes.input50
                          ]
                        }}
                      />
                    </Box>
                  </Box>
                  <Box
                    mb={window.innerWidth < 700 ? 0 : 2}
                    className={clsx(classes.displayFlex)}
                  >
                    <Box
                      display={'flex'}
                      className={clsx(classes.labelContainer)}
                    ></Box>
                    <Box display={'flex'} flexGrow={1}>
                      <TextField
                        name="emailcheck"
                        error={Boolean(touched.emailcheck && errors.emailcheck)}
                        helperText={touched.emailcheck && errors.emailcheck}
                        onBlur={handleBlur}
                        value={values.emailcheck}
                        onChange={handleChange}
                        fullWidth
                        InputProps={{
                          disableUnderline: true,
                          className: [
                            classes.wrapInput,
                            window.innerWidth <= 700 ? '' : classes.input50
                          ]
                        }}
                      />
                    </Box>
                  </Box>
                  <Box mb={3} className={clsx(classes.displayFlex)}>
                    <Box
                      display={'flex'}
                      className={clsx(classes.labelContainer)}
                    ></Box>
                    <Box display={'flex'} flexGrow={1}>
                      <Box flexGrow={1}>
                        <Typography style={{ fontSize: 14 }}>
                          確認のため再度入力お願いいたします
                        </Typography>
                      </Box>
                    </Box>
                  </Box>

                  <Box mb={3} className={clsx(classes.displayFlex)}>
                    <Box
                      display={'flex'}
                      className={clsx(classes.labelContainer)}
                    >
                      <Typography className={clsx(classes.label)}>
                        お問い合わせ項目
                      </Typography>
                      <Typography className={clsx(classes.labelRequire)}>
                        必須
                      </Typography>
                    </Box>
                    <Box
                      className={clsx(
                        classes.displayFlex,
                        classes.inquiryTextRight
                      )}
                      onClick={() => {
                        navigate(ROUTES.FAQ);
                      }}
                    >
                      <Typography style={{ fontSize: 14 }}>
                        お問い合わせの前に“よくあるご質問”をご確認ください。
                      </Typography>
                      {window.innerWidth > 700 && (
                        <FontAwesomeIcon
                          icon={faChevronRight}
                          color={'white'}
                        />
                      )}
                    </Box>
                  </Box>

                  <Box mb={3} className={clsx(classes.displayFlex)}>
                    <Box
                      display={'flex'}
                      className={clsx(classes.labelContainer)}
                    >
                      <Typography
                        className={clsx(classes.label)}
                        style={{ marginRight: 78 }}
                      ></Typography>
                    </Box>
                    <Box className={clsx(classes.displayFlex)}>
                      <RadioGroup
                        name="type"
                        error={Boolean(touched.type && errors.type)}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.type}
                      >
                        <FormControlLabel
                          className={classes.radioButton}
                          value="入会について"
                          control={
                            <Radio
                              classes={{
                                root: classes.radio,
                                checked: classes.checked
                              }}
                            />
                          }
                          label="入会について"
                        />
                        <FormControlLabel
                          className={classes.radioButton}
                          value="ログインについて"
                          control={
                            <Radio
                              classes={{
                                root: classes.radio,
                                checked: classes.checked
                              }}
                            />
                          }
                          label="ログインについて"
                        />
                        <FormControlLabel
                          className={classes.radioButton}
                          value="ファンクラブについて"
                          control={
                            <Radio
                              classes={{
                                root: classes.radio,
                                checked: classes.checked
                              }}
                            />
                          }
                          label="ファンクラブについて"
                        />
                        <FormControlLabel
                          className={classes.radioButton}
                          value="コミュニティの機能について"
                          control={
                            <Radio
                              classes={{
                                root: classes.radio,
                                checked: classes.checked
                              }}
                            />
                          }
                          label="コミュニティの機能について"
                        />
                        <FormControlLabel
                          className={classes.radioButton}
                          value="動画の再生について"
                          control={
                            <Radio
                              classes={{
                                root: classes.radio,
                                checked: classes.checked
                              }}
                            />
                          }
                          label="動画の再生について"
                        />
                        <FormControlLabel
                          className={classes.radioButton}
                          value="商品の購入について"
                          control={
                            <Radio
                              classes={{
                                root: classes.radio,
                                checked: classes.checked
                              }}
                            />
                          }
                          label="商品の購入について"
                        />
                        <FormControlLabel
                          className={classes.radioButton}
                          value="登録案内及び入金メールが届かない"
                          control={
                            <Radio
                              classes={{
                                root: classes.radio,
                                checked: classes.checked
                              }}
                            />
                          }
                          label="登録案内及び入金メールが届かない"
                        />
                        <FormControlLabel
                          className={classes.radioButton}
                          value="更新手続きについて"
                          control={
                            <Radio
                              classes={{
                                root: classes.radio,
                                checked: classes.checked
                              }}
                            />
                          }
                          label="更新手続きについて"
                        />
                        <FormControlLabel
                          className={classes.radioButton}
                          value="その他システムに関するお問い合わせ"
                          control={
                            <Radio
                              classes={{
                                root: classes.radio,
                                checked: classes.checked
                              }}
                            />
                          }
                          label="その他システムに関するお問い合わせ"
                        />
                        <FormControlLabel
                          className={classes.radioButton}
                          value="その他お問い合わせ"
                          control={
                            <Radio
                              classes={{
                                root: classes.radio,
                                checked: classes.checked
                              }}
                            />
                          }
                          label="その他お問い合わせ"
                        />
                      </RadioGroup>
                    </Box>
                  </Box>

                  <Box
                    mb={2}
                    className={clsx(classes.displayFlex)}
                    style={{ alignItems: 'start' }}
                  >
                    <Box
                      display={'flex'}
                      className={clsx(classes.labelContainer)}
                    >
                      <Typography className={clsx(classes.label)}>
                        お問い合わせ内容
                      </Typography>
                      <Typography className={clsx(classes.labelRequire)}>
                        必須
                      </Typography>
                    </Box>
                    <Box
                      className={clsx(classes.wrapTextArea)}
                      display="flex"
                      flexDirection="column"
                    >
                      <TextareaAutosize
                        name="body"
                        onBlur={(e) => {
                          handleBlur(e);

                          if (values.body === '') {
                            setCheckShowError(true);
                          } else {
                            setCheckShowError(false);
                          }
                        }}
                        value={values.body}
                        onChange={handleChange}
                        style={{
                          width: '100%',
                          border: 'none',
                          padding: '0.5rem',
                          fontSize: '1rem'
                        }}
                        type="text"
                        minRows={window.innerWidth < 700 ? 15 : 8}
                      />
                      {errors.body && values.body === '' && checkShowError && (
                        <FormHelperText style={{ color: '#FF0000' }}>
                          必須
                        </FormHelperText>
                      )}
                    </Box>
                  </Box>
                </Box>

                <Box
                  mt={4}
                  display="flex"
                  className={clsx(classes.wrapCheckbox)}
                >
                  <Checkbox onChange={handleChecked} />
                  <Typography className={clsx(classes.checkboxLabel)}>
                    <a
                      target="_blank"
                      href="https://www.frau-inter.co.jp/contact_privacypolicy/"
                    >
                      個人情報の取り扱い
                    </a>
                    に同意する
                  </Typography>
                </Box>

                <Box
                  mt={8}
                  mb={5}
                  display="flex"
                  sx={{ justifyContent: 'center' }}
                >
                  <Button
                    type="submit"
                    className={clsx(classes.button)}
                    style={{
                      backgroundColor: checked ? 'black' : '#7c7b7b'
                    }}
                    disabled={!checked}
                  >
                    確認画面
                  </Button>
                </Box>
              </form>
            )}
          </Formik>
        </Box>
      </div>
    </div>
  );
}
export default InquiryView;
