import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'src/utils/API';

export const getBlogById = createAsyncThunk(
  'blogs/id',
  async ({ page, perPage, id }, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `/blogs/${id}?page=${page}&perPage=${perPage}`
      );
      if (response.status === 200) {
        return response.data.data;
      }
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getBlog = createAsyncThunk(
  'blogs',
  async ({ page, perPage, type }, { rejectWithValue }) => {
    try {
      const response = await axios.get(`/blogs?page=${page}&perPage=${perPage}&type=${type}`);
      if (response.status === 200) {
        return response.data.data;
      }
      return rejectWithValue(response.data);
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const postFavoriteBlog = createAsyncThunk(
  'blogs/favotites',
  async ({ id }, { rejectWithValue }) => {
    try {
      const response = await axios.post(`/blogs/${id}/toggle-favorite`,
      {
        headers: {
          'Authorization': axios.defaults.headers.common.Authorization
        }
      });
      if (response.status === 200) {
        return response.data.data;
      }
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const postCommentBlog = createAsyncThunk(
  'blogs/comment',
  async ({ id, content }, { rejectWithValue }) => {
    try {
      const response = await axios.post(`/blogs/${id}/comment`,
      {
        headers: {
          'Authorization': axios.defaults.headers.common.Authorization
        },
        content
      });
      if (response.status === 200) {
        return response.data.data;
      }
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteCommentBlog = createAsyncThunk(
  'blogs/delete-comment',
  async ({ comment_id }, { rejectWithValue }) => {
    try {
      const response = await axios.delete(`/blogs/comment/${comment_id}`);
      if (response.status === 200) {
        return comment_id;
      }
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);