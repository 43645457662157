import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'src/utils/API';

export const getSettings = createAsyncThunk(
  'settings',
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.get(`/settings`);
      if (response.status === 200) {
        return response.data.data;
      }

      return rejectWithValue(response.data);
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
