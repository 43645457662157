import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'src/utils/API';

export const getDiscographiesByTagId = createAsyncThunk(
  'discography/tag-id',
  async ({ page, perPage, tagId }, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `/get-content-by-tag-id/discographies/${tagId}?page=${page}&perPage=${perPage}`
      );

      if (response.status === 200) {
        return response.data.data;
      }
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getTagsDiscography = createAsyncThunk(
  'discography/tags',
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.get('/tags?type=discographies');

      if (response.status === 200) {
        return response.data.data.data;
      }

      return rejectWithValue(response.data);
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
