import React from 'react';
import clsx from 'clsx';
import moment from 'moment';
import PropTypes from 'prop-types';
import {
  Button,
  Grid,
  Divider,
  Typography,
  makeStyles
} from '@material-ui/core';
// import ViewConfigIcon from '@material-ui/icons/ViewComfyOutlined';
// import ViewWeekIcon from '@material-ui/icons/ViewWeekOutlined';
// import ViewDayIcon from '@material-ui/icons/ViewDayOutlined';
// import ViewAgendaIcon from '@material-ui/icons/ViewAgendaOutlined';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

// const viewOptions = [
//   {
//     label: 'Month',
//     value: 'dayGridMonth',
//     icon: ViewConfigIcon
//   },
//   {
//     label: 'Week',
//     value: 'timeGridWeek',
//     icon: ViewWeekIcon
//   },
//   {
//     label: 'Day',
//     value: 'timeGridDay',
//     icon: ViewDayIcon
//   },
//   {
//     label: 'Agenda',
//     value: 'listWeek',
//     icon: ViewAgendaIcon
//   }
// ];

const useStyles = makeStyles(() => ({
  root: {
    flexDirection: 'column'
  },
  gridItem: {
    width: '100%',
    textAlign: 'center'
  },
  prevButton: {
    float: 'left',
    color: '#000',
    fontSize: 25,
    fontWeight: 700
  },
  todayButton: {
    color: '#000',
    fontSize: 25,
    fontWeight: 700
  },
  nextButton: {
    float: 'right',
    color: '#000',
    fontSize: 25,
    fontWeight: 700
  },
  divider: {
    borderTop: '1px solid #000'
  },
  date: {
    fontSize: 25,
    fontWeight: 700,
    margin: '16px auto'
  }
}));

function Toolbar({
  date,
  view,
  onDatePrev,
  onDateNext,
  onDateToday,
  className,
  ...rest
}) {
  const classes = useStyles();

  return (
    <Grid
      className={clsx(classes.root, className)}
      {...rest}
      alignItems="center"
      container
      justifyContent="space-between"
      spacing={3}
    >
      <Grid item className={classes.gridItem}>
        <Button className={classes.prevButton} onClick={onDatePrev}>
          <ArrowBackIosIcon />
          前月
        </Button>
        <Button className={classes.todayButton} onClick={onDateToday}>今月</Button>
        <Button className={classes.nextButton} onClick={onDateNext}>
          来月
          <ArrowForwardIosIcon />
        </Button>
        <Divider className={classes.divider} />
      </Grid>
      <Grid item>
          <Typography
            variant="h3"
            color="inherit"
            className={classes.date}
          >
            {moment(date).format('YYYY.MM')}
          </Typography>
        </Grid>
    </Grid>
  );
}

Toolbar.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  date: PropTypes.any.isRequired,
  onDateNext: PropTypes.func,
  onDatePrev: PropTypes.func,
  onDateToday: PropTypes.func,
  view: PropTypes.string.isRequired
};

export default Toolbar;
