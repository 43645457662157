/* eslint-disable */
import { createSlice } from '@reduxjs/toolkit';
import {
  loginUser,
  registerUser,
  resetPassword,
  verifyUser,
  sendCode,
  sendForgotEmail,
  getUserProfile,
  applyForgotCode,
  getAvatar,
  updateProfile,
  deleteAccount,
  sendVerifyUpdateEmail,
  verifyEmailUpdate,
  checkDuplicateNickname
} from 'src/services/authService';
import { LOGIN_TOKEN } from 'src/constants';
import axios from 'src/utils/API';

export const authSlice = createSlice({
  name: 'auth',
  initialState: {
    isAuthenticated: false,
    isFetching: false,
    isSuccess: false,
    isError: false,
    isFromRegister: false,
    isFromForgetPassword: false,
    isForgotFetching: false,
    isForgotSuccess: false,
    isForgotError: false,
    isVerifySuccess: false,
    isVerifyError: false,
    isResetPasswordError: false,
    isResetPasswordSuccess: false,
    isResendCodeError: false,
    isResendCodeSuccess: false,
    errorMessage: '',
    forgotErrorMessage: '',
    verifyErrorMessage: '',
    resendErrorMessage: '',
    emailSendVerify: '',
    newEmailUpdate: '',
    infoSendForgot: {},
    sendVerifyEmailMessage: '',
    registerInfo: {},
    profile: {},
    avatars: []
  },
  reducers: {
    clearState: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.isFetching = false;
      state.isForgotFetching = false;
      state.isForgotSuccess = false;
      state.isForgotError = false;
      state.isVerifyError = false;
      state.isVerifySuccess = false;
      state.isResetPasswordError = false;
      state.isResetPasswordSuccess = false;
      state.isResendCodeError = false;
      state.isResendCodeSuccess = false;
      state.sendVerifyEmailMessage = '';
      state.verifyErrorMessage = '';
      state.errorMessage = '';
      state.emailSendVerify = '';
      state.newEmailUpdate = '';
      state.infoSendForgot = {};
      state.registerInfo = {};
      state.profile = {};
      state.avatars = [];
      return state;
    },
    clearInfoSendForgot: (state) => {
      state.infoSendForgot = {};
      return state;
    },
    setAuthenticated: (state) => {
      state.isAuthenticated = true;
    },
    logout: (state) => {
      state.isAuthenticated = false;
      state.errorMessage = '';
      localStorage.removeItem(LOGIN_TOKEN);
      delete axios.defaults.headers.common.Authorization;
      state.isFetching = false;
      return state;
    },
    setRegisterInfo: (state, action) => {
      state.registerInfo = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(loginUser.rejected, (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.errors;
    }),
      builder.addCase(loginUser.pending, (state) => {
        state.isFetching = true;
      }),
      builder.addCase(loginUser.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;
        state.isAuthenticated = true;
        return state;
      }),
      builder.addCase(registerUser.pending, (state, { payload }) => {
        state.isFetching = true;
      }),
      builder.addCase(registerUser.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;
        state.isFromRegister = true;
        state.registerInfo = {};
        return state;
      }),
      builder.addCase(registerUser.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.isSuccess = false;
        state.isFromRegister = true;
      });
    builder.addCase(verifyUser.fulfilled, (state, { payload }) => {
      state.isFetching = false;
      state.isVerifySuccess = true;
      state.isSuccess = true;
      return state;
    }),
      builder.addCase(verifyUser.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isVerifyError = true;
        state.errorMessage = payload;
        state.verifyErrorMessage = payload;
      }),
      builder.addCase(verifyUser.pending, (state) => {
        state.isFetching = true;
      });
    builder.addCase(sendCode.fulfilled, (state, { payload }) => {
      state.isFetching = false;
      state.isSuccess = true;
      state.isResendCodeSuccess = true;
      state.emailSendVerify = payload?.email;
      state.sendVerifyEmailMessage = payload?.data.msg;
      return state;
    }),
      builder.addCase(sendCode.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.isResendCodeError = true;
        state.sendVerifyEmailMessage = payload.msg;
      }),
      builder.addCase(sendCode.pending, (state) => {
        state.isFetching = true;
      });
    builder.addCase(sendForgotEmail.pending, (state) => {
      state.isFetching = true;
    }),
      builder.addCase(sendForgotEmail.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;
        state.infoSendForgot = payload?.info;
        return state;
      }),
      builder.addCase(sendForgotEmail.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = false;
        state.errorMessage = payload?.msg;
      });
    builder.addCase(applyForgotCode.pending, (state) => {
      state.isFetching = true;
    }),
      builder.addCase(applyForgotCode.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;
        return state;
      }),
      builder.addCase(applyForgotCode.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = false;
        state.errorMessage = payload?.errors;
      });
    builder.addCase(resetPassword.fulfilled, (state, { payload }) => {
      state.isFetching = false;
      state.isSuccess = true;
      state.isResetPasswordSuccess = true;
      return state;
    }),
      builder.addCase(resetPassword.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isError = true;
        state.isResetPasswordError = true;
        state.errorMessage = payload;
      }),
      builder.addCase(resetPassword.pending, (state) => {
        state.isFetching = true;
      });
    builder.addCase(getUserProfile.pending, (state) => {
      state.isFetching = true;
    }),
      builder.addCase(getUserProfile.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;
        state.profile = payload;
        state.isAuthenticated = true;
        return state;
      }),
      builder.addCase(getUserProfile.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = false;
        state.errorMessage = payload;
        state.isAuthenticated = false;
      });
    builder.addCase(getAvatar.pending, (state) => {
      state.isFetching = true;
    }),
      builder.addCase(getAvatar.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;
        state.avatars = payload;
        return state;
      }),
      builder.addCase(getAvatar.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = false;
        state.errorMessage = payload;
      });
    builder.addCase(updateProfile.pending, (state) => {
      state.isFetching = true;
    }),
      builder.addCase(updateProfile.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;
        return state;
      }),
      builder.addCase(updateProfile.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = false;
        state.errorMessage = payload;
      });
    builder.addCase(deleteAccount.pending, (state) => {
      state.isFetching = true;
    }),
      builder.addCase(deleteAccount.fulfilled, (state) => {
        state.isFetching = false;
        state.isSuccess = true;
        return state;
      }),
      builder.addCase(deleteAccount.rejected, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = false;
        state.errorMessage = payload;
      });
    builder.addCase(sendVerifyUpdateEmail.pending, (state) => {
      state.isFetching = true;
    }),
      builder.addCase(sendVerifyUpdateEmail.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.isSuccess = true;
        state.newEmailUpdate = payload?.new_email;
        return state;
      }),
      builder.addCase(sendVerifyUpdateEmail.rejected, (state) => {
        state.isFetching = false;
        state.isError = true;
      });
    builder.addCase(verifyEmailUpdate.pending, (state) => {
      state.isFetching = true;
    }),
      builder.addCase(verifyEmailUpdate.fulfilled, (state) => {
        state.isFetching = false;
        state.isSuccess = true;
        state.newEmailUpdate = '';
        return state;
      }),
      builder.addCase(verifyEmailUpdate.rejected, (state) => {
        state.isFetching = false;
        state.isError = true;
      });
    builder.addCase(checkDuplicateNickname.pending, (state) => {
      state.isFetching = true;
    }),
      builder.addCase(checkDuplicateNickname.fulfilled, (state) => {
        state.isFetching = false;
        state.isSuccess = true;
        return state;
      }),
      builder.addCase(checkDuplicateNickname.rejected, (state) => {
        state.isFetching = false;
        state.isError = true;
      });
  }
});

export const authSelector = (state) => state.auth;
export const reducer = authSlice.reducer;
export const {
  clearState,
  logout,
  setAuthenticated,
  setRegisterInfo,
  clearInfoSendForgot
} = authSlice.actions;
