import _ from 'lodash';
import clsx from 'clsx';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useNavigate } from 'react-router';
import React, { useState, useEffect } from 'react';
import {
  Box,
  Container,
  makeStyles,
  Typography,
  Button,
  TextField,
  styled
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';

import { ROUTES_AUTHEN } from 'src/constants';
import DeleteDialog from '../DeleteDialog';
import LoaderLayer from 'src/components/LoaderLayer';
import { deleteAccount } from 'src/services/authService';
import { getDomainTalent, getLoginToken } from 'src/utils/auth';
import { authSelector } from 'src/slices/auth';

const useStyles = makeStyles((theme) => ({
  root: {
    justifyContent: 'center',
    display: 'flex',
    flexDirection: 'column',
    paddingTop: '5rem',
    [theme.breakpoints.down(700)]: {
      padding: '3rem 0 2rem'
    }
  },
  fieldError: {
    '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
      borderColor: 'red'
    },
    '&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
      borderColor: 'red'
    },
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: 'red'
    }
  },
  borderBottom: {
    borderBottom: '1px solid #919090'
  },
  itemCenter: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  wrapTitleSP: {
    margin: '2rem 0 3rem',
    [theme.breakpoints.down(700)]: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: '2rem'
    }
  },
  pageTitle: {
    color: '#000000',
    fontSize: '1.4rem',
    fontWeight: '600',
    [theme.breakpoints.down(700)]: {
      fontSize: '1rem',
      padding: '0 1.5rem 0 0'
    }
  },
  wrapButton: {
    display: 'flex',
    justifyContent: 'center',
    margin: '5rem 0',
    [theme.breakpoints.down(700)]: {
      flexDirection: 'column-reverse',
      margin: '2rem 0'
    }
  },
  buttonBack: {
    backgroundColor: '#A7A7A7',
    color: '#fff !important',
    borderRadius: 0,
    padding: '11px 0',
    width: '38%',
    marginRight: '2rem',
    '&:hover': {
      backgroundColor: '#5b5b5b'
    },
    [theme.breakpoints.down(700)]: {
      padding: '12px 0',
      width: '100%',
      marginBottom: '1rem'
    }
  },
  buttonEdit: {
    color: '#fff !important',
    borderRadius: 0,
    padding: '11px 0',
    width: '38%',
    fontSize: 18,
    '&:hover': {
      backgroundColor: '#1175ae'
    },
    [theme.breakpoints.down(700)]: {
      padding: '12px 0',
      width: '100%',
      marginBottom: '1rem'
    }
  },
  wrapTextConfirm: {
    margin: '3rem 0 3rem',
    paddingBottom: '0.8rem'
  },
  textConfirm: {
    fontWeight: '600'
  },
  containerProfile: {
    backgroundColor: '#ffffff',
    padding: '2rem',
    [theme.breakpoints.down(700)]: {
      padding: '0.5rem'
    }
  },
  containerProfileDetail: {
    display: 'flex'
  },
  profileLeft: {
    width: '20%',
    backgroundColor: '#C5C5C5',
    textAlign: 'center',
    borderRight: '1px solid #707070',
    '& p': {
      padding: '0.5rem 0',
      height: '100%',
      [theme.breakpoints.down(700)]: {
        fontSize: '0.9rem'
      }
    },
    [theme.breakpoints.down(700)]: {
      width: '37%'
    }
  },
  profileRight: {
    flexGrow: 1,
    width: '80%',
    '& p': {
      padding: '0.5rem 0 0 1rem',
      height: '100%',
      [theme.breakpoints.down(700)]: {
        padding: '0.5rem 0 0.5rem 0.8rem',
        fontSize: '0.9rem'
      }
    },
    [theme.breakpoints.down(700)]: {
      width: '63%'
    }
  },
  borderDashed: {
    borderBottom: '1px dashed #AFAFAF'
  },
  profileRightContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  }
}));

const CssTextField = styled(TextField)({
  marginTop: 4,
  paddingLeft: '0.5rem',
  '& input': {
    color: 'black',
    padding: '6.5px 14px'
  },
  '& input[type="password"]': {
    fontSize: window.innerWidth <= 767 ? '0.6rem' : '1rem',
    letterSpacing: window.innerWidth <= 767 ? 1 : -6,
    height: window.innerWidth <= 767 ? 20 : '100%'
  },
  '& fieldset': {
    borderRadius: 0
  },
  '& label.Mui-focused': {
    color: 'black'
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: '#707070'
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#707070'
    },
    '&:hover fieldset': {
      borderColor: '#707070'
    },
    '&.Mui-focused fieldset': {
      borderColor: '#707070'
    }
  }
});

function DeleteAccount({ setting }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const auth = useSelector(authSelector);
  const { profile } = auth;

  const [openDialog, setOpenDialog] = useState(false);
  const [currentProfile, setCurrentProfile] = useState({});

  const formik = useFormik({
    initialValues: {
      member_id: '',
      name_1: '',
      name_2: '',
      password: '',
      reason: ''
    },
    validationSchema: Yup.object().shape({
      member_id: Yup.string()
        .required('必須')
        .test(
          'member_id',
          '会員番号が間違っています。ご確認ください。',
          (value) => value === currentProfile?.member_id.toString()
        ),
      name_1: Yup.string()
        .required('必須')
        .test(
          'name_1',
          'お名前が間違っています。',
          (value) => value === profile?.family_name
        ),
      name_2: Yup.string()
        .required('必須')
        .test(
          'name_2',
          'お名前が間違っています。',
          (value) => value === profile?.first_name
        ),
      password: Yup.string().required('必須'),
      reason: Yup.string().max(100, '100文字以内').required('必須')
    }),
    onSubmit: async (values) => {
      const domainTalent = getDomainTalent();

      let data = {
        member_id: values.member_id,
        name: values.name_1 + '　' + values.name_2,
        password: values.password,
        reason: values.reason
      };

      if (domainTalent === process.env.REACT_APP_TALENT_ZO_IN_SUNG_DOMAIN) {
        data = {
          ...data,
          member_id: values.member_id.replace('No00', '')
        };
      } else if (
        domainTalent === process.env.REACT_APP_TALENT_SONG_JOON_KI_DOMAIN
      ) {
        data = {
          ...data,
          member_id: parseInt(values.member_id)
        };
      } else if (
        domainTalent === process.env.REACT_APP_TALENT_LEE_JONG_SUK_DOMAIN
      ) {
        data = {
          ...data,
          member_id: values.member_id.replace('L', '')
        };
      } else if (
        domainTalent === process.env.REACT_APP_TALENT_JANG_KEUN_SUK_DOMAIN
      ) {
        data = {
          ...data,
          member_id: values.member_id.replace('JKS', '')
        };
      }

      setOpenDialog(false);
      const { payload } = await dispatch(deleteAccount(data));

      if (payload?.msg === 'Success') {
        navigate(ROUTES_AUTHEN.MY_PAGE);
      } else {
        alert(payload?.msg);
      }
    }
  });

  useEffect(() => {
    const { members } = profile;
    const domainTalent = getDomainTalent();

    const myProfile = _.find(
      members,
      (member) => member.talent.domain.split('//')[1] === domainTalent
    );

    setCurrentProfile(myProfile);
  }, [profile]);

  return (
    <div className={clsx(classes.root)}>
      {getLoginToken() && (
        <Container maxWidth="md">
          <Box className={clsx(classes.wrapTitleSP)}>
            <Typography className={clsx(classes.pageTitle)}>
              退会する
            </Typography>
          </Box>

          <Box>
            <Typography>
              ■退会手続きを完了された翌月より会員資格は失効となります。
            </Typography>
            <Typography>
              ※「退会する」ボタンを押されましても「退会する」ボタンを押した月の末日までは会員とみなされます。
            </Typography>
            <Typography>
              ■退会された場合、いかなる事由においても会費の返金はいたしません。
            </Typography>
            <Typography>
              ■退会された場合、再入会されても会員番号は引き継ぐことができません。
            </Typography>
            <Typography>
              ■退会後はすべてのサービスの提供を停止いたします。
            </Typography>
          </Box>

          <Box
            className={clsx(
              classes.itemCenter,
              classes.wrapTextConfirm,
              classes.borderBottom
            )}
          >
            <Typography variant="h3" className={clsx(classes.textConfirm)}>
              会員情報入力
            </Typography>
          </Box>
          <form id="delete" onSubmit={formik.handleSubmit}>
            <Box className={clsx(classes.containerProfile)}>
              <Box>
                <Box className={clsx(classes.containerProfileDetail)}>
                  <Box
                    className={clsx(classes.profileLeft, classes.borderDashed)}
                  >
                    <Typography>会員番号</Typography>
                  </Box>

                  <Box
                    className={clsx(classes.profileRight, classes.borderDashed)}
                  >
                    <CssTextField
                      name="member_id"
                      value={formik.values.member_id}
                      onChange={formik.handleChange}
                      error={Boolean(
                        formik.touched.member_id && formik.errors.member_id
                      )}
                      className={clsx(
                        Boolean(formik.errors.member_id) && classes.fieldError
                      )}
                      variant="outlined"
                      size="small"
                      fullWidth
                    />
                    {formik.errors.member_id && (
                      <Typography style={{ color: 'red', fontSize: '0.8rem' }}>
                        {formik.errors.member_id}
                      </Typography>
                    )}
                  </Box>
                </Box>
                <Box className={clsx(classes.containerProfileDetail)}>
                  <Box
                    className={clsx(classes.profileLeft, classes.borderDashed)}
                  >
                    <Typography>お名前</Typography>
                  </Box>

                  <Box
                    className={clsx(classes.profileRight, classes.borderDashed)}
                    display="flex"
                  >
                    <Box flexGrow={1}>
                      <CssTextField
                        name="name_1"
                        value={formik.values.name_1}
                        onChange={formik.handleChange}
                        error={Boolean(
                          formik.touched.name_1 && formik.errors.name_1
                        )}
                        className={clsx(
                          Boolean(formik.errors.name_1) && classes.fieldError
                        )}
                        variant="outlined"
                        size="small"
                        fullWidth
                      />
                      {formik.errors.name_1 && (
                        <Typography
                          style={{ color: 'red', fontSize: '0.8rem' }}
                        >
                          {formik.errors.name_1}
                        </Typography>
                      )}
                    </Box>

                    <Box flexGrow={1}>
                      <CssTextField
                        name="name_2"
                        value={formik.values.name_2}
                        onChange={formik.handleChange}
                        error={Boolean(
                          formik.touched.name_2 && formik.errors.name_2
                        )}
                        className={clsx(
                          Boolean(formik.errors.name_2) && classes.fieldError
                        )}
                        variant="outlined"
                        size="small"
                        fullWidth
                      />
                      {formik.errors.name_2 && (
                        <Typography
                          style={{ color: 'red', fontSize: '0.8rem' }}
                        >
                          {formik.errors.name_2}
                        </Typography>
                      )}
                    </Box>
                  </Box>
                </Box>
                <Box className={clsx(classes.containerProfileDetail)}>
                  <Box
                    className={clsx(classes.profileLeft, classes.borderDashed)}
                  >
                    <Typography>パスワード</Typography>
                  </Box>

                  <Box
                    className={clsx(classes.profileRight, classes.borderDashed)}
                  >
                    <CssTextField
                      name="password"
                      value={formik.values.password}
                      onChange={formik.handleChange}
                      error={Boolean(
                        formik.touched.password && formik.errors.password
                      )}
                      className={clsx(
                        Boolean(formik.errors.password) && classes.fieldError
                      )}
                      variant="outlined"
                      size="small"
                      type="password"
                      fullWidth
                    />
                    {formik.errors.password && (
                      <Typography style={{ color: 'red', fontSize: '0.8rem' }}>
                        {formik.errors.password}
                      </Typography>
                    )}
                  </Box>
                </Box>

                <Box className={clsx(classes.containerProfileDetail)}>
                  <Box className={clsx(classes.profileLeft)}>
                    <Typography>退会理由</Typography>
                  </Box>

                  <Box className={clsx(classes.profileRight)}>
                    <CssTextField
                      name="reason"
                      value={formik.values.reason}
                      onChange={formik.handleChange}
                      error={Boolean(
                        formik.touched.reason && formik.errors.reason
                      )}
                      className={clsx(
                        Boolean(formik.errors.reason) && classes.fieldError
                      )}
                      variant="outlined"
                      size="small"
                      fullWidth
                    />
                    {formik.errors.reason && (
                      <Typography style={{ color: 'red', fontSize: '0.8rem' }}>
                        {formik.errors.reason}
                      </Typography>
                    )}
                  </Box>
                </Box>
              </Box>
            </Box>

            <Box className={clsx(classes.wrapButton)}>
              <Button
                onClick={() => navigate(-1)}
                className={clsx(classes.buttonBack)}
              >
                マイページに戻る
              </Button>
              <Button
                disabled={currentProfile?.delete_request === 1 ? true : false}
                onClick={() => setOpenDialog(true)}
                className={clsx(classes.buttonEdit)}
                style={{
                  backgroundColor:
                    currentProfile?.delete_request === 1 ? '#7d7d7d' : 'black'
                }}
              >
                {currentProfile?.delete_request === 1
                  ? '退会申請済み'
                  : '同意して送信する'}
              </Button>
            </Box>
          </form>
        </Container>
      )}

      <DeleteDialog open={openDialog} onClose={setOpenDialog} />

      <LoaderLayer loading={auth?.isFetching} />
    </div>
  );
}

export default DeleteAccount;
