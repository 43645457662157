import clsx from 'clsx';
import _ from 'lodash';
import moment from 'moment';
import ReactPlayer from 'react-player/lazy';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart } from '@fortawesome/free-solid-svg-icons';
import { faHeart as heart } from '@fortawesome/free-regular-svg-icons';
import { Box, Container, makeStyles, Typography } from '@material-ui/core';
import { getVideoById, postFavoriteVideo } from 'src/services/videoService';
import { videoSelector } from 'src/slices/videos';
import LoaderLayer from 'src/components/LoaderLayer';
import { authSelector } from 'src/slices/auth';
import { getLoginToken, getDomainTalent } from 'src/utils/auth';
import CheckPaymentDialog from 'src/components/CheckPaymentDialog';
import { ROUTES } from 'src/constants';
import AuthDialog from 'src/components/AuthDialog';

const useStyles = makeStyles((theme) => ({
  root: {
    justifyContent: 'center',
    display: 'flex',
    flexDirection: 'column',
    marginTop: '1rem',
    [theme.breakpoints.down(700)]: {
      padding: '0 2px 0',
      marginTop: 10
    }
  },
  container: {
    maxWidth: 1040,
    padding: theme.spacing(0, 8),
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(0, 2)
    }
  },
  contentTitle: {
    color: '#000000',
    fontSize: '1.5rem',
    fontWeight: '600',
    [theme.breakpoints.down(700)]: {
      fontSize: '1rem'
    }
  },
  newContent: {
    margin: '1rem 0 3rem',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    [theme.breakpoints.down(700)]: {
      margin: '1rem 0 2rem',
      justifyContent: 'space-evenly'
    }
  },
  wrapPagination: {
    margin: '40px 0'
  },
  boxContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '4rem',
    width: '100%',
    [theme.breakpoints.down(700)]: {
      marginBottom: '1.5rem'
    }
  },
  boxContent: {
    cursor: 'pointer',
    '& > div': {
      maxWidth: '100%',
      width: '100% !important',
      height: '500px !important',
      maxHeight: '100% !important'
    },
    [theme.breakpoints.down(700)]: {
      '& > div': {
        maxHeight: '300px !important'
      }
    }
  },
  iframe: {
    width: '100% !important',
    height: '100% !important'
  },
  videoTitle: {
    marginTop: '2rem',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down(700)]: {
      width: '100%',
      marginTop: '0.5rem'
    }
  },
  videoDate: {
    fontSize: 18
  },
  videoText: {
    fontSize: 18,
    margin: '0.5rem 0',
    [theme.breakpoints.down(700)]: {
      margin: '0.2rem 0'
    }
  },
  iconLike: {
    cursor: 'pointer'
  },
  likeText: {
    marginLeft: '0.5rem',
    [theme.breakpoints.down(700)]: {
      fontSize: '0.8rem'
    }
  },
  backButton: {
    width: '100%',
    textAlign: 'center',
    fontSize: 13,
    margin: '30px 0',
    cursor: 'pointer',
    padding: 10
  }
}));

function VideoDetail({ setting }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { id } = useParams();
  const videos = useSelector(videoSelector);
  const { profile } = useSelector(authSelector);
  const navigate = useNavigate();
  const [openDialog, setOpenDialog] = useState(false);

  useEffect(() => {
    dispatch(getVideoById({ id }));

    const { members } = profile;
    const domainTalent = getDomainTalent();

    const myProfile = _.find(
      members,
      (member) => member.talent.domain.split('//')[1] === domainTalent
    );

    if (
      myProfile?.member_type === 'lifetime' ||
      myProfile?.member_type === 'existing_fan'
    ) {
      return;
    } else if (
      myProfile?.role === 'talent' ||
      myProfile?.role === 'staff_view' ||
      myProfile?.role === 'staff_edit'
    ) {
      return;
    } else if (
      myProfile?.end_datetime >= moment(new Date()).format('YYYY-MM-DD') &&
      myProfile?.role === 'fan'
    ) {
      return;
    } else {
      setOpenDialog(true);
    }
  }, []);

  return (
    <div className={clsx(classes.root)}>
      {getLoginToken() && !openDialog && !videos?.loading && (
        <Container className={classes.container}>
          <Box>
            <Typography className={clsx(classes.contentTitle)}>
              VIDEO
            </Typography>
          </Box>
          <Box className={clsx(classes.newContent)}>
            <Box className={clsx(classes.boxContainer)}>
              <Box className={clsx(classes.boxContent)}>
                <ReactPlayer
                  url={videos?.tagsVideo?.url}
                  playing={false}
                  controls={true}
                  config={{ file: { attributes: { controlsList: 'nodownload' } } }}
                  onContextMenu={e => e.preventDefault()}
                  width="100%"
                  height="100%"
                  // className={classes.iframe}
                />
              </Box>
              <Box className={clsx(classes.videoTitle)}>
                <Box flexGrow={1}>
                  <Typography className={clsx(classes.videoDate)}>
                    {moment(videos?.tagsVideo?.release_date).format(
                      'YYYY/MM/DD'
                    )}
                  </Typography>
                  <Typography className={clsx(classes.videoText)}>
                    {videos?.tagsVideo?.title}
                  </Typography>
                </Box>
                <Box display="flex" ml={3}>
                  <FontAwesomeIcon
                    className={clsx(classes.iconLike)}
                    icon={
                      videos?.tagsVideo?.favorites_count > 0 ? faHeart : heart
                    }
                    size="lg"
                    onClick={() =>
                      dispatch(postFavoriteVideo({ id: videos?.tagsVideo?.id }))
                    }
                    style={{ color: setting.theme_color2 }}
                  />
                  <Typography
                    className={clsx(classes.likeText)}
                    style={{ color: setting.theme_color2 }}
                  >
                    {videos?.tagsVideo?.favorites_count}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
          <div
            className={classes.backButton}
            onClick={() => {
              navigate(-1);
            }}
          >
            一覧へ戻る
          </div>
        </Container>
      )}
      <LoaderLayer loading={videos?.loading} />
      {!getLoginToken() ? (
        <AuthDialog open={true} onClose={() => {}} path={ROUTES.VIDEO} />
      ) : (
        openDialog && (
          <CheckPaymentDialog
            open={openDialog}
            onClose={setOpenDialog}
            pathName={ROUTES.VIDEO}
          />
        )
      )}
    </div>
  );
}

export default VideoDetail;
