/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';

import MainLayout from './MainLayout';
import SubLayout from './SubLayout';
import Footer from './Footer';
import { ROUTES, ROUTES_AUTHEN } from '../constants';
import Header from './Header';
import NavBar from './Header/NavBar';
import { clearPostCommunityInfo } from 'src/slices/community';
import { settingSelector } from 'src/slices/settings';

const useMainLayout = [
  ROUTES_AUTHEN.LOGIN,
  ROUTES_AUTHEN.FORGOT_PASSWORD,
  ROUTES_AUTHEN.FORGOT_PASSWORD_OTP,
  ROUTES_AUTHEN.RESET_PASSWORD,
  ROUTES_AUTHEN.REGISTER,
  ROUTES_AUTHEN.REGISTRATION,
  ROUTES_AUTHEN.REGISTRATION_INFO,
  ROUTES_AUTHEN.REGISTER_INFO_CONFIRM,
  ROUTES_AUTHEN.PAYMENT_CREDIT_INFO,
  ROUTES_AUTHEN.PAYMENT_COMPLETE,
  ROUTES_AUTHEN.LOGIN_UNPROTECTED,
  ROUTES_AUTHEN.REGISTER_UNPROTECTED,
  ROUTES_AUTHEN.VIDEO_DETAIL,
  ROUTES_AUTHEN.MY_PAGE,
  ROUTES_AUTHEN.MY_PAGE_PAYMENT_ATM,
  ROUTES_AUTHEN.MY_PAGE_PAYMENT_ATM_FINISH,
  ROUTES_AUTHEN.MY_PAGE_PAYMENT_STORE,
  ROUTES_AUTHEN.MY_PAGE_PAYMENT_STORE_FINISH,
  ROUTES_AUTHEN.MY_PAGE_PAYMENT_BANKING,
  ROUTES_AUTHEN.MY_PAGE_PAYMENT_BANKING_FINISH,
  ROUTES_AUTHEN.EDIT_PROFILE,
  ROUTES_AUTHEN.UPDATE_PROFILE,
  ROUTES_AUTHEN.MAGAZINE_DETAIL,
  ROUTES_AUTHEN.DELETE_ACCOUNT,
  ROUTES_AUTHEN.CHANGE_EMAIL,
  ROUTES_AUTHEN.CHANGE_EMAIL_OTP,
  ROUTES_AUTHEN.UPDATE_PASSWORD,
  ROUTES_AUTHEN.PAYMENT_TYPE,
  ROUTES_AUTHEN.PAYMENT_ATM,
  ROUTES_AUTHEN.PAYMENT_ATM_FINISH,
  ROUTES_AUTHEN.CHOOSE_CREDIT,
  ROUTES_AUTHEN.CREDIT_INFO,
  ROUTES_AUTHEN.PAYMENT_FINISH,
  ROUTES_AUTHEN.PAYMENT_STORE,
  ROUTES_AUTHEN.PAYMENT_STORE_FINISH,
  ROUTES_AUTHEN.PAYMENT_BANKING,
  ROUTES_AUTHEN.PAYMENT_BANKING_FINISH,
  ROUTES_AUTHEN.COMMUNITY_BOARD,
  ROUTES_AUTHEN.COMMUNITY_ON_BOARD,
  ROUTES_AUTHEN.COMMUNITY_POST,
  ROUTES_AUTHEN.POST_COMMUNITY,
  ROUTES_AUTHEN.COMMUNITY_POST_CONFIRM,
  ROUTES_AUTHEN.MEMBER_BENEFITS
];

function Layout({ children, profile }) {
  const location = useLocation();
  const dispatch = useDispatch();

  const setting = useSelector(settingSelector);

  const [openNavBarMobile, setOpenNavBarMobile] = useState(false);
  const [scrollTop, setScrollTop] = useState(
    window.location.pathname === ROUTES.HOMEVIEW ? false : true
  );

  useEffect(() => {
    if (window.innerWidth <= 900) {
      setScrollTop(true);
    } else if (window.location.pathname !== ROUTES.HOMEVIEW) {
      setScrollTop(true);
    } else {
      setScrollTop(false);
    }

    window.addEventListener('scroll', handleScroll);
  }, [window.location.pathname]);

  const handleScroll = () => {
    if (setting?.settings?.talent_settings?.menu_type === 1) {
      if (window.innerWidth <= 900) {
        setScrollTop(true);
      }
    } else if (setting?.settings?.talent_settings?.menu_type === 2) {
      if (
        window.scrollY > 102 &&
        window.location.pathname === ROUTES.HOMEVIEW
      ) {
        setScrollTop(true);
      } else if (window.location.pathname !== ROUTES.HOMEVIEW) {
        setScrollTop(true);
      } else {
        setScrollTop(false);
      }
    }
  };

  useEffect(() => {
    if (
      location.pathname.split('/')[1] !== 'community-post-confirm' &&
      location.pathname.split('/')[1] !== 'post-community'
    ) {
      dispatch(clearPostCommunityInfo());
    }
  }, [location.pathname]);

  return (
    <div>
      {setting?.settings?.talent_settings?.menu_type === 2 ? (
        ''
      ) : (
        <Header
          style={{
            display: scrollTop ? 'block' : 'none'
          }}
          onOpenNavBarMobile={() => setOpenNavBarMobile(true)}
          setting={setting}
          profile={profile}
        />
      )}
      <NavBar
        onMobileClose={() => setOpenNavBarMobile(false)}
        openMobile={openNavBarMobile}
        setting={setting}
        profile={profile}
      />
      {useMainLayout.includes(location.pathname) ? (
        <MainLayout setting={setting}>{children}</MainLayout>
      ) : (
        <SubLayout setting={setting}>{children}</SubLayout>
      )}
      <Footer setting={setting.settings} />
      {setting?.settings?.talent_settings?.menu_type === 2 ? (
        <Header
          style={{
            display: scrollTop ? 'block' : 'none'
          }}
          onOpenNavBarMobile={() => setOpenNavBarMobile(true)}
          setting={setting}
          profile={profile}
        />
      ) : (
        ''
      )}
    </div>
  );
}

export default Layout;
