import moment from 'moment';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import { Box, makeStyles, Button } from '@material-ui/core';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(4)
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1)
  }
}));

const useStyles = makeStyles((theme) => ({
  wrapDialog: {
    padding: '32px 0 0',
    display: 'flex',
    [theme.breakpoints.down(700)]: {
      flexDirection: 'column'
    }
  },
  image: {
    width: '100%',
    backgroundColor: '#B1B1B1',
    marginBottom: 20,
    objectFit: 'cover',
    maxHeight: '32rem',
    [theme.breakpoints.down(700)]: {
      width: '100%',
      height: 175
    }
  },
  dialogLeft: {
    marginRight: '2.5rem',
    width: '50%',
    [theme.breakpoints.down(700)]: {
      padding: '0 2.5rem',
      marginBottom: '1.5rem',
      marginRight: 0,
      width: '100%'
    }
  },
  button: {
    backgroundColor: 'black',
    color: '#fff !important',
    borderRadius: 0,
    padding: '10px 0',
    width: '100%',
    '&:hover': {
      backgroundColor: '#1175ae'
    },
    [theme.breakpoints.down(700)]: {
      padding: '8px 0',
      width: '100%'
    }
  },
  iconClose: {
    '& svg': {
      fontSize: '2rem'
    }
  },
  title: {
    fontSize: '1.8rem !important'
  },
  htmlCode: {
    '& *': {
      padding: 'revert'
    }
  }
}));

export default function DiscographyDialog({ setShowDialog, discographyInfo }) {
  const classes = useStyles();

  const handleClose = () => {
    setShowDialog(false);
  };

  return (
    <div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={true}
        fullWidth
        maxWidth="md"
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        ></BootstrapDialogTitle>
        <DialogContent>
          <Box className={clsx(classes.wrapDialog)}>
            <Box className={clsx(classes.dialogLeft)}>
              <Box
                className={clsx(classes.image)}
                component="img"
                src={discographyInfo?.img}
              />
              {discographyInfo?.url && (
                <Button
                  onClick={() => window.open(discographyInfo?.url, '_blank')}
                  className={clsx(classes.button)}
                >
                  商品ページへ
                </Button>
              )}
            </Box>
            <Box>
              <Typography gutterBottom>
                {moment(discographyInfo?.release_date).format('YYYY/MM/DD')}
              </Typography>
              <Typography gutterBottom className={clsx(classes.title)}>
                {discographyInfo?.title}
              </Typography>
              <span
                className={clsx(classes.htmlCode)}
                dangerouslySetInnerHTML={{ __html: discographyInfo?.body }}
              />
            </Box>
          </Box>
        </DialogContent>
      </BootstrapDialog>
    </div>
  );
}

DiscographyDialog.propTypes = {
  setShowDialog: PropTypes.func.isRequired,
  discographyInfo: PropTypes.object.isRequired
};

function BootstrapDialogTitle(props) {
  const classes = useStyles();
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 1 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          style={{ backgroundColor: 'transparent' }}
          aria-label="close"
          onClick={onClose}
          size="large"
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: '#000'
          }}
          className={clsx(classes.iconClose)}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired
};
