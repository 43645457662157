import React from 'react';
import { checkNameTalent } from 'src/utils/auth';

import LogoKYD from 'src/assets/images/logo_kyd_white.png';
import LogoPMY from 'src/assets/images/logo_pmy_white.png';

function Logo(props) {
  const domain = window.location.host;
  const { logo } = checkNameTalent();

  return (
    <>
      {domain === process.env.REACT_APP_TALENT_KIM_YOUNG_DEA_DOMAIN &&
      window.innerWidth <= 700 ? (
        <img alt="Logo" src={LogoKYD} {...props} width="124" />
      ) : domain === process.env.REACT_APP_TALENT_PARK_MIN_YOUNG_DOMAIN &&
        window.innerWidth > 700 ? (
        <img alt="Logo" src={LogoPMY} {...props} width="320" />
      ) : domain === 'www.' + process.env.REACT_APP_TALENT_ZO_IN_SUNG_DOMAIN ||
        domain === process.env.REACT_APP_TALENT_ZO_IN_SUNG_DOMAIN ? (
        <img alt="Logo" src={logo} {...props} width="55" />
      ) : domain === process.env.REACT_APP_TALENT_SONG_JOON_KI_DOMAIN ? (
        <img alt="Logo" src={logo} {...props} width="164" />
      ) : domain ===
        'www' + process.env.REACT_APP_TALENT_LEE_JONG_SUK_DOMAIN ? (
        <img alt="Logo" src={logo} {...props} width="124" />
      ) : domain === process.env.REACT_APP_TALENT_KIM_JI_WON_DOMAIN ? (
        <img
          alt="Logo"
          style={{ marginTop: 5 }}
          src={logo}
          {...props}
          width="140"
        />
      ) : (
        <img alt="Logo" src={logo} {...props} width="124" />
      )}
    </>
  );
}

export default Logo;
