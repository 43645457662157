import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'src/utils/API';

export const getNoticesAll = createAsyncThunk(
  'notices',
  async ({ page, perPage }, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `/notices?page=${page}&perPage=${perPage}`
      );

      if (response.status === 200) {
        return response?.data?.data;
      }
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getNoticesByTagId = createAsyncThunk(
  'notices/tag-id',
  async ({ page, perPage, tagId }, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `/get-content-by-tag-id/notices/${tagId}?page=${page}&perPage=${perPage}`
      );

      if (response.status === 200) {
        return response.data.data;
      }
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getTagsNotice = createAsyncThunk(
  'notices/tags',
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.get('/tags?type=notices');

      if (response.status === 200) {
        return response.data.data.data;
      }

      return rejectWithValue(response.data);
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getNoticeDetail = createAsyncThunk(
  'notice/detail',
  async ({ id }, { rejectWithValue }) => {
    try {
      const response = await axios.get('/notices/' + id);

      if (response.status === 200) {
        return response?.data;
      }
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
