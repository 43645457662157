import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'react-perfect-scrollbar/dist/css/styles.css';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import 'react-quill/dist/quill.snow.css';
import 'nprogress/nprogress.css';
import 'src/assets/css/prism.css';
import 'src/assets/css/styles.css';
import { enableES5 } from 'immer';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { SettingsProvider } from 'src/context/SettingsContext';
import store from 'src/store';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import { restoreSettings } from 'src/utils/settings';
import App from 'src/App';

enableES5();

const settings = restoreSettings();
const persistor = persistStore(store);

ReactDOM.render(
  <Provider store={store}>
    <SettingsProvider settings={settings}>
      <PersistGate loading={null} persistor={persistor}>
        <App />
      </PersistGate>
    </SettingsProvider>
  </Provider>,
  document.getElementById('root')
);
