import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Container,
  Grid,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/'
import ReactPlayer from 'react-player';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingBottom: 50,
    [theme.breakpoints.down('md')]: {
      paddingTop: 60,
      paddingBottom: 60
    }
  },
  container: {
    maxWidth: 1040,
    padding: theme.spacing(0, 8),
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(0, 2)
    }
  },
  divider: {
    margin: '10px auto',
    width: '25px'
  },
  wrapIframe: {
    '& > div': {
      maxWidth: '100%',
      margin: 'auto',
      '@media(max-width: 960px)': {
        height: '450px !important'
      },
      '@media(max-width: 600px)': {
        height: '400px !important'
      },
      '@media(max-width: 400px)': {
        height: '250px !important'
      }
    }
  }
}));

function Youtube({ className, url }) {
  const classes = useStyles();

  return (
    <div
      className={clsx(classes.root, className)}
    >
      <Container className={classes.container}>
        <Grid
          container
          spacing={3}
        >
          <Grid
            item
            xs={12}
            md={12}
          >
            <Box mt={3} textAlign="center" className={classes.wrapIframe}>
              <ReactPlayer
                url={url}
                width="1040px"
                height="600px"
                playing={false}
                controls={true}
                className={classes.iframe}
                config={{
                  youtube:{
                    playerVars: { modestbranding: 1, controls: 1 },
                  }
                }}
              />
            </Box>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}

Youtube.propTypes = {
  className: PropTypes.string,
  noticeList: PropTypes.array
};

export default Youtube;
