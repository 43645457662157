import { forwardRef } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import Dialog from '@mui/material/Dialog';
import { Box, makeStyles, Typography, Button } from '@material-ui/core';
import Slide from '@mui/material/Slide';
import { saveAs } from 'file-saver';

import CustomIconMember from 'src/components/CustomIconMember';
import CustomIconBirthday from 'src/components/CustomIconBirthday';

const useStyles = makeStyles((theme) => ({
  dialogContainer: {
    padding: '1.5rem 0',
    [theme.breakpoints.down(700)]: {
      padding: '1.5rem 0'
    }
  },
  title: {
    margin: '1rem 0',
    padding: '0 3rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& h5': {
      fontWeight: '600',
      color: 'white',
      textAlign: 'center'
    },
    [theme.breakpoints.down(700)]: {
      padding: '0 2rem'
    }
  },
  wrapButton: {
    display: 'flex',
    justifyContent: 'center',
    margin: '1rem 0 0',
    [theme.breakpoints.down(700)]: {
      flexDirection: 'column',
      margin: '2rem 0'
    }
  },
  button: {
    padding: '12px 0 !important',
    color: '#fff !important',
    borderRadius: 0,
    width: '100%',
    maxWidth: 350,
    margin: 'auto',
    backgroundColor: 'black !important',
    '&:hover': {
      backgroundColor: '#1175ae'
    },
    [theme.breakpoints.down(700)]: {
      padding: '12px 0 !important'
    }
  },
  dialogCustom: {
    backgroundColor: 'transparent'
  },
  iconBirthday: {
    marginRight: '1.5rem'
  },
  imageBirthday: {
    maxWidth: 1040,
    maxHeight: '30rem',
    cursor: 'pointer',
    [theme.breakpoints.down(700)]: {
      width: '100%',
      maxHeight: '20rem',
      objectFit: 'contain'
    }
  },
  buttonBack: {
    backgroundColor: '#A7A7A7 !important',
    color: '#fff !important',
    width: '100%',
    maxWidth: 350,
    display: 'flex',
    margin: 'auto'
  }
}));

export default function BannerDialog({ open, onClose, image, type }) {
  const classes = useStyles();

  const handleClose = () => {
    onClose(false);
  };

  return (
    <Dialog
      TransitionComponent={Transition}
      maxWidth="md"
      onClose={handleClose}
      open={open}
      aria-describedby="alert-dialog-slide-description"
      className={clsx(classes.dialogCustom)}
      PaperProps={{
        style: {
          backgroundColor: 'transparent',
          boxShadow: 'none'
        }
      }}
    >
      <Box className={clsx(classes.dialogContainer)}>
        {/* <Box className={clsx(classes.title)}>
          <Box className={clsx(classes.iconBirthday)}>
            {type.icon === 'birthday' ? (
              <CustomIconBirthday color={type.colorIcon} />
            ) : (
              <CustomIconMember color={type.colorIcon} />
            )}
          </Box>
          <Typography variant="h5" style={{ color: type.colorText }}>
            {type.title}
          </Typography>
        </Box> */}
        <Box display="flex" justifyContent="center">
          <Box
            component="img"
            className={clsx(classes.imageBirthday)}
            src={image}
            alt="FRAU INTERNATIONAL"
          />
        </Box>

        <Box className={clsx(classes.wrapButton)}>
          <Button
            onClick={() => {
              const fileName = image.substring(image.lastIndexOf('/') + 1);
              let isSafari = /^((?!chrome|android).)*safari/i.test(
                navigator.userAgent
              );
              if (isSafari) {
                window.open(image, '_blank');
              } else {
                saveAs(image, fileName);
              }
            }}
            className={clsx(classes.button)}
          >
            画像ダウンロード
          </Button>
        </Box>
        <Box mt={2}>
          <Button
            onClick={() => {
              handleClose();
            }}
            className={clsx(classes.button, classes.buttonBack)}
          >
            閉じる
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
}

BannerDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  type: PropTypes.object.isRequired,
  image: PropTypes.string.isRequired
};

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
