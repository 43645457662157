import _ from 'lodash';
import clsx from 'clsx';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import {
  Box,
  Container,
  makeStyles,
  Typography,
  Button,
  TextField,
  styled
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';

import { authSelector } from 'src/slices/auth';
import LoaderLayer from 'src/components/LoaderLayer';
import { updateProfile } from 'src/services/authService';
import { getLoginToken } from 'src/utils/auth';
import NotiDialog from 'src/components/NotiDialog';

const useStyles = makeStyles((theme) => ({
  root: {
    justifyContent: 'center',
    display: 'flex',
    flexDirection: 'column',
    paddingTop: '5rem',
    [theme.breakpoints.down(700)]: {
      padding: '3rem 0 2rem'
    }
  },
  fieldError: {
    '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
      borderColor: 'red'
    },
    '&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
      borderColor: 'red'
    },
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: 'red'
    }
  },
  borderBottom: {
    borderBottom: '1px solid #919090'
  },
  itemCenter: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  wrapTitleSP: {
    margin: '2rem 0 6rem',
    [theme.breakpoints.down(700)]: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: '2rem'
    }
  },
  pageTitle: {
    color: '#000000',
    fontSize: '1.4rem',
    fontWeight: '600',
    [theme.breakpoints.down(700)]: {
      fontSize: '1rem',
      padding: '0 1.5rem 0 0'
    }
  },
  wrapButton: {
    display: 'flex',
    justifyContent: 'center',
    margin: '5rem 0',
    [theme.breakpoints.down(700)]: {
      flexDirection: 'column-reverse',
      margin: '2rem 0'
    }
  },
  buttonBack: {
    backgroundColor: '#A7A7A7',
    color: 'white !important',
    borderRadius: 0,
    padding: '11px 100px',
    marginRight: '2rem',
    '&:hover': {
      backgroundColor: '#5b5b5b'
    },
    [theme.breakpoints.down(700)]: {
      padding: '12px 0',
      width: '100%',
      marginBottom: '1rem'
    }
  },
  buttonEdit: {
    backgroundColor: 'black',
    color: 'white !important',
    borderRadius: 0,
    padding: '11px 130px',
    '&:hover': {
      backgroundColor: '#1175ae'
    },
    [theme.breakpoints.down(700)]: {
      padding: '12px 0',
      width: '100%',
      marginBottom: '1rem'
    }
  },
  wrapTextConfirm: {
    margin: '3rem 0 3rem',
    paddingBottom: '0.8rem'
  },
  textConfirm: {
    fontWeight: '600'
  },
  containerProfile: {
    backgroundColor: '#ffffff',
    padding: '2rem',
    [theme.breakpoints.down(700)]: {
      padding: '0.5rem'
    }
  },
  containerProfileDetail: {
    display: 'flex'
  },
  profileLeft: {
    width: '20%',
    backgroundColor: '#C5C5C5',
    textAlign: 'center',
    borderRight: '1px solid #707070',
    '& p': {
      padding: '0.5rem 0',
      height: '100%',
      [theme.breakpoints.down(700)]: {
        fontSize: '0.9rem'
      }
    },
    [theme.breakpoints.down(700)]: {
      width: '37%'
    }
  },
  profileRight: {
    flexGrow: 1,
    width: '80%',
    '& p': {
      padding: '0.5rem 0 0 1rem',
      height: '100%',
      [theme.breakpoints.down(700)]: {
        padding: '0.5rem 0 0.5rem 0.8rem',
        fontSize: '0.9rem'
      }
    },
    [theme.breakpoints.down(700)]: {
      width: '63%'
    }
  },
  borderDashed: {
    borderBottom: '1px dashed #AFAFAF'
  },
  profileRightContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  }
}));

const CssTextField = styled(TextField)({
  marginTop: 4,
  paddingLeft: '0.5rem',
  '& input': {
    color: 'black',
    padding: '6.5px 14px',
    fontSize: window.innerWidth <= 767 ? '0.6rem' : '1rem',
    letterSpacing: window.innerWidth <= 767 ? 1 : -6
  },
  '& fieldset': {
    borderRadius: 0
  },
  '& label.Mui-focused': {
    color: 'black'
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: '#707070'
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#707070'
    },
    '&:hover fieldset': {
      borderColor: '#707070'
    },
    '&.Mui-focused fieldset': {
      borderColor: '#707070'
    }
  }
});

function UpdatePassword({ setting }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { state } = useLocation();
  const auth = useSelector(authSelector);

  const [openDialog, setOpenDialog] = useState(false);

  const formik = useFormik({
    initialValues: {
      old_password: '',
      new_password: '',
      new_password_confirmation: ''
    },
    validationSchema: Yup.object().shape({
      old_password: Yup.string().required('必須'),
      new_password: Yup.string()
        .min(6, '半角英数字6文字以上')
        .max(45, '半角英数字45文字以内')
        .required('必須'),
      new_password_confirmation: Yup.string()
        .oneOf([Yup.ref('new_password')], 'パスワードが一致しません')
        .required('必須')
    }),
    onSubmit: async (values) => {
      const { payload } = await dispatch(updateProfile(values));

      if (payload?.msg === 'Success') {
        setOpenDialog(true);
      } else {
        alert('現在のパスワードが間違っています');
      }
    }
  });

  return (
    <div className={clsx(classes.root)}>
      {getLoginToken() && (
        <Container maxWidth="md">
          <Box className={clsx(classes.wrapTitleSP)}>
            <Typography className={clsx(classes.pageTitle)}>
              パスワード変更する
            </Typography>
          </Box>

          <form id="delete" onSubmit={formik.handleSubmit}>
            <Box className={clsx(classes.containerProfile)}>
              <Box>
                <Box className={clsx(classes.containerProfileDetail)}>
                  <Box
                    className={clsx(classes.profileLeft, classes.borderDashed)}
                  >
                    <Typography>現在のパスワード</Typography>
                  </Box>

                  <Box
                    className={clsx(classes.profileRight, classes.borderDashed)}
                  >
                    <CssTextField
                      name="old_password"
                      value={formik.values.old_password}
                      onChange={formik.handleChange}
                      error={Boolean(
                        formik.touched.old_password &&
                          formik.errors.old_password
                      )}
                      className={clsx(
                        Boolean(formik.errors.old_password) &&
                          classes.fieldError
                      )}
                      variant="outlined"
                      size="small"
                      type="password"
                      fullWidth
                    />
                    {formik.errors.old_password && (
                      <Typography style={{ color: 'red', fontSize: '0.8rem' }}>
                        {formik.errors.old_password}
                      </Typography>
                    )}
                  </Box>
                </Box>

                <Box className={clsx(classes.containerProfileDetail)}>
                  <Box
                    className={clsx(classes.profileLeft, classes.borderDashed)}
                  >
                    <Typography>新しいパスワード</Typography>
                  </Box>

                  <Box
                    className={clsx(classes.profileRight, classes.borderDashed)}
                  >
                    <CssTextField
                      name="new_password"
                      value={formik.values.new_password}
                      onChange={formik.handleChange}
                      error={Boolean(
                        formik.touched.new_password &&
                          formik.errors.new_password
                      )}
                      className={clsx(
                        Boolean(formik.errors.new_password) &&
                          classes.fieldError
                      )}
                      variant="outlined"
                      size="small"
                      type="password"
                      fullWidth
                    />
                    {formik.errors.new_password && (
                      <Typography style={{ color: 'red', fontSize: '0.8rem' }}>
                        {formik.errors.new_password}
                      </Typography>
                    )}
                  </Box>
                </Box>

                <Box className={clsx(classes.containerProfileDetail)}>
                  <Box className={clsx(classes.profileLeft)}>
                    <Typography>新しいパスワード 確認</Typography>
                  </Box>

                  <Box className={clsx(classes.profileRight)}>
                    <CssTextField
                      name="new_password_confirmation"
                      value={formik.values.new_password_confirmation}
                      onChange={formik.handleChange}
                      error={Boolean(
                        formik.touched.new_password_confirmation &&
                          formik.errors.new_password_confirmation
                      )}
                      className={clsx(
                        Boolean(formik.errors.new_password_confirmation) &&
                          classes.fieldError
                      )}
                      variant="outlined"
                      size="small"
                      type="password"
                      fullWidth
                    />
                    {formik.errors.new_password_confirmation && (
                      <Typography style={{ color: 'red', fontSize: '0.8rem' }}>
                        {formik.errors.new_password_confirmation}
                      </Typography>
                    )}
                  </Box>
                </Box>
              </Box>
            </Box>

            <Box className={clsx(classes.wrapButton)}>
              <Button
                onClick={() => navigate(-1)}
                className={clsx(classes.buttonBack)}
              >
                マイページに戻る
              </Button>
              <Button type="submit" className={clsx(classes.buttonEdit)}>
                変更する
              </Button>
            </Box>
            {openDialog && (
              <NotiDialog
                open={openDialog}
                path={state?.pathBack}
                message={'パスワードの変更が完了しました'}
                onClose={setOpenDialog}
                buttonName={'マイページに戻る'}
              />
            )}
          </form>
        </Container>
      )}

      <LoaderLayer loading={auth?.isFetching} />
    </div>
  );
}

export default UpdatePassword;
