import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles, Container, Grid, Box } from '@material-ui/core';
import 'react-alice-carousel/lib/alice-carousel.css';
import { TwitterTimelineEmbed } from 'react-twitter-embed';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 0
  },
  container: {
    maxWidth: '100%',
    padding: 0
  },
  gridContainer: {
    padding: 0,
    '& > div': {
      padding: '0px !important'
    }
  },
  divider: {
    margin: '10px auto',
    width: '25px'
  },
  title: {
    '&:after': {
      content: '""',
      display: 'block',
      margin: '0 auto',
      width: '25px',
      paddingTop: '10px',
      borderBottom: '1px solid #277F45'
    }
  },
  twitterEmbed: {
    '& > div > iframe': {
      maxWidth: '700px !important'
    },
    '& > div > div': {
      margin: 'auto'
    },
    '& > div > div > iframe': {
      maxWidth: '100% !important'
    }
  }
}));

function Twitter({ className }) {
  const classes = useStyles();
  const domain = window.location.host;
  var twitterID = '';
  switch (domain) {
    case process.env.REACT_APP_TALENT_ZO_IN_SUNG_DOMAIN:
      twitterID = process.env.REACT_APP_ZIS_TWITTER;
      break;
    case 'www.' + process.env.REACT_APP_TALENT_ZO_IN_SUNG_DOMAIN:
      twitterID = process.env.REACT_APP_ZIS_TWITTER;
      break;
    case process.env.REACT_APP_TALENT_KIM_NAN_GIL_DOMAIN:
      twitterID = process.env.REACT_APP_KNG_TWITTER;
      break;
    case process.env.REACT_APP_TALENT_LEE_JONG_SUK_DOMAIN:
      twitterID = process.env.REACT_APP_LJS_TWITTER;
      break;
    case 'www.' + process.env.REACT_APP_TALENT_LEE_JONG_SUK_DOMAIN:
      twitterID = process.env.REACT_APP_LJS_TWITTER;
      break;
    case process.env.REACT_APP_TALENT_LEE_SEUNG_GI_DOMAIN:
      twitterID = process.env.REACT_APP_LSG_TWITTER;
      break;
    case process.env.REACT_APP_TALENT_SONG_JOON_KI_DOMAIN:
      twitterID = process.env.REACT_APP_SJK_TWITTER;
      break;
    case process.env.REACT_APP_TALENT_KIM_YOUNG_DEA_DOMAIN:
      twitterID = process.env.REACT_APP_KYD_TWITTER;
      break;
    case process.env.REACT_APP_TALENT_YOO_SEUNG_HO_DOMAIN:
      twitterID = process.env.REACT_APP_YSH_TWITTER;
      break;
    case process.env.REACT_APP_TALENT_LEE_MIN_KI_DOMAIN:
      twitterID = process.env.REACT_APP_LMK_TWITTER;
      break;
    case process.env.REACT_APP_TALENT_JANG_KEUN_SUK_DOMAIN:
      twitterID = process.env.REACT_APP_JKS_TWITTER;
      break;
    default:
      twitterID = process.env.REACT_APP_KYD_TWITTER;
      break;
  }

  return (
    <div className={clsx(classes.root, className)}>
      <Container className={classes.container}>
        <Grid container spacing={3} className={classes.gridContainer}>
          <Grid item xs={12} md={12}>
            <Box textAlign="center" className={classes.wrapCarousel}>
              <div className={classes.twitterEmbed}>
                {twitterID && (
                  <TwitterTimelineEmbed
                    screenName={twitterID.toString()}
                    options={{ height: 600, width: 500 }}
                  />
                )}
              </div>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}

Twitter.propTypes = {
  className: PropTypes.string,
  items: PropTypes.array,
  isPause: PropTypes.bool
};

export default Twitter;
