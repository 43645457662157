import _ from 'lodash';
import clsx from 'clsx';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import React, { useEffect, useRef, useState } from 'react';
import {
  Box,
  Container,
  Typography,
  makeStyles,
  Button
} from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';

import { authSelector } from 'src/slices/auth';
import { settingSelector } from 'src/slices/settings';
import { registerUser } from 'src/services/authService';
import PaymentDialog from 'src/components/PaymentDialog';
import { REGISTER_TOKEN, ROUTES_AUTHEN } from 'src/constants';

const useStyles = makeStyles((theme) => ({
  root: {
    justifyContent: 'center',
    display: 'flex',
    flexDirection: 'column',
    padding: '100px 5px 0'
  },
  registerBorderBottom: {
    borderBottom: '1px solid #919090'
  },
  wrapTitle: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    [theme.breakpoints.down(700)]: {
      flexDirection: 'column'
    }
  },
  registerTitle: {
    fontWeight: 'bold',
    fontSize: 22,
    marginBottom: 5,
    [theme.breakpoints.down('700')]: {
      fontSize: 18
    }
  },
  registerTitleText: {
    fontWeight: 'bold',
    fontSize: 20,
    [theme.breakpoints.down(700)]: {
      fontSize: '1rem'
    }
  },
  wrapTitleRight: {
    display: 'flex',
    flexDirection: 'row',
    '& p': {
      fontSize: 16,
      opacity: 0.3
    },
    [theme.breakpoints.down(700)]: {
      '& p': {
        fontSize: '0.8rem',
        marginTop: 10
      }
    }
  },
  registrationTitleActive: {
    color: '#000000',
    borderBottom: '2px solid #5D7BAB',
    opacity: '1 !important',
    marginRight: 20,
    [theme.breakpoints.down(700)]: {
      marginRight: 10
    }
  },
  titleRightText: {
    marginRight: 20,
    [theme.breakpoints.down(700)]: {
      marginRight: 10
    }
  },
  registerSubTitle: {
    margin: '70px 0 40px',
    fontSize: '0.9rem'
  },
  registerDescription: {
    marginBottom: 5,
    fontSize: '0.9rem'
  },
  registerButtonLeft: {
    backgroundColor: '#989898',
    color: 'white !important',
    borderRadius: 0,
    padding: '12px 130px',
    fontSize: 18,
    '&:hover': {
      backgroundColor: '#1175ae'
    },
    [theme.breakpoints.down(700)]: {
      fontSize: 16,
      padding: '10px 108px'
    }
  },
  registerButtonRight: {
    borderRadius: 0,
    color: 'white !important',
    padding: '12px 115px',
    fontSize: 18,
    [theme.breakpoints.down(700)]: {
      fontSize: 16,
      padding: '10px 60px'
    }
  },
  displayFlex: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down(700)]: {
      flexDirection: 'column',
      alignItems: 'start',
      display: 'block'
    }
  },
  displayFlexSP: {
    [theme.breakpoints.down(700)]: {
      display: 'flex',
      flexDirection: 'row'
    }
  },
  labelContainer: {
    width: '25%',
    justifyContent: 'flex-end',
    [theme.breakpoints.down(700)]: {
      width: '100%',
      justifyContent: 'flex-start',
      marginBottom: 14
    }
  },
  label: {
    fontWeight: 'bold',
    marginRight: 70
  },
  labelAddress: {
    fontWeight: 'bold',
    marginRight: 70,
    [theme.breakpoints.down(700)]: {
      fontWeight: 'normal',
      fontSize: 11
    }
  },
  wrapButton: {
    display: 'flex',
    justifyContent: 'center',
    fontSize: 18,
    [theme.breakpoints.down(700)]: {
      flexDirection: 'column',
      alignItems: 'center'
    }
  },
  buttonBack: {
    marginBottom: 0,
    color: '#fff !important',
    borderRadius: 0,
    marginRight: '1rem',
    fontSize: 18,
    [theme.breakpoints.down(700)]: {
      marginBottom: '1rem',
      marginRight: 0
    }
  }
}));

function RegistrationInfoConfirm() {
  const classes = useStyles();
  const elementRef = useRef();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { registerInfo } = useSelector(authSelector);
  const settings = useSelector(settingSelector);

  const [hover, setHover] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [titleDialog, setTitleDialog] = useState('');
  const [disabledButton, setDisableButton] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      elementRef?.current?.scrollIntoView({
        behavior: 'smooth'
      });
    }, 700);

    if (_.isEmpty(registerInfo)) {
      navigate(ROUTES_AUTHEN.PAYMENT_TYPE);
    }
  }, []);

  const paymentType = () => {
    switch (registerInfo?.payment_type) {
      case '1':
        return 'クレジットカード';
      case '2':
        return 'ATM決済';
      case '3':
        return 'コンビニ決済';
      case '4':
        return '銀行ネット決済';
      default:
        break;
    }
  };

  const registerUserConfirm = async () => {
    setDisableButton(true);
    const data = {
      email: registerInfo?.email,
      password: registerInfo?.password,
      password_confirmation: registerInfo?.password_confirmation,
      first_name: registerInfo?.first_name,
      family_name: registerInfo?.family_name,
      first_furigana: registerInfo?.first_furigana,
      family_furigana: registerInfo?.family_furigana,
      birthday: registerInfo?.birthday,
      post_code: registerInfo?.post_code,
      address1: registerInfo?.address1,
      address2: registerInfo?.address2,
      address3: registerInfo?.address3,
      nickname: registerInfo?.nickname,
      payment_type: parseInt(registerInfo?.payment_type),
      tel: registerInfo?.tel1 + registerInfo?.tel2 + registerInfo?.tel3,
      gender: registerInfo?.gender,
      code: localStorage.getItem(REGISTER_TOKEN)
    };

    const { payload } = await dispatch(registerUser(data));

    if (payload?.msg === 'Success') {
      switch (registerInfo?.payment_type) {
        case '1':
          return navigate(ROUTES_AUTHEN.PAYMENT_CREDIT_INFO);
        case '2':
          return navigate(ROUTES_AUTHEN.PAYMENT_ATM);
        case '3':
          return navigate(ROUTES_AUTHEN.PAYMENT_STORE);
        case '4':
          return navigate(ROUTES_AUTHEN.PAYMENT_BANKING);
        default:
          break;
      }
    } else {
      setDisableButton(false);
      if (payload?.errors?.nickname) {
        setTitleDialog(payload?.errors?.nickname[0]);
      } else {
        setTitleDialog(payload?.msg);
      }
      setOpenDialog(true);
    }
  };

  const renderPassword = (password) => {
    return '※'.repeat(password?.length);
  };

  return (
    <div ref={elementRef} className={classes.root}>
      <Container maxWidth="md">
        <Box mb={8} paddingBottom={window.innerWidth <= 700 ? 4 : 8}>
          <Box className={classes.wrapTitle}>
            <Typography className={classes.registerTitleText}>
              入力内容の確認
            </Typography>
            <Box className={classes.wrapTitleRight}>
              <Typography className={classes.titleRightText}>
                会員情報入力
              </Typography>
              <Typography className={clsx(classes.registrationTitleActive)}>
                入力内容確認
              </Typography>
              <Typography>会員登録決済</Typography>
            </Box>
          </Box>
          <Typography className={classes.registerSubTitle}>
            ご入力内容に間違いがないかご確認ください。
            <br />
            修正が必要な場合は下にある「戻る」ボタンで前画面にお戻りください。
            <br />
            <br />
            尚、こちらにご登録されたお客様情報のうちニックネームと決済情報を除く項目は
            <br />
            FRAU IDとしても同時に登録されますので、予めご了承ください。
            <br />
            <br />
            ※FRAU IDとは
            <br />
            FRAU
            IDとは株式会社フラウ・インターナショナル（以下、弊社）が運営する
            <br />
            ファンクラブの一部で導入しているシステムです。すでに弊社が運営するファンクラブに
            <br />
            ご登録いただいている方は、同じメールアドレスとパスワードでログインしていただくことで、
            <br />
            決済情報の登録のみで簡単に新たなファンクラブにご入会いただけます。
            <br />
            ※弊社が運営しているファンクラブは
            <a
              href="https://www.frau-inter.co.jp/business/fanclub/"
              target="_blank"
            >
              こちら
            </a>
          </Typography>
          <Box
            sx={{ justifyContent: 'center' }}
            mb={5}
            mt={6}
            display="flex"
            alignItems="center"
            className={clsx(classes.registerBorderBottom)}
          >
            <Typography className={clsx(classes.registerTitle)}>
              会員情報確認
            </Typography>
          </Box>

          <Box pb={5} className={classes.registerBorderBottom}>
            {/* First name */}
            <Box mb={3} className={clsx(classes.displayFlex)}>
              <Box display={'flex'} className={clsx(classes.labelContainer)}>
                <Typography className={clsx(classes.label)}>お名前</Typography>
              </Box>
              <Box display={'flex'}>
                <Box mr={10}>
                  <Typography>{registerInfo.family_name}</Typography>
                </Box>
                <Box>
                  <Typography>{registerInfo.first_name}</Typography>
                </Box>
              </Box>
            </Box>

            {/* Furigana name */}
            <Box mb={3} className={clsx(classes.displayFlex)}>
              <Box display={'flex'} className={clsx(classes.labelContainer)}>
                <Typography className={clsx(classes.label)}>
                  フリガナ
                </Typography>
              </Box>
              <Box display={'flex'} flexGrow={1}>
                <Box mr={10}>
                  <Typography>{registerInfo.family_furigana}</Typography>
                </Box>
                <Box>
                  <Typography>{registerInfo.first_furigana}</Typography>
                </Box>
              </Box>
            </Box>

            {/* Nickname */}
            <Box mb={3} className={clsx(classes.displayFlex)}>
              <Box display={'flex'} className={clsx(classes.labelContainer)}>
                <Typography className={clsx(classes.label)}>
                  ニックネーム
                </Typography>
              </Box>
              <Box display={'flex'} flexGrow={1}>
                <Box>
                  <Typography>{registerInfo?.nickname}</Typography>
                </Box>
              </Box>
            </Box>

            {/* Birthday */}
            <Box mb={3} className={clsx(classes.displayFlex)}>
              <Box display={'flex'} className={clsx(classes.labelContainer)}>
                <Typography className={clsx(classes.label)}>
                  生年月日
                </Typography>
              </Box>
              <Box display={'flex'} flexGrow={1}>
                <Box>
                  <Typography>
                    {moment(registerInfo.birthday, 'YYYY-MM-DD').format(
                      'YYYY年MM月DD日'
                    )}
                  </Typography>
                </Box>
              </Box>
            </Box>

            {/* Post_code */}
            <Box
              mb={window.innerWidth <= 700 ? -1 : 1}
              className={clsx(classes.displayFlex)}
            >
              <Box display={'flex'} className={clsx(classes.labelContainer)}>
                <Typography className={clsx(classes.label)}>
                  郵便番号／住所
                </Typography>
              </Box>
              <Box>
                <Typography className={clsx(classes.labelAddress)}>
                  郵便番号
                </Typography>
                <Typography>{registerInfo.post_code}</Typography>
              </Box>
            </Box>

            {/* Address */}
            <Box mb={3} className={clsx(classes.displayFlex)}>
              <Box display={'flex'} className={clsx(classes.labelContainer)}>
                <Typography className={clsx(classes.label)}></Typography>
              </Box>
              <Box>
                <Box mb={1}>
                  <Typography className={clsx(classes.labelAddress)}>
                    都道府県
                  </Typography>
                  <Typography>{registerInfo.address1}</Typography>
                </Box>

                <Box mb={1}>
                  <Typography className={clsx(classes.labelAddress)}>
                    市区町村
                  </Typography>
                  <Typography>{registerInfo.address2}</Typography>
                </Box>

                <Box>
                  <Typography className={clsx(classes.labelAddress)}>
                    番地・建物名
                  </Typography>
                  <Typography>{registerInfo?.address3}</Typography>
                </Box>
              </Box>
            </Box>

            {/* Phone number */}
            <Box mb={3} className={clsx(classes.displayFlex)}>
              <Box display={'flex'} className={clsx(classes.labelContainer)}>
                <Typography className={clsx(classes.label)}>
                  電話番号
                </Typography>
              </Box>
              <Box>
                <Typography>
                  {registerInfo.tel1} - {registerInfo.tel2} -{' '}
                  {registerInfo.tel3}
                </Typography>
              </Box>
            </Box>

            {/* Email */}
            <Box mb={3} className={clsx(classes.displayFlex)}>
              <Box display={'flex'} className={clsx(classes.labelContainer)}>
                <Typography className={clsx(classes.label)}>
                  メールアドレス
                </Typography>
              </Box>
              <Box>
                <Typography>{registerInfo?.email}</Typography>
              </Box>
            </Box>

            {/* Gender */}
            <Box mb={3} className={clsx(classes.displayFlex)}>
              <Box display={'flex'} className={clsx(classes.labelContainer)}>
                <Typography className={clsx(classes.label)}>性別</Typography>
              </Box>
              <Box>
                <Typography>
                  {registerInfo.gender === '2'
                    ? '女性'
                    : registerInfo.gender === '1'
                    ? '男性'
                    : 'その他'}
                </Typography>
              </Box>
            </Box>

            <Box mb={3} className={clsx(classes.displayFlex)}>
              <Box display={'flex'} className={clsx(classes.labelContainer)}>
                <Typography className={clsx(classes.label)}>
                  パスワード
                </Typography>
              </Box>
              <Box>
                <Typography>
                  {renderPassword(registerInfo?.password)}
                </Typography>
              </Box>
            </Box>

            {/* Payment */}
            <Box mb={3} className={clsx(classes.displayFlex)}>
              <Box display={'flex'} className={clsx(classes.labelContainer)}>
                <Typography className={clsx(classes.label)}>
                  支払い方法
                </Typography>
              </Box>
              <Box>
                <Typography>{paymentType()}</Typography>
              </Box>
            </Box>
          </Box>
          <Box mt={8} className={clsx(classes.wrapButton)}>
            <Box className={clsx(classes.buttonBack)}>
              <Button
                onClick={() => navigate(ROUTES_AUTHEN.REGISTRATION_INFO)}
                className={clsx(classes.registerButtonLeft)}
              >
                戻る
              </Button>
            </Box>
            <Box>
              <Button
                onClick={registerUserConfirm}
                onMouseOver={() => setHover(true)}
                onMouseOut={() => setHover(false)}
                disabled={disabledButton}
                style={{
                  backgroundColor: hover
                    ? settings?.settings?.theme_color1 + 'cc'
                    : settings?.settings?.theme_color1
                }}
                className={clsx(classes.registerButtonRight)}
              >
                登録する
              </Button>
            </Box>
          </Box>
        </Box>
        <PaymentDialog
          open={openDialog}
          onClose={setOpenDialog}
          title={titleDialog}
        />
      </Container>
    </div>
  );
}
export default RegistrationInfoConfirm;
