import clsx from 'clsx';
import moment from 'moment';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import {
  Box,
  Container,
  makeStyles,
  Typography,
  Grid
} from '@material-ui/core';
import PaginationButton from 'src/components/PaginationButton';
import iconZoomIn from 'src/assets/images/feather-zoom-in.svg';
import { ROUTES } from 'src/constants';
import { magazineSelector } from 'src/slices/magazine';
import { getMagazines } from 'src/services/magazine';
import LoaderLayer from 'src/components/LoaderLayer';
import { authSelector } from 'src/slices/auth';
import { getDomainTalent, getLoginToken } from 'src/utils/auth';
import CheckPaymentDialog from 'src/components/CheckPaymentDialog';

const useStyles = makeStyles((theme) => ({
  root: {
    justifyContent: 'center',
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down(600)]: {
      padding: '0 2px 0'
    }
  },
  container: {
    maxWidth: 1040,
    padding: theme.spacing(0, 8),
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(0, 2)
    }
  },
  wrapTitle: {
    display: 'flex',
    justifyContent: 'center',
    paddingBottom: '0.5rem',
    borderBottom: '1px solid #707070'
  },
  contentTitle: {
    color: '#000000',
    fontSize: '1.5rem',
    fontWeight: '600',
    [theme.breakpoints.down(600)]: {
      fontSize: '1rem',
      padding: '0 1.5rem'
    }
  },
  newContent: {
    margin: '4rem 0 3rem',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    [theme.breakpoints.between(600, 1023)]: {
      justifyContent: 'space-evenly'
    },
    [theme.breakpoints.down(600)]: {
      margin: '1rem 0 2rem',
      justifyContent: 'center'
    }
  },
  wrapPagination: {
    margin: '40px 0'
  },
  boxContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '4rem',
    [theme.breakpoints.down(600)]: {
      marginBottom: '2rem',
      marginTop: '2rem',
      width: '100%'
    }
  },
  boxContent: {
    height: 242,
    position: 'relative',
    cursor: 'pointer',
    [theme.breakpoints.down(600)]: {
      height: '100%'
    }
  },
  imageContainer: {
    width: '100%',
    height: 'inherit',
    objectFit: 'cover'
  },
  iconZoomIn: {
    position: 'absolute',
    right: '0.6rem',
    bottom: '0.6rem'
  },
  magazineContent: {
    marginTop: '1rem',
    width: '100%',
    [theme.breakpoints.down(600)]: {
      marginTop: '1.2rem'
    }
  },
  magazineDate: {
    fontWeight: '600'
  },
  magazineTitle: {
    margin: '0.5rem 0',
    fontWeight: 'bold',
    [theme.breakpoints.down(600)]: {
      margin: '0.2rem 0'
    }
  },
  htmlCode: {
    display: '-webkit-box',
    '-webkit-box-orient': 'vertical',
    '-webkit-line-clamp': 3,
    overflow: 'hidden',
    whiteSpace: 'pre-line',
    textAlign: 'start',
    '& *': {
      padding: 'revert'
    }
  }
}));

function MagazineView() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const magazine = useSelector(magazineSelector);
  const { profile } = useSelector(authSelector);
  const [searchParams, setSearchParams] = useSearchParams();
  const ncrp = parseInt(searchParams.get('page'));
  const [page, setPage] = useState(ncrp ? ncrp : 1);
  const [isPayment, setIsPayment] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);

  useEffect(() => {
    const { members } = profile;
    const domainTalent = getDomainTalent();

    const myProfile = _.find(
      members,
      (member) => member.talent.domain.split('//')[1] === domainTalent
    );

    if (
      myProfile?.member_type === 'lifetime' ||
      myProfile?.member_type === 'existing_fan'
    ) {
      setIsPayment(true);
    } else if (
      myProfile?.role === 'talent' ||
      myProfile?.role === 'staff_view' ||
      myProfile?.role === 'staff_edit'
    ) {
      setIsPayment(true);
    } else if (
      myProfile?.end_datetime >= moment(new Date()).format('YYYY-MM-DD') &&
      myProfile?.role === 'fan'
    ) {
      setIsPayment(true);
    }
  }, []);

  useEffect(() => {
    dispatch(getMagazines({ page: page, perPage: 6 }));
  }, [page]);

  useEffect(() => {
    const searchPage = parseInt(window.location.search.split('=')[1]);

    if (!ncrp && !window.location.search) {
      setPage(1);
    } else if (searchPage !== page) {
      setPage(searchPage);
    }
  }, [window.location.search]);

  return (
    <div className={clsx(classes.root)}>
      {getLoginToken() && (
        <Container className={classes.container}>
          <Box className={clsx(classes.wrapTitle)}>
            <Typography className={clsx(classes.contentTitle)}>
              MAGAZINE
            </Typography>
          </Box>
          <Box className={clsx(classes.newContent)}>
            <Grid container spacing={3}>
              {magazine?.magazines.map((item) => (
                <Grid item md={4} sm={6} xs={12} key={item.id}>
                  <Box className={clsx(classes.boxContainer)}>
                    <Box
                      className={clsx(classes.boxContent)}
                      onClick={() => {
                        if (isPayment === true) {
                          navigate(ROUTES.MAGAZINE + '/' + item?.id, {
                            state: {
                              ...item
                            }
                          });
                        } else {
                          setOpenDialog(true);
                        }
                      }}
                    >
                      <Box
                        className={clsx(classes.imageContainer)}
                        component="img"
                        src={item?.img}
                      />

                      <Box
                        className={clsx(classes.iconZoomIn)}
                        component="img"
                        src={iconZoomIn}
                      />
                    </Box>
                    <Box className={clsx(classes.magazineContent)}>
                      <Typography className={clsx(classes.magazineDate)}>
                        {moment(item?.release_date).format('YYYY/MM/DD')}
                      </Typography>
                      <Typography className={clsx(classes.magazineTitle)}>
                        {item?.title}
                      </Typography>
                      <span
                        className={clsx(classes.htmlCode)}
                        dangerouslySetInnerHTML={{ __html: item?.detail }}
                      />
                    </Box>
                  </Box>
                </Grid>
              ))}
            </Grid>
          </Box>

          {magazine?.totalPage > 1 && (
            <Box className={clsx(classes.wrapPagination)}>
              <PaginationButton
                page={page}
                totalItem={magazine?.totalPage * 6}
                itemPerPage={6}
                paginate={setPage}
              />
            </Box>
          )}
        </Container>
      )}
      <LoaderLayer loading={magazine.loading} />
      {openDialog && (
        <CheckPaymentDialog
          open={openDialog}
          onClose={setOpenDialog}
          pathName={ROUTES.MAGAZINE}
        />
      )}
    </div>
  );
}

export default MagazineView;
