import clsx from 'clsx';
import _ from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Box,
  Container,
  makeStyles,
  Typography,
  Button
} from '@material-ui/core';

import LoaderLayer from 'src/components/LoaderLayer';
import { settingSelector } from 'src/slices/settings';
import { authSelector } from 'src/slices/auth';
import { sendLoveLetter } from 'src/services/loveLetter';
import { loveLetterSelector } from 'src/slices/loveLetter';
import { getDomainTalent, getLoginToken } from 'src/utils/auth';
import CheckPaymentDialog from 'src/components/CheckPaymentDialog';
import { ROUTES } from 'src/constants';

const useStyles = makeStyles((theme) => ({
  root: {
    justifyContent: 'center',
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down(700)]: {
      padding: '0 2px 0'
    }
  },
  container: {
    maxWidth: 1040,
    padding: theme.spacing(0, 8),
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(0, 2)
    }
  },
  contentTitle: {
    color: '#000000',
    fontSize: '1.5rem',
    fontWeight: '600',
    [theme.breakpoints.down(600)]: {
      fontSize: '1rem',
      padding: '0 1.5rem'
    }
  },
  newContent: {
    margin: '3rem 0',
    display: 'flex',
    flexWrap: 'wrap',
    gap: 8,
    justifyContent: 'space-between',
    [theme.breakpoints.down(700)]: {
      margin: '1rem 0 2rem'
    }
  },
  boxWrap: {
    backgroundColor: '#FAFAF0',
    padding: '1.5rem',
    width: '100%',
    wordBreak: 'break-word',
    '& p': {
      lineHeight: 1.8
    },
    [theme.breakpoints.down(700)]: {
      wordBreak: 'break-all',
      padding: '1rem'
    }
  },
  boxTitle: {
    fontSize: '1.4rem',
    fontWeight: '700',
    display: 'flex',
    justifyContent: 'center',
    margin: '0 0 2rem',
    [theme.breakpoints.down(700)]: {
      fontSize: '1rem',
      margin: '1rem 0'
    }
  },
  boxContent: {
    borderBottom: '1px dotted',
    paddingBottom: '0.5rem',
    lineHeight: 2.5,
    fontSize: '1rem',
    fontFamily: 'sans-serif',
    whiteSpace: 'break-spaces',
    [theme.breakpoints.down(700)]: {
      fontSize: '0.9rem'
    }
  },
  wrapButton: {
    display: 'flex',
    justifyContent: 'center',
    margin: '4rem 0 5rem',
    [theme.breakpoints.down(700)]: {
      flexDirection: 'column-reverse'
    }
  },
  button: {
    padding: '12px 130px',
    color: 'white',
    [theme.breakpoints.down(700)]: {
      padding: '10px 0',
      width: '100%',
      margin: '0 0 1rem'
    }
  },
  buttonBack: {
    backgroundColor: '#989898',
    padding: '12px 130px',
    borderRadius: 0,
    marginRight: '1rem',
    color: 'white',
    '&:hover': {
      backgroundColor: '#989898'
    },
    [theme.breakpoints.down(700)]: {
      padding: '10px 0',
      width: '100%',
      margin: 0
    }
  },
  textCenter: {
    textAlign: 'center'
  },
  image: {
    borderRadius: '50%'
  },
  textName: {
    fontWeight: '700',
    fontSize: '1.3rem',
    marginLeft: '1rem'
  }
}));

function SendLetterPreview() {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const loveLetters = useSelector(loveLetterSelector);
  const { settings } = useSelector(settingSelector);
  const { profile } = useSelector(authSelector);
  const { state } = useLocation();

  const [currentProfile, setCurrentProfile] = useState({});
  const [openDialog, setOpenDialog] = useState(false);

  const handleSend = async () => {
    const { payload } = await dispatch(
      sendLoveLetter({ body: state?.content })
    );

    if (payload?.msg == 'Success') {
      navigate('/loveletter/success');
    } else {
      alert(payload?.msg);
    }
  };

  useEffect(() => {
    const { members } = profile;
    const domainTalent = getDomainTalent();

    const myProfile = _.find(
      members,
      (member) => member.talent.domain.split('//')[1] === domainTalent
    );

    setCurrentProfile(myProfile);

    if (myProfile?.member_type === 'lifetime' || myProfile?.member_type === 'existing_fan') {
      return;
    } else if (
      myProfile?.role === 'talent' ||
      myProfile?.role === 'staff_view' ||
      myProfile?.role === 'staff_edit'
    ) {
      return;
    } else if (
      myProfile?.end_datetime >= moment(new Date()).format('YYYY-MM-DD') &&
      myProfile?.role === 'fan'
    ) {
      return;
    } else {
      setOpenDialog(true);
    }
  }, []);

  return (
    <div className={clsx(classes.root)}>
      {getLoginToken() && !openDialog && (
        <Container className={classes.container}>
          <Box>
            <Typography className={clsx(classes.contentTitle)}>
              LOVE LETTER
            </Typography>
          </Box>
          <Box className={clsx(classes.newContent)}>
            <Box className={clsx(classes.boxWrap)}>
              <Typography className={clsx(classes.boxTitle)}>
                {`${settings?.name}さんへ`}
              </Typography>
              <span className={clsx(classes.boxContent)}>{state?.content}</span>
              <Box
                mt={4}
                display="flex"
                justifyContent="flex-end"
                alignItems="end"
              >
                <img
                  src={currentProfile?.avatar}
                  alt="FRAU INTERNATIONAL"
                  width={70}
                  height={70}
                  className={clsx(classes.image)}
                />
                <Typography className={clsx(classes.textName)}>より</Typography>
              </Box>
            </Box>
          </Box>

          <Box className={clsx(classes.wrapButton)}>
            <Box>
              <Button
                onClick={() =>
                  navigate('/loveletter/write', {
                    state: {
                      ...state
                    }
                  })
                }
                className={clsx(classes.buttonBack)}
              >
                前画面に戻る
              </Button>
            </Box>
            <Box>
              <Button
                onClick={handleSend}
                disabled={currentProfile?.status === 'frozen' ? true : false}
                className={clsx(classes.button)}
                style={{ backgroundColor: settings?.theme_color2 }}
              >
                手紙を送る
              </Button>
            </Box>
          </Box>
        </Container>
      )}
      <LoaderLayer loading={loveLetters?.loading} />
      {getLoginToken() && openDialog && (
        <CheckPaymentDialog
          open={openDialog}
          onClose={setOpenDialog}
          pathName={ROUTES.LOVE_LETTER}
        />
      )}
    </div>
  );
}

export default SendLetterPreview;
