import clsx from 'clsx';
import { useLocation } from 'react-router-dom';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Container, makeStyles } from '@material-ui/core';
import {
  getNoticesAll,
  getNoticesByTagId,
  getTagsNotice
} from 'src/services/noticeService';
import NoticeNews from './NoticeNews';
import { noticeSelector } from 'src/slices/notices';
import LoaderLayer from 'src/components/LoaderLayer';

const useStyles = makeStyles((theme) => ({
  root: {
    justifyContent: 'center',
    display: 'flex',
    flexDirection: 'column',
  },
  container: {
    maxWidth: 1040,
    '@media(max-width: 960px)': {
      padding: 0
    }
  }
}));

function NoticeView({ setting }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const notices = useSelector(noticeSelector);
  const { state } = useLocation();
  const [page, setPage] = useState(1);
  const [currentTag, setCurrentTag] = useState({ id: '', title: 'ALL' });

  useEffect(() => {
    dispatch(getNoticesAll({ page: page, perPage: 5 }));
  }, []);

  return (
    <div className={clsx(classes.root)}>
      <Container className={classes.container}>
        <Box className={clsx(classes.newContent)}>
          {notices?.notices?.map((item, index) => (
            <NoticeNews
              key={item.id}
              id={item.id}
              tagName={
                currentTag.id === '' ? item?.tag?.title : currentTag.title
              }
              date={item.release_date}
              title={item.title}
              currentTag={currentTag}
              setting={setting}
              indexItem={index}
            />
          ))}
        </Box>
      </Container>
      <LoaderLayer loading={notices?.loading} />
    </div>
  );
}

export default NoticeView;