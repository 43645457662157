import { createSlice } from '@reduxjs/toolkit';
import {
  getDiscographiesByTagId,
  getTagsDiscography
} from 'src/services/discographyService';

export const discographySlice = createSlice({
  name: 'discography',
  initialState: {
    loading: false,
    discographies: [],
    tagsDiscography: [],
    discographyDetail: '',
    errorMessage: '',
    currentPage: 1,
    totalPage: 1
  },
  reducers: {
    clearState: (state) => {
      state.discographies = [];
      state.tagsDiscography = [];
      state.discographyDetail = '';
      state.errorMessage = '';
      state.currentPage = 1;
      state.totalPage = 1;
      state.loading = false;
      return state;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getTagsDiscography.pending, (state) => {
      state.loading = true;
    }),
      builder.addCase(getTagsDiscography.fulfilled, (state, { payload }) => {
        state.tagsDiscography = payload;
        state.loading = false;
        return state;
      }),
      builder.addCase(getTagsDiscography.rejected, (state, { payload }) => {
        state.errorMessage = payload?.error;
        state.loading = false;
      }),
      builder.addCase(getDiscographiesByTagId.pending, (state) => {
        state.loading = true;
      }),
      builder.addCase(
        getDiscographiesByTagId.fulfilled,
        (state, { payload }) => {
          state.discographies = payload.data;
          state.currentPage = payload.current_page;
          state.totalPage = payload.last_page;
          state.loading = false;
          return state;
        }
      ),
      builder.addCase(
        getDiscographiesByTagId.rejected,
        (state, { payload }) => {
          state.errorMessage = payload.error;
          state.loading = false;
        }
      );
  }
});

export const discographySelector = (state) => state.discography;
export const reducer = discographySlice.reducer;
export const { clearState } = discographySlice.actions;
