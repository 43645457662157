import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Box, Typography, makeStyles } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { settingSelector } from 'src/slices/settings';

const useStyles = makeStyles((theme) => ({
  tagText: {
    padding: '5px 0',
    width: 160,
    textAlign: 'center',
    borderRadius: 24,
    fontSize: 16,
    fontWeight: '500',
    fontFamily: 'futura-pt',
    [theme.breakpoints.down(700)]: {
      padding: '2px 0',
      width: 120
    },
    '@media(max-width: 400px)': {
      marginBottom: 8
    }
  },
  tagFanclub: {
    borderColor: '#006EB1',
    color: '#006EB1'
  },
  tagNormal: {
    borderColor: '#707070'
  }
}));

function TagNotice({ tagName }) {
  const classes = useStyles();
  const { settings } = useSelector(settingSelector);

  return (
    <Typography
      className={clsx(classes.tagText)}
      style={{
        backgroundColor:
          tagName?.toUpperCase() === 'FAN CLUB' ||
          tagName?.toUpperCase() === 'FANCLUB'
            ? settings?.theme_color2
            : '#000000',
        color: '#FFFFFF'
      }}
    >
      {tagName}
    </Typography>
  );
}

TagNotice.propTypes = {
  tagName: PropTypes.string.isRequired
};

export default TagNotice;
