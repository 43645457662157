import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Container, makeStyles, Typography } from '@material-ui/core';
import { useSearchParams } from 'react-router-dom';
import Accordion from './Accordion';
import PaginationButton from 'src/components/PaginationButton';
import { inquirySelector } from 'src/slices/inquiry';
import { getFAQ } from 'src/services/inquiryService';

const useStyles = makeStyles((theme) => ({
  root: {
    justifyContent: 'center',
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '4rem',
    [theme.breakpoints.down(700)]: {
      padding: '0 2px 0'
    }
  },
  contentTitle: {
    color: '#000000',
    fontFamily: 'futura-pt',
    fontSize: 30,
    fontWeight: '600',
    [theme.breakpoints.down(600)]: {
      fontSize: 18
    }
  }
}));
function FAQView({ setting }) {
  const [searchParams] = useSearchParams();
  const classes = useStyles();
  const dispatch = useDispatch();
  const inquiry = useSelector(inquirySelector);
  const { faqs } = inquiry;

  const [page, setPage] = useState(1);
  useEffect(() => {
    dispatch(getFAQ({ page: page }));
  }, [page]);

  useEffect(() => {
    const searchPage = parseInt(window.location.search.split('=')[1]);
    if (!window.location.search) {
      setPage(1);
    } else if (searchPage !== page) {
      setPage(searchPage);
    }
  }, [window.location.search]);

  return (
    <div className={clsx(classes.root)}>
      <Container maxWidth={false} style={{ maxWidth: '1040px' }}>
        <Box>
          <Typography className={clsx(classes.contentTitle)}>FAQ</Typography>
        </Box>

        {faqs?.map((faq) => (
          <Accordion
            key={faq.id}
            title={faq.question}
            content={faq.answer}
            setting={setting}
          />
        ))}

        {inquiry?.totalPage > 1 && (
          <Box mt={5}>
            <PaginationButton
              page={page}
              totalItem={inquiry?.totalPage * 10}
              itemPerPage={10}
              paginate={setPage}
            />
          </Box>
        )}
      </Container>
    </div>
  );
}
export default FAQView;
