import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Container,
  Grid,
  Typography,
  CardMedia,
  IconButton
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import XIcon from '@mui/icons-material/X';
import InstagramIcon from '@mui/icons-material/Instagram';
import YouTubeIcon from '@mui/icons-material/YouTube';
import DialogMember from './DialogMember';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingBottom: 50
  },
  container: {
    maxWidth: 1040,
    padding: 0,
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(0, 2)
    }
  },
  media: {
    height: 220,
    cursor: 'pointer'
  },
  wrapBox: {
    display: 'flex',
    gap: 3
  },
  name: {
    margin: theme.spacing(1)
  },
  htmlCode: {
    '& *': {
      padding: 'revert'
    }
  },
  iconButton: {
    padding: 0
  },
  sns_disable: {
    display: 'none'
  }
}));

function Member({ className, profiles, member, setting }) {
  const classes = useStyles();
  const [openDialog, setOpenDialog] = useState(false);
  const [profile, setProfile] = useState({});

  return (
    <div
      className={clsx(classes.root, className)}
      style={{ paddingBottom: profiles?.totalPage > 1 ? 20 : 50 }}
    >
      <Container className={classes.container}>
        <Grid container spacing={3}>
          <Grid item xs={12} className={classes.wrapBox}>
            <Grid spacing={3} container>
              {profiles?.profiles?.map((member) => (
                <Grid item md={3} sm={6} xs={12} key={member.id}>
                  <Box textAlign="center">
                    <div>
                      <CardMedia
                        className={classes.media}
                        image={member.img}
                        onClick={() => {
                          setProfile(member);
                          setOpenDialog(true);
                        }}
                      />
                      <Typography
                        variant="body1"
                        color="inherit"
                        className={classes.name}
                      >
                        {member.title}
                      </Typography>
                      <div className={classes.wrapSNS}>
                        <IconButton
                          className={
                            member.twitter_active
                              ? classes.iconButton
                              : classes.sns_disable
                          }
                          style={{ color: setting.theme_color2 }}
                          onClick={() => {
                            window.open(
                              member?.twitter_url,
                              '_blank',
                              'noopener,noreferrer'
                            );
                          }}
                        >
                          <XIcon className={classes.iconSNS} />
                        </IconButton>
                        <IconButton
                          className={
                            member.insta_active
                              ? classes.iconButton
                              : classes.sns_disable
                          }
                          style={{ color: setting.theme_color2 }}
                          onClick={() => {
                            window.open(
                              member?.insta_url,
                              '_blank',
                              'noopener,noreferrer'
                            );
                          }}
                        >
                          <InstagramIcon className={classes.iconSNS} />
                        </IconButton>
                        <IconButton
                          className={
                            member.youtube_active
                              ? classes.iconButton
                              : classes.sns_disable
                          }
                          style={{ color: setting.theme_color2 }}
                          onClick={() => {
                            window.open(
                              member?.youtube_url,
                              '_blank',
                              'noopener,noreferrer'
                            );
                          }}
                        >
                          <YouTubeIcon className={classes.iconSNS} />
                        </IconButton>
                      </div>
                    </div>
                  </Box>
                </Grid>
              ))}
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Box mt={8}>
              <span
                className={clsx(classes.htmlCode)}
                dangerouslySetInnerHTML={{
                  __html: profiles?.profiles[0]?.introduction
                }}
              />
            </Box>
          </Grid>
        </Grid>
      </Container>
      <DialogMember
        open={openDialog}
        onClose={setOpenDialog}
        profile={profile}
      />
    </div>
  );
}

Member.propTypes = {
  className: PropTypes.string,
  noticeList: PropTypes.array
};

export default Member;
