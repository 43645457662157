import { forwardRef } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import Dialog from '@mui/material/Dialog';
import { Box, makeStyles } from '@material-ui/core';
import Slide from '@mui/material/Slide';
import { Button } from '@mui/material';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

const useStyles = makeStyles((theme) => ({
  dialogContainer: {
    padding: '1.5rem'
  },
  wrapButton: {
    display: 'flex',
    justifyContent: 'center',
    margin: '5rem 0',
    [theme.breakpoints.down(700)]: {
      flexDirection: 'column',
      margin: '2rem 0'
    }
  },
  wrapImages: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    maxHeight: '30rem',
    margin: 'auto',
    overflowX: 'hidden'
  },
  image: {
    width: 80,
    backgroundColor: '#B1B1B1',
    margin: 1,
    cursor: 'pointer'
  },
  button: {
    backgroundColor: 'black !important',
    borderRadius: 0,
    padding: '12px 0 !important',
    width: '100%',
    color: '#fff !important',
    '&:hover': {
      backgroundColor: '#1175ae'
    }
  },
  iconCheck: {
    position: 'absolute',
    top: '0.5rem',
    right: '0.3rem',
    color: 'white',
    boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px'
  }
}));

export default function ImageDialog({
  open,
  onClose,
  avatars,
  avatarId,
  setCurrentAvatar
}) {
  const classes = useStyles();

  const handleClose = () => {
    onClose(false);
  };

  return (
    <Dialog
      TransitionComponent={Transition}
      onClose={handleClose}
      open={open}
      aria-describedby="alert-dialog-slide-description"
    >
      <Box className={clsx(classes.dialogContainer)}>
        <Box className={clsx(classes.wrapImages)}>
          {avatars?.map((avatar) => (
            <Box
              key={avatar.id}
              onClick={() => {
                setCurrentAvatar({
                  id: avatar.id,
                  img: avatar.img
                });
              }}
              position="relative"
            >
              <LazyLoadImage
                onClick={() => {
                  setCurrentAvatar({
                    id: avatar.id,
                    img: avatar.img
                  });
                }}
                className={clsx(classes.image)}
                src={avatar.img}
                loading="lazy"
                alt="FRAU INTERNATIONAL"
              />
              {avatarId === avatar.id && (
                <CheckCircleOutlineIcon className={clsx(classes.iconCheck)} />
              )}
            </Box>
          ))}
        </Box>

        <Box mt={2}>
          <Button onClick={handleClose} className={clsx(classes.button)}>
            画像を変更する
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
}

ImageDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  avatars: PropTypes.array.isRequired,
  setCurrentAvatar: PropTypes.func.isRequired
};

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
