import clsx from 'clsx';
import { useLocation } from 'react-router-dom';
import React, { useEffect, useRef } from 'react';
import { Box, Container, Typography, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    justifyContent: 'center',
    display: 'flex',
    flexDirection: 'column'
  },
  registerBorderBottom: {
    borderBottom: '1px solid #919090'
  },
  containerProfile: {
    backgroundColor: '#ffffff',
    padding: '2rem',
    margin: '3rem 0 2rem',
    [theme.breakpoints.down(700)]: {
      padding: '0.5rem'
    }
  },
  containerProfileDetail: {
    display: 'flex'
  },
  profileLeft: {
    width: '20%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    borderTop: '1px solid',
    borderLeft: '1px solid',
    borderRight: '1px solid',
    '& p': {
      padding: '0.5rem 0',
      fontWeight: '500',
      [theme.breakpoints.down(700)]: {
        fontSize: '0.9rem'
      }
    },
    [theme.breakpoints.down(700)]: {
      width: '40%'
    }
  },
  profileRight: {
    flexGrow: 1,
    width: '80%',
    borderTop: '1px solid',
    borderRight: '1px solid',
    display: 'flex',
    alignItems: 'center',
    '& p': {
      padding: '0.5rem',
      [theme.breakpoints.down(700)]: {
        padding: '0.5rem 0 0.5rem 0.8rem',
        fontSize: '0.9rem'
      }
    },
    [theme.breakpoints.down(700)]: {
      width: '60%'
    }
  },
  textWhite: {
    color: '#ffffff',
    marginTop: 'auto',
    marginLeft: 30,
    marginBottom: 30,
    fontSize: 25,
    [theme.breakpoints.up(700)]: {
      fontSize: 40
    }
  },
  topBanner: {
    width: '100%',
    background: '#B1B1B1',
    display: 'flex',
    height: 300,
    [theme.breakpoints.up(700)]: {
      height: 400
    }
  }
}));

const AboutPayment = () => {
  const classes = useStyles();
  const location = useLocation();
  const anotherRef = useRef();
  const creditRef = useRef();

  useEffect(() => {
    const checkElement = setInterval(() => {
      if (location?.hash === '#another') {
        anotherRef.current.scrollIntoView({
          behavior: 'smooth',
          block: 'start'
        });
        clearInterval(checkElement);
      } else {
        creditRef.current.scrollIntoView({
          behavior: 'smooth',
          block: 'end'
        });
        clearInterval(checkElement);
      }
    }, 100);
  }, []);

  return (
    <div className={classes.root}>
      <Container maxWidth="md">
        <div className={classes.topBanner}>
          <Typography className={classes.textWhite}>
            <strong style={{ fontSize: 30 }}>
              支払時期・方法/サービス提供時期/解約方法詳細について
            </strong>
          </Typography>
        </div>
        <Box mb={2} paddingBottom={3}>
          <Box className={clsx(classes.containerProfile)}>
            <Box>
              <Typography style={{ fontWeight: '500' }}>
                ・クレジットカード
              </Typography>
            </Box>

            <Box className={clsx(classes.containerProfileDetail)}>
              <Box className={clsx(classes.profileLeft)}>
                <Typography>
                  サービス <br />
                  提供期間
                </Typography>
              </Box>
              <Box className={clsx(classes.profileRight)}>
                <Typography>
                  ・入金完了月の1日から翌年前月末まで
                  <br />
                  ※なお、クレジットカード情報に変更がない場合、
                  <br />
                  会員期限満了月の10日までに退会手続きを行っていただいた場合を除き
                  <br />
                  サービス利用契約が1年間自動更新されます。
                  <br />
                  例）12月15日にご入会の場合、会員期間は12月1日〜翌年11月30日までとなります。また、翌年11月10日までに退会手続きがなされない場合は、
                  <br />
                  更新後の契約期間を翌年12月1日〜翌々年11月30日として契約が自動更新されます。
                </Typography>
              </Box>
            </Box>
            <Box className={clsx(classes.containerProfileDetail)}>
              <Box className={clsx(classes.profileLeft)}>
                <Typography>
                  支払い時期 <br />
                  方法
                </Typography>
              </Box>
              <Box className={clsx(classes.profileRight)}>
                <Typography>お申込み完了後、即時決済となります。</Typography>
              </Box>
            </Box>
            <Box className={clsx(classes.containerProfileDetail)}>
              <Box className={clsx(classes.profileLeft)}>
                <Typography>サービス提供時期</Typography>
              </Box>
              <Box className={clsx(classes.profileRight)}>
                <Typography>決済完了後、直ちにご利用いただけます。</Typography>
              </Box>
            </Box>
            <Box
              ref={anotherRef}
              id="another"
              className={clsx(classes.containerProfileDetail)}
            >
              <Box
                className={clsx(classes.profileLeft)}
                style={{ borderBottom: '1px solid' }}
              >
                <Typography>解約について</Typography>
              </Box>
              <Box
                className={clsx(classes.profileRight)}
                style={{ borderBottom: '1px solid' }}
              >
                <Typography>
                  決済完了後はお客様のご都合によるキャンセルはお受け出来ません。
                  <br />
                  また、会員資格期間の途中において解約手続きを行った場合でも、
                  <br />
                  お支払い済みの料金は返金されません。
                </Typography>
              </Box>
            </Box>

            <Box pt={3}>
              <Typography style={{ fontWeight: '500' }}>
                ・コンビニ/ATM決済/銀行ネット決済
              </Typography>
            </Box>

            <Box className={clsx(classes.containerProfileDetail)}>
              <Box className={clsx(classes.profileLeft)}>
                <Typography>
                  サービス <br />
                  提供期間
                </Typography>
              </Box>
              <Box className={clsx(classes.profileRight)}>
                <Typography>
                  ・入金完了月の1日から翌年前月末まで
                  <br />
                  ※会員期限満了月前月1日~満了月20日までが更新期間となり、
                  <br />
                  期間内にお手続きをいただけなかった場合、会員資格は停止となります。
                  <br />
                  例）12月15日にご入会の場合、会員期間は12月1日~翌年11月30日まで、
                  <br />
                  更新期間は10月1日~11月20日となります。
                </Typography>
              </Box>
            </Box>
            <Box
              ref={creditRef}
              className={clsx(classes.containerProfileDetail)}
            >
              <Box className={clsx(classes.profileLeft)}>
                <Typography>
                  支払い時期 <br />
                  方法
                </Typography>
              </Box>
              <Box className={clsx(classes.profileRight)}>
                <Typography>
                  お申込み完了後、5日以内にお支払いください。
                </Typography>
              </Box>
            </Box>
            <Box className={clsx(classes.containerProfileDetail)}>
              <Box className={clsx(classes.profileLeft)}>
                <Typography>サービス提供時期</Typography>
              </Box>
              <Box className={clsx(classes.profileRight)}>
                <Typography>入金完了後、直ちにご利用いただけます。</Typography>
              </Box>
            </Box>
            <Box className={clsx(classes.containerProfileDetail)}>
              <Box
                className={clsx(classes.profileLeft)}
                style={{ borderBottom: '1px solid' }}
              >
                <Typography>解約について</Typography>
              </Box>
              <Box
                className={clsx(classes.profileRight)}
                style={{ borderBottom: '1px solid' }}
              >
                <Typography>
                  入金完了後はお客様のご都合によるキャンセルはお受け出来ません。
                  <br />
                  また、会員資格期間の途中において解約手続きを行った場合でも、
                  <br />
                  お支払い済みの料金は返金されません。
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </Container>
    </div>
  );
};

export default AboutPayment;
