import React, { useEffect, useState, useRef } from 'react';
import { makeStyles } from '@material-ui/core';
import { getSettings } from 'src/services/settingService';
import { settingSelector } from 'src/slices/settings';
import { useDispatch, useSelector } from 'react-redux';
import { getTopLayouts } from 'src/services/topLayoutService';
import { topLayoutSelector } from 'src/slices/topLayout';
import SliderMedia from './SliderMedia';
import Slider from './Slider';
import Layout1 from './TopLayout/Layout1';
import Layout2 from './TopLayout/Layout2';
import Layout3 from './TopLayout/Layout3';
import Layout4 from './TopLayout/Layout4';
import Layout5 from './TopLayout/Layout5';
import Layout6 from './TopLayout/Layout6';
import Layout7 from './TopLayout/Layout7';
import Layout8 from './TopLayout/Layout8';
import TopBanner from './TopBanner';
import BottomBanner from './BottomBanner';
import MainSlide from './MainSlide.js';
import HeaderOnTop from 'src/layouts/HeaderOnTop';
const useStyles = makeStyles(() => ({
  root: {},
  media: {
    maxHeight: '100%',
    height: 'auto',
    width: '100%',
    '& > video': {
      maxHeight: '100%',
      height: 'auto',
      width: '100%'
    }
  },
  display: {
    '@media(max-width: 960px)': {
      display: 'none'
    }
  },
  displayImg: {
    '@media(min-width: 960px)': {
      display: 'none'
    }
  },
  slideSP: {
    display: 'block',
    marginTop: 70,
    '@media(min-width: 700px)': {
      display: 'none !important'
    }
  },
  slidePc: {
    display: 'block',
    '@media(max-width: 700px)': {
      display: 'none'
    }
  },
  bottomBanner: {
    display: 'flex',
    margin: '50px 0',
    width: '100%',
    '& div': {
      width: '24%',
      margin: 'auto'
    },
    '& img': {
      width: '100%',
      maxWidth: 400,
      minWidth: 150,
      aspectRatio: 16 / 9
    },
    '@media(max-width: 700px)': {
      flexDirection: 'column',
      '& div': {
        marginTop: 10,
        width: '70%',
        maxWidth: 350,
        height: 'auto',
        margin: 'auto'
      }
    }
  },
  sticky: {
    position: 'fixed',
    top: 0,
    width: '100%',
    zIndex: 10
  }
}));

function HomeView({ profile }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const setting = useSelector(settingSelector);
  const layout = useSelector(topLayoutSelector);
  const [layoutTop, setLayoutTop] = useState(layout?.layout);
  const [currentPage, setCurrentPage] = useState(1);
  const [scrollTop, setScrollTop] = useState(false);

  const ref = useRef(null);

  useEffect(() => {
    window.onbeforeunload = function () {
      window.scrollTo(0, 0);
    };

    const height = ref?.current?.clientHeight - 70;

    window.addEventListener('scroll', () => {
      if (window.scrollY >= height) {
        setScrollTop(true);
      } else {
        setScrollTop(false);
      }
    });
  }, [ref]);

  useEffect(() => {
    dispatch(getSettings());
    dispatch(getTopLayouts());
  }, []);

  useEffect(() => {
    setLayoutTop(layout.layout);
  }, [layout]);

  const paymentType1 = () => {
    switch (layoutTop?.block_1?.layout_type) {
      case '1':
        return (
          <Layout1 setting={setting?.settings} layout={layoutTop?.block_1} />
        );
      case '2':
        return (
          <Layout2 setting={setting?.settings} layout={layoutTop?.block_1} />
        );
      case '3':
        return (
          <Layout3 setting={setting?.settings} layout={layoutTop?.block_1} />
        );
      case '4':
        return (
          <Layout4 setting={setting?.settings} layout={layoutTop?.block_1} />
        );
      case '5':
        return (
          <Layout5 setting={setting?.settings} layout={layoutTop?.block_1} />
        );
      case '6':
        return (
          <Layout6 setting={setting?.settings} layout={layoutTop?.block_1} />
        );
      case '7':
        return (
          <Layout7 setting={setting?.settings} layout={layoutTop?.block_1} />
        );
      case '8':
        return (
          <Layout8 setting={setting?.settings} layout={layoutTop?.block_1} />
        );
      default:
        break;
    }
  };
  const paymentType2 = () => {
    switch (layoutTop?.block_2?.layout_type) {
      case '1':
        return (
          <Layout1 setting={setting?.settings} layout={layoutTop?.block_2} />
        );
      case '2':
        return (
          <Layout2 setting={setting?.settings} layout={layoutTop?.block_2} />
        );
      case '3':
        return (
          <Layout3 setting={setting?.settings} layout={layoutTop?.block_2} />
        );
      case '4':
        return (
          <Layout4 setting={setting?.settings} layout={layoutTop?.block_2} />
        );
      case '5':
        return (
          <Layout5 setting={setting?.settings} layout={layoutTop?.block_2} />
        );
      case '6':
        return (
          <Layout6 setting={setting?.settings} layout={layoutTop?.block_2} />
        );
      case '7':
        return (
          <Layout7 setting={setting?.settings} layout={layoutTop?.block_2} />
        );
      case '8':
        return (
          <Layout8 setting={setting?.settings} layout={layoutTop?.block_2} />
        );
      default:
        break;
    }
  };
  const paymentType3 = () => {
    switch (layoutTop?.block_3?.layout_type) {
      case '1':
        return (
          <Layout1 setting={setting?.settings} layout={layoutTop?.block_3} />
        );
      case '2':
        return (
          <Layout2 setting={setting?.settings} layout={layoutTop?.block_3} />
        );
      case '3':
        return (
          <Layout3 setting={setting?.settings} layout={layoutTop?.block_3} />
        );
      case '4':
        return (
          <Layout4 setting={setting?.settings} layout={layoutTop?.block_3} />
        );
      case '5':
        return (
          <Layout5 setting={setting?.settings} layout={layoutTop?.block_3} />
        );
      case '6':
        return (
          <Layout6 setting={setting?.settings} layout={layoutTop?.block_3} />
        );
      case '7':
        return (
          <Layout7 setting={setting?.settings} layout={layoutTop?.block_3} />
        );
      case '8':
        return (
          <Layout8 setting={setting?.settings} layout={layoutTop?.block_3} />
        );
      default:
        break;
    }
  };
  const paymentType4 = () => {
    switch (layoutTop?.block_4?.layout_type) {
      case '1':
        return (
          <Layout1 setting={setting?.settings} layout={layoutTop?.block_4} />
        );
      case '2':
        return (
          <Layout2 setting={setting?.settings} layout={layoutTop?.block_4} />
        );
      case '3':
        return (
          <Layout3 setting={setting?.settings} layout={layoutTop?.block_4} />
        );
      case '4':
        return (
          <Layout4 setting={setting?.settings} layout={layoutTop?.block_4} />
        );
      case '5':
        return (
          <Layout5 setting={setting?.settings} layout={layoutTop?.block_4} />
        );
      case '6':
        return (
          <Layout6 setting={setting?.settings} layout={layoutTop?.block_4} />
        );
      case '7':
        return (
          <Layout7 setting={setting?.settings} layout={layoutTop?.block_4} />
        );
      case '8':
        return (
          <Layout8 setting={setting?.settings} layout={layoutTop?.block_4} />
        );
      default:
        break;
    }
  };
  const paymentType5 = () => {
    switch (layoutTop?.block_5?.layout_type) {
      case '1':
        return (
          <Layout1 setting={setting?.settings} layout={layoutTop?.block_5} />
        );
      case '2':
        return (
          <Layout2 setting={setting?.settings} layout={layoutTop?.block_5} />
        );
      case '3':
        return (
          <Layout3 setting={setting?.settings} layout={layoutTop?.block_5} />
        );
      case '4':
        return (
          <Layout4 setting={setting?.settings} layout={layoutTop?.block_5} />
        );
      case '5':
        return (
          <Layout5 setting={setting?.settings} layout={layoutTop?.block_5} />
        );
      case '6':
        return (
          <Layout6 setting={setting?.settings} layout={layoutTop?.block_5} />
        );
      case '7':
        return (
          <Layout7 setting={setting?.settings} layout={layoutTop?.block_5} />
        );
      case '8':
        return (
          <Layout8 setting={setting?.settings} layout={layoutTop?.block_5} />
        );
      default:
        break;
    }
  };
  const paymentType6 = () => {
    switch (layoutTop?.block_6?.layout_type) {
      case '1':
        return (
          <Layout1 setting={setting?.settings} layout={layoutTop?.block_6} />
        );
      case '2':
        return (
          <Layout2 setting={setting?.settings} layout={layoutTop?.block_6} />
        );
      case '3':
        return (
          <Layout3 setting={setting?.settings} layout={layoutTop?.block_6} />
        );
      case '4':
        return (
          <Layout4 setting={setting?.settings} layout={layoutTop?.block_6} />
        );
      case '5':
        return (
          <Layout5 setting={setting?.settings} layout={layoutTop?.block_6} />
        );
      case '6':
        return (
          <Layout6 setting={setting?.settings} layout={layoutTop?.block_6} />
        );
      case '7':
        return (
          <Layout7 setting={setting?.settings} layout={layoutTop?.block_6} />
        );
      case '8':
        return (
          <Layout8 setting={setting?.settings} layout={layoutTop?.block_6} />
        );
      default:
        break;
    }
  };
  const paymentType7 = () => {
    switch (layoutTop?.block_7?.layout_type) {
      case '1':
        return (
          <Layout1 setting={setting?.settings} layout={layoutTop?.block_7} />
        );
      case '2':
        return (
          <Layout2 setting={setting?.settings} layout={layoutTop?.block_7} />
        );
      case '3':
        return (
          <Layout3 setting={setting?.settings} layout={layoutTop?.block_7} />
        );
      case '4':
        return (
          <Layout4 setting={setting?.settings} layout={layoutTop?.block_7} />
        );
      case '5':
        return (
          <Layout5 setting={setting?.settings} layout={layoutTop?.block_7} />
        );
      case '6':
        return (
          <Layout6 setting={setting?.settings} layout={layoutTop?.block_7} />
        );
      case '7':
        return (
          <Layout7 setting={setting?.settings} layout={layoutTop?.block_7} />
        );
      case '8':
        return (
          <Layout8 setting={setting?.settings} layout={layoutTop?.block_7} />
        );
      default:
        break;
    }
  };
  const paymentType8 = () => {
    switch (layoutTop?.block_8?.layout_type) {
      case '1':
        return (
          <Layout1 setting={setting?.settings} layout={layoutTop?.block_8} />
        );
      case '2':
        return (
          <Layout2 setting={setting?.settings} layout={layoutTop?.block_8} />
        );
      case '3':
        return (
          <Layout3 setting={setting?.settings} layout={layoutTop?.block_8} />
        );
      case '4':
        return (
          <Layout4 setting={setting?.settings} layout={layoutTop?.block_8} />
        );
      case '5':
        return (
          <Layout5 setting={setting?.settings} layout={layoutTop?.block_8} />
        );
      case '6':
        return (
          <Layout6 setting={setting?.settings} layout={layoutTop?.block_8} />
        );
      case '7':
        return (
          <Layout7 setting={setting?.settings} layout={layoutTop?.block_8} />
        );
      case '8':
        return (
          <Layout8 setting={setting?.settings} layout={layoutTop?.block_8} />
        );
      default:
        break;
    }
  };
  const paymentType9 = () => {
    switch (layoutTop?.block_9?.layout_type) {
      case '1':
        return (
          <Layout1 setting={setting?.settings} layout={layoutTop?.block_9} />
        );
      case '2':
        return (
          <Layout2 setting={setting?.settings} layout={layoutTop?.block_9} />
        );
      case '3':
        return (
          <Layout3 setting={setting?.settings} layout={layoutTop?.block_9} />
        );
      case '4':
        return (
          <Layout4 setting={setting?.settings} layout={layoutTop?.block_9} />
        );
      case '5':
        return (
          <Layout5 setting={setting?.settings} layout={layoutTop?.block_9} />
        );
      case '6':
        return (
          <Layout6 setting={setting?.settings} layout={layoutTop?.block_9} />
        );
      case '7':
        return (
          <Layout7 setting={setting?.settings} layout={layoutTop?.block_9} />
        );
      case '8':
        return (
          <Layout8 setting={setting?.settings} layout={layoutTop?.block_9} />
        );
      default:
        break;
    }
  };
  const paymentType10 = () => {
    switch (layoutTop?.block_10?.layout_type) {
      case '1':
        return (
          <Layout1 setting={setting?.settings} layout={layoutTop?.block_10} />
        );
      case '2':
        return (
          <Layout2 setting={setting?.settings} layout={layoutTop?.block_10} />
        );
      case '3':
        return (
          <Layout3 setting={setting?.settings} layout={layoutTop?.block_10} />
        );
      case '4':
        return (
          <Layout4 setting={setting?.settings} layout={layoutTop?.block_10} />
        );
      case '5':
        return (
          <Layout5 setting={setting?.settings} layout={layoutTop?.block_10} />
        );
      case '6':
        return (
          <Layout6 setting={setting?.settings} layout={layoutTop?.block_10} />
        );
      case '7':
        return (
          <Layout7 setting={setting?.settings} layout={layoutTop?.block_10} />
        );
      case '8':
        return (
          <Layout8 setting={setting?.settings} layout={layoutTop?.block_10} />
        );
      default:
        break;
    }
  };

  return (
    <div className={classes.root}>
      {(layoutTop || layoutTop?.length !== 0) && (
        <>
          {layoutTop?.landscape?.type &&
          layoutTop?.landscape?.type === 'image' ? (
            <>
              {/* <Slider
                isPause
                className={classes.slideSP}
                items={Object?.values(layoutTop?.landscape.image.sp)?.map(
                  (media, index) => (
                    <>
                      <SliderMedia
                        key={index}
                        type="img"
                        urlMedia={media?.image_url}
                        external_url={media?.external_url}
                        id={media?.id}
                      />
                      {setting?.settings?.talent_settings?.menu_type === 1 && (
                        <div
                          className={scrollTop ? classes.sticky : ''}
                          id="header-mobile-id"
                        >
                          <HeaderOnTop setting={setting} profile={profile} />
                        </div>
                      )}
                    </>
                  )
                )}
              /> */}

              <div className={classes.slideSP}>
                <MainSlide
                  imageList={Object?.values(layoutTop?.landscape.image.sp)}
                />
              </div>

              <div ref={ref} className={classes.slidePc}>
                <MainSlide
                  imageList={Object?.values(layoutTop?.landscape.image.web)}
                />

                {setting?.settings?.talent_settings?.menu_type === 1 && (
                  <div className={scrollTop ? classes.sticky : ''}>
                    <HeaderOnTop setting={setting} profile={profile} />
                  </div>
                )}
              </div>
            </>
          ) : (
            <div
              className={`${classes.media} ${
                layoutTop?.landscape?.video?.external_url ? 'open__newtab' : ''
              }`}
              onClick={() => {
                if (layoutTop?.landscape?.video?.external_url) {
                  window.open(
                    layoutTop?.landscape?.video?.external_url,
                    '_blank'
                  );
                }
              }}
              dangerouslySetInnerHTML={{
                __html: `<video
                  loop
                  muted
                  autoplay
                  playsinline
                  preload="metadata"
                >
                  <source src=${layoutTop?.landscape?.video?.video_url} type="video/mp4" />
                </video>`
              }}
            />
          )}
          {layoutTop?.top_banner && (
            <TopBanner
              imageList={Object?.values(layoutTop?.top_banner)}
              currentPage={currentPage}
            />
          )}
          {paymentType1()}
          {paymentType2()}
          {paymentType3()}
          {paymentType4()}
          {paymentType5()}
          {paymentType6()}
          {paymentType7()}
          {paymentType8()}
          {paymentType9()}
          {paymentType10()}
          {layoutTop?.bottom_banner &&
          Object?.values(layoutTop?.bottom_banner)?.length < 4 ? (
            <div className={classes.bottomBanner}>
              {Object?.values(layoutTop?.bottom_banner)?.map((image, index) => {
                return (
                  <div
                    key={index}
                    className={image.external_url ? 'open__newtab' : ''}
                    onClick={() => {
                      if (image?.external_url)
                        window.open(image?.external_url, '_blank');
                    }}
                  >
                    <img src={image?.image_url} />
                  </div>
                );
              })}
            </div>
          ) : (
            layoutTop?.bottom_banner && (
              <BottomBanner
                imageList={Object?.values(layoutTop?.bottom_banner)}
                currentPage={currentPage}
              />
            )
          )}
        </>
      )}
    </div>
  );
}

export default HomeView;
