import clsx from 'clsx';
import { forwardRef } from 'react';
import PropTypes from 'prop-types';
import Slide from '@mui/material/Slide';
import Dialog from '@mui/material/Dialog';
import { useNavigate } from 'react-router';
import { Box, makeStyles, Typography, Button } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  dialogContainer: {
    padding: '1.5rem 4rem',
    [theme.breakpoints.down(700)]: {
      padding: '1.5rem 3rem'
    }
  },
  title: {
    margin: '1rem 0 3rem',
    padding: '0 1.5rem',
    textAlign: 'center',
    '& h5': {
      fontWeight: '600'
    },
    [theme.breakpoints.down(700)]: {
      padding: '0 1.5rem'
    }
  },
  wrapButton: {
    display: 'flex',
    justifyContent: 'center',
    margin: '5rem 0',
    [theme.breakpoints.down(700)]: {
      flexDirection: 'column',
      margin: '2rem 0'
    }
  },
  buttonBack: {
    backgroundColor: '#A7A7A7 !important',
    color: '#fff !important'
  },
  buttonDelete: {
    backgroundColor: 'black !important',
    color: '#fff !important'
  },
  button: {
    padding: '12px 0 !important',
    borderRadius: 0,
    width: '100%',
    color: '#fff !important',
    '&:hover': {
      backgroundColor: '#1175ae'
    }
  }
}));

export default function AutoPaymentDialog({
  open,
  onClose,
  title,
  setFocus,
  path = ''
}) {
  const classes = useStyles();
  const navigate = useNavigate();

  const handleClose = () => {
    onClose(false);
    setFocus(true);

    if (path !== '') {
      navigate(path);
    }
  };

  return (
    <Dialog
      TransitionComponent={Transition}
      open={open}
      onClose={handleClose}
      aria-describedby="alert-dialog-slide-description"
    >
      <Box className={clsx(classes.dialogContainer)}>
        <Box className={clsx(classes.title)}>
          <Typography style={{ fontSize: 16 }}>
            <strong>{title}</strong>
          </Typography>
        </Box>

        <Box mt={2}>
          <Button
            onClick={() => {
              handleClose();
            }}
            className={clsx(classes.button, classes.buttonDelete)}
          >
            閉じる
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
}

AutoPaymentDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired
};

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
