/* eslint-disable */
import * as Sentry from '@sentry/react';

export const apiCatch = (error) => {
  // Sentry.showReportDialog(); // Get users feedback on error

  Sentry.captureException(error,
    {
      contexts: {
        response: error.response,
      },
      extra: {
        headers: error.response?.headers,
        request: error.request,
      },
      tags: {
        section: 'API',
      },
    });
};
