import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core';
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';

const responsive = {
  1024: { items: 1 }
};

const useStyles = makeStyles(() => ({
  root: {
    '@media(max-width: 900px)': {
      marginTop: 70
    },
    position: 'relative',
    '& > div > ul': {
      display: 'none'
    },
    '& > div > div > div > ul > li > div > img': {
      height: '897px !important',
      maxHeight: '100%'
    },
    '& > div > div > div > ul > li > div > video': {
      height: '800px !important',
      maxHeight: '100%'
    },
    '@media(max-width: 486px)': {
      '& > div > div > div > ul > li > div > img': {
        maxHeight: '100%',
        height: 'auto !important',
        objectFit: 'fill'
      },
      '& > div > div > div > ul > li > div > video': {
        height: 'auto !important',
        maxHeight: '100%'
      }
    }
  }
}));

function Slider({ className, items, isPause }) {
  const classes = useStyles();

  return (
    <div className={clsx(classes.root, className)}>
      <AliceCarousel
        mouseTracking
        items={items}
        responsive={responsive}
        controlsStrategy="alternate"
        autoPlayInterval={3000}
        // autoPlay={!isPause}
        autoPlay="true"
        infinite="true"
        disableButtonsControls="true"
        animationEasingFunction="true"
      />
    </div>
  );
}

Slider.propTypes = {
  className: PropTypes.string,
  items: PropTypes.array,
  isPause: PropTypes.bool
};

export default Slider;
