import React, { useState } from 'react';
import OtpInput from 'react-otp-input';
import {
  Box,
  Container,
  Typography,
  makeStyles,
  Button
} from '@material-ui/core';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';

import { authSelector } from 'src/slices/auth';
import { applyForgotCode, sendForgotEmail } from 'src/services/authService';
import { useNavigate } from 'react-router-dom';
import { ROUTES_AUTHEN } from 'src/constants';
import { settingSelector } from 'src/slices/settings';
import LoaderLayer from 'src/components/LoaderLayer';
import PaymentDialog from 'src/components/PaymentDialog';

const useStyles = makeStyles((theme) => ({
  root: {
    justifyContent: 'center',
    display: 'flex',
    flexDirection: 'column',
    paddingTop: 100
  },
  registerBorderBottom: {
    borderBottom: '1px solid #919090'
  },
  wrapTitle: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center'
  },
  registerTitleText: {
    fontWeight: 'bold',
    marginBottom: 5,
    fontSize: 20,
    [theme.breakpoints.down(700)]: {
      fontSize: '1rem'
    }
  },
  registerSubTitle: {
    fontSize: 20,
    fontWeight: 'bold',
    textAlign: 'center',
    margin: '70px 0'
  },
  registerDescription: {
    marginBottom: 5,
    fontSize: 14
  },
  buttonHover: {
    color: '#fff !important',
    '&:hover': {
      backgroundColor: 'rgb(34 152 221);'
    }
  },
  registerLabel: {
    fontWeight: 'bold',
    marginRight: 100,
    [theme.breakpoints.down('700')]: {
      fontSize: '0.8rem',
      lineHeight: 2.2
    }
  },
  email: {
    fontWeight: '600'
  },
  otpInput: {
    margin: '0 5px',
    width: '4em',
    height: '4em'
  },
  spViewEmail: {
    [theme.breakpoints.down(700)]: {
      flexDirection: 'column'
    }
  },
  registerButton: {
    padding: '12px 180px',
    color: 'white !important',
    borderRadius: 0,
    fontSize: 18,
    [theme.breakpoints.down(700)]: {
      fontSize: 16,
      padding: '6px 120px'
    }
  },
  otpInputContainer: {
    justifyContent: 'center',
    [theme.breakpoints.down(700)]: {
      justifyContent: 'space-around'
    }
  },
  otpInputStyle: {
    width: '3em !important',
    height: '3em',
    margin: '0 6px !important',
    fontSize: '1.2rem',
    [theme.breakpoints.down(700)]: {
      margin: '0 !important'
    }
  },
  textResend: {
    cursor: 'pointer',
    '& p': {
      fontWeight: '600'
    }
  },
  textResendDisable: {
    cursor: 'not-allowed',
    opacity: 0.5
  }
}));

function PasswordForgotOtpView() {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const auth = useSelector(authSelector);
  const setting = useSelector(settingSelector);

  const [otp, setOtp] = useState('');
  const [titleDialog, setTitleDialog] = useState('');
  const [hover, setHover] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [disableResend, setDisableResend] = useState(false);

  const handleChange = (newValue) => {
    setOtp(newValue);
  };

  const handleResendEmail = async () => {
    if (!disableResend) {
      const { payload } = await dispatch(
        sendForgotEmail({ ...auth?.infoSendForgot })
      );
      setDisableResend(true);

      if (payload?.data?.msg === 'Success') {
        setTitleDialog('送信しました');
        setOpenDialog(true);
      } else {
        setTitleDialog(payload?.data?.msg);
        setOpenDialog(true);
      }
    }

    setTimeout(() => {
      setDisableResend(false);
    }, 15 * 60 * 1000);
  };

  const handleSubmitCodeVerify = async () => {
    const { payload } = await dispatch(
      applyForgotCode({ email: auth?.infoSendForgot.email, code: otp })
    );

    if (payload.msg === 'Success') {
      navigate(ROUTES_AUTHEN.RESET_PASSWORD);
    } else {
      setTitleDialog(payload?.msg);
      setOpenDialog(true);
    }
  };

  return (
    <div className={classes.root}>
      <Container maxWidth="md">
        <Box mb={8} paddingBottom={8} className={classes.registerBorderBottom}>
          <Box
            className={clsx(classes.wrapTitle, classes.registerBorderBottom)}
          >
            <Typography className={classes.registerTitleText}>
              パスワード再発行
            </Typography>
          </Box>
          <Typography className={classes.registerSubTitle}>
            ご入力されたメールアドレス宛に認証コードを送信しました。
            <br />
            メールの受信をご確認ください。
          </Typography>

          <Typography className={classes.registerDescription}>
            ※メールが届かない場合は、ご入力ミスや、携帯キャリアの設定によりブロックされている可能性がございます。
          </Typography>
          <Typography className={classes.registerDescription}>
            {setting?.settings?.email}
            からのメールが受信できるよう、受信設定を行っていただきますようお願いいたします。
          </Typography>
          <Typography className={classes.registerDescription}>
            ※一部のメールクライアントの場合、迷惑メールフォルダに振り分けられている場合がございます。
          </Typography>
          <Typography className={classes.registerDescription}>
            迷惑メールフォルダにメールが届いていないかご確認ください。
          </Typography>
        </Box>

        <Box
          mb={10}
          paddingBottom={8}
          paddingLeft={window.innerWidth <= 700 ? 0 : 3}
        >
          <Box
            display="flex"
            className={clsx(classes.spViewEmail)}
            sx={{ justifyContent: 'flex-start' }}
          >
            <Typography className={classes.registerLabel}>
              ご登録メールアドレス
            </Typography>
            <Typography className={classes.email}>
              {auth?.infoSendForgot.email}
            </Typography>
          </Box>

          <Box mt={3}>
            <OtpInput
              containerStyle={clsx(classes.otpInputContainer)}
              value={otp}
              onChange={handleChange}
              numInputs={4}
              isInputNum
              inputStyle={clsx(classes.otpInputStyle)}
            />
          </Box>

          <Box mt={1} display="flex" justifyContent="center">
            <Typography style={{ fontSize: 14, color: 'red' }}>
              ※半角英数字でご入力ください
            </Typography>
          </Box>

          <Box
            mt={3}
            pb={2}
            onClick={handleResendEmail}
            display="flex"
            justifyContent="center"
            className={clsx(
              disableResend ? classes.textResendDisable : classes.textResend,
              classes.registerBorderBottom
            )}
          >
            <Typography>認証コードを再送信する</Typography>
          </Box>

          <Box mt={8} display="flex" sx={{ justifyContent: 'center' }}>
            <Button
              onClick={handleSubmitCodeVerify}
              onMouseOver={() => setHover(true)}
              onMouseOut={() => setHover(false)}
              style={{
                backgroundColor: hover
                  ? setting?.settings?.theme_color1 + 'cc'
                  : setting?.settings?.theme_color1
              }}
              disabled={otp.length === 4 ? false : true}
              className={clsx(classes.registerButton)}
            >
              確認
            </Button>
          </Box>
        </Box>
        <PaymentDialog
          open={openDialog}
          onClose={setOpenDialog}
          title={titleDialog}
        />

        <LoaderLayer loading={auth?.isFetching} />
      </Container>
    </div>
  );
}

export default PasswordForgotOtpView;
