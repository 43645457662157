import clsx from 'clsx';
import moment from 'moment';
import _ from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Box,
  Container,
  makeStyles,
  Typography,
  Button,
  TextareaAutosize
} from '@material-ui/core';

import LoaderLayer from 'src/components/LoaderLayer';
import { settingSelector } from 'src/slices/settings';
import { authSelector } from 'src/slices/auth';
import CheckPaymentDialog from 'src/components/CheckPaymentDialog';
import { ROUTES } from 'src/constants';
import { getDomainTalent, getLoginToken } from 'src/utils/auth';

const useStyles = makeStyles((theme) => ({
  root: {
    justifyContent: 'center',
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down(700)]: {
      padding: '0 2px 0'
    }
  },
  container: {
    maxWidth: 1040,
    padding: theme.spacing(0, 8),
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(0, 2)
    }
  },
  borderBottom: {
    borderBottom: '1px solid #919090'
  },
  borderDotted: {
    borderBottom: '1px dotted #919090'
  },
  contentTitle: {
    color: '#000000',
    fontSize: '1.5rem',
    fontWeight: '600',
    [theme.breakpoints.down(600)]: {
      fontSize: '1rem',
      padding: '0 1.5rem'
    }
  },
  newContent: {
    margin: '3rem 0',
    display: 'flex',
    flexWrap: 'wrap',
    gap: 8,
    justifyContent: 'space-between',
    [theme.breakpoints.down(700)]: {
      margin: '1rem 0 2rem'
    }
  },
  wrapPagination: {
    margin: '40px 0'
  },
  boxWrap: {
    backgroundColor: '#fff',
    padding: '1.5rem',
    width: '100%',
    wordBreak: 'break-word',
    '& p': {
      lineHeight: 1.8
    },
    [theme.breakpoints.down(700)]: {
      wordBreak: 'normal',
      padding: '1rem'
    }
  },
  boxTitle: {
    fontSize: '1.4rem',
    fontWeight: '700',
    display: 'flex',
    justifyContent: 'center',
    margin: '1rem 0 2rem',
    [theme.breakpoints.down(700)]: {
      fontSize: '1rem',
      margin: '1rem 0'
    }
  },
  boxContent: {
    [theme.breakpoints.down(700)]: {
      fontSize: '0.9rem'
    }
  },
  wrapButton: {
    display: 'flex',
    justifyContent: 'end',
    margin: '3rem 0',
    [theme.breakpoints.down(700)]: {
      margin: '1rem 0'
    }
  },
  button: {
    padding: '12px 130px',
    borderRadius: 0,
    color: 'white',
    [theme.breakpoints.down(700)]: {
      padding: '10px 0',
      width: '100%'
    }
  },
  subTitle: {
    fontSize: '1.1rem',
    fontWeight: '700',
    [theme.breakpoints.down(700)]: {
      fontSize: '1rem'
    }
  },
  wrapInput: {
    color: 'black',
    backgroundColor: '#FFFFFF'
  },
  textWarning: {
    color: 'red'
  },
  textCenter: {
    textAlign: 'center'
  }
}));

function WriteLetterView() {
  const classes = useStyles();
  const navigate = useNavigate();
  const settings = useSelector(settingSelector);
  const { profile } = useSelector(authSelector);
  const { state } = useLocation();

  const [content, setContent] = useState('');
  const [openDialog, setOpenDialog] = useState(false);
  const [statusProfile, setStatusProfile] = useState('');

  const handleChange = (value) => {
    setContent(value);
  };

  useEffect(() => {
    const { members } = profile;
    const domainTalent = getDomainTalent();

    const myProfile = _.find(
      members,
      (member) => member.talent.domain.split('//')[1] === domainTalent
    );

    setStatusProfile(myProfile?.status);
    if (myProfile?.member_type === 'lifetime' || myProfile?.member_type === 'existing_fan') {
      return;
    } else if (
      myProfile?.role === 'talent' ||
      myProfile?.role === 'staff_view' ||
      myProfile?.role === 'staff_edit'
    ) {
      return;
    } else if (
      myProfile?.end_datetime >= moment(new Date()).format('YYYY-MM-DD') &&
      myProfile?.role === 'fan'
    ) {
      return;
    } else {
      setOpenDialog(true);
    }
  }, []);

  useMemo(() => {
    if (state !== null) {
      setContent(state?.content);
    }
  }, []);

  return (
    <div className={clsx(classes.root)}>
      {getLoginToken() && !openDialog && (
        <Container className={classes.container}>
          <Box>
            <Typography className={clsx(classes.contentTitle)}>
              LOVE LETTER
            </Typography>
          </Box>
          <Box className={clsx(classes.newContent)}>
            <Box className={clsx(classes.boxWrap)}>
              <Typography
                className={clsx(classes.boxTitle, classes.textCenter)}
              >
                {`${settings?.settings.name}さんへのラブレターについてお願い`}
              </Typography>
              <Typography className={clsx(classes.boxContent)}>
                {settings?.settings.love_letter_rule}
              </Typography>
            </Box>
          </Box>

          {statusProfile !== 'frozen' && (
            <Box>
              <Box mt={8} mb={2}>
                <Typography className={clsx(classes.subTitle)}>
                  {`${settings?.settings.name}さんに手紙を書く（400文字以内）`}
                </Typography>
              </Box>

              <Box>
                <TextareaAutosize
                  value={content}
                  maxLength={400}
                  onChange={(event) => handleChange(event.target.value)}
                  type="text"
                  required
                  style={{
                    maxWidth: '100%',
                    width: '100%',
                    padding: '0.5rem',
                    fontSize: '1rem'
                  }}
                  minRows={15}
                />
              </Box>

              <Box mt={1}>
                <Typography className={clsx(classes.textWarning)}>
                  ※ご使用いただけない一部絵文字を入力されると送信エラーや文字化けが発生する場合がございます。
                </Typography>
              </Box>

              <Box>
                <Typography>{`のこり ${400 - content.length} 文字`}</Typography>
              </Box>

              <Box className={clsx(classes.wrapButton)}>
                <Button
                  onClick={() =>
                    navigate('/loveletter/send', {
                      state: {
                        content: content
                      }
                    })
                  }
                  disabled={content.length === 0 ? true : false}
                  className={clsx(classes.button)}
                  style={{ backgroundColor: settings?.settings.theme_color2 }}
                >
                  入力内容確認
                </Button>
              </Box>
            </Box>
          )}
        </Container>
      )}
      <LoaderLayer loading={settings?.loading} />
      {getLoginToken() && openDialog && (
        <CheckPaymentDialog
          open={openDialog}
          onClose={setOpenDialog}
          pathName={ROUTES.LOVE_LETTER}
        />
      )}
    </div>
  );
}

export default WriteLetterView;
