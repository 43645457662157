import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import {
  Box,
  Container,
  Typography,
  makeStyles,
  Button,
  Link
} from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { ROUTES, ROUTES_AUTHEN } from 'src/constants';
import LoaderLayer from 'src/components/LoaderLayer';
import { productSelector } from 'src/slices/product';
import { getProducts, payment } from 'src/services/productService';
import banking from 'src/assets/images/banking/banking.png';
import { authSelector } from 'src/slices/auth';
import PaymentDialog from 'src/components/PaymentDialog';
import { checkNameTalent, getLoginToken } from 'src/utils/auth';
import { settingSelector } from 'src/slices/settings';
const useStyles = makeStyles((theme) => ({
  root: {
    justifyContent: 'center',
    display: 'flex',
    flexDirection: 'column',
    padding: '100px 5px 0'
  },
  registerBorderBottom: {
    borderBottom: '1px solid #919090'
  },
  wrapTitle: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    [theme.breakpoints.down(700)]: {
      flexDirection: 'column'
    }
  },
  registerTitle: {
    fontWeight: 'bold',
    fontSize: 22,
    marginBottom: 5,
    [theme.breakpoints.down('700')]: {
      fontSize: 18
    }
  },
  registerTitleText: {
    fontWeight: 'bold',
    fontSize: 20,
    [theme.breakpoints.down(700)]: {
      fontSize: '1rem'
    }
  },
  wrapTitleRight: {
    display: 'flex',
    flexDirection: 'row',
    '& p': {
      fontSize: 16,
      opacity: 0.3
    },
    [theme.breakpoints.down(700)]: {
      '& p': {
        fontSize: '0.7rem',
        marginTop: 10
      }
    }
  },
  registrationTitleActive: {
    color: '#000000',
    borderBottom: '2px solid #5D7BAB',
    opacity: '1 !important',
    marginRight: 20,
    [theme.breakpoints.down(700)]: {
      marginRight: 10
    }
  },
  titleRightText: {
    marginRight: 20,
    [theme.breakpoints.down(700)]: {
      marginRight: 10
    }
  },
  displayFlex: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down(700)]: {
      flexDirection: 'column',
      alignItems: 'start',
      display: 'block'
    }
  },
  wrapButton: {
    display: 'flex',
    justifyContent: 'center',
    margin: '0 0 6rem',
    [theme.breakpoints.down(700)]: {
      flexDirection: 'column-reverse',
      margin: '2rem 0'
    }
  },
  buttonBack: {
    backgroundColor: '#A7A7A7',
    color: '#fff !important',
    borderRadius: 0,
    padding: '11px 100px',
    marginRight: '2rem',
    '&:hover': {
      backgroundColor: '#5b5b5b'
    },
    [theme.breakpoints.down(700)]: {
      padding: '8px 0',
      fontSize: 16,
      width: '100%'
    }
  },
  buttonEdit: {
    backgroundColor: 'black',
    color: '#fff !important',
    borderRadius: 0,
    padding: '11px 70px',
    fontSize: 18,
    '&:hover': {
      backgroundColor: '#1175ae'
    },
    [theme.breakpoints.down(700)]: {
      padding: '8px 0',
      fontSize: 16,
      width: '100%',
      marginBottom: '1rem'
    }
  },
  textColor: {
    color: '#FF0000',
    [theme.breakpoints.down(700)]: {
      fontSize: '0.88rem'
    }
  },
  imageAtm: {
    margin: '4rem 0',
    [theme.breakpoints.down(700)]: {
      width: '-webkit-fill-available'
    }
  },
  linkTo: {
    color: 'black',
    '&:hover': {
      textDecoration: 'none'
    }
  },
  boxWrap: {
    textAlign: 'center',
    border: '1px solid',
    marginTop: '1rem',
    '& p': {
      padding: '0.5rem 1rem'
    }
  }
}));
function MyPagePaymentBanking() {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { talentName } = checkNameTalent();
  const product = useSelector(productSelector);
  const { profile } = useSelector(authSelector);
  const { settings } = useSelector(settingSelector);

  const [hover, setHover] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [titleDialog, setTitleDialog] = useState('');

  useEffect(() => {
    dispatch(getProducts('subscription'));
  }, []);
  const submitPaymentATM = async () => {
    const data = {
      telegram_kind: '060',
      product_id: product?.products[0].id,
      customer_name: profile?.first_furigana,
      customer_family_name: profile?.family_furigana
    };
    const { payload } = await dispatch(payment(data));
    if (payload?.msg === 'Success') {
      navigate(ROUTES_AUTHEN.PAYMENT_BANKING_FINISH, {
        state: {
          ...payload?.data
        }
      });
    } else {
      setTitleDialog(payload?.msg);
      setOpenDialog(true);
    }
  };
  return (
    <div className={classes.root}>
      {getLoginToken() && (
        <Container maxWidth="md">
          <Box mb={2} paddingBottom={window.innerWidth <= 700 ? 4 : 8}>
            <Box className={classes.wrapTitle}>
              <Typography className={classes.registerTitleText}>
                決済方法の確認
              </Typography>
              <Box className={classes.wrapTitleRight}>
                <Typography className={classes.titleRightText}>
                  決済方法選択
                </Typography>
                <Typography className={clsx(classes.registrationTitleActive)}>
                  選択内容確認
                </Typography>
                <Typography className={classes.titleRightText}>
                  決済方法確認
                </Typography>
                <Typography className={classes.titleRightText}>
                  年会費決済
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{ justifyContent: 'center' }}
              mb={5}
              mt={8}
              display="flex"
              alignItems="center"
            >
              <Typography className={clsx(classes.registerTitle)}>
                銀行ネット決済を選択されました。
              </Typography>
            </Box>
            <Box>
              <Typography>利用できる金融機関をご確認ください。</Typography>
              <Typography>
                決済手続き中にネットワークが切断されると、正常に処理ができない場合がございます。
                <br />
                移動中やネットワークが不安定な場所では手続きをされないようにお願いいたします。
              </Typography>
            </Box>
            <Box mt={3}>
              <Typography>
                銀行ネット決済は全国の次の銀行でご利用いただけます。
              </Typography>
            </Box>
            <Box display="flex" justifyContent="center">
              <Box
                className={clsx(classes.imageAtm)}
                component="img"
                src={banking}
                alt="FRAU INTERNATIONAL"
              />
            </Box>
            <Box>
              <Typography>
                １）申し込みボタンを押すとペイジェントの金融機関選択画面へ移行します。
              </Typography>
              <Typography>
                ２）選択した金融機関のインターネットバンキングのログイン画面へ移行します。
              </Typography>
              <Typography>
                ３）ログイン後、金融機関の画面に従って手続きを行って頂きます。
              </Typography>
              <Typography>
                ４）銀行での手続きが完了しましたら
                {/* {window.location.host ===
                process.env.REACT_APP_TALENT_ZO_IN_SUNG_DOMAIN
                  ? 'チョ・インソン'
                  : window.location.host ===
                    process.env.REACT_APP_TALENT_KIM_NAN_GIL_DOMAIN
                  ? 'キム・ナムギル'
                  : window.location.host ===
                    process.env.REACT_APP_TALENT_LEE_JONG_SUK_DOMAIN
                  ? 'イ・ジョンソク'
                  : window.location.host ===
                    process.env.REACT_APP_TALENT_LEE_SEUNG_GI_DOMAIN
                  ? 'イ・スンギ'
                  : window.location.host ===
                    process.env.REACT_APP_TALENT_SONG_JOON_KI_DOMAIN
                  ? 'ソン・ジュンギ'
                  : window.location.host ===
                    process.env.REACT_APP_TALENT_KIM_YOUNG_DEA_DOMAIN
                  ? 'キム・ヨンデ'
                  : window.location.host ===
                    process.env.REACT_APP_TALENT_YOO_SEUNG_HO_DOMAIN
                  ? 'ユ・スンホ'
                  : window.location.host ===
                    process.env.REACT_APP_TALENT_LEE_MIN_KI_DOMAIN
                  ? 'イ・ミンギ'
                  : 'チャン・グンソク'}{' '} */}
                当ファンクラブのウェブサイトへ戻り、手続きが完了します。
              </Typography>
              <Typography>
                ※提携金融機関の画面操作については、各金融機関によって手続画面が異なりますので、
                <br />
                各金融機関のホームページ等でご確認ください。
              </Typography>
            </Box>
            <Link
              href="https://www.paygent.co.jp/payment_service/way_to_pay/Internet_banking"
              target="_blank"
              className={clsx(classes.linkTo)}
            >
              <Box mt={2} display="flex" style={{ cursor: 'pointer' }}>
                <ArrowForwardIosIcon sx={{ width: '0.8em' }} />
                <Typography>詳細を見る</Typography>
              </Box>
            </Link>

            <Box mb={2} mt={2} display="flex" alignItems="center">
              <Typography>
                ※申し込み完了後、期限内にお支払いください。
                <br />
                ※ご入金完了後はお客様のご都合によるキャンセルはお取り扱いいたしません。
                <br />
                申し込むボタンを押される前に必ず、お支払い金額と注意事項を十分にご確認ください。
              </Typography>
            </Box>

            <Box display="flex" justifyContent="center">
              <Box className={clsx(classes.boxWrap)}>
                <Typography>
                  {talentName} ジャパン オフィシャル ファンクラブ
                </Typography>
                <Typography>金額：6,600円/年（税込）</Typography>
                <Box
                  onClick={() => {
                    navigate(ROUTES.ABOUT_PAYMENT + '#another');
                  }}
                  style={{ cursor: 'pointer', textDecoration: 'underline' }}
                >
                  <Typography>
                    支払い時期・方法/サービス提供時期/解約について
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box className={clsx(classes.wrapButton)}>
            <Button
              onClick={() => navigate(ROUTES_AUTHEN.EDIT_PROFILE)}
              className={clsx(classes.buttonBack)}
            >
              登録情報を変更
            </Button>
            <Button
              onClick={submitPaymentATM}
              onMouseOver={() => setHover(true)}
              onMouseOut={() => setHover(false)}
              style={{
                backgroundColor: hover
                  ? settings?.theme_color1 + 'cc'
                  : settings?.theme_color1
              }}
              className={clsx(classes.buttonEdit)}
            >
              銀行ネット決済を申し込む
            </Button>
          </Box>
        </Container>
      )}
      <LoaderLayer loading={product?.loading} />
      <PaymentDialog
        open={openDialog}
        onClose={setOpenDialog}
        title={titleDialog}
      />
    </div>
  );
}
export default MyPagePaymentBanking;
