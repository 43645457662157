import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import moment from 'moment';
import {
  makeStyles,
  Container,
  Grid,
  Card,
  CardContent,
  CardMedia,
  Divider,
  Button
} from '@material-ui/core';
import { ROUTES } from 'src/constants';
import { getLoginToken } from 'src/utils/auth';
import AuthDialog from 'src/components/AuthDialog';
import 'react-alice-carousel/lib/alice-carousel.css';
import Notice from '../notice/index.js';
import ReactPlayer from 'react-player';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(6)
  },
  container: {
    maxWidth: 1920,
    padding: theme.spacing(0, 8),
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(0, 2)
    }
  },
  gridContainer: {
    textAlign: '-webkit-center',
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(1)
    },
    '& > div > div': {
      maxWidth: '100%',
      height: 'auto !important',
      width: 'auto !important',
      maxHeight: '100%',
      aspectRatio: 16/9
    },
    '@media(max-width: 960px)': {
      padding: 0
    }
  },
  card: {
    maxWidth: 1040,
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto',
    color: '#000',
    boxShadow: 'none',
    borderRadius: 0,
    backgroundColor: 'rgb(255,255,255, 50%)',
    '@media(max-width: 960px)': {
      backgroundColor: 'inherit'
    }
  },
  content: {
    '@media(max-width: 960px)': {
      padding: '16px 0px'
    }
  },
  media: {
    width: 250,
    margin: 'auto',
    [theme.breakpoints.down(700)]: {
      width: 220
    }
  },
  divider: {
    margin: '10px auto',
    width: '40px',
    border: '1px solid'
  },
  image: {
    maxWidth: '100%',
    height: '60vh',
    width: 'auto',
    maxHeight: '100%',
    aspectRatio: 16 / 9
  },
  wrapButton: {
    textAlign: 'end',
    marginTop: -20,
    [theme.breakpoints.down(700)]: {
      textAlign: 'center'
    }
  },
  buttonViewMore: {
    position: 'relative',
    color: '#000',
    fontSize: '17px',
    borderRadius: 0,
    padding: '8px 0',
    '&:hover': {
      color: '#000'
    },
    '&:after': {
      content: '""',
      position: 'absolute',
      bottom: 0,
      left: 0,
      width: '100%',
      height: '3px',
      background: '#000',
      transition: 'all .3s',
      transform: 'scale(0, 1)',
      transformOrigin: 'left top'
    },
    '&:hover': {
      '&:after': {
        transform: 'scale(1, 1)'
      }
    },
    '& span': {
      fontFamily: 'futura-pt !important'
    }
  }
}));

function Layout1({ className, setting, layout }) {
  const classes = useStyles();
  const navigate = useNavigate();
  const [openDialog, setOpenDialog] = useState(false);
  const id = layout?.items[1]?.content.id;

  const handleVideoDetail = () => {
    if (!getLoginToken()) {
      setOpenDialog(true);
    } else {
      navigate(ROUTES.VIDEO + '/' + id);
    }
  };

  return (
    <div className={clsx(classes.root, className)}>
      <Container
        className={classes.container}
        style={{ backgroundColor: layout?.background_color }}
      >
        <Card className={classes.card}>
          <img
            className={classes.media}
            src={`${layout?.title}?v=${moment().toDate().getTime()}`}
          />
          <Divider
            className={classes.divider}
            style={{ color: setting.theme_color2 }}
          />
          <CardContent className={classes.content}>
            <Grid container spacing={3} className={classes.gridContainer}>
              <Grid item xs={12} md={12}>
                {layout?.items[1]?.type === 'notice' ? (
                  <Notice setting={setting} />
                ) : layout?.items[1]?.type === 'image' ? (
                  <CardMedia
                    className={classes.image}
                    image={`${layout?.items[1]?.content}?v=${moment()
                      .toDate()
                      .getTime()}`}
                  />
                ) : layout?.items[1]?.type === 'video' ? (
                  <CardMedia
                    className={classes.image}
                    image={`${layout?.items[1]?.content.thumbnail}?v=${moment()
                      .toDate()
                      .getTime()}`}
                    onClick={handleVideoDetail}
                  />
                ) : (
                  <ReactPlayer
                    url={layout?.items[1]?.content.url}
                    playing={false}
                    controls={true}
                    className={classes.iframe}
                    config={{
                      youtube: {
                        playerVars: { modestbranding: 1, controls: 1 }
                      }
                    }}
                  />
                )}
              </Grid>
              {layout?.items[1]?.type === 'youtube' && (
                <Grid item xs={12} md={12} className={classes.wrapButton}>
                  <Button
                    className={classes.buttonViewMore}
                    onClick={() => navigate(ROUTES.YOUTUBE)}
                  >
                    VIEW MORE
                  </Button>
                </Grid>
              )}
              {layout?.items[1]?.type === 'notice' && (
                <Grid item xs={12} md={12} className={classes.wrapButton}>
                  <Button
                    className={classes.buttonViewMore}
                    onClick={() => navigate(ROUTES.NOTICE)}
                  >
                    VIEW MORE
                  </Button>
                </Grid>
              )}
            </Grid>
          </CardContent>
        </Card>
      </Container>
      <AuthDialog
        open={openDialog}
        onClose={setOpenDialog}
        path={ROUTES.HOMEVIEW}
      />
    </div>
  );
}

Layout1.propTypes = {
  className: PropTypes.string,
  items: PropTypes.array,
  isPause: PropTypes.bool
};

export default Layout1;
